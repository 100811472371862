import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import moment from "moment";
import { FeatherIcon } from "component/shared/feather-icon";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { urls } from "routes/urls";
import { dateTimeFormatter } from "shared/date-formatter";
import { styles } from "./style";
import { Task } from "model/task";
import { RootState } from "redux/store";
import { getAgentById, getAgents, getLeadById, getUser } from "redux/selector";
import { connect } from "react-redux";
import { updateTask } from "redux/slice/tasks";
import { Dispatch, bindActionCreators } from "redux";
import { TaskStatus } from "type/task-status";
import { Dialog } from "component/shared/dialog";
import { TaskType } from "type/task-type";
import { agentTasksDev } from "shared/env";
import { AgentTag } from "component/shared/agent-tag";

const mapStateToProps = (state: RootState, ownProps: any) => {
	const { leadId, agentId } = ownProps.task;
	return {
		lead: getLeadById(state, leadId),
		agent: getAgentById(state, agentId),
		user: getUser(state),
		agents: getAgents(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateTask
		},
		dispatch
	);

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapDispatchToProps>,
		ReturnType<typeof mapStateToProps> {
	task: Task;
	onClick: Function;
}

interface State {
	showDeleteTaskDialog: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			showDeleteTaskDialog: false
		};
	}

	private toggleCheckBox = () => {
		const { task, updateTask } = this.props;
		const status = task.status.active ? TaskStatus.COMPLETE : TaskStatus.ACTIVE;

		updateTask({ task: {
			...task,
			status
		}});
	};

	private togglePriority = () => {
		const { task, updateTask } = this.props;

		updateTask({ task: {
			...task,
			priority: !task.priority
		}});
	};

	private deleteTask = () => {
		const { task, updateTask } = this.props;
		updateTask({ task: {
			...task,
			status: TaskStatus.DELETED
		}});
	};

	renderConfirmationDialog = () => {
		return (
			<Dialog
				open={this.state.showDeleteTaskDialog}
				onClose={() => this.setState({ showDeleteTaskDialog: false })}
			>
				<Mui.DialogTitle>Delete Task</Mui.DialogTitle>
				<Mui.DialogContent>
					<p>Are you sure you want to delete this task?</p>
				</Mui.DialogContent>
				<Mui.DialogActions>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.deleteTask();
							this.setState({ showDeleteTaskDialog: false });
						}}
					>
						Yes
					</Mui.Button>
					<Mui.Button
						variant="outlined"
						onClick={() => this.setState({ showDeleteTaskDialog: false })}
					>
						No
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	};

	public render() {
		const { classes, task, lead, agent, onClick, user, agents } = this.props;
		const { title, description, type, startOn, endOn } = task;
		const checked = !task.status.active;
		const checkedStyle = { textDecoration: checked ? "line-through" : "none" };
		const dateText = type === TaskType.APPOINTMENT
				? `Starts ${dateTimeFormatter(startOn)}`
				: `Due ${dateTimeFormatter(endOn)}`;
		const disabled = !user.admin && task.creatorId !== user.agentId;
		const assigner = agents.find(agent => agent.id === task.creatorId);
		const ownsTask = task.creatorId === user.agentId;

		return (
			<>
				{this.renderConfirmationDialog()}
				<Mui.Card square>
					<Mui.CardContent className={classes.cardContent}>
						<Mui.Checkbox
							onChange={() => this.toggleCheckBox()}
							checked={checked}
							className={classes.checkbox}
						/>
						<Mui.CardActionArea
							onClick={() => onClick()}
							className={classes.cardActionArea}
						>
							<Mui.Grid
								container
								item
								direction="column"
								justifyContent="space-between"
								spacing={1}
							>
								<Mui.Grid item xs zeroMinWidth>
									<div className={classes.flexRow}>
										<Mui.Typography noWrap style={checkedStyle} className={classes.title}>
											{title}
										</Mui.Typography>
										{lead && (
											<Mui.Typography className={classes.flexRow} style={checkedStyle}>
													<span className={classes.forText}>for</span>
													<RouterLinkWrapper className={classes.leadLink} style={{ whiteSpace: "nowrap"}} to={urls.lead(lead)}>
														{lead.label}
													</RouterLinkWrapper>
											</Mui.Typography>
										)}
									</div>
								</Mui.Grid>
								{description && (
									<Mui.Grid item xs zeroMinWidth>
										<Mui.Typography
											noWrap
											className={classes.description}
											style={checkedStyle}
										>
											{description}
										</Mui.Typography>
									</Mui.Grid>
								)}
								<Mui.Grid item alignItems="center">
									<Mui.Grid container direction="row" alignItems="center" justifyContent="space-between">
										<Mui.Grid item>
											<Mui.Grid container direction="row">
												<Mui.Grid item>
													{type && (
														<Mui.Chip
														label={type.name}
														style={{ backgroundColor: type.backgroundColor }}
														className={classes.typeChip}
														/>
														)}
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Typography
														className={classes.date}
														style={{ color: endOn < moment() ? "#f00" : "#000" }}
														>
														{dateText}
													</Mui.Typography>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
										{agent && agentTasksDev && (
											<Mui.Grid item>
												{user.admin ? (
													task.agentId === user.agentId ? (
														<AgentTag 
															prefix={"Assigned to"}
															agent={!ownsTask ? user.agent : assigner}
															nameSub="You"
															preposition={!ownsTask ? "by" : undefined}
															assigner={!ownsTask ? assigner : undefined}
														/>
													) : (
														<AgentTag
															prefix="Assigned to"
															agent={agent}
														/>
													)
												) : (
													!ownsTask && task.agentId === user.agentId && (
														<AgentTag
															prefix="Assigned by"
															agent={assigner}
															clickable={false}
														/>
													)
												)}
											</Mui.Grid>
										)}
									</Mui.Grid>									
								</Mui.Grid>
							</Mui.Grid>
						</Mui.CardActionArea>
						<div className={classes.priorityAndTrashContainer}>
							<Mui.IconButton onClick={() => this.togglePriority()} disabled={disabled}>
								<FeatherIcon>
									<Icons.Flag color={task.priority ? "#f00" : "#777"} />
								</FeatherIcon>
							</Mui.IconButton>
							<Mui.Tooltip title="Delete task">
								<Mui.IconButton
									className="delete"
									onClick={() => this.setState({ showDeleteTaskDialog: true })}
									disabled={disabled}
								>
									<FeatherIcon>
										<Icons.Trash />
									</FeatherIcon>
								</Mui.IconButton>
							</Mui.Tooltip>
						</div>
					</Mui.CardContent>
				</Mui.Card>
			</>
		);
	}
}

export const TaskCard = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
