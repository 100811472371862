import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { arrayToById } from "redux/normalize";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { AggregateStatsResource } from "api/resource/aggregate-stats";

const initialState: AggregateStatsState = {
	byId: {},
};

export interface AggregateStatsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: AggregateStatsResource;
	};
};

const fetchAggregateStats = createAsyncThunk("aggregateStats/fetchAggregateStats", async (payload, thunkAPI) => {
	thunkAPI.dispatch(aggregateStatsLoading());
	const result = api.getAggregateStats();
	result
		.then(aggregateStats => thunkAPI.dispatch(aggregateStatsLoaded(aggregateStats)))
		.catch(error => thunkAPI.dispatch(aggregateStatsFailed(error)));
	return result;
});

export const aggregateStatsSlice = createSlice({
	  name: "aggregateStats",
	  initialState,
	  reducers: {
		aggregateStatsLoading: (state) => {
			state.loading = true;
		},
		aggregateStatsLoaded: (state, action: PayloadAction<AggregateStatsResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		aggregateStatsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
            return state;
        }
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});
const { aggregateStatsLoading, aggregateStatsLoaded, aggregateStatsFailed } = aggregateStatsSlice.actions;
export { fetchAggregateStats };