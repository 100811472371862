import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import * as yup from "yup";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { isEmail } from "shared/validate";

interface Item {
	id?: number | string;
	label: string;
	value: string;
}

interface OwnProps {
	items: Item[];
	includedItems?: Item[];
	includedItemsLabel: string;
	reset: boolean;
	onUpdate: (selected: Item[], reset?: boolean) => void;
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles>


interface State {
	input: string;
	selected: Item[];
	emailValidation?: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			input: "",
			selected: [],
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.reset !== this.props.reset) {
			if (this.props.reset) {
				this.setState({selected: []}, () => {
					this.props.onUpdate(this.state.selected, false)
				});
			}
		}
	}

	private get options() {
		const { items } = this.props;
		const { input } = this.state;
		const options = [...items];

		if (input.trim()) { 
			const schema = yup.string().email();
			const valid = schema.isValidSync(input);
			if (valid) {
				const exists = options.some(option => option.value === input);
				if (!exists) {
					options.unshift({
						value: input,
						label: `Create "${input}"`,
					});
				}
			}
		}
		return options;
	}
	
	private select(item: Item[]) {
		const { selected } = this.state;
		
		this.setState({
			selected: [...selected, ...item],
		}, () => {
			this.props.onUpdate(this.state.selected)
		});
	}

	private unselect(item: Item) {
		const selected = [...this.state.selected];
		const index = item.id 
			? selected.findIndex(value => value.id === item.id)
			: selected.findIndex(value => value.value === item.value);

		if (index !== -1) {
			selected.splice(index, 1);
			this.setState({
				selected
			}, () => this.props.onUpdate(this.state.selected));
		}
	}

	private selectMultipleEmails = (emailsString: string) => {
		let items: Item[] = [];
		const invalidEmails: string[] = [];
		const emails = emailsString.split(",").filter(value => value);
		emails.forEach((value) => {
			const email = value.trim();
			const optionItem = {
				label: email,
				value: email,
			};
			if (!isEmail(email)) {
				invalidEmails.push(email);
			} else {
				items.push(optionItem);
			}
		});
		if (items.length > 0) {
			this.select(items);
		}
		if (invalidEmails.length > 0) {
			const invalidEmailsString = invalidEmails.join(", ");
			this.setState({ emailValidation: `${invalidEmails.length} email address${invalidEmails.length > 1 ? "es" : ""} 
				in your separated list ${invalidEmails.length > 1 ? "were" : "was"} not formatted correctly. (${invalidEmailsString})` });
		}
	};
	
	private selectSingleEmail = (email: string) => {
		if (!isEmail(email)) {
			this.setState({ emailValidation: "Invalid email address" });
		} else {
			const result = {
				label: email,
				value: email,
			};
			this.select([result]);
		}
	};

	public render() {
		const { classes, includedItems, includedItemsLabel} = this.props;
		const { input, selected } = this.state;
		return (
			<Mui.Grid container direction="column" spacing={2} >
				<Mui.Grid item xs={12}>
					<CustomAutocomplete
						options={this.options}
						optionLabelExtractor={(option) => {
							if (typeof option === "string") {
								return option;
							}
							return option.label || " ";
						}}						
						fullWidth
						margin="dense"
						placeholder="Select Leads or Add a New Email"
						textInputHeight={false}
						error={!!this.state.emailValidation}
						helperText={this.state.emailValidation}
						onInputChange={(event, value, reason) => {
							if (!this.state.input) {
								this.setState({emailValidation: undefined})
							}
							if (reason === "reset") {
								value = "";
							}
							this.setState({
								input: value
							})
						}}
						onChange={(event, option) => {
							this.setState({ emailValidation: undefined });
							if (option && typeof option === "string") {
								if (option.includes(",")) {
									this.selectMultipleEmails(option);
								} else {
									this.selectSingleEmail(option);
								}
							} else {
								const item = option as Item;
								if (item) {
									const result = {
										id: item.id,
										label: item.label,
										value: item.value,
									};
									this.select([result]);
								}
							}
						}}
						inputValue={input}
					/>
				</Mui.Grid>
				{selected.length > 0 && (
					<Mui.Grid className={classes.tags} item>
						<Mui.Grid container spacing={2}>
							{selected.map((item, index) => {
								const included = includedItems?.some(value => value.id === item.id); 
								return (
									<Mui.Grid key={index} item>
										<Mui.Chip
											label={!!included ? item.value + ` (${includedItemsLabel})` : item.value + " (Add)"}
											onDelete={(event) => {
												this.unselect(item);
											}}
										/>
									</Mui.Grid>)
								})
							}
						</Mui.Grid>
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}
}

export const FindSelect = Mui.withStyles(styles)(
	(Component)
);