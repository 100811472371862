import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { styles } from './style';
import { Agent } from "model/agent";
import { Select } from "component/shared/select";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import { Avatar } from "component/shared/avatar";
import { RootState, getPayload } from "redux/store";
import { updateAgent } from "redux/slice/agents"
import { SaveButton } from "component/shared/save-button";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
	onClose: () => void;
}

interface State {
	photoSource: "photoUrl" | "uploadPhoto",
	photoUrl: string,
	openSnackbar: boolean,
	snackbarMessage: string,
	snackbarSeverity: string,
	submitted: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		loading: state.agents.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			updateAgent,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			photoSource: this.props.agent.photoUrl ? "photoUrl" : "uploadPhoto",
			photoUrl: this.props.agent.photoUrl ? this.props.agent.photoUrl : "",
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
			submitted: false,
		};
	}

	private async save() {
		const { updateAgent, agent, onClose } = this.props;
		const { photoUrl } = this.state;
		const agentResource = getPayload( await updateAgent( { agent: {
			...agent,
			photoUrl,
		}}));
		if (agentResource) {
			onClose();
		}
	}

	render() {
		const { photoSource, photoUrl, submitted } = this.state;
		const { loading, classes } = this.props;
		return (
			<Mui.Grid container direction="column">
				<Mui.Grid item>
					<Select
						fullWidth
						labelExtractor={(type) => type.label}
						margin="dense"
						onChange={(value) => {
							
							this.setState({ photoSource: value });
						}}
						options={[
							{
								id: "photoUrl",
								label: "Photo URL",
							},
							{
								id: "uploadPhoto",
								label: "Upload Photo",
							},
						]}
						optionRenderer={(type) => (
							<Mui.ListItemText primary={type.label} key={type.id} />
						)}
						label="Agent Photo"
						value={photoSource}
						valueExtractor={(type) => type.id}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item>
					{photoSource === "photoUrl" && (
						<Mui.TextField
							fullWidth
							inputProps={{ maxLength: 100 }}
							label="Enter Photo URL"
							margin="dense"
							onChange={(event) => {
								this.setState({ photoUrl: event.target.value });
							}}
							placeholder="Enter Photo URL (include http://)"
							value={photoUrl}
							variant="outlined"
						/>
					)}
					{photoSource === "uploadPhoto" && (
						<PhotoUploadDialogButton
							multiple={false}
							callback={(uploads) => {
								const upload = uploads.find((upload) => upload.url);
								if (upload && upload.url) {										
									this.setState({
										photoSource: "photoUrl",
										photoUrl: upload.url,
										openSnackbar: true,
										snackbarMessage: "Upload Successful",
										snackbarSeverity: "success",
									});
								} else {
									this.setState({
										openSnackbar: true,
										snackbarMessage: "Error",
										snackbarSeverity: "error",
									});
								}
							}}
							label="Upload Agent Photo"
							style={{ marginBottom: 4, marginTop: 8 }}
							resourceType="image"
						/>
					)}
				</Mui.Grid>
				{photoUrl && (
					<Mui.Grid item>
						<Avatar
							diameter={80}
							phrase=""
							src={photoUrl}
						/>
					</Mui.Grid>
				)}
				<Mui.Grid item>
					<Mui.Grid container spacing={2} className={classes.saveContainer}>
						<Mui.Grid item>
							<SaveButton 
								loading={submitted && !!loading}
								error={submitted ? "Please fix the highlighted errors" : undefined} 
								label={{
									primary: "Save",
									inProgress: "Saving...",
									completed: "Saved",
								}}
								snackBar={true}
								onClick={() => {
									this.setState({submitted: true});
									this.save();
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button 
								variant="contained"
								onClick={() => this.props.onClose() }
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Photo = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));