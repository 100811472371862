import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { LeadImport } from "model/lead-import";
import * as Icons from "react-feather";
import { getLeads } from "redux/selector";
import { getMarkets } from "redux/selector";
import { getCampaigns } from "redux/selector";
import { getAgentsWithLeads } from "redux/selector";
import { styles } from "./style";
import { validate, isEmail } from "shared/validate";
import moment from "moment";
import { LeadTagType } from "type/lead-tag";
import { LeadPipelineType } from "type/lead-pipeline";
import { ImportError } from "type/import-error";
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { LeadStatus } from "type/lead-status";
import { Table } from "component/shared/table";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

interface OwnProps {
	leadImports: LeadImport[];
	onLoad?: (valid: boolean, warn: boolean) => void;
	validate?: boolean;
}

interface Props extends 
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}

interface TableData extends LeadImport {
	validationErrors?: {
		email?: string;
		birthday?: string;
		contactType?: string;
		transactionAnniversary?: string;
		piplineType?: string;
	},
	validationWarnings?: {
		email?: string;
		agentId?: string;
		marketIds?: string;
		campaignIds?: string;
	}
}

type Filter = "all" | "error" | "warn";

interface State {
	page: number;
	pageSize: number;
	filter: Filter;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const leads = getLeads(state);
	const markets = getMarkets(state);
	const campaigns = getCampaigns(state);
	const agents = getAgentsWithLeads(state);
	return {
		leads,
		markets,
		campaigns,
		agents,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);	
		this.state = {
			page: 0,
			pageSize: 250,
			filter: "all",
		}
	}

	public componentDidMount(): void {
		const { onLoad, validate } = this.props;
		const tableData = this.validatedTableData();
		const valid = !tableData.some((data) => data.validationErrors);
		const warn = tableData.some((data) => data.validationWarnings);
		if (validate && onLoad && tableData.length > 0) {
			onLoad(valid, warn);
		}
	}

	private commaDelimit(array: string[]) {
		let result = "";
		array.forEach((item, index) => {
			result = result + `${item}${(index === (array.length - 1)) ? "" : ", "}`
		});
		return result;
	}

	private validatedTableData() {
		const { markets, leads, campaigns, agents, leadImports, validate: shouldValidate} = this.props;
		const result = leadImports.map((leadImport, index) => {
			const validationWarnings = validate({
				email: () => {
					if (shouldValidate) {
						const isDuplicate = leadImports.filter((result) => leadImport.email === result.email).length > 1;
						if (isEmail(leadImport.email) && isDuplicate) {
							return "Email appears in this data multiple times. Only the first record will be imported.";
						}
					}
					
				},
				agentId: () => {
					if (shouldValidate && leadImport.agentId) {
						if (!agents.some((agent) => agent.id === leadImport.agentId)) {
							return `The lead will be imported but not assigned to ${leadImport.agentId}. The AgentID does not exist.`;
						}
					}
				},
				marketIds: () => {
					if (shouldValidate && leadImport.marketIds) {
						const ids = leadImport.marketIds.map((id) => {
							if (!markets.some((market) => market.id === id)) {
								return id.toString();
							}
							return null;
						}).filter(Boolean) as string[];
						if (ids.length > 0) {
							return `The lead can be imported without subscribing to markets. Could not find MarkedIDs for: ${this.commaDelimit(ids)}.`;
						}
					}
				},
				campaignIds: () => {
					if (shouldValidate && leadImport.campaignIds) {
						const ids = leadImport.campaignIds.map((id) => {
							if (!campaigns.some((campaign) => campaign.id === id)) {
								return id.toString();
							}
							return null;
						}).filter(Boolean) as string[];
						if (ids.length > 0) {
							return `The lead can be imported without subscribing to campaigns. Could not find CampaignIDs for: ${this.commaDelimit(ids)}.`;
						}
					}
				},
			});
			const validationErrors = validate({
				email: () => {
					if (shouldValidate) {
						if (!leadImport.email) {
							return "Email is Required.";
						}
						if (!isEmail(leadImport.email)) {
							return "Email is not formatted correctly.";
						}
						if (leads.some((lead) => {
							if (lead.status === LeadStatus.ACTIVE) {
								return lead.email === leadImport.email;
							}
							return false;
						})) {
							return `Email is already in use.`;
						}
					}
				},
				birthday: () => {
					if (shouldValidate && leadImport.birthday) {
						if (!moment(leadImport.birthday, "MM/DD/YYYY", true).isValid()) {
							return `Invalid Date: Use MM/DD/YYYY.`;
						}
					}
				},
				contactType: () => {
					if (shouldValidate && leadImport.contactType) {
						if (!LeadTagType.getLeadTypeById(leadImport.contactType)) {
							return `No such contact type. Supported options are:${LeadTagType.BUYER.label}, ${LeadTagType.SELLER.label} or ${LeadTagType.RENTER.label} are suported`;
						}
					}
				},
				piplineType: () => {
					if (shouldValidate && leadImport.pipelineType) {
						const typeLabels = LeadPipelineType.values().map((value) => value.label);
						if (!LeadPipelineType.getByLabel(leadImport.pipelineType)) {
							return `No such pipeline. Supported options are: ${this.commaDelimit(typeLabels)}.`;
						}
					}
				},
				transactionAnniversary: () => {
					if (shouldValidate && leadImport.transactionAnniversary) {
						if (!moment(leadImport.transactionAnniversary, "MM/DD/YYYY", true).isValid()) {
							return `Invalid Date: Use MM/DD/YYYY.`;
						}
					}
				},
			});
			const tableData: TableData = {
				...leadImport,
				validationWarnings,
				validationErrors
			}
			return tableData;
		})
		
		return result;
	}

	private renderCellContent(field?: string | string[] | number | number[], error?: string, warn?: boolean) {
		const { classes } = this.props;
		let content: React.ReactNode;
		if (field && Array.isArray(field) && field.length > 0) {
			content = this.commaDelimit(field.map((item) => item.toString()));
		}
		if (field && !Array.isArray(field)) {
			content = <>{field.toString()}</>;
		}
		return (
			<>
				{error ? (
					<Mui.Tooltip 
						title={error}
						classes={{tooltip: classes.tooltip}}
					>
						<Mui.Card elevation={0} className={warn ? classes.warningBackground : classes.errorBackground}>
							<Mui.Typography className={classes.address}>
								{content}
							</Mui.Typography>
						</Mui.Card>
					</Mui.Tooltip> 
				) : (
						<Mui.Typography className={classes.address}>
							{content}
						</Mui.Typography>
					)
				}
			</>
		)
	}

	private renderAddressContent(address: string = "", city: string = "", state: string = "", zip: string = "") {
		const { classes } = this.props;
		return (
			<>
				<Mui.Typography className={classes.address}>
					{address}
				</Mui.Typography>
				<Mui.Typography className={classes.address}>
					{
						`${city + " " + state + " " + zip }`
					}
				</Mui.Typography>
			</>
		)
	}
	
	public render() {
		const { classes, validate } = this.props;
		const tableData = this.validatedTableData();
		const errors = tableData.some((data) => data.validationErrors);
		const warn = tableData.some((data) => data.validationWarnings);
		const filteredTableData = () => {
			if (this.state.filter === "warn") {
				return tableData.filter((data) => data.validationWarnings);
			}
			if (this.state.filter === "error") {
				return tableData.filter((data) => data.validationErrors);
			}
			return tableData;
		}
		return (
			<Mui.Grid container spacing={1} direction="column">
				{(errors || warn) && (
					<Mui.Grid item >
						<ToggleButtonGroup
							value={this.state.filter}
							exclusive
							size="small"
							aria-label="text alignment"
						>
							<ToggleButton
								value="all" 
								aria-label="left aligned" 
								onClick={() => this.setState({filter: "all"})} 
							>
								<Icons.FileText />
								<span style={{marginLeft: 5}}>Show All</span>
							</ToggleButton>
							<ToggleButton 
								value="warn" 
								aria-label="centered" 
								onClick={() => this.setState({filter: "warn"})}
							>
								<Icons.AlertTriangle color="#ff9800" />
								<span style={{marginLeft: 5}}>Show Warnings</span>
							</ToggleButton>
							<ToggleButton 
								value="error" 
								aria-label="right aligned" 
								onClick={() => this.setState({filter: "error"})}
							>
								<Icons.AlertCircle color="#f00" size={20} /> 
								<span style={{marginLeft: 5}}>Show Errors</span>
							</ToggleButton>
						</ToggleButtonGroup>
					</Mui.Grid>
				)}
				<Mui.Grid item>
					<Mui.Typography variant="subtitle1">
						*Address, City, State, and Zip have been combined for this preview. They are imported as individual fields.
					</Mui.Typography>
				</Mui.Grid>
				{tableData.length > 0 && 
					<Mui.Grid item>
						<Table
							items={filteredTableData()}
							columns={[
								{
									id: "validate",
									value: (row) => {
										return (
											<>
												{validate && 
													<>
														{(row.error) ? (
															<>
																{(row.error === ImportError.import) && (
																	<Icons.AlertCircle color="#f00" />
																)}
																{row.error === ImportError.action && (
																	<Mui.Tooltip 
																		classes={{tooltip: classes.tooltip}} 
																		title="This lead was imported but an action failed. Check the lead's profile for tags, subscriptions, etc."
																	>
																		<Icons.AlertTriangle color="#ff9800" />
																	</Mui.Tooltip>
																)}
																{row.error === ImportError.duplicate && (
																	<Mui.Tooltip 
																		classes={{tooltip: classes.tooltip}} 
																		title="Duplicate record was skipped."
																	>
																		<PeopleAltOutlinedIcon style={{color: "#ff9800"}} />
																	</Mui.Tooltip>
																)}
															</>
														) : (
															<>
																{(row.validationErrors) && (
																	<Icons.AlertCircle color="#f00" />
																)}
																{!row.validationErrors && row.validationWarnings && (
																	<Icons.AlertTriangle color="#ff9800" />
																)}
																{!row.validationWarnings && !row.validationErrors && (
																	<Icons.CheckCircle color="#4caf50" />
																)}
															</>
														)}
													</>
												}
											</>
										);
									},
								},
								{
									id: "email",
									title: "Email",
									value: (row) => (
											<>
												{row.validationErrors?.email ? (this.renderCellContent(row.email, row.validationErrors?.email)) : 
													(this.renderCellContent(row.email, row.validationWarnings?.email, true))}
											</>
										)
								},
								{
									id: "name",
									title: "Name",
									value: (row) => <>{this.renderCellContent(row.name)}</>,
								},
								{
									id: "phone",
									title: "Phone",
									value: (row) => <>{this.renderCellContent(row.phone)}</>,
								},
								{
									id: "address",
									title: "Address",
									width: 200,
									value: (row) => <>{this.renderAddressContent(row.address, row.city, row.state, row.zip)}</>,
								},
								{
									id: "birthday",
									title: "Birthday",
									value: (row) => <>{this.renderCellContent(row.birthday, row.validationErrors?.birthday)}</>,
								},
								{
									id: "contactType",
									title: "Type",
									value: (row) => <>{this.renderCellContent(row.contactType, row.validationErrors?.contactType)}</>,
								},
								{
									id: "agentId",
									title: "AgentID",
									value: (row) => <>{this.renderCellContent(row.agentId, row.validationWarnings?.agentId, true)}</>,
								},
								{
									id: "transactionAnniversary",
									title: "Anniversary",
									value: (row) => <>{this.renderCellContent(row.transactionAnniversary, row.validationErrors?.transactionAnniversary)}</>,
								},
								{
									id: "pipelineType",
									title: "Pipeline",
									value: (row) => <>{this.renderCellContent(row.pipelineType, row.validationErrors?.piplineType)}</>,
								},
								{
									id: "textMessageOptIn",
									title: "Texting",
									value: (row) => <>{this.renderCellContent(row.textMessageOptIn ? "True" : undefined)}</>,
								},
								{
									id: "tags",
									title: "Tags",
									value: (row) => <>{this.renderCellContent(row.tags)}</>,
								},
								{
									id: "campaignIds",
									title: "CampaignIDs",
									value: (row) => <>{this.renderCellContent(row.campaignIds, row.validationWarnings?.campaignIds, true)}</>,
								},
								{
									id: "marketIds",
									title: "MarketIDs",
									value: (row) => <>{this.renderCellContent(row.marketIds, row.validationWarnings?.marketIds, true)}</>,
								},
								{
									id: "note",
									title: "Note",
									value: (row) => <>{this.renderCellContent(row.note)}</>,
								},
								{
									id: "leadSource",
									title: "Source",
									value: (row) => <>{this.renderCellContent(row.leadSource)}</>,
								},
							]}
							pageSize={this.state.pageSize}
							onPageSizeChange={(pageSize) => {
								this.setState({pageSize: pageSize});
							}}
							page={this.state.page}
							onPageChange={(page) => {
								this.setState({page: page});
							}}
						/>
					</Mui.Grid>
				}
			</Mui.Grid>			
		);
	}
}

export const TableDisplay = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));