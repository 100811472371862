import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { LeadNote } from "model/lead-note";
import { LeadNoteType } from "type/lead-note-type";
import { RootState } from "redux/store";
import { updateLead } from "redux/slice/leads";
import { updateLeadNote, createLeadNote } from "redux/slice/lead-notes";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { validate } from "shared/yup";
import * as yup from "yup";
import { Select } from "component/shared/select";
import { Dialog } from "component/shared/dialog";

const mapStateToProps = (state: RootState) => {
	return {
		leadNoteTypes: LeadNoteType.values()
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
	updateLeadNote,
	createLeadNote
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	open: boolean;
	onClose: Function;
	leadNote?: LeadNote;
	lead: Lead;
	isExistingNote?: boolean;
}

interface State {
	submitted: boolean;
	typeId: number;
	text: string;
	deleted: boolean;
}

const initialState: State = {
	submitted: false,
	typeId: 1,
	text: "",
	deleted: false,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
			if (this.props.lead && this.props.leadNote) {
				const { type, text, deleted } = this.props.leadNote;
				const typeId = type && type.id;
				this.setState({
					typeId: typeId || 0,
					text: text || "",
					deleted: deleted || false
				});
			}
		}
	}

	private validationSchema = {
		text: yup.string().required("Some text is required"),
		typeId: yup.number().test("typeId", "Type is required", (value) => {
			return !!value;
		})
	};

	public handleSave = () => {
		const { onClose, leadNote, lead, isExistingNote, updateLead, updateLeadNote, createLeadNote } = this.props;
		const { text, typeId, deleted } = this.state;
		const leadId = lead.id;
		const type = (typeId) ? LeadNoteType.getById(typeId) : LeadNoteType.getById(1);
		const errors = validate(this.validationSchema, this.state);
		if (!errors) {
			// Delete from Lead object, store as LeadNote object instead
			if (isExistingNote) {
				let updatedLead = { ...lead };
				updatedLead.note = "";
				updateLead({lead: updatedLead});
			}
			if (type) {
				if (leadNote && leadNote.id) {
					updateLeadNote({ leadNote: {
						...leadNote,
						type,
						text,
						leadId,
						deleted
					}});
				} else {
					createLeadNote({ leadNote: {
						type,
						text,
						leadId,
						deleted
					}});
				}
			}
			onClose();
		}
	}

	public render() {
		const { leadNoteTypes, open, onClose, classes, leadNote } = this.props;
		const { submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					{leadNote && "Edit Item"}
					{!leadNote && "Add Item"}
				</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid item>
							<Mui.Grid container item xs={5}>
								<Select
									placeholder="Select a Type"
									variant="outlined"
									margin="dense"
									fullWidth
									value={this.state.typeId}
									options={Array.from(leadNoteTypes)}
									valueExtractor={(type) => type.id}
									labelExtractor={(type) => type.label}
									optionRenderer={(type) => (
										<Mui.ListItemText primary={type.label} />
									)}
									onChange={(value) => this.setState({ typeId: value })}
								/>
							</Mui.Grid>
						</Mui.Grid>
						{submitted && errors && errors.typeId && (
							<Mui.Grid item>
								<Mui.Typography color="error">
									{errors.typeId}
								</Mui.Typography>
							</Mui.Grid>
						)}
						<Mui.Grid item>
							<Mui.Grid container item>
								<Mui.TextField
									multiline
									minRows={8}
									fullWidth
									variant="outlined"
									placeholder="Add history/notes about this lead here."
									onChange={event => this.setState({ text: event.target.value })}
									defaultValue={this.state.text}
								/>
							</Mui.Grid>
						</Mui.Grid>
						{submitted && errors && errors.text && (
							<Mui.Grid item>
								<Mui.Typography color="error">
									{errors.text}
								</Mui.Typography>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						variant="contained"
						color="secondary"
						onClick={() => {
							this.setState({submitted: true}, 
								() => this.handleSave()
							);
						}}
					>
						Save
					</Mui.Button>
					<Mui.Button
						variant="outlined"
						onClick={() => onClose()}>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}
}

export const LeadNoteDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
