import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { savePage } from "redux/slice/pages";
import { styles } from "./style";
import { Page } from "model/page";
import { validate } from "shared/yup";
import * as yup from "yup";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import MuiAlert from "@material-ui/lab/Alert";
import { CodeBlock } from "component/shared/code-block";
import { getUser } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			savePage,
		},
		dispatch);

}

type Props = OwnProps
	& ReturnType<typeof mapStateToProps>
	& ReturnType<typeof mapDispatchToProps>
	& Mui.WithStyles<typeof styles>
;

interface OwnProps {
	page: Page;
}

interface State {
	edit: boolean;
	path: string;
	title: string;
	head: string;
	snackBarMessage: string | undefined;
	enabled: boolean;
	popoverAncherEl?: React.RefObject<HTMLInputElement> | null;
	pagePath: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			edit: false,
			path: this.props.page.path,
			title: this.props.page.title,
			head: this.props.page.head,
			snackBarMessage: "",
			enabled: this.props.page.enabled,
			pagePath: "/homesearch",
		};
	}

	private titleInput = React.createRef<HTMLInputElement>();
	private headInputRef = React.createRef<HTMLInputElement>();
	private titleId = "title";
	private headId = "head";
	private pageVariableTooltip = `${"Type \"{\" in the field for available options "}`;

	private handlePath(value: string) {
		if (value && !value.startsWith("/")) {
			value = `/${value}`
		}
		return value;
	}

	private validationSchema = {
		defaultPagePath: yup
			.string()
			.test("defaultPagePath", "Cannot have two or more \"/\"s in a row", (value) => {
				if (value && value.includes("//")) {
					return false;
				}
				return true;
			})
	};

	public save() {
		const updatedPage: Page = {
			...this.props.page,
			path: this.state.path,
			title: this.state.title,
			head: this.state.head,
			enabled: this.state.enabled,
		};
		this.props.savePage({page: updatedPage});
		this.openSnackBar("Settings Saved");
	}

	private openSnackBar(message: string) {
		this.setState({snackBarMessage: undefined}, () => {
			setTimeout(() => {
				this.setState({snackBarMessage: message});	
			}, 250);
			
		});
	}

	private insert(variable: string, ref: React.RefObject<HTMLInputElement>) {
		const value = ref.current ? ref.current.value : "";
		this.setState({
			popoverAncherEl: null,
		});
		const position = ref.current && ref.current.selectionStart ? ref.current.selectionStart : 0;
		const left = value.substring(0, position);
		const right = value.substring(position);
		const inserted = left + variable + "}" + right;
		if (ref.current && ref.current.id === this.headId) {
			this.setState({head: inserted}, () => {
				this.refocus(position + variable.length, ref);
			});
		}
		if (ref.current && ref.current.id === this.titleId) {
			this.setState({title: inserted}, () => {
				this.refocus(position + variable.length, ref);	
			});
		}
	}

	private refocus(insertPosition: number, ref: React.RefObject<HTMLInputElement>) {
		if (ref.current) {
			ref.current.focus();
			ref.current.setSelectionRange(insertPosition, insertPosition);
		}
		
	}

	private pathName() {
		const { path } = this.state;
		return path.split("?")[0];
	}

	public render() {
		const { classes, user, page } = this.props;
		const errors = validate(this.validationSchema, this.state);
		const pageVariables = page.type.variables || [];
		const { 
			edit, 
			enabled, 
			head, 
			path, 
			title, 
			popoverAncherEl, 
			snackBarMessage,
		} = this.state;
		return (
			<>
				<Mui.Grid container direction="column" spacing={2} style={{ display: "table" }} >
					<Mui.Grid item>
						<Mui.Typography variant="h4">
							Page Location
						</Mui.Typography>
						{!edit && (
							<Mui.Typography>
								{`Create a page at: ${user.baseUrl + this.pathName()}`}
							</Mui.Typography>
						)}
						{edit && (
							<>
								{page.type.queryVars && 
									<Mui.Typography style={{paddingTop: 5}}>
										Caution: This page requires variables to load the IDX content.
									</Mui.Typography>
								}
								<Mui.Grid container direction="row" spacing={1} alignItems="center" style={{paddingTop: 5}} >
									<Mui.Grid item>
										<Mui.Chip label={user.baseUrl} />
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.TextField 
											value={path} 
											variant="outlined" 
											size="small"
											onChange={(event) => this.setState({path: this.handlePath(event.currentTarget.value)})}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													this.setState({edit: false});
												}
											}}
											className={classes.input}
											error={!!errors}
											helperText={errors && errors.defaultPagePath}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</>
						)}
						<Mui.Button 
							size="small" 
							variant="text"
							onClick={() => this.setState({edit: !edit})} id="edit-url"
							color="secondary"
							startIcon={<Icons.Settings size={16} />}
						>
							{!edit ? "Advanced URL Settings" : "Close"}
						</Mui.Button>
						{edit && page.type.queryVars && (path !== page.type.queryVars) && (
							<Mui.Button 
								size="small" 
								variant="text"
								onClick={() => {
									if (window.confirm("Reset the URL Path back to factory default?\nYou will still need to save for the changes to take effect.")) {
										this.setState({path: page.type.queryVars ? page.type.queryVars : "/"})
									}
								}} 
								id="edit-url"
								color="secondary"
								startIcon={<Icons.RefreshCw size={16} />}
							>
								Reset
							</Mui.Button>
						)}
						
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography variant="h4">
							SEO Settings
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Tooltip placement="bottom" arrow title={pageVariables.length > 0 ? this.pageVariableTooltip : ""}>
							<Mui.TextField
								id={this.titleId}
								inputRef={this.titleInput}
								value={title} 
								label="Page Title"
								variant="outlined" 
								size="small"
								fullWidth
								onChange={(event) => this.setState({
									title: event.currentTarget.value,
								})}
								onKeyUp={(event) => {
									if (event.key === "{" && (pageVariables.length > 0)) {
										this.setState({popoverAncherEl: this.titleInput});
									}
								}}
								className={classes.input}
								error={!!errors}
								helperText={errors && errors.defaultPagePath}
							/> 
						</Mui.Tooltip>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Tooltip placement="bottom" arrow title={pageVariables.length > 0 ? this.pageVariableTooltip : ""}>
							<Mui.TextField 
								id={this.headId}
								inputRef={this.headInputRef}
								value={head} 
								label="Page Head"
								variant="outlined"
								fullWidth
								multiline
								onChange={(event) => this.setState({
									head: event.currentTarget.value,
								})}
								onKeyUp={(event) => {
									if (event.key === "{" && pageVariables.length > 0) {
										this.setState({popoverAncherEl: this.headInputRef});
									}
								}}
								className={classes.input}
								error={!!errors}
								helperText={errors && errors.defaultPagePath}
							/>	
						</Mui.Tooltip>
						
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography variant="h4">
							Embed Code
						</Mui.Typography>
						<Mui.Typography>
							Paste this code into the body of the page
						</Mui.Typography>
						<CodeBlock 
							code={kestrelEmbedCode.page(user.websitePlatform)}
							language={user.websitePlatform.textEmbedCode ? "text" : "html"}
							className={classes.codeBlock}
						/>
						<Mui.FormControlLabel
							control={
								<Mui.Checkbox checked={enabled} onChange={() => this.setState({enabled: !enabled})} name="enabled" />
							}
							label="I've completed the setup for this page"
						/>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.save()}
						>
							Save
						</Mui.Button>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Popover
					open={!!popoverAncherEl}
					anchorEl={popoverAncherEl ? popoverAncherEl.current : undefined}
					onClose={() => this.setState({popoverAncherEl: null})}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
				>
					<Mui.List component="nav" className={classes.popoverList}>
						{pageVariables.map((pageVariable, index) => {
							return (
								<Mui.ListItem 
									key={index} 
									button 
									onClick={() => {
										if (popoverAncherEl && popoverAncherEl.current) {
											this.insert(pageVariable.id, popoverAncherEl)
										}
									}}
								>
									<Mui.ListItemText primary={pageVariable.label} />
								</Mui.ListItem>
							)
						})}
					</Mui.List>
				</Mui.Popover>
				<Mui.Snackbar
					open={!!snackBarMessage}
					message={snackBarMessage}
					autoHideDuration={6000}
					onClose={() => this.setState({ snackBarMessage: undefined })}
					action={
						<Mui.IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => this.setState({ snackBarMessage: undefined })}
						>
							<Icons.X fontSize="small" />
						</Mui.IconButton>
					}
				>
					<MuiAlert severity="info">
						<Mui.Typography>{snackBarMessage}</Mui.Typography>
					</MuiAlert>
				</Mui.Snackbar>
			</>
		);
	}
}

export const Details = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));
