import { createStyles } from "@material-ui/core";

export const styles = () => {
	return createStyles({
		bottomTab: {
			textAlign: "center", 
			cursor: "pointer"
		}
	});
};
