import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { styles } from "../../style";
import { Dialog } from "component/shared/dialog";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { getAllTagStrings, getTagsForLeads } from "redux/selector";
import { createLeadTag } from "redux/slice/lead-tags";
import * as yup from "yup";
import { validate } from "shared/yup";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		allTagStrings: getAllTagStrings(state),
		leadsTags: getTagsForLeads(state, ownProps.leadIds)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLeadTag,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	onClose: Function,
	leadIds: number[],
}

interface State {
	tags: string;
	input: string;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			tags: "", 
			input: "",
			submitted: false,
		};
	}

	private get options() {
		let { allTagStrings } = this.props;
		const { input } = this.state;
		
		let texts = allTagStrings.sort();
		const options = texts.map(text => ({
			value: text,
			label: text,
		}));
		if (input.trim()) {
			const exists = options.map(tag => tag.label.toLowerCase()).includes(input.toLowerCase());
			if (!exists) {
				options.unshift({
					value: input,
					label: `Create "${input}"`,
				});
			}
		}
		return options;
	}

	private validationSchema = {
		tags: yup
			.string()
			.required("Please select valid tag."),
	};

	private saveTagsForLeads() {
		const { leadsTags, leadIds, createLeadTag } = this.props;
		const { tags } = this.state;

		const errors = validate(this.validationSchema, this.state);

		if (!errors) {
			leadIds.forEach((id) => {
				if (tags && !leadsTags.find((tag) => ((tag.text.toLowerCase() === tags.toLowerCase()) && tag.leadId === id))) {
					createLeadTag({ leadTag: {
						leadId: id,
						text: tags,
					}});
				}
			});
			this.onClose();
		} else {
			this.setState({ submitted: true });
		}
	}

	private onClose() {
		this.props.onClose();
		this.setState({ tags: "", input: "", submitted: false });
	}

	render() {
		const { classes } = this.props;
		const { tags, input, submitted } = this.state;
		const { options } = this;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Dialog
				open={true}
				onClose={() => {
					this.onClose()
				}}
				fullWidth={true}
				maxWidth="xs" 
			>
				<Mui.DialogTitle>Add Tags</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Select tag to apply to the selected records.</Mui.DialogContentText>
						</Mui.Grid>
						{!!tags.length &&
							<Mui.Grid item xs={12}>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item>
										<Mui.Chip 
											label={tags}
											className={classes.tagChip}
											onDelete={(event) => {
												this.setState({ tags: "" });
											}}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}
						<Mui.Grid item xs={12}>
							<CustomAutocomplete
								options={options}
								textInputHeight={true}
								label="Enter Tag"
								optionLabelExtractor={(option) => option.label}
								fullWidth
								margin="dense"
								placeholder="Enter tag here"
								onInputChange={(event, value, reason) => {
									if (reason === "reset") {
										this.setState({
											input: ""
										});
									} else {
										this.setState({
											input: value,
											tags: "", 
										});
									}
								}}
								onChange={(event, option) => {
									if (option && option.value) {
										this.setState({ 
											tags: option.value, 
											input: "",
										});
									}
								}}
								inputValue={input}
								helperText={submitted && errors && errors.tags}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => {
							this.saveTagsForLeads()
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && tags.trim().length === 0}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => {
							this.onClose()
						}} 
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}
}

export const BulkAddTagsDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);