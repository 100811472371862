class Type {
	public static ADD_TAGS = new Type(1, "Add Tags", false);
	public static REMOVE_TAGS = new Type(2, "Remove Tags", false);
	public static UPDATE_STAGE = new Type(3, "Update Pipeline Stage", false);
	public static ADD_TO_CAMPAIGN = new Type(4, "Add to Campaign", false);
	public static EXPORT_TO_CSV = new Type(6, "Export to CSV", false);
	public static ASSIGN_TO_AGENT = new Type(7, "Assign to agent", true);
	public static ADD_TO_MARKET = new Type (8, "Add to Market", false);

	private constructor(
		public readonly id: number,
		public readonly label: string,
		public readonly needBrokerPermission: boolean,
	) {
	}

	public get addTags() {
		return this === Type.ADD_TAGS;
	}

	public get removeTags() {
		return this === Type.REMOVE_TAGS;
	}

	public get updateStage() {
		return this === Type.UPDATE_STAGE;
	}

	public get addToCampaign() {
		return this === Type.ADD_TO_CAMPAIGN;
	}

	public get exportToCSV() {
		return this === Type.EXPORT_TO_CSV;
	}

	public get assignToAgent() {
		return this === Type.ASSIGN_TO_AGENT;
	}

	public get addToMarket() {
		return this === Type.ADD_TO_MARKET;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(label: string) {
		return this.values().find(value => value.label === label);
	}

}

export { Type as BulkActionTypes };
