import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { styles } from './styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { RoundRobinRoute } from "model/round-robin-route";
import { Avatar } from "component/shared/avatar";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	roundRobinRoute: RoundRobinRoute;
	position: number;
	onDelete: () => void;
}

interface State {
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators({
	}, dispatch);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { classes, position, roundRobinRoute, onDelete } = this.props;
		return (
			<Mui.Card>																			
				<Mui.CardContent className={classes.cardContent}>
					<Mui.Grid container direction="row" spacing={1} alignItems="center">
						<Mui.Grid item>
							<DragIndicatorIcon />
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Box display="flex" alignItems="center" flexDirection="row">
								<Avatar 
									phrase={roundRobinRoute.agent.label} 
									src={roundRobinRoute.agent.photoUrl} 
								/>
								<Mui.Typography className={classes.agentLabel} >
									{roundRobinRoute.agent.label}
								</Mui.Typography>
							</Mui.Box>
						</Mui.Grid>
						<Mui.Grid item className={classes.positionItem}>
							<Mui.Chip label={position} size="small" className={classes.position} />
						</Mui.Grid>
						<Mui.Grid item className={classes.deleteItem}>
							<Mui.Tooltip title="Remove Agent from Rotation">
								<Mui.IconButton
									onClick={() => window.confirm(`Remove ${roundRobinRoute.agent.label} from the rotation?`) && onDelete()}
									className="actions"
								>
									<FeatherIcon>
										<Icons.Trash />
									</FeatherIcon>
								</Mui.IconButton>
							</Mui.Tooltip>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardContent>
			</Mui.Card>
		);
	}
}

export const Card = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));