import { arrayToById, objectToById } from "redux/normalize";
import * as api from "api/api";
import { NewLeadImport } from "model/lead-import";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { LeadPipelineType } from "type/lead-pipeline";
import moment from "moment";
import { LeadImportResource } from "api/resource/lead-import";
import { loginReset } from "redux/slice/authentication";

export interface LeadImportsState {
    loading?: boolean;
    error?: string | null;
    byId: {
        [key: string]: LeadImportState;
    };
};

export interface LeadImportState extends LeadImportResource {};

const initialState: LeadImportsState = {
    byId: {},
    loading: false,
};

const saveLeadImport = createAsyncThunk("lead-import/saveLeadImport", async (payload: { leadImports: NewLeadImport[] }, thunkAPI) => {
    thunkAPI.dispatch(leadImportsLoading());
    const resources = payload.leadImports.map((leadImport) => {
        let leadPipelineTypeId: number | undefined;
        let birthday: string | undefined;
        let transactionAnniversary: string | undefined;
        if (leadImport.pipelineType) {
            leadPipelineTypeId = LeadPipelineType.getByLabel(leadImport.pipelineType)?.id;
        }
        if (leadImport.contactType) {
            leadImport.tags = [
                ...leadImport.tags ? leadImport.tags : [],
                leadImport.contactType,
            ]
        }
        if (leadImport.birthday) {
            birthday = moment(leadImport.birthday, "MM/DD/YYYY", true).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        if (leadImport.transactionAnniversary) {
            transactionAnniversary = moment(leadImport.transactionAnniversary, "MM/DD/YYYY", true).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        const leadImportResource: LeadImportResource = {
            id: leadImport.id,
            email: leadImport.email,
            name: leadImport.name,
            address: leadImport.address,
            state: leadImport.state,
            phone: leadImport.phone,
            city: leadImport.city,
            zip: leadImport.zip,
            birthday: birthday,
            agentId: leadImport.agentId,
            transactionAnniversary: transactionAnniversary,
            pipelineTypeId: leadPipelineTypeId,
            tags: leadImport.tags,
            note: leadImport.note, 
            textMessageOptIn: leadImport.textMessageOptIn,
            marketIds: leadImport.marketIds,
            campaignIds: leadImport.campaignIds,
            leadSource: leadImport.leadSource,
        };
        return leadImportResource;
    });
    const result = api.saveLeadImport(resources);
    result
        .then(leadImports => thunkAPI.dispatch(leadImportsLoaded(leadImports)))
        .catch(error => thunkAPI.dispatch(leadImportsFailed(error)));
    return result;
});

const reset = createAsyncThunk("lead-import/reset", async (payload, thunkAPI) => {
    thunkAPI.dispatch(leadImportsReset());
});

export const leadImportsSlice = createSlice({
    name: "leadImport",
    initialState,
    reducers: {
        leadImportsLoading: (state) => {
            state.loading = true;
        },
        leadImportsLoaded: (state, action: PayloadAction<any[]>) => {
            const byId = arrayToById(action.payload, "id");
            state.loading = false;
            state.error = null;
            state.byId = {
                ...state.byId,
                ...byId,
            };
        },
        leadImportLoaded: (state, action: PayloadAction<any>) => {
            const byId = objectToById(action.payload, "id");
            state.loading = false;
            state.error = null;
            state.byId = {
                ...state.byId,
                ...byId,
            };
        },
        leadImportsFailed: (state, action: PayloadAction<Error>) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        leadImportsReset: () => {
            const result = { ...initialState };
            return result;
        },
        default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
    }
});

export const {leadImportLoaded, leadImportsFailed, leadImportsLoaded, leadImportsLoading, leadImportsReset} = leadImportsSlice.actions;
export { saveLeadImport, reset };