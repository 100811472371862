import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { User } from "model/user";
import { UAParser } from "ua-parser-js";
import appStoreLogo from "image/app-store.png";
import googlePlayLogo from "image/google-play.png";
import { getMobileDialogDate } from "redux/selector";
import { resetMobileDialogDate } from "redux/slice/mobile-dialog-date";
import moment from "moment";
import * as Mui from "@material-ui/core";

const mapStateToProps = (state: RootState) => {
	return {
		mobileDialogDate: getMobileDialogDate(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	resetMobileDialogDate,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	user: User,
}

interface State {
	open: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			open: false,

		};
	}
	
	componentDidMount() {
		const { user, mobileDialogDate } = this.props;
		const appInfo = this.getAppInfo();

		if (user.permissions.mobileApp && appInfo.link) {
			if (!mobileDialogDate || (mobileDialogDate && mobileDialogDate.add(7, "days").isBefore(moment()))) {
				this.setState({ open: true });
			}
		}
	}

	private getAppInfo() {
		let link; 
		let image;
		const osName = new UAParser().getResult().os.name;
		if (osName === "iOS") {
			link = "https://apps.apple.com/us/app/optima-leads/id1448438377";
			image = appStoreLogo;
		} else if (osName === "Android") {
			link = "https://play.google.com/store/apps/details?id=com.ihomefinder.homebase";
			image = googlePlayLogo;
		} 
		return {link, image}
	}

	private onMobileDialogClose() {
		this.setState({open : false});
		this.props.resetMobileDialogDate(moment());
	}

    render() {
		const { open } = this.state;
		const {link, image} = this.getAppInfo();
		if (!link || !image) {
			return null;
		}
        return (
			<Mui.Dialog
				open={open}
			>
				<Mui.DialogContent>
					<Mui.Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
						<Mui.Grid item>
							<Mui.Box fontWeight="fontWeightBold" fontSize="h3.fontSize" textAlign="center">
								Work Your Leads From Anywhere
							</Mui.Box>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.DialogContentText align="center">Download the Optima Leads App</Mui.DialogContentText>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								onClick={() => this.onMobileDialogClose()}
								href={link}
								target="_blank"
							>
								<img 
									style={{maxWidth: "100%"}} 
									src={image} 
									alt="Click to view app in app store."
								/>
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions>
					<Mui.Button
						variant="outlined"
						onClick={() => this.onMobileDialogClose()}
						size="small"
					>
						Close
					</Mui.Button>
				</Mui.DialogActions>
			</Mui.Dialog>
		)
	}
}

export const MobileAppDialog = connect(mapStateToProps, mapDispatchToProps)(Component)