import { QuotaResource } from "api/resource/quota";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import * as api from "api/api";

export interface QuotaState {
	loading?: boolean;
	error?: string | null;
	quota?: QuotaResource;
};

const initialState: QuotaState = {};

const fetchQuota = createAsyncThunk("quota/fetchQuota", async (payload, thunkAPI) => {
	thunkAPI.dispatch(quotaLoading());
	const result = api.getQuota();
	result
		.then((quota) => thunkAPI.dispatch(quotaLoaded(quota)))
		.catch(error => thunkAPI.dispatch(quotaFailed(error)));
	return result;
});

export const quotaSlice = createSlice({
	name: "quota",
	initialState,
	reducers: {
		quotaLoading: (state) => {
			state.loading = true;
		},
		quotaLoaded: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = null;
			state.quota = action.payload;
		},
		quotaFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	}
});

export const { quotaLoading, quotaLoaded, quotaFailed } = quotaSlice.actions;
export { fetchQuota };