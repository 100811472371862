import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";

interface Props {
	open: boolean;
	onClick: () => void;
}

const Component: React.FC<Props> = ({ open, onClick }) => {
	return (
		<Dialog open={open} onClose={() => {}}>
			<DialogTitle>Inactivity Timeout</DialogTitle>
			<DialogContent>
				You've been inactive for 15 minutes. Do you want to continue?
			</DialogContent>
			<DialogActions>
				<Button onClick={onClick} color="primary">
					Yes, I'm still here
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export const SessionExpiredDialog = Component;
