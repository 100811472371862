import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getAgents } from "redux/selector";
import Fuse from 'fuse.js';
import * as Icon from "react-feather";
import { Avatar } from "../avatar";
import { Agent } from "model/agent";
import { AgentSearchSelectionType } from "type/agent-search-selection";
import { number } from "yup";

const mapStateToProps = (state: RootState) => {
	const agents = getAgents(state);
	return {
		agents,
		loading: state.agents.loading,
	};
};

interface State {
	searchResults: Agent[];
	anchorEl: any;
	searchQuery: string;
	agentSelected?: Agent;
	unassignedSelected?: boolean;
	allAgentsSelected?: boolean;
}

interface Props
	extends ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		showUnassiged: boolean,
		onSelectAgent: (selectedId: number) => void,
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			searchResults: this.props.agents,
			anchorEl: null,
			searchQuery: ""
		};
	}

	handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { agents } = this.props;
		const { searchResults } = this.state;

		if (searchResults.length === 0) {
			this.setState({ searchResults: agents });
		}

		this.setState({ anchorEl: event.currentTarget });
	};

	handleChange = (newValue: number) => {
		const { agents } = this.props;
		this.props.onSelectAgent(newValue);
		if (newValue === AgentSearchSelectionType.UNASSIGNED.id) {
			this.setState({ anchorEl: null, unassignedSelected: true, agentSelected: undefined });
		} else {
			const agent = agents.find(item => item.id === newValue);
			this.setState({ anchorEl: null, agentSelected: agent, unassignedSelected: false });
		}
	};

	handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { agents } = this.props;
		const searchQuery = e.target.value;

		if (!searchQuery) {
			this.setState({ searchResults: agents, searchQuery: "" });
		} else {
			const fuseOptions = {
				keys: ['name'],
				threshold: 0.3,
			};
			const fuse = new Fuse(agents, fuseOptions);
			const searchResults = fuse.search(searchQuery).map((result) => result.item);
			this.setState({ searchResults, searchQuery });
		}
	};

	render() {
		const { classes, agents, loading, showUnassiged } = this.props;
		const { searchResults, anchorEl, searchQuery, agentSelected, allAgentsSelected, unassignedSelected } = this.state;

		const highlightSearchQuery = (text: string, query: string) => {
			if (!query) return text;

			const regex = new RegExp(`(${escapeRegExp(query)})`, 'gi');
			return text.replace(regex, '<span style="color: #039BE5;">$1</span>');
		};

		const escapeRegExp = (string: string) => {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		};

		return (
			<Mui.Grid container
				className={classes.form}
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Mui.Grid item xs={12}>
					<Mui.Button
						variant="outlined"
						color="primary"
						onClick={this.handleClick}
						endIcon={anchorEl ? (<Icon.ChevronUp size={16} />) : (<Icon.ChevronDown size={16} />)}
						disabled={loading || !agents}
					>
						{agentSelected ? (
							<Mui.Grid container wrap="nowrap" alignItems="center" className={classes.nameContainer}>
								<Mui.Grid item>
									<Avatar phrase={agentSelected.label} src={agentSelected.photoUrl} />
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Typography noWrap className={classes.agentName}>
										{agentSelected.name}
									</Mui.Typography>
								</Mui.Grid>
							</Mui.Grid>
						) : (showUnassiged ? (unassignedSelected ? (<><Icon.User className={classes.usersIcon} size={36}/><Mui.Typography noWrap className={classes.agentName}>Unassigned</Mui.Typography></>) : (<><Icon.Users className={classes.usersIcon} size={36}/><Mui.Typography noWrap className={classes.agentName}>All Agents</Mui.Typography></>))
							 : (<><Icon.Users className={classes.usersIcon} size={36} /><Mui.Typography noWrap className={classes.agentName}>Select an Agent</Mui.Typography></>)
						)}
					</Mui.Button> 
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.Popover
						keepMounted
						anchorEl={anchorEl}
						open={!!anchorEl}
						onClose={() => this.setState({ anchorEl: null })}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						className={classes.popover}
					>
						<Mui.TextField
							id="agent-search"
							onChange={this.handleSearch}
							placeholder="Search Agents"
							color="secondary"
							InputProps={{
								startAdornment: (
									<Icon.Search className={classes.searchIcon} />
								),
								style: { color: 'black' },
							}}
							fullWidth
							className={classes.textField}
						/>
						{showUnassiged && (
							<Mui.MenuItem 
								value={0}
								onClick={() => this.handleChange(AgentSearchSelectionType.ALL_AGENTS.id)}
							>
								<Icon.Users className={classes.usersIcon} size={36}/>
								<Mui.Typography className={classes.agentName}>
								All Agents
								</Mui.Typography>
							</Mui.MenuItem>
						)}
						{searchResults.map((agent, i) => (
							<Mui.MenuItem
								key={i}
								value={agent.id}
								onClick={() => {
									this.handleChange(agent.id)
								}}
								selected={agent.id === agentSelected?.id}
							>
								<Avatar phrase={agent.label} src={agent.photoUrl} />
								<Mui.Typography className={classes.agentName}>
									<Mui.Grid dangerouslySetInnerHTML={{ __html: highlightSearchQuery(agent.name, searchQuery) }} />
								</Mui.Typography>
							</Mui.MenuItem>
						))}
						{showUnassiged && (
							<Mui.MenuItem 
								value={0}
								onClick={() => this.handleChange(AgentSearchSelectionType.UNASSIGNED.id)}
							>
								<Icon.Users className={classes.usersIcon} size={36}/>
								<Mui.Typography className={classes.agentName}>
								Unassigned
								</Mui.Typography>
							</Mui.MenuItem>
						)}
					</Mui.Popover>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const AgentSearch = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
