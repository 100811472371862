import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props extends Mui.WithStyles<typeof styles> {
	id: number;
	text?: string;
	onHeader?: boolean;
}

interface State {
	clipboard: string
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props)
		this.state = {
			clipboard: "",
		}
	}

	public render() {
		const { id, text, onHeader = false, classes } = this.props;
		const { clipboard } = this.state;

		return (
			<CopyToClipboard 
				text={`${id}`}
				onCopy={(id) => this.setState({clipboard: id})}
			>
				<Mui.Tooltip title={text && clipboard !== `${id}` ? `${text}` : "Copied!"}>
					<Mui.Button 
						className={!onHeader ? classes.button : classes.buttonOnHeader}
						variant="text"
					>
						{id}
					</Mui.Button>
				</Mui.Tooltip>
			</CopyToClipboard>
		);
	}
}

export const CopyID = Mui.withStyles(styles)(Component);
