import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Router from "react-router-dom";
import { DashboardLayout } from "component/layout/dashboard";
import { styles } from "../style";
import { getCampaignCampaignMessageById } from "redux/selector";
import { RootState, getPayload } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { PageProps } from "shared/page-props";
import Unlayer, { HtmlExport, Design } from "react-email-editor";
import { urls } from "routes/urls";
import { validate } from "shared/yup";
import * as yup from "yup";
import { updateCampaign } from "redux/slice/campaigns";
import { updateCampaignMessage } from "redux/slice/campaign-messages";
import { updateCampaignCampaignMessage } from "redux/slice/campaign-campaign-messages";
import { resetLeadsPageFilters } from "redux/slice/leads-page";
import { getCampaignById } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { CampaignMessageType } from "type/campaign-message";
import { unlayerOptions } from "shared/unlayer";

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
};

interface RouteParams {
	id: string;
}

interface OwnProps extends PageProps, Router.RouteComponentProps<RouteParams>, Mui.WithStyles<typeof styles> {}

interface State {
	subject: string;
	name: string;
	maxCharacters: boolean;
	submitted: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const id = getId(ownProps);
	const campaignCampaignMessage = getCampaignCampaignMessageById(state, id);
	const campaign = campaignCampaignMessage && getCampaignById(state, campaignCampaignMessage.campaignId );
	return {
		campaignCampaignMessage,
		campaign, 
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaign, 
	updateCampaignMessage,
	updateCampaignCampaignMessage,
	resetLeadsPageFilters,
}, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const MAX_CHARACTERS = 256;

class Component extends React.Component<Props, State> {

	private unlayer: Unlayer | null = null;

	public constructor(props: Props) {
		super(props);
		this.state = {
			subject: "",
			name: "",
			maxCharacters: false,
			submitted: false,
		};
	}

	private get data() {
		let campaignCampaignMessage = null;
		let campaignMessage = null;
		campaignCampaignMessage = this.props.campaignCampaignMessage;
		if (campaignCampaignMessage && campaignCampaignMessage.id && campaignCampaignMessage.campaignMessage) {
			campaignMessage = campaignCampaignMessage.campaignMessage;
		}
		return { campaignCampaignMessage, campaignMessage };
	}

	public componentDidMount() {
		this.updateFields();
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.campaignCampaignMessage !== this.props.campaignCampaignMessage) {
			if (!this.state.name && !this.state.subject) {
				this.updateFields();
			}
		}
	}

	private updateFields() {
		const { campaignMessage } = this.data;
		if (campaignMessage && campaignMessage.subject && campaignMessage.name) {
			this.setState({ 
				name: campaignMessage.name,
				subject: campaignMessage.subject})
		}
	}

	private validationSchema = {
		subject: yup
		.string()
		.required("Subject is required")
		.test(
			"check-max-subject-line-characters",
			"Subject line cannot exceed 256 characters.",
			(subject) => {
				let pass = true;
				if (subject && subject.length >= (MAX_CHARACTERS)) {
					pass = false;
				}
				return pass;
			}
		),
		name: yup
			.string()
			.required("Name is required")
	};

	private async save() {
		const { history, campaignCampaignMessage, updateCampaignMessage, updateCampaignCampaignMessage } = this.props;
		const unlayer = this.unlayer;
		let messageOrder: number = 0;
		const errors = validate(this.validationSchema, this.state);
		if (!errors && this.state.submitted) {
			if (campaignCampaignMessage && campaignCampaignMessage.messageOrder) {
				messageOrder = campaignCampaignMessage.messageOrder;
			}
			if (unlayer && campaignCampaignMessage) {
				const data = await new Promise<HtmlExport>(resolve => unlayer.exportHtml(resolve));
				let campaignMessage = this.data.campaignMessage;
				if (campaignMessage) {
					const processedHtml = this.addCustomCSS(data.html);
					campaignMessage = {
						...campaignMessage,
					};
					const savedCampaignMessage = getPayload(await updateCampaignMessage({
						campaignMessage: {
							...campaignMessage,
							name: this.state.name,
							subject: this.state.subject,
							htmlContent: processedHtml,
							jsonContent: JSON.stringify(data.design),
							messageType: CampaignMessageType.EMAIL,
							isInactiveYn: false,
						}
					}));
					getPayload(await updateCampaignCampaignMessage({
						campaignCampaignMessage: {
							...campaignCampaignMessage,
							campaignMessageId: savedCampaignMessage.id,
							messageOrder: messageOrder,
							campaignId: campaignCampaignMessage.campaignId,
							id: campaignCampaignMessage.id,
							dateCreated: campaignCampaignMessage.dateCreated,
						}
					}));
					history.goBack();
				}
			}
		}
	}
	
	private addCustomCSS(html: string): string {
		const style = `
			<style>
				body, table {
					max-width: 500px !important;
				}
			</style>
		`;
		return html.replace(/<\/head>/, `${style}</head>`);
	}
	

	private onLoad() {
		const interval = setInterval(() => {
			const { campaignMessage } = this.data;
			const { unlayer } = this;
			if (unlayer && campaignMessage && campaignMessage.jsonContent) {
				const design = JSON.parse(campaignMessage.jsonContent) as Design;
				unlayer.loadDesign(design);
				clearInterval(interval);
			}
		}, 500);
	}

	private handleCancel() {
		const {campaign, history } = this.props;
		if (campaign && window.confirm("Discard changes?")) {
			history.push(urls.campaign(campaign));
		}
	}

	private renderSubject() {
		const { classes, campaign } = this.props;
		const { subject, name, submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (				
			<Mui.Card>
				<Mui.CardContent>
					<Mui.Grid container direction="column" spacing={2}>
					<Mui.Grid item>
							<Mui.TextField
								required
								label="Name"
								placeholder="Enter name of message here"
								FormHelperTextProps={{
									className: classes.errorMessage,
								}}
								error={submitted && !!errors && !!errors.name}
								helperText={submitted && errors && errors.name}
								InputLabelProps={{ shrink: true }}
								value={name}
								onChange={event => {
									const input = event.target.value;
									this.setState({ name: input, maxCharacters: false });
								}}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.TextField
								required
								label="Subject Line"
								placeholder="Enter email subject line here"
								FormHelperTextProps={{
									className: classes.errorMessage,
								}}
								error={submitted && !!errors && !!errors.subject}
								helperText={submitted && errors && errors.subject}
								InputLabelProps={{ shrink: true }}
								value={subject}
								onChange={event => {
									const input = event.target.value;
									if (subject.length < MAX_CHARACTERS || input.length < subject.length) {
										this.setState({ subject: input, maxCharacters: false });
									} else {
										this.setState({ maxCharacters: true });
									}
									
								}}
								fullWidth
								margin="dense"
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardContent>
				<Mui.CardActions>
					<Mui.Grid container spacing={2} justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Grid container spacing={2}>
								<Mui.Grid item>
								<Mui.Button
									variant="contained"
									color="secondary"
									
									onClick={() => {
										this.setState({submitted: true}, 
											() => this.save()
										);
									}}
									className={classes.button}
								>
									Save
								</Mui.Button>
								</Mui.Grid>
								<Mui.Grid item>
									{campaign && 
										<Mui.Button
											variant="outlined"
											color="primary"
											onClick={() => this.handleCancel()}
										>
											Cancel
										</Mui.Button>
									}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						
					</Mui.Grid>
				</Mui.CardActions>
			</Mui.Card>
		);
	}

	public render() {
		const title = "Edit Message";	
		return (
			<DashboardLayout
				permitted={true}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Edit />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				{!this.props.campaignCampaignMessage ? (
					<>
						<LoadingIndicator />
					</>
				): (
						<Mui.Grid container direction="column" spacing={4}>
							<Mui.Grid item>
								{this.renderSubject()}
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Card>
									<Mui.CardContent>
										<Unlayer
											ref={unlayer => this.unlayer = unlayer}
											options={unlayerOptions}
											onReady={() => this.onLoad()}
										/>
									</Mui.CardContent>
								</Mui.Card>
							</Mui.Grid>													
						</Mui.Grid>		
					)
				}
			</DashboardLayout>		
		);
	}
}

export const EditCampaignMessagePage = Mui.withStyles(styles)(
	Router.withRouter(
		connect<StateProps, DispatchProps, OwnProps, RootState>(
			mapStateToProps,
			mapDispatchToProps
		)(Component)
	)
);