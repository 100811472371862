import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { CsvReader } from './csv-reader';
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NewLeadImport } from "model/lead-import";
import { saveLeadImport, reset } from "redux/slice/lead-import";
import { fetchLeads } from "redux/slice/leads";
import { fetchLeadTags } from "redux/slice/lead-tags"
import { getPayload, RootState } from "redux/store";
import { getLeadImports } from "redux/selector";
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableDisplay } from './table-display';
import MuiAlert from "@material-ui/lab/Alert";
import { styles } from "./style";
import { ImportError } from "type/import-error";
import moment from "moment";
import {CSVLink as CsvLink} from "react-csv";
import { LoadingIndicator } from "component/shared/loading-indicator";

interface OwnProps {}

interface Props extends 
OwnProps,
	PageProps, 
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
}


interface State {
	submitted: boolean;
	importPreview: NewLeadImport[];
	valid?: boolean;
	warning?: boolean;
	invalidHeaders?: {
		header: string;
		info: string;
	}[];
} 

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const importResult = getLeadImports(state);
	return {
		importResult,
		loading: state.leadImports.loading,
		error: state.leadImports.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			saveLeadImport,
			reset,
			fetchLeads,
			fetchLeadTags,
		},
		dispatch);
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			submitted: false,
			importPreview: [],
			// invalidHeaders: [],
		};
	}

	private leaveMessage = "Are you sure you want to leave the importer? Make sure your import has completed.";

	private async save() {
		const chunkSize = 25;
		this.setState({submitted: true});
		this.props.reset();
		for (let i = 0; i < this.state.importPreview.length; i += chunkSize) {
			let chunk = [...this.state.importPreview.slice(i, i + chunkSize)];
			await this.props.saveLeadImport({leadImports: chunk});
		}
		this.props.fetchLeads();
		this.props.fetchLeadTags();
		this.setState({submitted: false});
	}	

	private progress() {
		return (this.props.importResult.length - 0) * 100 / (this.state.importPreview.length - 0)
	}

	private inProgress() {
		return this.state.submitted && this.progress() < 100;
	}

	private clear() {
		this.setState({importPreview: []});
		this.props.reset();
	}

	private formattedDateString(dateString: string) {
		const dateRegex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2}|[0-9]{4})$/;
		if (dateRegex.test(dateString)) {
			let formattedDateString: string;
			const centuryLimit = parseInt(moment().format("YY")) + 7;
			const dateParts = dateString.split("/");
			const yearPadding = dateParts[2].length === 2 && parseInt(dateParts[2]) > centuryLimit ? 19 : 20;
			let month = dateParts[0].length === 1 ? `0${dateParts[0]}` : dateParts[0];
			let day = dateParts[1].length === 1 ? `0${dateParts[1]}` : dateParts[1];
			let year = dateParts[2].length === 2 ? `${yearPadding}${dateParts[2]}` : dateParts[2];
			formattedDateString = `${month}/${day}/${year}`;
			if (formattedDateString) {
				return formattedDateString;
			}
		}
		return dateString;
	}

	private renderCsvReader(buttonLabel?: string) {
		const { user } = this.props;
		return (
			<CsvReader 
				user={user}
				buttonLabel={buttonLabel}
				onUpload={(results, invalidHeaders) => {
				this.clear();
				this.setState({invalidHeaders: invalidHeaders})
				const leadImports: NewLeadImport[] = results.map((result) => {
					const leadImport: NewLeadImport = {
						id: Math.random().toString().split(".")[1],
						address: result.address,
						email: result.email,
						city: result.city,
						name: result.name,
						phone: result.phone,
						state: result.state,
						zip: result.zip,
						birthday: result.birthday ? this.formattedDateString(result.birthday) : undefined,
						contactType: result.type,
						agentId: result.agentid ? parseInt(result.agentid) : undefined,
						transactionAnniversary: result.anniversary ? this.formattedDateString(result.anniversary) : undefined,
						pipelineType: result.pipeline,
						tags: result.tags ? result.tags.split(",").map((tag: string) => tag.trim()): undefined,
						note: result.note,
						textMessageOptIn: result.texting && result.texting.toLowerCase() === "true" ? true : false,
						marketIds: result.marketids ? result.marketids.split(",").map((market: string) => parseInt(market)) : undefined,
						campaignIds: result.campaignids ? result.campaignids.split(",").map((campaign: string) => parseInt(campaign)) : undefined,
						leadSource: result.source,
					}
					return leadImport;
				})
				this.setState({importPreview: [...this.state.importPreview, ...leadImports]})
			}}/>
		);
	}

	public beforeUnload = (event: BeforeUnloadEvent) => {
		event.preventDefault();
		event.returnValue = this.leaveMessage;
	}

	public componentWillUnmount() {
		window.removeEventListener("beforeunload", this.beforeUnload);
		this.props.history.block(true);
	}

	public componentDidMount() {
		this.props.reset();
		window.addEventListener("beforeunload", this.beforeUnload);
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.loading || this.inProgress()) {
			this.props.history.block(this.leaveMessage);
		} else {
			   this.props.history.block(true);
		}	
	}

	private templateData() {
		const { user } = this.props;
		if (user.permissions.broker && user.permissions.crm) { //Team CRM
			return "Name,Email,Phone,Address,City,State,Zip,Birthday,Type,AgentID,Anniversary,Pipeline,Texting,Tags,CampaignIDs,MarketIDs,Note"
		}
		if (user.permissions.crm) {
			return "Name,Email,Phone,Address,City,State,Zip,Birthday,Type,Anniversary,Pipeline,Texting,Tags,CampaignIDs,MarketIDs,Note,Source"
		}
		if (user.permissions.broker) {
			return "Name,Email,Phone,Address,City,State,Zip,AgentID,MarketIDs,Note,Source"
		}
		if (user.permissions.markets) {
			return "Name,Email,Phone,Address,City,State,Zip,MarketIDs,Note,Source"
		}
		
	}

	public render() {
		const { user, classes, importResult, loading } = this.props;
		const { importPreview, valid, invalidHeaders, submitted, warning } = this.state;
		const title = "Import Leads";
		const leadImportSuccesses = importResult.filter((leadImport) => {
			return !leadImport.error || leadImport.error === ImportError.action;
		});
		const leadImportFails = importResult.filter((leadImport) => {
			return leadImport.error === ImportError.duplicate || leadImport.error === ImportError.import;
		});
		return (
			<>
				<DashboardLayout
					permitted={user && user.permissions.leads}
					title={title}
					header={
						<Mui.Typography variant="h1">
							<FeatherIcon>
								<Icons.User />
							</FeatherIcon>
							{title}
						</Mui.Typography>
					}
				>					
					<Mui.Grid container spacing={2} direction="column" justifyContent="center">
						<Mui.Grid item>
							<Mui.Typography>
								Import leads to your account from a CSV. 
							</Mui.Typography>
							<Mui.Typography style={{verticalAlign: "middle", display: "inline-flex"}}>
								<FeatherIcon className={classes.downloadIcon}>
									<Icons.Download />
								</FeatherIcon>
								{this.templateData() && 
									<Mui.Typography >
										<CsvLink
											data={this.templateData() || ""}
											filename="lead-import-template.csv"
											className={classes.csvLink}
										>
											Download Template
										</CsvLink>
									</Mui.Typography>	
								}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item style={{display: importPreview.length > 0 ? "none" : undefined}}>
							{this.renderCsvReader()}
						</Mui.Grid>
						{importPreview.length > 0 && (
							<Mui.Grid item className={classes.importButtonsContainer}>
								<div className={classes.importButtons}>
									{this.progress() !== 100 && (
										<Mui.Grid container spacing={2}>
											{this.inProgress() && importResult.length === 0 ? (
												<Mui.Grid item>
													<Mui.Grid container direction="row" alignItems="center" spacing={2}>
														<Mui.Grid item>
															<LoadingIndicator size={25}/>
														</Mui.Grid>
														<Mui.Grid item>
															<span>Sending...</span>
														</Mui.Grid>
													</Mui.Grid>
												</Mui.Grid>
													
											) : (
												<>
													<Mui.Grid item>
														<Mui.Button 
															variant="contained" 
															color="secondary" 
															disabled={(valid !== undefined && !valid) || this.inProgress()}
															onClick={() => this.save()}
														>
															Import
														</Mui.Button>
													</Mui.Grid>
													<Mui.Grid item>
														<Mui.Button 
															variant="contained"
															onClick={() => {
																if (this.inProgress()) {
																	if (window.confirm(this.leaveMessage)) {
																		this.clear();			
																	}
																} else {
																	this.clear();
																}
															}}
														>
															{this.inProgress() ? "Stop" : valid ? "Cancel" : "Upload Again"}
														</Mui.Button>
													</Mui.Grid>
												</>
											)}
										</Mui.Grid>
									)}
									{this.progress() === 100 && 
										<>{this.renderCsvReader("Import Again")}</>
									}
								</div>
							</Mui.Grid>
						)}
						{importPreview.length > 0 && importResult.length === 0 &&
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Import Preview
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										{invalidHeaders && invalidHeaders.length > 0 && (
											<MuiAlert severity="warning" className={classes.alert}>
												{`Warning! There are columns in your data that are not supported and will not be imported:`}
												{invalidHeaders.map((value, index) => {
													if (value.header.length > 0) {
														return (
															<Mui.Tooltip title={value.info} classes={{tooltip: classes.tooltip}}>
																<Mui.Chip 
																	style={{margin: 3}} 
																	size="small" 
																	key={index} 
																	label={value.header} 
																	icon={
																		<FeatherIcon fontSize="small">
																			<Icons.Info />
																		</FeatherIcon>																		
																	}
																/>
															</Mui.Tooltip>
														)
													}
												})}
											</MuiAlert>
										)}
										{valid !== undefined && !valid && (
											<MuiAlert severity="error" className={classes.alert}>
												Oops! We found some errors. Correct the highlighted issues below and upload again.
											</MuiAlert>
										)}
										{valid && !warning && (
											<MuiAlert severity="success" className={classes.alert}>
												Looks Good! The data has passed our tests. Continue to import.
											</MuiAlert>
										)}
										{valid && warning && (
											<MuiAlert severity="warning" className={classes.alert}>
												The data can be imported but we found some potential issues. Please review the highlighted issues before you import.
											</MuiAlert>
										)}											
										<TableDisplay 
											validate
											onLoad={(valid, warning) => this.setState({valid, warning})}
											leadImports={importPreview}
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						}				
						{importResult.length > 0 && (
							<Mui.Grid item>
								{this.progress() !== 100 && importPreview.length > 0 && (		
									<LinearProgress 
										color="secondary"
										variant="determinate" 
										value={this.progress()} 
									/>
								)}
								{importPreview.length > 0 && (
									<Mui.Grid container direction="column" spacing={2}>
										<Mui.Grid item>
											{`${loading ? "loading..." : "Import Complete:"}`}
											<Mui.Typography>
												{`${leadImportSuccesses.length} of ${importPreview.length} Imported`}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								)}
							</Mui.Grid>
						)}
						{leadImportFails.length > 0 && (
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Failed To Import:
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<TableDisplay validate leadImports={leadImportFails}/>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
						{leadImportSuccesses.length > 0 && this.progress() === 100 && (
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Typography variant="h2">
											Import Successful:
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<TableDisplay leadImports={leadImportSuccesses} />
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</DashboardLayout>
			</>
		);
	}

}

export const LeadImportPage = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));