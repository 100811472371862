import React, { useState, useEffect } from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill-overrides.scss";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	onChange: (value: string) => void;
	toolbarId: string;
	value: string;
}
interface State {
	isRawHTML: boolean;
	rawHtml: string;
	quillHtml: string;
	showSource: boolean;
}
interface ToolbarProps {
	toolbarId: string;
	onSourceToggle: () => void;
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
	return (
		<div id={props.toolbarId}>
			<select className="ql-size">
				<option value="small"></option>
				<option defaultValue="normal"></option>
				<option value="large"></option>
			</select>
			<button className="ql-bold"></button>
			<button className="ql-italic"></button>
			<button className="ql-underline"></button>
			<select className="ql-align">
				<option defaultValue="left"></option>
				<option value="center"></option>
				<option value="right"></option>
				<option value="justify"></option>
			</select>
			<button className="ql-list" value="ordered"></button>
			<button className="ql-list" value="bullet"></button>
			<select className="ql-color">{colorOptions.map((option) => option)}</select>
			<select className="ql-background">
				{colorOptions.map((option) => option)}
			</select>
			<button className="ql-toggleShowSource" onClick={props.onSourceToggle}>
				<Icons.Code />
			</button>
			<button className="ql-link"></button>
			<button className="ql-image"></button>
			<button className="ql-clean"></button>
		</div>
	);
};

const Component: React.FC<Props> = (props) => {
	// State initialization: we store the HTML or Quill content, and show/hide source editor state
	const [state, setState] = useState<State>({
		isRawHTML: false,
		rawHtml: props.value || "",
		quillHtml: props.value || "",
		showSource: false,
	});

	// useEffect to notify parent component of changes to content
	useEffect(() => {
		// We call onChange with the rawHtml or quillHtml based on the current state
		props.onChange(state.rawHtml || state.quillHtml);
	}, [state.isRawHTML]);

	// Toggle the visibility of the raw HTML editor (textarea)
	const toggleShowSource = () => {
		setState((prevState) => ({
			...prevState,
			showSource: !prevState.showSource,
		}));
	};

	// Helper function to check if an HTML string is empty or contains only whitespace
	const isEmptyHTMLString = (html: string) => {
		const regex = /\S/; // \S matches any non-whitespace character
		const text = html.replace(/<[^>]*>/g, ""); // Remove HTML tags from the string
		return !regex.test(text.trim()); // Return true if there's no non-whitespace character
	};

	// Handle changes in the content (either raw HTML or Quill editor content)
	const handleTextChange = (text: string, quill: boolean = false) => {
		// If the content is empty, clear both rawHtml and quillHtml
		if (isEmptyHTMLString(text)) {
			setState((prevState) => ({
				...prevState,
				quillHtml: "",
				rawHtml: "",
			}));
			props.onChange(""); // Notify the parent that the content is now empty
			return;
		}
		// Update state based on whether it's raw HTML or Quill HTML
		setState((prevState) => {
			let newState = quill
				? { ...prevState, quillHtml: text, isRawHTML: false } // Quill editor content
				: { ...prevState, rawHtml: text, isRawHTML: true }; // Raw HTML content
			if (newState.isRawHTML) {
				// Ensure that if we are in raw HTML mode, the Quill content mirrors the raw HTML
				newState.quillHtml = newState.rawHtml;
			} else {
				// Notify parent if we're in editing in Quill mode
				props.onChange(newState.quillHtml);
			}
			return newState;
		});
	};

	return (
		<div>
			<Toolbar toolbarId={props.toolbarId} onSourceToggle={toggleShowSource} />
			{state.showSource && (
				<textarea
					placeholder="Enter raw HTML content here (avoid using HTML tables; use divs with inline CSS for layout)."
					className={props.classes.textarea}
					onChange={(event) => handleTextChange(event.target.value)}
					value={state.rawHtml}
				/>
			)}
			<ReactQuill
				modules={{
					toolbar: {
						container: `#${props.toolbarId}`,
					},
				}}
				style={{
					display: state.showSource ? "none" : "block",
					wordBreak: "break-word",
				}}
				onChange={(value) => handleTextChange(value, true)}
				value={state.isRawHTML ? state.rawHtml : state.quillHtml}
			/>
		</div>
	);
};

export const RichTextEditor = Mui.withStyles(styles)(Component);

const colorOptions = [
	<option key="placeholder" defaultValue="#000000"></option>,
	<option key="#e60000" value="#e60000"></option>,
	<option key="#ff9900" value="#ff9900"></option>,
	<option key="#ffff00" value="#ffff00"></option>,
	<option key="#008a00" value="#008a00"></option>,
	<option key="#0066cc" value="#0066cc"></option>,
	<option key="#9933ff" value="#9933ff"></option>,
	<option key="#ffffff" value="#ffffff"></option>,
	<option key="#facccc" value="#facccc"></option>,
	<option key="#ffebcc" value="#ffebcc"></option>,
	<option key="#ffffcc" value="#ffffcc"></option>,
	<option key="#cce8cc" value="#cce8cc"></option>,
	<option key="#cce0f5" value="#cce0f5"></option>,
	<option key="#ebd6ff" value="#ebd6ff"></option>,
	<option key="#bbbbbb" value="#bbbbbb"></option>,
	<option key="#f06666" value="#f06666"></option>,
	<option key="#ffc266" value="#ffc266"></option>,
	<option key="#ffff66" value="#ffff66"></option>,
	<option key="#66b966" value="#66b966"></option>,
	<option key="#66a3e0" value="#66a3e0"></option>,
	<option key="#c285ff" value="#c285ff"></option>,
	<option key="#888888" value="#888888"></option>,
	<option key="#a10000" value="#a10000"></option>,
	<option key="#b26b00" value="#b26b00"></option>,
	<option key="#b2b200" value="#b2b200"></option>,
	<option key="#006100" value="#006100"></option>,
	<option key="#0047b2" value="#0047b2"></option>,
	<option key="#6b24b2" value="#6b24b2"></option>,
	<option key="#444444" value="#444444"></option>,
	<option key="#5c0000" value="#5c0000"></option>,
	<option key="#663d00" value="#663d00"></option>,
	<option key="#666600" value="#666600"></option>,
	<option key="#003700" value="#003700"></option>,
	<option key="#002966" value="#002966"></option>,
	<option key="#3d1466" value="#3d1466"></option>,
];
