import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginReset } from 'redux/slice/authentication';

interface TasksPageState {
	filters: TasksPageFiltersState;
};

export interface TasksPageFiltersState {
	agent?: number[],
	type?: number[],
	status?: number[],
	priority?: boolean,
	today?: boolean,
	lead?: number,
};

const initialState: TasksPageState = {
	filters: {
		status: [1],
	},
};

export const tasksPageSlice = createSlice({
	name: 'tasksPage',
	initialState,
	reducers: {
		setTasksPageFilters: (state, action: PayloadAction<TasksPageFiltersState>) => {
			state.filters = action.payload;
			return state;
		},
		resetTasksPageFilters: () => {
			return initialState;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
	},
});

export const { setTasksPageFilters, resetTasksPageFilters } = tasksPageSlice.actions;