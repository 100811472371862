import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { RootState } from "redux/store";
import { getActiveSavedListingsForLead } from "redux/selector";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ListingCard } from "./listing-card";
import { SavedListing } from "model/saved-listing";
import { MasonryLayout } from "./masonry-layout"
import { LoadingIndicator } from "component/shared/loading-indicator";
import { User } from "model/user";
import { Select } from "component/shared/select";
import { SaveListingDialog } from "./save-listing-dialog";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		savedListings: getActiveSavedListingsForLead(state, ownProps.lead.id),
		loading: state.savedListings.loading && Object.keys(state.savedListings.byId).length < 1,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	lead: Lead;
	user: User;
}

const sorts = [
	{
		id: 1,
		label: "Price (High to Low)",
		comparator: (a: SavedListing, b: SavedListing) => {
			return (b.listing!.price || 0) - (a.listing!.price || 0);
		},
	},
	{
		id: 2,
		label: "Price (Low to High)",
		comparator: (a: SavedListing, b: SavedListing) => {
			return (a.listing!.price || 0) - (b.listing!.price || 0);
		},
	},
	{
		id: 3,
		label: "Interest Level",
		comparator: (a: SavedListing, b: SavedListing) => {
			return (b.rating || 0) - (a.rating || 0);
		},
	},
	{
		id: 4,
		label: "Recently Saved",
		comparator: (a: SavedListing, b: SavedListing) => {
			return b.createdOn.valueOf() - a.createdOn.valueOf();
		},
	},
];

interface State {
	sortBy: number;
	dialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			sortBy: 1,
			dialogIsOpen: false,
		};
	}

	openSearchDialog = (dialogIsOpen: boolean) => {
		this.setState({ dialogIsOpen });
	};

	public render() {
		let { savedListings, loading, lead, user } = this.props;
		const { sortBy } = this.state;
		const count = savedListings.length;
		savedListings = savedListings.sort((a, b) => sorts[sortBy-1].comparator(a, b))
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<SaveListingDialog
					user={user}
					lead={lead}
					open={this.state.dialogIsOpen}
					onClose={()=>{
						this.openSearchDialog(false);
					}}
					savedListings={savedListings}
				/>
				{loading ? (
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				) : (
					<>
					<Mui.Grid item>
						<Mui.Grid container 
							justifyContent="space-between" 
							alignItems="center" 
							spacing={1}
						>
						<Mui.Hidden smUp>
							<Mui.Grid item xs={12}>
								<Mui.Grid container
									justifyContent="space-between" 
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
											onClick={() => this.openSearchDialog(true)}
										>
											<Mui.Typography noWrap>Add New</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
									{savedListings.length > 0 && 
										<Mui.Grid item>
											<Select
												placeholder="Sort by"
												value={sortBy}
												options={sorts}
												valueExtractor={sort => sort.id}
												labelExtractor={sort => sort.label}
												optionRenderer={sort => <Mui.ListItemText primary={sort.label} />}
												onChange={value => {
													if (typeof(value) === "number" ) {
														this.setState({ sortBy: value })
													}
												}}
											/>
										</Mui.Grid>
									}
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item>
								{count === 1 &&
									<Mui.Typography>1 Active Saved Listing</Mui.Typography>
								}
								{count > 1 &&
									<Mui.Typography>{count} Active Saved Listings</Mui.Typography>
								}
							</Mui.Grid>
						</Mui.Hidden>

						<Mui.Hidden xsDown>
							<Mui.Grid item>
								{count === 1 &&
									<Mui.Typography>1 Saved Listing</Mui.Typography>
								}
								{count > 1 &&
									<Mui.Typography>{count} Saved Listings</Mui.Typography>
								}
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Grid 
									container 
									spacing={1}
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									{savedListings.length > 0 && 
										<Mui.Grid item>
											<Select
												placeholder="Sort by"
												value={sortBy}
												options={sorts}
												valueExtractor={sort => sort.id}
												labelExtractor={sort => sort.label}
												optionRenderer={sort => <Mui.ListItemText primary={sort.label} />}
												onChange={value => {
													if (typeof(value) === "number" ) {
														this.setState({ sortBy: value })
													}
												}}
											/>
										</Mui.Grid>
									}
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
											onClick={() => this.openSearchDialog(true)}
										>
											<Mui.Typography noWrap>Add New</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Hidden>

						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<MasonryLayout 
							columns={2}
							spacing={2}
						>
							{savedListings.map((savedListing, index) => (
								<ListingCard key={index} index={index} savedListing={savedListing} />
							))}
						</MasonryLayout>
					</Mui.Grid>
					
					<Mui.Grid item>
						{count === 0 &&
							<Mui.Grid container
								direction="column"
								alignItems="center"
								justifyContent="center"
							>
								<Mui.Grid item xs={9}>
									<Mui.Typography align="center">
										This lead has not favorited any listings yet.
									</Mui.Typography>
								</Mui.Grid>
							</Mui.Grid>
						}
					</Mui.Grid>

					</>
				)}
			</Mui.Grid>
		);
	}
}

export const SavedListings = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
