import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		container: {
			display: "flex",
			alignItems: "center",
			cursor: "pointer",
			paddingTop: 10,
			paddingBottom: 12,
		},
		content: {
			marginBottom: 15,
		},
		icon: {
			color: "#0000008a", 
			width: 22, 
			height: 24, 
			marginTop: 1,
		}
	});
};
