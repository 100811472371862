import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { Agent } from "model/agent";
import { createAgent, resetError} from "redux/slice/agents";
import { getBoardsForUser } from "redux/selector";
import { getOffices } from "redux/selector";
import { RootState, getPayload } from "redux/store";
import { styles } from "./style";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { NewRoundRobinRoute } from "model/round-robin-route";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import {getLanguages, getRoundRobinRoutes} from "redux/selector";
import * as env from "shared/env";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Row } from "./row";
import * as yup from "yup";
import { getUser } from "redux/selector";
import Checkbox from '@material-ui/core/Checkbox';
import { validate } from "shared/yup";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { AgentStaffType } from "type/agent-staff";

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agents: Agent[];
	onClose: () => void;
	open: boolean;
}

interface State {
	lastName: string;
	firstName: string;
	email: string;
	username: string;
	password: string;
	admin: boolean;
	ownsLeads: boolean,
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity?: Color;
	roundRobin: boolean
	submitted: boolean;
}

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.agentBoards.loading || state.boards.loading,
		boards: getBoardsForUser(state),
		offices: getOffices(state),
		languages: getLanguages(state),
		currentroundRobinRoutes: getRoundRobinRoutes(state),
		agentError: state.agents.error,
		user : getUser(state),
		usernameConflict: state.agents.error === "conflict",
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
			updateRoundRobinRoutes,
            createAgent,
            resetError,
        },
        dispatch
    );

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			lastName: "",
			firstName: "",
			username: "",
			password: "",
			email: "",	
			submitted: false,
			openSnackbar: false,
			snackbarMessage: "",
			roundRobin: false,
			admin: false,
			ownsLeads: true,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		const { currentroundRobinRoutes, agents, user, history } = this.props;
		const { username, roundRobin } = this.state;
		const limitTwoAgentsWithLeads = user.permissions.limitTwoAgentsWithLeads && agents.filter(agent => agent.ownsLeads).length === 2;
		if (prevProps.usernameConflict !== this.props.usernameConflict) {
			if (this.props.usernameConflict) {
				this.setState({
					openSnackbar: true,
					snackbarMessage: "Please fix the username errors and try again",
					snackbarSeverity: "error",
				})
			}
		}
		if (prevProps.agents.length !== agents.length) {
			const roundRobinPermission = user.roundRobinRouting && user.permissions.roundRobinRouting;
			const newAgent = agents.find((agent) => agent.username === username);
			if (newAgent) {
				if (roundRobinPermission && roundRobin) {
					const roundRobinRoutes = currentroundRobinRoutes.map(roundRobinRoute => ({
						agent: roundRobinRoute.agent,
					}));
					roundRobinRoutes.push({
						agent: newAgent,
					});
					this.props.updateRoundRobinRoutes({ roundRobinRoutes });
				}
				history.push(urls.agentProfile(newAgent.id));
				this.props.onClose();
			}
		}
	}

	private submit = async () => {
		const { user, agents } = this.props;
		const limitTwoAgentsWithLeads = user.permissions.limitTwoAgentsWithLeads && agents.filter(agent => agent.ownsLeads).length === 2;
		const {
			lastName,
			firstName,
			email,
			username,
			password,
			admin,
			ownsLeads,
		} = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		this.setState({ submitted: true });
		if (!!!errors && email) {
			this.props.createAgent( { agent: 
				{
					firstName: firstName,
					lastName: lastName,
					email: email,
					username: username,
					password: password,
					hasOwnSite: false,
					languageIds: [],
					admin: admin,
					staffType: AgentStaffType.default,
					ownsLeads: limitTwoAgentsWithLeads ? false : ownsLeads,
					showOnSignupDropDown: true,
					allowModifyBio: true,
					allowModifyListings: true,
					allowModifySubscribers: true,
					enableAgentLink: true
				}
			});
		}
	};

	private validationSchema = {
		email: yup.string().email("Invalid email.").required("Email is required."),
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					return !!!username || !!correctLength;
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					return yup.string().nullable().trim().required().isValidSync(username);
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					return !!!password || !!this.state.username;
				}
			),
	};
	
	render() {
		const { classes, loading, onClose, open, usernameConflict, user, agents } = this.props;
		const {
			lastName,
			firstName,
			email,
			username,
			password,
			admin,
			ownsLeads,
			submitted,
			openSnackbar,
			snackbarMessage,
			snackbarSeverity,
			roundRobin,
		} = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		const roundRobinPermission = user.roundRobinRouting && user.permissions.roundRobinRouting;
		const limitTwoAgentsWithLeads = user.permissions.limitTwoAgentsWithLeads && agents.filter(agent => agent.ownsLeads).length === 2;
		
		return (
			<Dialog
				disableBackdropClick
				maxWidth="md"
				onClose={onClose}
				open={open}
				scroll="paper"
			>
				<Mui.DialogTitle>
					<Mui.Grid container direction="column">
						<Mui.Grid item>
							<Mui.Typography>
								Add Agent
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.IconButton
						className={classes.closeButton}
						onClick={() => this.props.onClose()}
					>
						<Icons.X />
					</Mui.IconButton>
				</Mui.DialogTitle>
				<Mui.DialogContent>
				<Mui.Grid container direction="column" spacing={2} className={classes.container}>
						{loading ? (
								<Mui.Grid item alignItems="center">
									<LoadingIndicator enable />
								</Mui.Grid>
						) : (
							<>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.Grid container spacing={1}>
											<Mui.Grid item xs={6}>
												<Mui.TextField
													fullWidth
													margin="dense"
													onChange={(event) =>
														this.setState({ firstName: event.target.value })
													}
													label="First Name"
													value={firstName}
													variant="outlined"
												/>
											</Mui.Grid>
											<Mui.Grid item xs={6}>
												<Mui.TextField
													fullWidth
													margin="dense"
													onChange={(event) =>
														this.setState({ lastName: event.target.value })
													}
													label="Last Name"
													value={lastName}
													variant="outlined"
												/>
											</Mui.Grid>
										</Mui.Grid>
									</Row>
								</Mui.Grid>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.TextField
											error={submitted && !!errors && !!errors.email}
											FormHelperTextProps={{
												className: classes.errorMessage,
											}}
											fullWidth
											helperText={submitted && errors && errors.email}
											margin="dense"
											onChange={(event) =>
												this.setState({ email: event.target.value })
											}
											label="Email"
											required
											value={email}
											variant="outlined"
										/>
									</Row>
								</Mui.Grid>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.TextField
											error={(submitted && !!usernameConflict) || !!(submitted && errors && errors.username)}
											fullWidth
											helperText={
												!!usernameConflict ? "Please choose a different username and try again" :
												submitted && errors && errors.username
											}
											inputProps={{
												autoComplete: "off",
											}}
											label="Username"
											margin="dense"
											onChange={(event) => {
												if (usernameConflict) {
													this.props.resetError();
												}	
												this.setState({ username: event.target.value })
											}}
											value={username}
											variant="outlined"
											required
										/>
									</Row>
								</Mui.Grid>
								<Mui.Grid item xs={12}>
									<Row>
										<Mui.Grid item xs={12}>
											<Mui.TextField
												fullWidth
												margin="dense"
												onChange={(event) =>
													this.setState({ password: event.target.value })
												}
												label="Password"
												type="password"
												autoComplete="new-password"
												value={password}
												variant="outlined"
											/>
										</Mui.Grid>
									</Row>
								</Mui.Grid>
								<Mui.Grid item>
									{roundRobinPermission &&
										<Row>
											<FormControlLabel
												control={
													<Checkbox
														checked={roundRobin}
														name="roundRobin"
														onChange={(event) => {
															this.setState({ roundRobin: event.target.checked })
														}}
													/>
												}
												label="Add to Round Robin List"
											/>
										</Row>
									}
									<Row>
										<Mui.Tooltip 
											title={limitTwoAgentsWithLeads ? "Limited to 2 agents" : "Enable lead assignments and contact requests."}
											classes={{tooltip: classes.toolTip}}
										>
											<Mui.FormControlLabel
												control={
													<Mui.Switch 
														checked={ownsLeads && !limitTwoAgentsWithLeads} 
														onChange={() => this.setState({ownsLeads: !ownsLeads})}
														name="manages-leads"
														disabled={limitTwoAgentsWithLeads}
													/>
												}
												label="Agent/Lead Owner (billable user)"
												/>
										</Mui.Tooltip>
									</Row>
									{!user.permissions.oneCrm && (
										<Row>
											<Mui.Tooltip 
												title="View and edit all features and settings including agents and leads." 
												classes={{tooltip: classes.toolTip}}
											>
												<Mui.FormControlLabel
													control={
														<Mui.Switch 
															checked={admin} 
															onChange={() => this.setState({admin: !admin})}
															name="admin" 
														/>
													}
													label="Administrator"
													/>
											</Mui.Tooltip>
										</Row>
									)}
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container justifyContent="center" spacing={2}>
										<Mui.Grid item>
											<Mui.Button
												className={classes.button}
												variant="contained"
												color="secondary"
												onClick={() => this.submit()}
												disabled={submitted && !!errors}
											>
												Save & Continue
											</Mui.Button>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Button
												variant="outlined"
												color="secondary"
												onClick={() => this.props.onClose()}
											>
												Cancel
											</Mui.Button>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</>
						)}
					</Mui.Grid>
					<Mui.Snackbar
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() =>
									this.setState({
										openSnackbar: false,
									})
								}
							>
								<Icons.Link fontSize="small" />
							</Mui.IconButton>
						}
						autoHideDuration={6000}
						onClose={() =>
							this.setState({
								openSnackbar: false,
							})
						}
						open={openSnackbar}
					>
						<MuiAlert severity={snackbarSeverity}>
							<Mui.Typography>{snackbarMessage}</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const AddEditAgentDialog = Router.withRouter(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
));
