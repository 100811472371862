import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginReset } from './authentication';

export interface SidebarMenuState {
	active?: string; 
	open?: string; 
};

const initialState: SidebarMenuState = {};

export const sidebarMenuSlice = createSlice({
	name: 'sidebarMenu',
	initialState,
	reducers: {
		activeChange: (state, action: PayloadAction<string>) => {
			state.active = action.payload;
			state.open = action.payload;
		},
		openChange: (state, action: PayloadAction<string>) => {
			state.open = action.payload;
		},
		default: (state) => {
			return state;
		} 
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
	},
});

export const { activeChange, openChange } = sidebarMenuSlice.actions;
