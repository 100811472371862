import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import { getUser } from "redux/selector";
import { styles } from "./style";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Icons from "react-feather";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FeatherIcon } from "component/shared/feather-icon";



const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
}


interface State {
	copied: boolean;
}


class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			copied: false
		};
	}

	public render() {
		const { user } = this.props;
		let mailText: string[] = []
		//'Hello, I would like to cancel my iHomefinder Account CID: '+user?.clientId+'  Email Adress '+user?.email+', Account '+user?.name
		mailText.push("Hello, ")
		mailText.push("I would like to cancel my iHomefinder Account")
		mailText.push("CID: "+user?.clientId)
		mailText.push("Email Address: " + user.email)
		mailText.push("Account Name: "+user.name)
		const text = mailText.join(" ");
		const subject = "Account Cancelation"
		const email = "support@ihomefinder.com"
		if (!user) {
			return null;
		}
		return (
			<div>
			<Accordion>
			  <AccordionSummary
				expandIcon={<ExpandMoreIcon />}
			  >
				<Typography>Cancel Account</Typography>
			  </AccordionSummary>
			  <AccordionDetails>
				<Typography>
				<Box component="span" m={1}>
				Email the below information to {" "}
				<a href={`mailto:${email}?subject=${subject || ""}&body=${text || ""} target="_blank" rel="noopener noreferrer"`}>
				  support@ihomefinder.com
				</a>
				</Box>
				<Box component="span" m={1}>

				<CopyToClipboard text={text}
					onCopy={() => this.setState({copied: true})}>

					<Mui.Fab
							aria-label={"label"}
							color={"secondary"} 
							size="small" 
							variant="extended"
						>
							Copy
							<FeatherIcon> 
								<Icons.Copy />
							</FeatherIcon>
						</Mui.Fab>
				</CopyToClipboard>
				</Box>
				<br/>
			
				{
				 mailText.map((textLine)=>{
					return <p>{textLine}</p>
				})
				}
				
				<div>
					<Snackbar 
						open={this.state.copied} 
						autoHideDuration={2000} 
						onClose={() => this.setState({copied: false})}
					>
					<MuiAlert elevation={6} variant="filled" severity="info" > Cancellation message copied to clipboard</MuiAlert>
					</Snackbar>
					
				 </div> 
				
				</Typography>
			  </AccordionDetails>
			</Accordion>
		  </div>
		);
	}
}

export const CancelAccount = Mui.withStyles(styles)(
	Router.withRouter(Component)
);

