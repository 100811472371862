import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface RadioOption {
	label: string;
	value: any;
}

interface Props extends Mui.WithStyles<typeof styles> {
	default?: boolean;
	label?: string;
	onChange: (value: any) => void;
	options: RadioOption[];
	row?: boolean;
	value: any;
	sidebarNote?: React.ReactNode;
}

class Component extends React.Component<Props> {
	public render() {
		const { classes, label, onChange, options, row, sidebarNote } = this.props;
		const value =
			this.props.value !== undefined
				? this.props.value
				: this.props.default || false;
		return (
			<Mui.Grid container direction="row" spacing={2}>
				<Mui.Grid item xs>
					<Mui.Grid container spacing={3}>
						{label && (
							<Mui.Grid item xs={8}>
								<Mui.Typography className={classes.label}>{label}</Mui.Typography>
							</Mui.Grid>
						)}
						<Mui.Grid item xs={label ? 4 : 12}>
							<Mui.RadioGroup 
								row={row} 
								value={value}
								onChange={(event) => {
									onChange(event.target.value);
								}}
							>
								{options.map((option) => {
									return (
										<Mui.FormControlLabel
											key={option.label}
											label={option.label}
											control={<Mui.Radio />}
											value={option.value}
										/>
									);
								})}
							</Mui.RadioGroup>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				{sidebarNote && (
					<Mui.Grid item xs>
						<Mui.Typography>
							<strong>Note:</strong>
							{" "}
							{sidebarNote}
						</Mui.Typography>
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}
}

export const CustomLabelRadioField = Mui.withStyles(styles)(Component);
