import React from "react";
import * as Mui from "@material-ui/core";
import { ExpansionTable } from "./expansion-table";
import { Lead } from "model/lead";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getSavedSearchesForLead } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		savedSearches: getSavedSearchesForLead(state, ownProps.lead.id),
		loading: state.savedSearches.loading,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	lead: Lead;
	showPropertyOrganizer: Function;
}

interface State {
}

class Component extends React.Component<Props, State> {

	public render() {
		const { savedSearches, showPropertyOrganizer, loading } = this.props;
		if (!!loading) {
			return (
				<Mui.Grid container justifyContent="center">
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				</Mui.Grid>
			)
		} else if (savedSearches && savedSearches.length) {
			return savedSearches.filter((savedSearch) => savedSearch.recieveEmails).map((savedSearch, index) => {
				return (
					<ExpansionTable
						savedSearch={savedSearch}
						key={index}
						index={index}
						showPropertyOrganizer={showPropertyOrganizer}
					/>)
			})
		} else {
			return (
				<Mui.Typography>
					No email alerts. Add one to stay in touch.
				</Mui.Typography>
			)
		}
	}
};

export const SavedSearchesPanel = connect(mapStateToProps, mapDispatchToProps)(Component);
