import React from "react";
import * as Mui from "@material-ui/core";
import { ActivityCard } from "../activity-card";
import { styles } from "./style";
import { RootState } from "redux/store";
import { getLeadActivitiesChronological, getLeadsByAgentId, getLeadsByUnassignedAgent } from "redux/selector";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { Layers } from "react-feather";
import { Lead } from "model/lead";
import { Pagination } from "../pagination";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState, ownProps: any) => {
  const agentId = state.agents.value;
  const unassigned = agentId === -1
  return {
    agentId,
    leadsByAgent: getLeadsByAgentId(state, agentId),
    leadsByUnassigned: getLeadsByUnassignedAgent(state),
    unassigned,
    leadActivities: getLeadActivitiesChronological(state),
    loading: state.leadActivities.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>, 
	ReturnType<typeof mapDispatchToProps>, 
	Mui.WithStyles<typeof styles> {
	lead?: Lead,
	isAgentPage? : boolean,
}

interface State {
  selectedTypeId: number;
}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTypeId: 0
    };
  }

  handleChange(typeId: number) {
    this.setState({
      selectedTypeId: typeId
    });
  }

  public render() {
	const { loading, leadActivities, unassigned, leadsByUnassigned, leadsByAgent, agentId, lead, classes, isAgentPage } = this.props;
	const { selectedTypeId } = this.state;
	let filteredActivities;

	if (lead) {
	  // If a lead is passed in as a prop, filter activities for that specific lead.
	  filteredActivities = leadActivities.filter(activity => activity.leadId === lead.id);
	} else if (unassigned) {
	  // Filter activities for unassigned leads.
	  filteredActivities = leadActivities.filter(activity => leadsByUnassigned.some(lead => lead.id === activity.leadId));
	} else {
	  // Filter activities for leads assigned to the current agent or show all activities if agentId is not available.
	  filteredActivities = agentId ? leadActivities.filter(activity => leadsByAgent.some(lead => lead.id === activity.leadId)).slice(0, 100) : leadActivities;
	}
  
	const shouldShowAllActivities = selectedTypeId === 0;
  
	const filteredLeadActivities = shouldShowAllActivities
	  ? filteredActivities
	  : filteredActivities.filter((leadActivity) => leadActivity.type.id === selectedTypeId);
  
	const leadActivityTypes = new Set(filteredActivities.map(leadActivity => leadActivity.type));
  
  
	return (
	  <Mui.Grid container direction="column" spacing={2}>
		<Mui.Grid item>
		  <Mui.Grid container direction="row" spacing={3} wrap="nowrap">
			{!lead && (
				<Mui.Grid item>
					<Mui.Typography variant="h3">Latest Activity</Mui.Typography>
				</Mui.Grid>
			)}
			<Mui.Grid item style={{display: filteredLeadActivities.length === 0 ? "none" : "block"}}>
			  <Mui.FormControl>
				<Mui.Select
				  value={selectedTypeId}
				  onChange={(e) => this.handleChange(Number(e.target.value))}
				>
					<Mui.MenuItem value={0}>
						<Mui.Grid container alignItems="center">
							<Mui.Grid item>
								<Mui.ListItemIcon>
									<FeatherIcon>
										<Layers />
									</FeatherIcon>
								</Mui.ListItemIcon>
							</Mui.Grid>
							<Mui.Grid item>
								All Activity
							</Mui.Grid>
						</Mui.Grid>
					</Mui.MenuItem>
				  {Array.from(leadActivityTypes).map(activityType => (
					<Mui.MenuItem
					  key={activityType.id}
					  value={activityType.id}
					>
						<Mui.Grid container alignItems="center">
							<Mui.Grid item>
								<Mui.ListItemIcon>
									<FeatherIcon>
										<activityType.icon />
									</FeatherIcon>
								</Mui.ListItemIcon>
							</Mui.Grid>
							<Mui.Grid item>
								{activityType.label}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.MenuItem>
				  ))}
				</Mui.Select>
			  </Mui.FormControl>
			</Mui.Grid>
		  </Mui.Grid>
		</Mui.Grid>
		{!loading && !!filteredLeadActivities.length && (
		  <Mui.Grid container direction="column" spacing={4}>
			{lead ? (
				<Pagination>
					{filteredLeadActivities.map((activity, index) => (
						<Mui.Grid key={index} item xs>
							<ActivityCard activity={activity} isAgentPage={isAgentPage} />
						</Mui.Grid>
					))}
				</Pagination>
			):
				<>
					{filteredLeadActivities.map((activity, index) => (
						<Mui.Grid key={index} item xs>
							<ActivityCard activity={activity} isAgentPage={isAgentPage} showLeadName />
						</Mui.Grid>
					))}	
				</>
			}
			{selectedTypeId !== 0 && (
				<Mui.Grid item className={classes.bottomTab}>
					<Mui.Link onClick={() => this.setState({ selectedTypeId: 0 })}>
						View All Activities
					</Mui.Link>
				</Mui.Grid>
			)}
		  </Mui.Grid>
		)}
		<Mui.Grid item>
			{!!loading &&
				<Mui.Card>
					<Mui.CardContent>
						<Mui.Grid container justifyContent="center">
							<Mui.Grid item>
								<LoadingIndicator />
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardContent>
				</Mui.Card>
			}
		</Mui.Grid>
		{!loading && filteredLeadActivities.length === 0 &&
			<Mui.Grid container item xs={12}>
				<Mui.Card>
					<Mui.CardContent>
						<Mui.Grid container justifyContent="center">
							<Mui.Grid item>
								<Mui.Typography align="center">Lead activity has not yet been recorded on your site.</Mui.Typography>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardContent>
				</Mui.Card>
			</Mui.Grid>
		}
	  </Mui.Grid>
	);
  }
  
}

export const LeadActivityFilter = 
	Mui.withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(Component),
	)
;
