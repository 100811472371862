import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { fetchLeadActivities } from "redux/slice/lead-activities";
import { getLeadActivitiesChronological, getLeadsByAgentId, getLeadsByUnassignedAgent } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { RecentMonthlyActivity } from "component/page/lead-activity/recent-monthly-stats";
import { User } from "model/user";
import { LeadActivityFilter } from "../lead-activity-filter";

interface Props extends ReturnType<typeof mapStateToProps> {
	user: User;
}

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	const unassigned = agentId === -1
	return {
		leadActivities: getLeadActivitiesChronological(state),
		loading: state.leadActivities.loading,
		agentId,
		leadsByAgent: getLeadsByAgentId(state, agentId),
		leadsByUnassigned: getLeadsByUnassignedAgent(state),
		unassigned
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchLeadActivities
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	WithMediaQuery {}

class Component extends React.Component<Props> {

	public componentDidMount() {
		this.props.fetchLeadActivities({});
	}

	public render() {
		const { user, leadActivities, loading, mediaQuery, unassigned, leadsByUnassigned, leadsByAgent, agentId } = this.props;
		let hundredLeadActivities;
		if (unassigned) {
			hundredLeadActivities = leadActivities.filter(activity => leadsByUnassigned.some(lead => lead.id === activity.leadId)).slice(0, 100);
		} else {
			hundredLeadActivities = agentId ? leadActivities.filter(activity => leadsByAgent.some(lead => lead.id === activity.leadId)).slice(0, 100) : leadActivities.slice(0, 100);
		}
		return (
			<Mui.Grid
				container
				spacing={4}
				direction={mediaQuery ? "column-reverse" : "row"}
			>
				<Mui.Grid container item xs={12} md={7}>
					<Mui.Grid
						container
						item
						spacing={4}
						direction="row"
						justifyContent="space-between"
					>
						<Mui.Grid item xs={12}>
							<Mui.Grid container direction="column" spacing={4} >
								<Mui.Grid item>
									<Mui.Grid container direction="row" spacing={3}>
										<Mui.Grid item>
											<LeadActivityFilter />
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				{user && user.admin &&
					<RecentMonthlyActivity />
				}
			</Mui.Grid>
		);
	}

}

export const LeadActivity = withMediaQuery("(max-width:960px)")(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
