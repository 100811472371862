import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { RootState } from "redux/store";
import { updateLead } from "redux/slice/leads";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";

const mapStateToProps = (state: RootState) => {
	return {
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
	open: boolean;
	onClose: Function;
	lead: Lead;
}

interface State {
	note?: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			note: this.props.lead.note,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		const { lead } = this.props;
		if (!prevProps.open && this.props.open) {
			this.setState({
				note: lead.note,
			})
		}
	}

	public handleSave = () => {
		const { lead } = this.props;
		let { note } = this.state;

		this.props.updateLead({ lead: {
			...lead,
			note,
		}});
	}

	public render() {
		const { open, onClose, classes } = this.props;
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>Edit Note</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container>
						<Mui.TextField
							multiline
							minRows={8}
							fullWidth
							variant="outlined"
							placeholder="Add notes about this lead here"
							onChange={event => this.setState({ note: event.target.value })}
							defaultValue={this.state.note}
						/>
						<Mui.Hidden smUp>
							<Mui.Grid container item style={{ margin: 12 }}>
								<Mui.Grid container spacing={1}>
									<Mui.Grid item xs={6}>
										<Mui.Button
											fullWidth
											variant="contained"
											color="secondary"
											onClick={() => {
												onClose();
												this.handleSave();
											}}
										>
											Save
										</Mui.Button>
									</Mui.Grid>
									<Mui.Grid item xs={6}>
										<Mui.Button fullWidth variant="outlined" onClick={() => onClose()}>
											Cancel
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Hidden>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.Hidden xsDown>
					<Mui.DialogActions className={classes.dialogActions}>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => {
								onClose();
								this.handleSave();
							}}
						>
							Save
						</Mui.Button>
						<Mui.Button variant="outlined" onClick={() => onClose()}>
							Cancel
						</Mui.Button>
					</Mui.DialogActions>
				</Mui.Hidden>
			</Dialog>
		);
	}
}

export const EditNoteDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
