import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import MuiAlert from "@material-ui/lab/Alert";

interface Props {
	snackBar?: boolean;
	disabled?: boolean;
	loading: boolean;
	error?: string;
	label: {
		primary: string
		inProgress: string;
		completed: string;
	};
	duration?: number;
	fullWidth?: boolean;
	onClick: () => void;
	onReset?: () => void;
}

interface State {
	clicked: boolean;
	saved: boolean;
	label: string | undefined;
	error?: string;
	disabledOnSave?: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			clicked: false,
			saved: false,
			label: props.label.primary,
		};
	}

	autoHideDuration = 8000;

	public componentDidUpdate(prevProps: Props): void {
		const { loading, label, duration, onReset, error } = this.props;
		if (prevProps.error !== error && error?.length) {
			this.setState({error: error});
		} 
		if (prevProps.error !== error && !!!error) {
			this.setState({clicked: false, error: undefined});
		}
		if ((loading !== prevProps.loading && loading)) {
			this.setState({disabledOnSave: true, label: label.inProgress});
		}
		if ((loading !== prevProps.loading) && !loading) {
			this.setState({
				saved: true, 
				label: label.completed,
			});
			setTimeout(() => {
				this.setState({
					clicked: false, 
					saved: false, 
					disabledOnSave: false, 
					label: label.primary,
				});
				if (onReset) {
					onReset();
				}
			}, duration ? duration : this.autoHideDuration);
		}
	}

	public render() {
		const { onClick, snackBar, disabled, fullWidth, label: labelProp } = this.props;
		const { label, saved, disabledOnSave, clicked, error } = this.state;
		return (
			<>
				<Mui.Button
					color="secondary" 
					variant="contained"
					fullWidth={fullWidth}
					disabled={disabledOnSave || disabled}
					onClick={() => this.setState({
						clicked: true, 
					}, () => onClick())}
				>
					<Mui.Grid container justifyContent="center" alignItems="center">
						<Mui.Grid item>
							<Mui.Grid container direction="row" justifyContent="center" spacing={1}>
								<Mui.Grid item>
									{snackBar ? labelProp.primary : label}
								</Mui.Grid>
								{!snackBar && saved && (
									<Mui.Grid item>
										<Icons.CheckCircle size="1rem" style={{height: "100%"}}/>
									</Mui.Grid>
								)}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Button>
				{((clicked && snackBar) || (clicked && error)) && (
					<Mui.Snackbar
						open={clicked}
						message={label}
						autoHideDuration={this.autoHideDuration}
						TransitionProps={{}}
						key={label}
					>
						<MuiAlert severity={error ? "error" : "info"}>
							<Mui.Typography>{error ? error : label}</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				)}
			</>
		);
	}
}

export const SaveButton = Component;