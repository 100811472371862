import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { getAgentsWithLeads } from "redux/selector";
import { Agent } from "model/agent";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		agents: getAgentsWithLeads(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateRoundRobinRoutes
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: () => void;
	onSave: (agents : Agent[]) => void;
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	submitted: boolean;
	selectedAgents : Agent[]
}

const initialState: State = {
	submitted: false,
	selectedAgents: [],
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public render() {
		const { open, agents, onClose, onSave, classes } = this.props;
		const { selectedAgents } = this.state;
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					Add Agents to Round Robin
				</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container spacing={2} direction="column">
						<Mui.Grid item className={classes.autocompleteItem}>
							<Autocomplete
								className={classes.autocomplete}
								multiple
								id="checkboxes-tags"
								value={selectedAgents}
								onChange={ (event, value) => this.setState({ selectedAgents: value })}
								options={[...agents]}
								disableCloseOnSelect
								getOptionLabel={(option) => option.label}
								getOptionSelected={(option, value) => option === value}
								renderOption={(option, {selected}) => (
									<>
										<Checkbox checked={selected} className={classes.checkbox} />
										{option.label}
									</>
								)}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" label="Select one one more agents" />
								)}
							/>
							{selectedAgents.length !== agents.length && (
								<Mui.Button 
									className={classes.selectAll}
									size="small" 
									variant="text"
									onClick={() => this.setState({ selectedAgents: agents })}
								>
									Select All
								</Mui.Button>
							)}
						</Mui.Grid>
						<Mui.Grid item >
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => onSave(selectedAgents)}
								className={classes.addButton}
								>
									Add
							</Mui.Button>
							<Mui.Button
								variant="outlined"
								color="secondary"
								onClick={() => onClose()}
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		)
	}
}
export const AddDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
