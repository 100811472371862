import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NewRoundRobinRoute, RoundRobinRoute } from "model/round-robin-route";
import * as Router from "react-router-dom";
import { styles } from "./styles";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { getUserUnsafe } from "redux/selector";
import { fetchRoundRobinRoutes } from "redux/slice/round-robin-routes"
import { Switch } from "component/shared/switch";
import { saveUser } from "redux/slice/authentication";
import { UserPartial } from "model/user";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "./card";
import { deepCompare } from "shared/deep-compare";
import { AddDialog } from "./add-dialog";

interface OwnProps {
	roundRobinRoutes: RoundRobinRoute[];
}

interface State {
	routes: RoundRobinRoute[];
	enabled: boolean;
	dialogOpen: boolean;
}


const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};

interface Props	extends OwnProps,
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles>,
	Router.RouteComponentProps
{}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateRoundRobinRoutes,
	fetchRoundRobinRoutes,
	saveUser,
}, dispatch);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			routes: this.props.roundRobinRoutes,
			enabled: props.user && props.user.roundRobinRouting ? true : false,
			dialogOpen: false,
		};
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const { roundRobinRoutes } = this.props;
		if (!deepCompare(prevProps.roundRobinRoutes, roundRobinRoutes) ) {
			this.setState({routes: roundRobinRoutes});
		}
	}

	private onDragEnd = (result: any) => {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}
		const items = Array.from(this.state.routes);
		const [reordered] = items.splice(source.index, 1);
		items.splice(destination.index, 0, reordered);
		this.setState({
			routes: items,
		}, () => this.saveRoutes());
	}; 

	private saveRoutes = async () => {
		this.props.updateRoundRobinRoutes({ roundRobinRoutes: this.state.routes });
	}

	private saveUser() {
		const { saveUser } = this.props;
		const { enabled } = this.state;
		const user: UserPartial = {
			roundRobinRouting: enabled,
		}
		saveUser({user});
	}

	public render() {
		const { routes, dialogOpen, enabled } = this.state;
		return (
			<>
				<Mui.Grid container spacing={2} direction="column">
				<Mui.Grid item>
						<Mui.Typography>
							Assign leads to your agents in a rotating order. Drag and drop to change the order within the list. 
							To create a weighted distribution, add an agent to the list more than once. 
							Geographic and Featured Property routing take precedence over Round Robin
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Switch 
							checked={enabled}
							onChange={(checked) => {
								this.setState({enabled: checked}, () => this.saveUser());
							}} 
							label="Enabled"
						/>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography variant="h4">
							Agent Rotation Order
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item style={!enabled ? {pointerEvents: "none", opacity: .5}: {}}>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({ dialogOpen: !dialogOpen})}
							startIcon={
								<FeatherIcon>
									<Icons.PlusCircle />
								</FeatherIcon>
							}
						>
							Add
						</Mui.Button>
					</Mui.Grid>
					<Mui.Grid item style={!enabled ? {opacity: .5}: {}}>
						<DragDropContext onDragEnd={this.onDragEnd}>	
							<Droppable droppableId="droppable">
								{(provided) => (
									<Mui.Grid container direction="column" spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
										{routes.map((route, index) => (
											<Draggable key={route.id} draggableId={route.id.toString()} index={index}>
												{(provided) => (
													<Mui.Grid item
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													> 
														<Card 
															position={index + 1} 
															roundRobinRoute={route} 
															onDelete={() => {
																const results = [...routes];
																results.splice(index, 1);
																this.setState({routes: results}, () => this.saveRoutes())
															}}
														/>
													</Mui.Grid>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</Mui.Grid>
								)}
							</Droppable>
						</DragDropContext>
					</Mui.Grid>
				</Mui.Grid>
				{dialogOpen && (
					<AddDialog 
						open={dialogOpen}
						onClose={() => this.setState({ dialogOpen: false })}
						onSave={(agents) => {
							const newRoutes: NewRoundRobinRoute[] = agents.map((agent) => {
								return {agent};
							});
							const results: NewRoundRobinRoute[] = [...routes] as NewRoundRobinRoute[];
							results.unshift(...newRoutes);
							this.props.updateRoundRobinRoutes({ roundRobinRoutes: results });
							this.setState({dialogOpen: false});
						}}
					/>
				)}
			</>
		);
	}
}

export const RoundRobinTab = (Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
));
