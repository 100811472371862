import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		button: {
			borderRadius: 20,
			"&:hover": {
				backgroundColor: "#DCDCDC",
			},
		},
		buttonOnHeader: {
			display: "flex",
			top: 5,
			borderRadius: 20,
			color: "#DCDCDC",
			"&:hover": {
				backgroundColor: "rgba(220, 220, 220, 0.2)",
			}
		}
	});
};
