import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { getOpenTasksForLead, getUser } from "redux/selector";
import { Lead } from "model/lead";
import { TasksComponent } from "component/shared/tasks-component";
import { styles } from "./style";
import { Task } from "model/task";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const leadId = ownProps.lead.id;
	return {
		tasks: getOpenTasksForLead(state, leadId),
		user: getUser(state)
	};
};

interface Props
	extends ReturnType<typeof mapStateToProps>,
		Mui.WithStyles<typeof styles> {
	lead: Lead;
}

interface OwnProps {
	lead: Lead;
}

interface State {
	taskDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			taskDialogIsOpen: false
		};
	}

	public render() {
		const { tasks, lead, classes, user } = this.props;
		const { taskDialogIsOpen } = this.state;
		let filteredTasks = user.admin ? tasks : tasks.filter(task => task.agentId === user.agent?.id);

		return (
			<Mui.Card>
				<Mui.CardHeader
					title="To Do"
					classes={{
						root: classes.root,
						title: classes.title,
						action: classes.newButtonAction
					}}
					action={
						<Mui.Button
							classes={{ 
								root: classes.buttonRoot,
								label: classes.newButtonTitle 
							}}
							onClick={() => this.setState({ taskDialogIsOpen: true })}
						>
							New
						</Mui.Button>
					}
				/>
				<TasksComponent
					lead={lead}
					tasks={filteredTasks}
					taskDialogIsOpen={taskDialogIsOpen}
					onCloseDialog={() => this.setState({ taskDialogIsOpen: false })}
				/>
			</Mui.Card>
		);
	}
}

export const TasksCard = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
