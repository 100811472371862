import { UnlayerOptions } from "react-email-editor";

const mergeTags = [
	{
		name: "Lead First Name",
		value: "{{first_name}}",
	},
	{
		name: "Lead Last Name",
		value: "{{last_name}}",
	},
	{
		name: "Your Name",
		value: "{{client_name}}",
	},
	{
		name: "Phone",
		value: "{{phone_number}}",
	},
	{
		name: "Website",
		value: "{{web_site}}",
	},
]

export const unlayerOptions: UnlayerOptions = {
	id: "editor",
	version: "latest",
	projectId: 650,
	displayMode: "email",
	mergeTags,
	customCSS: [
		`
		table {
			max-width: 500px;
		}
		body {
			max-width: 500px;
		}
		`,
	],
	tools: {
		"custom#dynamic_content": {
			position: 4,
			// @ts-ignore
			properties: {
				ContentType: {
					editor: {
						data: {
							options: [
								{
									label: "Similar Listings",
									value: "{{similar_listings}}",
								},
								{
									label: "Area Stats",
									value: "{{area_stats}}",
								},
							],
						},
					},
				},
			},
		},
		button: {
			position: 3,
		},
		video: {
			position: 5,
		},
		text: {
			position: 1,
		},
		image: {
			position: 2,
		},
		divider: {
			position: 6,
		},
	},
};