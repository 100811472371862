import React from "react";
import { Moment } from "moment";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { LeadNote } from "model/lead-note";
import { Lead } from "model/lead";
import { LeadNoteDialog } from "../lead-note-dialog";
import { updateLead } from "redux/slice/leads";
import { updateLeadNote } from "redux/slice/lead-notes";
import { RootState } from "redux/store";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

const mapStateToProps = (state: RootState) => {
	return {
		
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
	updateLeadNote,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	lead: Lead;
	leadNote: LeadNote;
	isExistingNote?: boolean;
}

interface State { 
	leadNoteDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			leadNoteDialogIsOpen: false,
		};
	}

	public deleteNote = () => {
		const { lead, leadNote, isExistingNote, updateLead, updateLeadNote } = this.props;
		if (isExistingNote) {
			let updatedLead = { ...lead };
			updatedLead.note = "";
			updateLead({lead: updatedLead})
		} else {
			let deleted = true;
			updateLeadNote({ leadNote: {
				...leadNote,
				deleted,
			}});
		}
	}

	public render() {
		
		const getTimeLabel = (date: Moment) => {
			return date.fromNow();
		};
		
		const { leadNoteDialogIsOpen } = this.state;
		const { lead, leadNote, isExistingNote, classes } = this.props;
		const { text } = leadNote;
		const subHeaderText = leadNote.updatedOn && `${getTimeLabel(
			leadNote.updatedOn,
		)}`;
		return (
			<>
				<LeadNoteDialog
					open={leadNoteDialogIsOpen}
					onClose={() => this.setState({leadNoteDialogIsOpen: false})}
					lead={lead}
					leadNote={leadNote}
					isExistingNote={isExistingNote}
				/>
				<Mui.Card>
					<Mui.CardHeader
						component={() => {
							return <Mui.Grid container justifyContent="space-between" alignItems="center" className={classes.cardHeader}>
								<Mui.Grid item>
									<Mui.Grid container direction="row" spacing={2}>
										{
											leadNote.type && <Mui.Grid item>
												<FeatherIcon>
													<leadNote.type.icon />
												</FeatherIcon>
											</Mui.Grid>
										}
										<Mui.Grid item><Mui.Typography variant="h4">{leadNote.type && leadNote.type.label}</Mui.Typography></Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Button
										color="secondary"
										onClick={() => this.setState({leadNoteDialogIsOpen: true})}
									>
										Edit
									</Mui.Button>
								</Mui.Grid>
							</Mui.Grid>
}
						}
						subheader={subHeaderText}
					/>
					{(text) &&
						<Mui.CardContent>
							<Mui.Grid container spacing={2} direction="column">
								{text &&
									<Mui.Grid item>
										<Mui.Typography>
											{text}
										</Mui.Typography>
									</Mui.Grid>
								}
							</Mui.Grid>
						</Mui.CardContent>
					}
					<Mui.CardActions>
						<Mui.Grid container justifyContent="space-between">
							<Mui.Grid item />
							<Mui.Grid item>
								<Mui.IconButton
									size="small"
									onClick={() => {
										this.deleteNote();
									}}
								>
									<FeatherIcon>
										<Icons.Trash />
									</FeatherIcon>
								</Mui.IconButton>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardActions>
				</Mui.Card>
			</>
		);
	}
}

export const LeadNoteCard = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
