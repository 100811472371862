import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { Dialog } from "component/shared/dialog";
import { styles } from "../../style";
import { getLeadsById, getMarketReportSubscriptions, getMarkets, getUnsubscribedReportsByLeadId } from "redux/selector";
import { updateLead } from "redux/slice/leads";
import { Market } from "model/market";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { MarketReportType } from "type/market-report-type";
import { updateMarketSubscriptionsNoLoading as updateMarketSubscriptions } from "redux/slice/market-report-subscriptions";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		markets: getMarkets(state),
		exsistingSubscriptions: getMarketReportSubscriptions(state),
		leads: getLeadsById(state, ownProps.leadIds), 
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead, updateMarketSubscriptions
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	onClose: Function,
	open: boolean;
	leadIds: number[],
}

interface State {
	marketId?: number;
	submitted: boolean;
	saving: boolean;
	listingChecked: boolean;
	openHomesChecked: boolean;
	marketChecked: boolean;
	market?: Market;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			submitted: false,
			listingChecked: true,
			openHomesChecked: true,
			marketChecked: true,
			market: undefined,
			saving: false,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState({
				listingChecked: true,
				openHomesChecked: true,
				marketChecked: true,
			})
		}
	}

	private async add() {
		const { leads } = this.props;
		const { listingChecked, marketChecked, openHomesChecked, market } = this.state;
		let subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[];
		if (market) {
			subscriptions = market.marketReportSubscriptions ? [...market.marketReportSubscriptions] : [];
			for (const selected of leads) {
				const leadId = selected.id as number
				let lead = leads.find(lead => {
					if (leadId) {
						return lead.id === leadId;
					} 
				});
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.LISTING);
					if (i >= 0) {
						subscriptions[i].inactive = !listingChecked;
					} else if (lead && market.listingReport && market.listingReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.listingChecked,
							report: market.listingReport,
						});
					}
				}
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.OPEN_HOMES);
					if (i >= 0) {
						subscriptions[i].inactive = !openHomesChecked;
					} else if (lead && market.openHomesReport && market.openHomesReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.openHomesChecked,
							report: market.openHomesReport,
						});
					}
				}
				{
					const i = subscriptions.findIndex((item) => lead && item.lead.id === lead.id && item.report.type === MarketReportType.MARKET);
					if (i >= 0) {
						subscriptions[i].inactive = !marketChecked;
					} else if (lead && market.marketReport && market.marketReportSupported) {
						subscriptions.push({
							lead: lead,
							inactive: !this.state.marketChecked,
							report: market.marketReport,
						});
					}
				}
			}
			this.save(subscriptions);
		};
	}

	private async save(subscriptions: (MarketReportSubscription | NewMarketReportSubscription)[]) {
		if (this.state.market) {
			this.setState({saving: true});
			await this.props.updateMarketSubscriptions({market: this.state.market, subscriptions});
		}	
	}

	private onClose() {
		this.props.onClose();
		this.setState({ marketId: undefined, submitted: false });
	}

	private onSelectMarket = (selectedMarketId: number) => {
		const selectedMarket = this.props.markets.find(market => market.id === selectedMarketId);
		this.setState({
			marketId: selectedMarketId,
			market: selectedMarket,
		});
	}

	render() {
		const { classes, markets } = this.props;
		const { marketId, submitted, listingChecked, openHomesChecked, marketChecked, market } = this.state;

		return (
			<Dialog
				open={true}
				onClose={() => {
					this.onClose()
				}}
				fullWidth={true}
				maxWidth="xs"
			>
				<Mui.DialogTitle>Add to Market</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Add all selected leads to a Market.</Mui.DialogContentText>
						</Mui.Grid>
						<Mui.Grid item xs={12} justifyContent="center">
							{ 
							<FullWidthDropdown<number>
								label="Markets"
								onChange={(value) => this.onSelectMarket(value)}
								value={this.state.marketId}
							>
								{markets.map(market => (
									<Mui.MenuItem key={market.id} value={market.id}>
										{market.name}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown> 
							}
						</Mui.Grid>
						<Mui.Grid>
						{ market && (
							<div>
								{ market.listingReport &&
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={listingChecked}
												onChange={() => this.setState({ listingChecked: !listingChecked })}
												color="primary"
											/>
										}
										label={market.listingReport.type.label}
									/>
								}
								{ market.openHomesReport &&
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={openHomesChecked}
												onChange={() => this.setState({ openHomesChecked: !openHomesChecked })}
												color="primary"
											/>
										}
										label={market.openHomesReport.type.label}
									/>
								}
								{ market.marketReport &&
									<Mui.FormControlLabel
										control={
											<Mui.Checkbox
												checked={marketChecked}
												onChange={() => this.setState({ marketChecked: !marketChecked })}
												color="primary"
											/>
										}
										label={market.marketReport.type.label}
									/>
								}
							</div>
						)}
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
					<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
						onClick={() => {
							if (market && marketId) {
								this.add();
								this.setState({submitted: true})
								this.onClose()
							} 
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && !marketId}
						onReset={() => this.setState({submitted: false})}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => {
							this.onClose();
						}}
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}

}

export const BulkAddToMarketDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);