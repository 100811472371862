import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { LeadTagEdit } from "./lead-tag-edit";
import { FeatherIcon } from "component/shared/feather-icon";
import { Lead } from "model/lead";
import { LeadStatus } from "type/lead-status";
import { updateLead } from "redux/slice/leads";
import { urls } from "routes/urls";
import { styles } from "./style";
import { User } from "model/user";
import * as env from "shared/env";
import { getActiveLeadPhonesForLead, getActiveLeads } from "redux/selector";
import { RootState } from "redux/store";
import { getActiveLeadAnniversariesForLead } from "redux/selector";
import { formatPhoneNumber } from "shared/phone-number-formatter";
import { getTypeTagsForLead } from "redux/selector";
import { monthDayFormatter, numberedDateFormatter } from "shared/date-formatter";
import { ColumnLabel } from "component/shared/column-label";
import { Ranking } from "component/shared/ranking";
import { EditLeadDialog } from "component/shared/edit-lead-dialog";
import { calculateLeadRankSize } from "shared/lead-rank-size";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		leadPhones: getActiveLeadPhonesForLead(state, ownProps.lead.id),
		leads: getActiveLeads(state),
		leadAnniversaries: getActiveLeadAnniversariesForLead(state, ownProps.lead.id),
		typeTags: getTypeTagsForLead(state, ownProps.lead.id),
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	user: User;
	lead: Lead;
}

interface State {
	editLeadDialogIsOpen: boolean;
}

interface OwnProps {
	user: User;
	lead: Lead;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			editLeadDialogIsOpen: false,
		};
	}
	
	private mailto(email: string) {
		const { lead } = this.props;
		let result = "mailto:";
		if (lead.name) {
			result += lead.name + "<";
		}
		result += email;
		if (lead.name) {
			result += ">";
		}
		return result;
	}

	private tel(phoneNumber: string) {
		return `tel:${formatPhoneNumber(phoneNumber)}`;
	}

	private address() {
		const { lead } = this.props;
		const address = `${lead.address}, ${lead.city}, ${lead.state} ${lead.zip}`;
		return `https://www.google.com/maps/place/${encodeURI(address)}`;
	}

	private reactivateLead = () => {
		const { lead, updateLead } = this.props;
		updateLead({ lead: {
			...lead,
			status: LeadStatus.ACTIVE,
		}});
	};

	public render() {
		const { classes, user, lead, leads, leadPhones, leadAnniversaries, typeTags } = this.props;
		const { editLeadDialogIsOpen } = this.state;
		const noWrap: boolean = true;
		const leadRankData = calculateLeadRankSize([...leads]);
		return (
			<>
				<EditLeadDialog
					open={editLeadDialogIsOpen}
					onClose={() => this.setState({ editLeadDialogIsOpen: false })}
					user={user}
					lead={lead}
					leadPhones={leadPhones}
					leadAnniversaries={leadAnniversaries}
					typeTags={typeTags}
				/>
				<Mui.Card>
					<Mui.CardHeader
						title="Contact"
						classes={{
							root: classes.root,
							title: classes.title,
						}}
					/>
					<Mui.CardContent>
						<Mui.Grid container direction="column" spacing={2} wrap="nowrap">
							<Mui.Grid item>
								<Mui.Grid container>
									<Mui.Grid item xs={4}>
										<Mui.IconButton size="small" href={this.mailto(lead.email)}>
											<FeatherIcon>
												<Icons.Mail />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Grid>
									<Mui.Grid item xs={8}>
										<Mui.Typography noWrap={noWrap}>
											<Mui.Link href={this.mailto(lead.email)}>
												{lead.email}
											</Mui.Link>
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							{lead.email2 && (
								<Mui.Grid item>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small" href={this.mailto(lead.email2)}>
												<FeatherIcon>
													<Icons.Mail />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography noWrap={noWrap}>
												<Mui.Link href={this.mailto(lead.email2)}>
													{lead.email2}
												</Mui.Link>
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{lead.phone && (
								<Mui.Grid item>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small" href={this.tel(lead.phone)}>
												<FeatherIcon>
													<Icons.Phone />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography>{formatPhoneNumber(lead.phone)}</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{leadPhones && leadPhones.map(phone => !phone.deleted && (
								<Mui.Grid item key={phone.id}>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small" href={this.tel(phone.value)}>
												<FeatherIcon>
													<Icons.Phone />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography>{formatPhoneNumber(phone.value)}</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							))}
							{(lead.address || lead.city || lead.state || lead.zip) && (
								<Mui.Grid item>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small" href={this.address()} target="_blank">
												<FeatherIcon>
													<Icons.MapPin />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography noWrap={noWrap}>{lead.address}</Mui.Typography>
											<Mui.Typography noWrap={noWrap}>
												<span>
													{lead.city}
													{lead.city && lead.state && <span>, </span>}
													{lead.state} {lead.zip}
												</span>
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{leadAnniversaries && leadAnniversaries.map(anniversary => (
								<Mui.Grid item key={anniversary.id}>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small">
												{anniversary.type.id === 1 && (
													<FeatherIcon>
														<Icons.Gift />
													</FeatherIcon>
												)}
												{anniversary.type.id === 2 && (
													<FeatherIcon>
														<Icons.Key />
													</FeatherIcon>
												)}
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography>
												{monthDayFormatter(anniversary.upcomingAnniversary)}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							))}
							{lead.agent && (
								<Mui.Grid item>
									<Mui.Grid container>
										<Mui.Grid item xs={4}>
											<Mui.IconButton size="small" href={urls.settingsAgent(lead.agent)}>
												<FeatherIcon>
													<Icons.Award />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<Mui.Typography>{lead.agent && lead.agent.name}</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							<Mui.Grid item>
								<Mui.Grid container justifyContent="space-between">
									<Mui.Grid item />
									<Mui.Grid item>
										<Mui.Button
											color="secondary"
											onClick={() => this.setState({ editLeadDialogIsOpen: true })}
										>
											Edit
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Divider />
							</Mui.Grid>
							{user.permissions.crmTags && typeTags.length > 0 && (
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid item xs={4}>
											<ColumnLabel>Type</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography className={classes.text}>
												{typeTags.map(tag => tag.text).join(", ")}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{user.permissions.crmTags && (
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid item xs={4}>
											<ColumnLabel>Tags</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item xs={8}>
											<LeadTagEdit lead={lead} />
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{user.permissions.crmTags && (
								<Mui.Grid item>
									<Mui.Divider />
								</Mui.Grid>
							)}
							{user.permissions.crm && env.leadRanking && (
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid item xs={4}>
											<ColumnLabel>Rating</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography className={classes.text}>
												<Ranking 
													leadId={lead.id} 
													sortedLeadsByRanking={leadRankData.sortedLeads} 
													ratingSize={leadRankData.ratingSize} 
												/>
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							<Mui.Grid item>
								<Mui.Grid container spacing={2} direction="column">
									<Mui.Grid item>
										<Mui.Grid container alignItems="center">
											<Mui.Grid item xs={4}>
												<ColumnLabel>Status</ColumnLabel>
											</Mui.Grid>
											<Mui.Grid item xs={4}>
												<Mui.Chip
													label={lead.status.label}
													className={
														lead.status.active ? classes.activeChip : classes.inactiveChip
													}
												/>
											</Mui.Grid>
											<Mui.Grid item xs={4}>
												{!lead.status.active && (
													<Mui.Button
														classes={{ label: classes.reactivateButtonLabel }}
														variant="contained"
														color="secondary"
														size="small"
														onClick={() => this.reactivateLead()}
													>
														Reactivate
													</Mui.Button>
												)}
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							{lead.source && (
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid item xs={4}>
											<ColumnLabel>Source</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography className={classes.text}>
												{lead.source}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							)}
							<Mui.Grid item>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item xs={4}>
										<ColumnLabel>Date Added</ColumnLabel>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Typography className={classes.text}>
											{numberedDateFormatter(lead.createdOn)}
										</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							{user.permissions.leadLastActive && lead.lastActiveOn &&
								<Mui.Grid item>
									<Mui.Grid container alignItems="center">
										<Mui.Grid item xs={4}>
											<ColumnLabel>Last Active</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography className={classes.text}>
												{numberedDateFormatter(lead.lastActiveOn)}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							}
						</Mui.Grid>
					</Mui.CardContent>
				</Mui.Card>
			</>
		);
	}
}

export const LeadDetails = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
