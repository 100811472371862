import React from "react";
import * as Mui from "@material-ui/core";
import { Lead } from "model/lead";
import { LeadTag } from "model/lead-tag";
import { RootState } from "redux/store";
import { getAllTagStrings, getTagsForLead, getCustomTagsForLead } from "redux/selector";
import { createLeadTag, deleteLeadTag } from "redux/slice/lead-tags";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { styles } from "./style";
import { CustomAutocomplete } from "component/shared/auto-complete";
		
const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		allTagStrings: getAllTagStrings(state),
		leadTags: getTagsForLead(state, ownProps.lead.id),
		customLeadTags: getCustomTagsForLead(state, ownProps.lead.id),
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLeadTag,
	deleteLeadTag,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	lead: Lead;
}

interface State {
	input: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			input: "",
		}
	}

	private get options() {
		let { allTagStrings } = this.props;
		const { input } = this.state;
	
		let texts = allTagStrings.sort();
		const options = texts.map(text => ({
			value: text,
			label: text,
		}));
		if (input) {
			const exists = options.map(tag => tag.label.toLowerCase()).includes(input.toLowerCase());
			if (!exists) {
				options.unshift({
					value: input,
					label: `Create "${input}"`,
				});
			}
		}
		return options;
	}

	private saveTag(text: string) {
		const { lead, leadTags } = this.props;
		if (text && !leadTags.find((tag) => tag.text.toLowerCase() === text.toLowerCase())) {
			this.setState({ 
				input: "",
			});
			this.props.createLeadTag({ leadTag: {
				leadId: lead.id,
				text: text,
			}});
		}
	}

	private deleteTag(leadTag: LeadTag) {
		this.props.deleteLeadTag({ leadTag: {
			...leadTag,
		}});
	}

	public render() {
		let { customLeadTags, classes } = this.props;
		const { options } = this;
		const { input } = this.state;
		return (
			<Mui.Grid container alignItems="center" spacing={1}>
				{!!customLeadTags.length &&
					<Mui.Grid item xs={12}>
						<Mui.Grid container alignItems="center">
							{customLeadTags.map((leadTag, index) => (
								<Mui.Grid key={index} item>
									<Mui.Chip
										key={index}
										label={leadTag.text}
										className={
											classes.tagChip
										}
										onDelete={(event) => {
											this.deleteTag(leadTag);
										}}
									/>
								</Mui.Grid>
							))}
						</Mui.Grid>
					</Mui.Grid>
				}
				<Mui.Grid item xs={12}>
					<CustomAutocomplete
						options={options}
						optionLabelExtractor={(option) => option.label}
						fullWidth
						margin="dense"
						placeholder="Enter tag..."
						textInputHeight={false}
						onInputChange={(event, value, reason) => {
							if (reason === "reset") {
								value = "";
							}
							this.setState({
								input: value
							})
						}}
						onChange={(event, option) => {
							if (option) {
								this.saveTag(option.value);
							}
						}}
						inputValue={input}
					/>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const LeadTagEdit = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);