import { arrayToById, objectToById } from "redux/normalize";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { CampaignMessage, NewCampaignMessage } from "model/campaign-message";
import { loginReset } from "redux/slice/authentication";
import { CampaignMessageResource } from "api/resource/campaign-message";

export interface CampaignMessagesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignMessageResource;
	};
};

const initialState: CampaignMessagesState = {
	byId: {},
};

const fetchCampaignMessages = createAsyncThunk("campaignMessages/fetchCampaignMessages", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignMessagesLoading());
	const result = api.getCampaignMessages();
	result
		.then((campaignMessages) => thunkAPI.dispatch(campaignMessagesLoaded(campaignMessages)))
		.catch(error => thunkAPI.dispatch(campaignMessagesFailed(error)));
	return result;
});

const createCampaignMessage = createAsyncThunk("campaignMessages/createCampaignMessage", async (payload: {campaignMessage: NewCampaignMessage}, thunkAPI) => {
	thunkAPI.dispatch(campaignMessagesLoading());
	const result = api.createCampaignMessage(payload.campaignMessage);
	result
		.then((campaignMessage) => thunkAPI.dispatch(campaignMessageLoaded(campaignMessage)))
		.catch(error => thunkAPI.dispatch(campaignMessagesFailed(error)));
	return result;
});

const updateCampaignMessage = createAsyncThunk("campaignMessages/updateCampaignMessage", async (payload: {campaignMessage: CampaignMessage}, thunkAPI) => {
	thunkAPI.dispatch(campaignMessagesLoading());
	const result = api.updateCampaignMessage(payload.campaignMessage);
	result
		.then((campaignMessage) => thunkAPI.dispatch(campaignMessageLoaded(campaignMessage)))
		.catch(error => thunkAPI.dispatch(campaignMessagesFailed(error)));
	return result;
});

const deleteCampaignMessage = createAsyncThunk("campaignMessages/deleteCampaignMessage", async (payload: {campaignMessage: CampaignMessage}, thunkAPI) => {
	const result = api.deleteCampaignMessage(payload.campaignMessage);
	result
		.then(() => thunkAPI.dispatch(fetchCampaignMessages()))
		.catch(error => thunkAPI.dispatch(campaignMessagesFailed(error)));
	return result;
});

export const campaignMessagesSlice = createSlice({
	name: "campaignMessages",
	initialState,
	reducers: {
		campaignMessagesLoading: (state) => {
			state.loading = true;
		},
		campaignMessagesLoaded: (state, action: PayloadAction<CampaignMessageResource[]>) => {
			state.error = null;
			state.loading = false;
			state.byId = arrayToById(action.payload, "id");
		},
		campaignMessageLoaded: (state, action: PayloadAction<CampaignMessageResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId,
				...byId,
			}
		},
		campaignMessagesFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { campaignMessageLoaded, campaignMessagesFailed, campaignMessagesLoaded, campaignMessagesLoading } = campaignMessagesSlice.actions;
export { fetchCampaignMessages, createCampaignMessage, deleteCampaignMessage, updateCampaignMessage };