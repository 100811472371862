import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { updateCampaignCampaignMessages } from "redux/slice/campaign-campaign-messages"
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./styles";
import { connect } from "react-redux";
import { DragDropContext, Droppable, DropResult, DragStart } from "react-beautiful-dnd";
import { Card } from "./card";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { urls } from "routes/urls";
import { getCampaignCampaignMessagesByCampaignId } from "redux/selector";
import { getCampaignById } from "redux/selector";
import { TimeUnitType } from "type/time-unit";
import * as Router from "react-router-dom";
import { fetchCampaignCampaignMessages } from "redux/slice/campaign-campaign-messages"
import { getUserUnsafe } from "redux/selector";
import { generateId } from "shared/generate-id";

interface Props extends
	OwnProps,
	WithMediaQuery,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface OwnProps {
	campaignId: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = getUserUnsafe(state);
	const campaignCampaignMessages = getCampaignCampaignMessagesByCampaignId(state, ownProps.campaignId);
	const campaign = getCampaignById(state, ownProps.campaignId);
	return {
		loading: state.campaignCampaignMessages.loading,
		campaignCampaignMessages,
		campaign,
		user,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaignCampaignMessages,
	fetchCampaignCampaignMessages,
}, dispatch)

interface State {
	dndOrder: number[];
	dragging: string | null;
	anchorEl: HTMLElement | null;
	addMenuOpen: boolean
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			dndOrder: [],
			dragging: null,
			anchorEl: null,
			addMenuOpen: false,
		};
		this.newMessageRef = React.createRef() 
	}

	private newMessageRef: React.RefObject<HTMLInputElement>;

	public componentDidMount() {
		if (this.props.campaignCampaignMessages) {
			this.props.fetchCampaignCampaignMessages();
			const dndOrder = this.setDndOrder();
			this.setState({dndOrder});
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.campaignCampaignMessages.length !== this.props.campaignCampaignMessages.length) {
			this.props.fetchCampaignCampaignMessages();
			const dndOrder = this.setDndOrder();
			this.setState({dndOrder});
		}
	}

	private setDndOrder = () => {
		const {campaignCampaignMessages} = this.props;
		const dndOrder: number[] = []
		campaignCampaignMessages.forEach((campaignCampaignMessage) => {
			dndOrder.push(campaignCampaignMessage.id)
		});
		return dndOrder;
	}

	public copy = () => {
		if (this.newMessageRef.current) {
			this.newMessageRef.current.scrollIntoView();
		}
	}

	private save = (campaignCampaignMessages: CampaignCampaignMessage[]) => {
		const dndOrder = this.setDndOrder();
		this.setState({dndOrder}, () => {
			if (this.state.dndOrder.length > 0) {		
				let index = 1;
				campaignCampaignMessages.forEach((campaignCampaignMessage) => {
					campaignCampaignMessage.messageOrder = index;
					index++;
				});
				this.props.updateCampaignCampaignMessages({campaignCampaignMessages});
			}
		});
	}

	private onDragStart = (start: DragStart) => {
		this.setState({dragging: start.draggableId});
	}

	private onDragEnd = (result: DropResult) => {
		const { campaignCampaignMessages } = this.props;
		const { draggableId, source, destination } = result;
		const dropped = campaignCampaignMessages.find((campaignCampaignMessage) => 
			campaignCampaignMessage.id === parseInt(draggableId));
		if (!dropped || !destination) {
			this.setState({dragging: null});
			return;
		}
		if ((destination.droppableId === source.droppableId) && (destination.index === source.index)) {
			this.setState({dragging: null});
			return;
		}
		campaignCampaignMessages.splice(source.index, 1);
		campaignCampaignMessages.splice(destination.index, 0, {...dropped});
		this.save(campaignCampaignMessages);
		this.setState({dragging: null});
	}

	private getWithDayNumber = () => {
		const dayInMinutes: number = 1440;
		let dayNumber: number = 0;
		let fromTotalDays = 1
		return this.props.campaignCampaignMessages.map(campaignCampaignMessage => {
			if (campaignCampaignMessage.campaignMessage.timeToSendNumber && campaignCampaignMessage.campaignMessage.timeToSendUnit) {
				if ((campaignCampaignMessage.campaignMessage.timeToSendUnit === TimeUnitType.MINUTES)) {
					fromTotalDays = fromTotalDays + campaignCampaignMessage.campaignMessage.timeToSendNumber / dayInMinutes;
					dayNumber = Math.floor(fromTotalDays);
				}
				if (campaignCampaignMessage.campaignMessage.timeToSendUnit === TimeUnitType.DAYS) {
					fromTotalDays = fromTotalDays + campaignCampaignMessage.campaignMessage.timeToSendNumber * dayInMinutes / dayInMinutes;
					dayNumber = Math.floor(fromTotalDays);
				}
			}
			campaignCampaignMessage.dayNumber = dayNumber;
			return campaignCampaignMessage;
		});
	}

	public renderAddMessage = () => {
		const { campaign, history, user } = this.props;
		const addMessageMenuId = generateId();
		return (
			<>
				<Mui.Button 
					color="secondary"
					variant="contained"
					aria-controls={addMessageMenuId}
					aria-haspopup="true"
					onClick={(event) => this.handleActionsClick(event.currentTarget)}
					startIcon={
						<FeatherIcon>
							<Icons.PlusCircle />
						</FeatherIcon>
					}
				>
						Add Message 
				</Mui.Button>
				{user && user.permissions.dripMms && user.textMessageOptIn &&
					<Mui.Menu
						id={addMessageMenuId}
						anchorEl={this.state.anchorEl}
						keepMounted
						open={!!this.state.anchorEl}
						onClose={() => this.setState({anchorEl: null})}
						anchorOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<Mui.MenuItem onClick={() => {
							const url = urls.campaignCampaignEmailMessageNew(campaign);
							if (url) {
								history.push(url);
							}
						}}>
							<Mui.ListItemIcon>
								<FeatherIcon>
									<Icons.Mail />
								</FeatherIcon>
							</Mui.ListItemIcon>
							<Mui.ListItemText primary="Email" />
							
						</Mui.MenuItem>
						<Mui.MenuItem onClick={() => {
							const url = urls.campaignCampaignTextMessageNew(campaign);
							if (url) {
								history.push(url);
							}
						}}>
							<Mui.ListItemIcon>
								<FeatherIcon>
									<Icons.MessageSquare />
								</FeatherIcon>
							</Mui.ListItemIcon>
							<Mui.ListItemText primary="Text Message" />
						</Mui.MenuItem>
					</Mui.Menu>
				}
				
			</>
		)
	}

	private handleActionsClick(event: HTMLElement) {
		const { user, history, campaign } = this.props;
		if (user && user.permissions.dripMms && user.textMessageOptIn) {
			this.setState({anchorEl: event})
		} else {
			const url = urls.campaignCampaignEmailMessageNew(campaign);
			if (url) {
				history.push(url);
			}
		}
		
	}

	public render() {
		const { classes, campaignCampaignMessages, mediaQuery } = this.props;
		return (
			<Mui.Grid container direction="column" wrap="nowrap" justifyContent="center">
				{campaignCampaignMessages.length > 0 ? ( 
					<Mui.Grid item>
						<Mui.Grid container direction="column" wrap="nowrap" justifyContent="center">
							<Mui.Grid item className={classes.timeline}>
								<Mui.Typography className={classes.timelineLabel}>
									Subscriber Added
								</Mui.Typography>
								<Mui.Typography className={classes.TimelineIcon}>
									<FeatherIcon fontSize="inherit" >
										<Icons.ArrowDown/>
									</FeatherIcon>	
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Typography color="textSecondary">*Data provided only within the last 30 days is shown</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item >
								<DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
									<Droppable droppableId="column-1">
										{provided => (
											<Mui.Grid container direction="column" wrap="nowrap" innerRef={provided.innerRef} {...provided.droppableProps}>
												{this.state.dndOrder.length > 0 && this.state.dndOrder.map((id, index) => {
													const campaignCampaignMessage = this.getWithDayNumber().find((campaignCampaignMessage) => campaignCampaignMessage.id === id);												
													return (
														<Mui.Grid item key={index}>
															{campaignCampaignMessage && (
																<Card
																	campaignCampaignMessage={campaignCampaignMessage}
																	key={campaignCampaignMessage.id}
																	index={index}
																	dragging={this.state.dragging}
																	onCopy={() => this.copy()}
																/>
															)}
														</Mui.Grid>
													)
												})}
												{provided.placeholder}
											</Mui.Grid>
										)}
									</Droppable>
								</DragDropContext>
							</Mui.Grid>
							<Mui.Grid item className={classes.timeline}>
								<Mui.Typography className={classes.timelineLabel}>
									Campaign Complete
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item ref={this.newMessageRef} className={classes.add} style={{textAlign: mediaQuery ? "center" : undefined}}>
								{this.renderAddMessage()}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					) : (
						<>
							<Mui.Grid item>
								<Mui.Typography variant="h2" className={classes.addNew}>
									Add a new messages
								</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item ref={this.newMessageRef} className={classes.addNewButton}>
								{this.renderAddMessage()}
							</Mui.Grid>
						</>
					)}
			</Mui.Grid>			
		);
	}
}

export const Messages = Router.withRouter(withMediaQuery("(max-width:600px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)))
);