import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";

interface OwnProps {
}

type Props = OwnProps & Mui.WithStyles<typeof styles> & Mui.TablePaginationProps;

class Component extends React.Component<Props> {

	public render() {
		const { classes, ...others } = this.props;
		return (
			<Mui.TablePagination
				{...others}
				component={"div" as any}
				classes={{
					root: classes.root,
					spacer: classes.spacer,
					actions: classes.actions,
				}}
				labelRowsPerPage=""
				labelDisplayedRows={(paginationInfo) => {
					const { from, to, count } = paginationInfo;
					if (count === 0) {
						return "No results";
					}
					if (count === 1) {
						return "1 result";
					}
					if (count > 1) {
						return `${from}-${to} of ${count} results`;
					}
				}}
			/>
		);
	}
}

export const TablePagination = Mui.withStyles(styles)(Component);