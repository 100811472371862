import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { getPayload, RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { getActiveLeads } from "redux/selector";
import { createLead } from "redux/slice/leads";
import * as yup from "yup";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { Lead } from "model/lead";
import { NewCampaignSubscription } from "model/campaign-subscription";
import { Campaign } from "model/campaign";
import { createCampaignSubscription } from "redux/slice/campaign-subscriptions";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";
import { LeadStatus } from "type/lead-status";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		allLeads: getActiveLeads(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLead,
	createCampaignSubscription, 
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: () => void;
	campaign: Campaign;
	subscribedLeads: Lead[];
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

interface State {
	input: string;
	leadIds: Set<number>;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			input: "",
			leadIds: new Set(),
		};
	}

	public componentDidUpdate() {}

	private get options() {
		let { allLeads } = this.props;
		const { input } = this.state;
		const options = allLeads.map(lead => {
			const label = this.isSubscribed(lead) ? lead.label + " (subscribed)" : lead.label
			return {
				id: lead.id,
				email: lead.email,
				label: label,
			}
		});
		if (input) {
			const schema = yup.string().email();
			const valid = schema.isValidSync(input);
			if (valid) {
				const exists = allLeads.some(lead => lead.email === input);
				if (!exists) {
					options.unshift({
						id: 0,
						email: input,
						label: `Create "${input}"`,
					});
				}
			}
		}
		return options;
	}

	private isSubscribed(lead: Lead) {
		let isSubscribed = false;
		const subscribedLead = this.props.subscribedLeads.find((value) => value.id === lead.id)
		if (subscribedLead) {
			isSubscribed = true;
		}
		return isSubscribed;
	}

	private async createLead(email: string) {
		const { createLead } = this.props;
		const lead = await getPayload(createLead({ lead: {
			email: email,
			status: LeadStatus.ACTIVE,
			label: email,
		}}));
		if (lead.id) {
			this.addLeadIds([ lead.id ]);
		}
	}

	private addLeadIds(ids: number[]) {
		const { leadIds } = this.state;
		ids.forEach((id) => {
			leadIds.add(id);
		});
		this.setState({
			leadIds, 
		});
	}

	private removeLeadId(id: number) {
		const { leadIds } = this.state;
		leadIds.delete(id);
		this.setState({
			leadIds, 
		});
	}

	private saveSubscribers(leads: Lead[]) {
		for (let i = 0; i < leads.length; i++) {
			const lead = leads[i];
			const campaignSubscription: NewCampaignSubscription = {
				campaign: this.props.campaign,
				lead,
				status: CampaignSubscriptionStatus.ACTIVE,
			}
			this.props.createCampaignSubscription({subscription: campaignSubscription});
		}
		this.props.onClose();
	};

	public render() {
		const {open, onClose, classes, allLeads} = this.props;
		const { input, leadIds } = this.state;
		const leads = allLeads.filter((lead) => {
			return leadIds.has(lead.id);
		});
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper" fullWidth={true} maxWidth="md">
				<Mui.DialogTitle>
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid item xs={12}>Add a Subscriber</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={1} >
						<Mui.Grid item xs={12}>
							<CustomAutocomplete
								options={this.options}
								optionLabelExtractor={(option) => option.label}
								fullWidth
								margin="dense"
								label="Search Your Leads"
								placeholder="Select Leads"
								textInputHeight={false}
								onInputChange={(event, value, reason) => {
									if (reason === "reset") {
										value = "";
									}
									this.setState({
										input: value
									})
								}}
								onChange={(event, option) => {
									if (option) {
										const id = option.id as number;
										if (id) {
											this.addLeadIds([ id ]);
										} else {
											this.createLead(option.email);
										}
									}
								}}
								inputValue={input}
							/>
						</Mui.Grid>
						{!!leads.length && (
							<Mui.Grid className={classes.tags} item>
								<Mui.Grid container spacing={1}>
									{leads.map((lead) => { 
										const subscribed = this.isSubscribed(lead);
										return (
											<Mui.Grid key={lead.id} item>
												<Mui.Chip
													style={{color: subscribed ? "#aaa" : undefined}}
													label={subscribed ? lead.label + " (Subscribed)" : lead.label + " (Add)"}
													onDelete={(event) => {
														this.removeLeadId(lead.id);
													}}
												/>
											</Mui.Grid>)
								})}
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					{this.props.open &&
						<Mui.Button
							variant={"contained"}
							color="secondary"
							onClick={() => this.saveSubscribers(leads)}
						>
							<>
								Add Subscribers
								
							</>
						</Mui.Button>
					}
				</Mui.DialogActions>
			</Dialog>
		);
	}

}

export const AddSubscription = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);