import * as env from "shared/env";
import * as live from "./one-live";
import * as mock from "./one-mock";
import { OneUserResource } from "./resource/one-user-now";

export const getUser = () => {
	return api().getUser() as Promise<OneUserResource>;
}

const api = (): typeof live | typeof mock => {
	if (env.live) {
		return live;
	} else {
		return mock;
	}
}