import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { styles } from './styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Avatar } from "component/shared/avatar";
import { Agent } from 'model/agent';

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
	position: number;
	onDelete: () => void;
}

interface State {
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators({
	}, dispatch);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
		};
	}

	render() {
		const { classes, position, agent, onDelete } = this.props;
		return (
			<Mui.Card>	
				<Mui.Tooltip title={agent.enableAgentLink ? "" : "This agent will not appear in the agent list because ‘Enable Link on Agent Roster’ is turned off"}>																		
					<Mui.CardContent className={agent.enableAgentLink ? classes.cardContent : classes.greyCardContent}>
						<Mui.Grid container direction="row" spacing={1} alignItems="center">
							<Mui.Grid item>
								<DragIndicatorIcon />
							</Mui.Grid>
							<Mui.Grid item>
								<Mui.Box display="flex" alignItems="center" flexDirection="row">
									<Avatar 
										phrase={agent.label} 
										src={agent.photoUrl} 
									/>
									<Mui.Typography className={classes.agentLabel} >
										{agent.label}
									</Mui.Typography>
								</Mui.Box>
							</Mui.Grid>
							<Mui.Grid item className={classes.positionItem}>
								<Mui.Chip label={position} size="small" className={classes.position} />
							</Mui.Grid>
						</Mui.Grid>
					</Mui.CardContent>
				</Mui.Tooltip>
			</Mui.Card>
		);
	}
}

export const Card = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));