import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { getLeadsByAgentId, getLeadsByUnassignedAgent, getUpcommingLeadAnniversaries } from "redux/selector";
import { connect } from "react-redux";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { styles } from "./style";
import { monthDayFormatter } from "shared/date-formatter";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	const leadAnniversaries = getUpcommingLeadAnniversaries(state);
	const agentId = state.agents.value;
	const leadsByAgent = getLeadsByAgentId(state, agentId);
	const leadsByUnassigned = getLeadsByUnassignedAgent(state);
	const unassigned = agentId === -1;
	return {
		leadAnniversaries: !agentId ? (!unassigned ? leadAnniversaries : leadAnniversaries.filter(anniversary => leadsByUnassigned.some(agent => agent.id === anniversary.lead.id))) : leadAnniversaries.filter(anniversary => leadsByAgent.some(agent => agent.id === anniversary.lead.id)),
		loading: state.leadAnniversaries.loading,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch)

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> 
{
}

class Component extends React.Component<Props> {
	public render() {
		const { leadAnniversaries, loading } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Upcoming Dates</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
							{!!loading && (
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							<Mui.Grid container spacing={1} direction="column">
								{!loading && !leadAnniversaries.length && !loading &&
									<Mui.Grid item>
										<Mui.Typography align="center">You have no upcoming birthdays or transaction anniversaries in the next 7 days.</Mui.Typography>
									</Mui.Grid>
								}
								{!loading && leadAnniversaries.map((anniversary, index) => {
									const { lead } = anniversary;
									const url = urls.lead(lead);
									return (
										<Mui.Grid item key={index}>
											<Mui.Grid container>
												<Mui.Grid item xs={5}>
													<Mui.Typography noWrap>
														<Mui.Link
															component={RouterLinkWrapper}
															to={url}
														>
															{lead.label}
														</Mui.Link>
													</Mui.Typography>
												</Mui.Grid>
												<Mui.Grid item xs={2}>
													{monthDayFormatter(anniversary.upcomingAnniversary)}
												</Mui.Grid>
												<Mui.Grid item xs={5}>
													<Mui.Typography noWrap>
														{anniversary.type.label}
													</Mui.Typography>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									)
})
								}
							</Mui.Grid>
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

}

export const UpcomingDates = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));