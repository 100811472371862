import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState, getPayload } from "redux/store";
import { styles } from "./style";
import * as yup from "yup";
import { Agent } from "model/agent";
import { validate } from "shared/yup";
import { updateAgent, resetError} from "redux/slice/agents";
import { SaveButton } from "component/shared/save-button";
import * as Icons from "react-feather";
import { getAgents, getUser } from "redux/selector";
import MuiAlert from '@material-ui/lab/Alert';
import { FeatherIcon } from "component/shared/feather-icon";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
	onClose: () => void;
}

interface State {
	username: string;
	password: string;
	admin: boolean;
	submitted: boolean;
	passwordFocus: boolean;
	showPassword: boolean;
	openSnackbar: boolean;
	snackbarMessage: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = getUser(state);
	const agents = getAgents(state);
	return {
		usernameConflict: state.agents.error === "conflict",
		loading: state.agents.loading,
		user,
		agents,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			resetError,
			updateAgent,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			username: this.props.agent.username || "",
			password: "",
			admin: this.props.agent.admin || false,
			submitted: false,
			passwordFocus: false,
			showPassword: false,
			openSnackbar: false,
			snackbarMessage: "",
		};
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { usernameConflict, resetError } = this.props;
		if (prevProps.usernameConflict !== this.props.usernameConflict) {
			if (this.props.usernameConflict) {
				this.setState({
					openSnackbar: true,
					snackbarMessage: "Please fix the username errors and try again",
				});
			}
		}
	}

	private async save() {
		const { agent, onClose } = this.props;
		const { username, password, admin } = this.state;
		const agentResource = getPayload(await this.props.updateAgent( { agent: 
			{
				...agent,
				username,
				password,
				admin,
			}
		}));
		if (agentResource) {
			onClose();
		}
	}

	private validationSchema = {
		username: yup
			.string()
			.test(
				"username-length",
				"Username must be 4-75 characters",
				(username) => {
					const correctLength = username && (username.length >= 4 && username.length <= 75);
					return !!!username || !!correctLength;
				}
			)
			.test(
				"username-required",
				"A Username is required",
				(username) => {
					let result = true;
					if (this.props.agent && this.props.agent.username ) {
						if (!!!username) {
							result = false;
						}
					}
					return result;
				}
			)
			.test(
				"username-conflict",
				"Username already exists",
				(username) => {
					let result = true;
					if (this.props.usernameConflict) {
						result = false;
					}
					return result;
				}
			),
		password: yup
			.string()
			.test(
				"password-length",
				"Password must be 4-75 characters",
				(password) => {
					const correctLength = password && (password.length >= 4 && password.length <= 75);
					return !!!password || !!correctLength;
				}
			)
			.test(
				"password-username-required",
				"A Username is required when setting a password",
				(password) => {
					return !!!password || !!this.state.username;
				}
			),
	};

	private minimumAdmin() {
		return this.props.agents.filter((agent) => agent.admin).length === 1;
	}

	render() {
		const { usernameConflict, classes, loading, user, agent } = this.props;
		const { password, username, submitted, admin, passwordFocus, showPassword } = this.state;
		const errors = validate(this.validationSchema, {...this.state});
		return (
			<Mui.Grid container direction="column">
				<Mui.Grid item xs={12}>
					<Mui.TextField
						error={(!!usernameConflict) || !!(errors && errors.username)}
						fullWidth
						helperText={
							!!usernameConflict ? "Please choose a different username and try again" :
							errors && errors.username
						}
						inputProps={{
							autoComplete: "off",
						}}
						label="Username"
						margin="dense"
						onChange={(event) => {
							if (usernameConflict) {
								this.props.resetError();
							}	
							this.setState({username: event.target.value})
						}}
						value={username}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.Grid container alignItems="center">
						<Mui.Grid item style={{ flexGrow: 1 }}>
							<Mui.TextField
								error={!!errors && !!errors.password}
								FormHelperTextProps={{
									className:
										!!errors && !!errors.password
											? classes.errorMessage
											: undefined,
								}}
								fullWidth
								helperText={errors && errors.password}
								inputProps={{
									autoComplete: "new-password",
									onFocus: () => this.setState({passwordFocus: true}),
									onBlur: () => this.setState({passwordFocus: false}),
									
								}}
								InputProps={{
									endAdornment: (
										<Mui.InputAdornment position="end">
											<Mui.IconButton onClick={(event) => this.setState({showPassword: !showPassword})}>
												<FeatherIcon>
													{showPassword ? <Icons.Eye /> : <Icons.EyeOff />}
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.InputAdornment>
									)
								}}
								placeholder={passwordFocus ? "" : "********"}
								margin="dense"
								onChange={(event) =>
									this.setState({password: event.target.value})
								}
								type={showPassword ? undefined : "password"}
								value={password}
								variant="outlined"
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					{(user.agentId !== agent.id && !user.permissions.oneCrm) && (
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={admin} 
									onChange={() => this.setState({admin: !admin})} 
									name="admin" 
									disabled={agent.admin && this.minimumAdmin()}
								/>
							}
							label="Administrator"
						/>
					)}
					{agent.admin && this.minimumAdmin() && (
						<MuiAlert severity="info" variant="outlined" classes={{message: classes.infoMessage, icon: classes.infoMessageIcon, root: classes.infoRoot}}>
							<Mui.Typography>
								There must be at least one administrator.
							</Mui.Typography>
						</MuiAlert>
					)}
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container spacing={2} style={{marginTop: 15}}>
						<Mui.Grid item>
							<SaveButton 
								loading={submitted && !!loading}
								error={submitted && errors ? "Please fix the highlighted errors" : undefined} 
								label={{
									primary: "Save",
									inProgress: "Saving...",
									completed: "Saved",
								}}
								snackBar={true}
								onClick={() => {
									this.setState({submitted: true});
									this.save();
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button 
								variant="contained"
								onClick={() => {
									this.props.onClose()
									this.props.resetError()
								}}
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Login = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));