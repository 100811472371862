import React from "react";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { getActiveLeads, getAgents, getCallNotes } from "redux/selector";
import { Agent } from "model/agent";
import { withTheme } from "@material-ui/core";
import { Table } from "component/shared/table";
import moment from "moment";

const mapStateToProps = (state: RootState) => {

  return {
	agents: getAgents(state),
	leads: getActiveLeads(state),
	callNotes: getCallNotes(state),
  };
};

interface Props extends
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {}

class Component extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      
    };
  }
  
  render() {
    const { agents, leads, callNotes, classes } = this.props
    return (
		<Mui.Grid container direction="column" spacing={2}>
		<Mui.Grid item>
			<Mui.Typography variant="h3">Team Stats</Mui.Typography>
		</Mui.Grid>
		<Mui.Grid item>
			<Mui.Card>
						<Table
							items={agents}
							columns={[
								{
									id: "agents",
									title: "Agent",
									value: agent => {
										return (
											<Mui.Link href={`settings/broker/agent/${agent.id}`} className={classes.name}>{agent.name || agent.email}</Mui.Link>
										)
									},
									sort: (agent1, agent2) => {
										return (agent2.name || "").toLowerCase() < (agent1.name || "").toLowerCase() ? -1 : 1;
									},
								},
								{
									id: "activeLeads",
									title: "Active Leads",
									value: agent => {
										const activeLeads = leads.filter(lead => lead.agent?.id === agent.id)
										return (
											<Mui.Typography>
												{activeLeads.length}
											</Mui.Typography>
										)
									},
									sort: (agent1, agent2) => {
										const activeLeadsCount1 = leads.filter(lead => lead.agent?.id === agent1.id).length;
										const activeLeadsCount2 = leads.filter(lead => lead.agent?.id === agent2.id).length;
									
										return activeLeadsCount2 - activeLeadsCount1;
									  }
								},
								{
									id: "uncontactedLeads",
									title: "Uncontacted Leads",
									value: agent => {
										const agentLeads = leads.filter(lead => lead.agent?.id === agent.id);
										const contactedLeadIds = new Set(
										  callNotes
											.filter(note => agentLeads.some(lead => lead.id === note.leadId))
											.map(note => note.leadId)
										);
										const numUncontactedLeads = agentLeads.length - contactedLeadIds.size;
									  
										return (
											<Mui.Typography>
												{numUncontactedLeads}
											</Mui.Typography>
										);
									  },
									  sort: (agent1, agent2) => {
										// Repeat the same logic for each agent to calculate uncontacted leads
										const calculateUncontactedLeads = (agent: Agent) => {
										  const agentLeads = leads.filter(lead => lead.agent?.id === agent.id);
										  const contactedLeadIds = new Set(
											callNotes
											  .filter(note => agentLeads.some(lead => lead.id === note.leadId))
											  .map(note => note.leadId)
										  );
										  return agentLeads.length - contactedLeadIds.size;
										};
									
										const uncontactedLeads1 = calculateUncontactedLeads(agent1);
										const uncontactedLeads2 = calculateUncontactedLeads(agent2);
									
										return uncontactedLeads2 - uncontactedLeads1;
									  }
								},
								{
									id: "callsLogged",
									title: (
										<div>
											Calls Logged
											<br />
											<Mui.Typography style={{fontSize: 12}}>(last 7 days)</Mui.Typography>
										</div>
									),
									value: agent => {
										const sevenDaysAgo = moment().subtract(7, 'days');
										const agentLeads = leads.filter(lead => lead.agent?.id === agent.id);
										const callsInLastSevenDays = callNotes.filter(note =>
										  agentLeads.some(lead => lead.id === note.leadId) &&
										  moment(note.createdOn).isAfter(sevenDaysAgo)
										).length;
									
										return (
											<Mui.Typography>
												{callsInLastSevenDays}
											</Mui.Typography>
										);
									},
									sort: (agent1, agent2) => {
										const calculateCallsLogged = (agent: Agent) => {
										  const sevenDaysAgo = moment().subtract(7, 'days');
										  const agentLeads = leads.filter(lead => lead.agent?.id === agent.id);
										  return callNotes.filter(note =>
											agentLeads.some(lead => lead.id === note.leadId) &&
											moment(note.createdOn).isAfter(sevenDaysAgo)
										  ).length;
										};
									
										const callsLogged1 = calculateCallsLogged(agent1);
										const callsLogged2 = calculateCallsLogged(agent2);
									
										return callsLogged2 - callsLogged1;
									  }
								}
							]}
							hidePagination
						/>
			</Mui.Card>
		</Mui.Grid>
	</Mui.Grid>
    );
  }
}

export const TeamStats = Mui.withStyles(styles)(
  connect(mapStateToProps)(withTheme(Component))
);
