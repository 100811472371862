class Type {
	public static APPROVED = new Type("approved");
	public static PENDING = new Type("pending");
	public static UNVERIFIED = new Type("unverified");
	public static FAILED = new Type("failed");

	private constructor(
		public readonly id: string,
	) {
	}

	public get label() {
		return this.id;
	}

	public get approved() {
		return this === Type.APPROVED;
	}

	public get pending() {
		return this === Type.PENDING;
	}

	public get unverified() {
		return this === Type.UNVERIFIED;
	}

	public get failed() {
		return this === Type.FAILED;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as TextMessageCampaignStatus };