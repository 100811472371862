import { arrayToById, objectToById } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { Lead, NewLead } from "model/lead";
import { loginReset } from "redux/slice/authentication";
import { LeadResource } from "api/resource/lead";

export interface LeadsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: LeadResource;
	};
};

const initialState: LeadsState = {
	byId: {},
};

const fetchLeads = createAsyncThunk("leads/fetchLeads", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadsLoading());
	const result = api.getLeads();
	result
		.then(leads => thunkAPI.dispatch(leadsLoaded(leads)))
		.catch(error => thunkAPI.dispatch(leadsFailed(error)));
	return result;
});

const updateLead = createAsyncThunk("leads/updateLead", async (payload: {lead: Lead}, thunkAPI) => {
	thunkAPI.dispatch(leadsLoading());
	const result = api.updateLead(payload.lead);
	result
		.then(lead => thunkAPI.dispatch(leadLoaded(lead)))
		.catch(error => thunkAPI.dispatch(leadsFailed(error)));
	return result;
});

const createLead = createAsyncThunk("leads/createLead", async (payload: {lead: NewLead}, thunkAPI) => {
	thunkAPI.dispatch(leadsLoading());
	const result = api.createLead(payload.lead);
	result
		.then(lead => thunkAPI.dispatch(leadLoaded(lead)))
		.catch(error => thunkAPI.dispatch(leadsFailed(error)));
	return result;
});

export const leadsSlice = createSlice({
	name: "leads",
	initialState,
	reducers: {
		leadsLoading: (state) => {
			state.loading = true;
		},
		leadsLoaded: (state, action: PayloadAction<LeadResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		leadLoaded: (state, action: PayloadAction<LeadResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId
			};
		},
		leadsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
    }
});

export const { leadsLoading, leadsLoaded, leadLoaded, leadsFailed } = leadsSlice.actions;
export { fetchLeads, createLead, updateLead };