import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		textField: {
			width: "100%",
		},
		chipContainer: {
			cursor: "pointer",
		},
		chip: {
			margin: 3
		}
	});
};