import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		agentGrid: {
			display: 'flex', 
			alignItems: 'center'
		},
		agentLink: {
			fontSize: 15,
			color: theme.palette.secondary.main,
			textDecoration: "none",
			whiteSpace: "nowrap",
			overflow: 'hidden', 
			textOverflow: 'ellipsis',
			maxWidth: "140px",
			"&:hover": {
				textDecoration: "underline"
			}
		},
		avatarLink: {
			textDecoration: "none",
			flexShrink: 0, 
			marginRight: 5
		},
		iconGrid: {
			marginTop: 5
		},
		unassignedIcon: {
			border: `3px solid ${theme.palette.secondary.main}`,
			borderRadius: 50,
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		unassignedText: {
			paddingLeft: 5
		},
	});
