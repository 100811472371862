import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Campaign } from "model/campaign";
import { timeDateFormatter } from "shared/date-formatter";
import { ColumnLabel } from "component/shared/column-label";
import { CampaignCopyButton } from "component/shared/campaign-copy-button";
import { CampaignDeleteButton } from "component/shared/campaign-delete-button";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";

interface Props extends
	Mui.WithStyles<typeof styles>
{
	campaign: Campaign;
}

class Component extends React.Component<Props> {
	
	public render() {
		const { classes, campaign } = this.props;
		const campaignCampaignMessage = campaign.campaignCampaignMessages[0];
		if (!campaignCampaignMessage) {
			return null;
		}
		const campaignMessage = campaignCampaignMessage.campaignMessage;
		if (!campaignMessage) {
			return null;
		}
		let scheduledOn;
		{
			const subscription = campaign.subscriptions[0];
			if (subscription) {
				scheduledOn	= subscription.createdOn.add(campaignMessage.timeToSendNumber, "m");
			}
		}
		return (
			<Mui.Card>
				<Mui.CardContent>
					<Mui.Grid container justifyContent="space-between" alignItems="center" spacing={4}>
						<Mui.Grid item>
							<Mui.Grid container direction="column" spacing={2}>
								<Mui.Grid item>
									<Mui.Grid container direction="row">
										<Mui.Grid item>
											<ColumnLabel className={classes.label}>
												Subject Line
											</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography>
												{campaignMessage.subject}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Grid container>
										<Mui.Grid item>
											<ColumnLabel className={classes.label}>
												Last Saved
											</ColumnLabel>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Typography>
												{timeDateFormatter(campaign.dateModified || campaign.dateCreated)}
											</Mui.Typography>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								{scheduledOn && (
									<Mui.Grid item>
										<Mui.Typography>
										This message is scheduled to send at {timeDateFormatter(scheduledOn)}
										</Mui.Typography>
									</Mui.Grid>
								)}
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container spacing={2} justifyContent="flex-end">
								<Mui.Grid item>
									<Mui.Grid container spacing={2}>
										<Mui.Grid item>
											<Mui.Button
												component={RouterLinkWrapper}
												to={urls.campaignDraftEmail(campaign.id)}
												variant="contained"
												color="secondary"
											>
												Edit
											</Mui.Button>
										</Mui.Grid>
										<Mui.Grid item>
											<CampaignCopyButton campaign={campaign} />
										</Mui.Grid>
										<Mui.Grid item>
											<CampaignDeleteButton campaign={campaign} />
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardContent>		
			</Mui.Card>
		);
	}

}

export const Card = Mui.withStyles(styles)(Component);