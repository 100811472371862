import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		saveRow: {
			textAlign: "right",
		},
		mainImage: {
			width: 370, 
			height: 190, 
			marginTop:40, 
			objectFit: "cover"
		}
	});
};