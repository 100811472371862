import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { arrayToById, objectToById } from "redux/normalize";
import { CampaignCampaignMessage, NewCampaignCampaignMessage } from "model/campaign-campaign-message";
import { CampaignCampaignMessageSendEmail } from "model/campaign-campaign-message-send-email";
import { Lead } from "model/lead";
import { loginReset } from "redux/slice/authentication";
import { CampaignCampaignMessageResource } from "api/resource/campaign-campaign-message";

export interface CampaignCampaignMessagesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignCampaignMessageResource;
	};
};

const initialState: CampaignCampaignMessagesState = {
	byId: {},
};

const fetchCampaignCampaignMessages = createAsyncThunk("campaignCampaignMessages/fetchCampaignCampaignMessages", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessagesLoading());
	const result = api.getCampaignCampaignMessages();
	result
		.then((campaignCampaignMessages) => thunkAPI.dispatch(campaignCampaignMessagesLoaded(campaignCampaignMessages)))
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

const sendCampaignCampaignMessageTestEmail = createAsyncThunk("campaignCampaignMessages/sendCampaignCampaignMessageTestEmail", async (payload: {campaignCampaignMessage: CampaignCampaignMessage, lead: Lead}, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessagesLoading());
	const campaignCampaignMessage: CampaignCampaignMessage = payload.campaignCampaignMessage;
	const lead = payload.lead;
	const campaignCampaignMessageSendEmail: CampaignCampaignMessageSendEmail = {
		campaignCampaignMessage,
		lead,
	};
	const result = api.sendCampaignCampagaignMessageEmailTest(campaignCampaignMessageSendEmail);
	result
		.then(campaignCampaignMessage => {thunkAPI.dispatch(campaignCampaignMessageLoaded(campaignCampaignMessage))})
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

const sendCampaignCampaignMessageTestEmails = createAsyncThunk("campaignCampaignMessages/sendCampaignCampaignMessageTestEmails", async (payload: {campaignCampaignMessage: CampaignCampaignMessage, leads: Lead[]}, thunkAPI) => {
	const campaignCampaignMessage = payload.campaignCampaignMessage;
	payload.leads.forEach((lead: Lead) => {
		thunkAPI.dispatch(sendCampaignCampaignMessageTestEmail({campaignCampaignMessage, lead}));
	});
});

const resendTextingOtpVerification = createAsyncThunk("campaignCampaignMessages/resendOtpVerification", async (payload: undefined, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessagesLoading());
	const result = api.resendTextingOtpVerification();
	result
		.then(response => {thunkAPI.dispatch(campaignCampaignMessageLoaded(response))})
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

const createCampaignCampaignMessage = createAsyncThunk("campaignCampaignMessages/createCampaignCampaignMessage", async (payload: {campaignCampaignMessage: NewCampaignCampaignMessage}, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessagesLoading());
	const result = api.createCampaignCampaignMessage(payload.campaignCampaignMessage);
	result
		.then((campaignCampaignMessage) => thunkAPI.dispatch(campaignCampaignMessageLoaded(campaignCampaignMessage)))
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

const updateCampaignCampaignMessage = createAsyncThunk("campaignCampaignMessages/updateCampaignCampaignMessage", async (payload: {campaignCampaignMessage: CampaignCampaignMessage}, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessagesLoading());
	const result = api.updateCampaignCampaignMessage(payload.campaignCampaignMessage);
	result
		.then((campaignCampaignMessage) => thunkAPI.dispatch(campaignCampaignMessageLoaded(campaignCampaignMessage)))
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

const updateCampaignCampaignMessages = createAsyncThunk("campaignCampaignMessages/updateCampaignCampaignMessages", async (payload: {campaignCampaignMessages: CampaignCampaignMessage[]}, thunkAPI) => {
	const result = api.updateCampaignCampaignMessages(payload.campaignCampaignMessages);
	result
		.then((campaignCampaignMessages) => thunkAPI.dispatch(campaignCampaignMessagesLoaded(campaignCampaignMessages)))
		.catch(error => thunkAPI.dispatch(campaignCampaignMessagesFailed(error)));
	return result;
});

export const campaignCampaignMessagesSlice = createSlice({
	name: "campaignCampaignMessages",
	initialState,
	reducers: {
		campaignCampaignMessagesLoading: (state) => {
			state.loading = true;
		},
		campaignCampaignMessagesLoaded: (state, action: PayloadAction<CampaignCampaignMessageResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		campaignCampaignMessageLoaded: (state, action: PayloadAction<any>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId,
				...byId,
			}
		},
		campaignCampaignMessagesFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { campaignCampaignMessagesLoading, campaignCampaignMessagesLoaded, campaignCampaignMessagesFailed, campaignCampaignMessageLoaded } = campaignCampaignMessagesSlice.actions;
export { fetchCampaignCampaignMessages, createCampaignCampaignMessage, updateCampaignCampaignMessage, updateCampaignCampaignMessages, sendCampaignCampaignMessageTestEmail, sendCampaignCampaignMessageTestEmails, resendTextingOtpVerification };