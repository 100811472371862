/**
 * Normalizr has performance issues when dealing with large number of items
 * These methods replace and provide significantly better performance.
 * 
 * The complicated generics on each method is to enforce typesafety but does not affect the behavior.
 * 
 * @typeparam I The type of each item
 * @typeparam PK The name of the primary key (usually "id")
 * @typeparam PKN The type of the primary key (usually number, sometimes string)
 * @typeparam FK The name of the foreign key (ex. "leadId")
 * @typeparam FKN The type of the foreign key (usually number, sometimes string)
 */

type Key = number | string;

/**
 * Converts
 * ```ts
 * [
 *  {
 *   id: 1,
 *   name: "Tom",
 *  },
 *  {
 *   id: 2,
 *   name: "Jerry",
 *  },
 * ]
 * ```
 * to
 * ```ts
 * {
 *  1: {
 *   id: 1,
 *   name: "Tom",
 *  },
 *  2: {
 *   id: 2,
 *   name: "Jerry",
 *  },
 * }
 * ```
 */
export function arrayToById<
	I,
	PKN extends keyof I,
	PK extends I[PKN] & Key,
>(
	items: I[],
	primaryKeyName: PKN,
) {
	const results = {} as {
		[key in PK]: I;
	};
	items.forEach((item) => {
		const primaryKey = item[primaryKeyName] as PK;
		results[primaryKey] = item;
	});
	return results;
}

export function objectToById<
	I,
	PKN extends keyof I,
	PK extends I[PKN] & Key,
>(
	item: I,
	primaryKeyName: PKN,
) {
	const results = {} as {
		[key in PK]: I;
	};
	const primaryKey = item[primaryKeyName] as PK;
	results[primaryKey] = item;
	return results;
}

export function arrayToByForeignId<
	I,
	PKN extends keyof I,
	PK extends I[PKN],
	FKN extends keyof I,
	FK extends I[FKN] & Key,
>(
	items: I[],
	primaryKeyName: PKN,
	foreignKeyName: FKN,
) {
	const results = {} as {
		[key in FK]: PK[];
	};
	items.forEach((item) => {
		const primaryKey = item[primaryKeyName] as PK;
		const foreignKey = item[foreignKeyName] as FK;
		if (foreignKey) {
			if (!results[foreignKey]) {
				results[foreignKey] = [];
			}
			results[foreignKey].push(primaryKey);
		}
	});
	return results;
}

export function objectToByForeignId<
	I,
	PKN extends keyof I,
	PK extends I[PKN],
	FKN extends keyof I,
	FK extends I[FKN] & Key,
>(
	item: I,
	primaryKeyName: PKN,
	foreignKeyName: FKN,
) {
	const results = {} as {
		[key in FK]: PK[];
	};
	const primaryKey = item[primaryKeyName] as PK;
	const foreignKey = item[foreignKeyName] as FK;
	if (foreignKey) {
		results[foreignKey] = [
			primaryKey
		];
	}
	return results;
}

type ByForeignId<PK extends Key> = {
	[key: string]: PK[];
};

export function mergeByForeignId<PK extends Key>(a: ByForeignId<PK>, b: ByForeignId<PK>) {
	Object.keys(b).forEach((key) => {
		const foreignId = key;
		const bIds = b[foreignId];
		if (!a[foreignId]) {
			a[foreignId] = [];
		}
		const bExists = a[foreignId].some(value => bIds.includes(value));
		if (!bExists) {
			a[foreignId].push(...bIds);
		}
	});
	return a;
}