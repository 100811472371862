import * as React from "react";
import { BaseProps } from "../types";
import * as env from "shared/env";
import { KestrelInstanceConfig } from "./types";
import { translateToCriterion, translateToListingsCriteria } from "./translators";

interface Props extends BaseProps {
	hideSaveListingButton?: boolean;
	hideSaveButton?: boolean;
	disableRegistration?: boolean;
	hideStatusField?: boolean;
	hideHeartButton?: boolean;
	hideModal?: boolean;
}

class Component extends React.Component<Props> {

    private elementRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.elementRef = React.createRef();
    }

    async componentDidMount() {
        await this.loadScripts();
        this.load(this.elementRef.current);
    }

    private async loadScripts() {
        if (!window.ihfKestrel) {
            window.ihfKestrel = window.ihfKestrel || {};
            window.ihfKestrel.config = {
                clientId: this.props.user.clientId,
				platform: "control-panel",
            };
            let paths: string[];
            if (env.kestrelUrl.includes("localhost")) {
                paths = [
                    `${env.kestrelUrl}/static/js/bundle.js`,
                    `${env.kestrelUrl}/static/js/vendors~main.chunk.js`,
                    `${env.kestrelUrl}/static/js/main.chunk.js`,
                ];
            } else {
                paths = [
                    `${env.kestrelUrl}/ihf-kestrel.js`,
                ];
            }
            await Promise.all(paths.map(path => new Promise((resolve) => {
                const script = document.createElement("script");
                script.onload = resolve;
                script.src = path;
                document.head.appendChild(script);
            })));
        }
    }

    private load(element: HTMLDivElement | null) {
        const { criteria, savedListings, onCriteriaChange, onSaveListing, onUnsaveListing, hideSaveListingButton, hideSaveButton, disableRegistration, hideStatusField, hideHeartButton, hideModal } = this.props;
        if (!element) {
            return;
        }
        const config: KestrelInstanceConfig = {
            component: "controlPanelListingSearchWidget",
            query: translateToListingsCriteria(criteria) || {},
            savedListingIds: savedListings ? savedListings : [],
            onListingsQueryChange: (value) => {
                const newCriteria = translateToCriterion(value);
                if (onCriteriaChange && newCriteria !== criteria) {
                    onCriteriaChange(newCriteria);
                }
            },
            onSaveListingToggle: (value, listingId) => {
				if (onSaveListing) {
					if (value) {
						onSaveListing(listingId)
					}
				}
				if (onUnsaveListing) {
					if (!value) {
						onUnsaveListing(listingId);
					}
				}
            },
			hideSaveButton: hideSaveButton,
			hideSaveListingButton: hideSaveListingButton,
			disableRegistration: disableRegistration,
			hideStatusField: hideStatusField,
			disableMapDraw: false,
			mapPosition: "left",
			hideHeartButton: hideHeartButton,
			hideModal: hideModal
        }
        const root = window.ihfKestrel.render(config || {});
        element.appendChild(root);
    }

    public render() {
        return (
            <div ref={this.elementRef}></div>
        );
    }

}
export const Kestrel = Component;