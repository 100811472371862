import * as Isemail from "isemail";
import { Buffer } from "buffer";

// polyfill required for isemail
window.Buffer = Buffer;

type Falsy = undefined | null | void | false | 0 | "";

type Validator = () => string | Falsy;

type Schema = {
	[key: string]: Validator;
};

/**
 * returns an object with error message for each key of the schema
 * 
 * @example
 * {
 * 	email: "Email is required",
 * }
 * 
 */
export function validate<T extends Schema>(schema: T) {
	const result: {
		[key in keyof T]?: string;
	} = {};
	for (const key in schema) {
		const validator = schema[key];
		const message = validator();
		if (message) {
			result[key] = message;
		}
	}
	if (!Object.keys(result).length) {
		return;
	}
	return result;
}

export const isEmail = (email: string, strict?: boolean) => {
	if (strict) {
		/** used regext to validate email rather than isemail library. 
		 * Our requirements state .com is required, however the 
		 * library accepts this because it technically valid
		 **/
		const emailReg = /\S+@\S+\.\S+/;
		return emailReg.test(email);
	} else {
		return Isemail.validate(email, { minDomainAtoms: 2 });
	}
}