import React from "react";
import * as Mui from "@material-ui/core";
import { Chart } from "./chart";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import {
	getActiveLeads,
	getActiveLeadsWithinMonth
} from "redux/selector";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { urls } from "routes/urls";
import { Lead } from "model/lead";
import { styles } from "./style";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	const newLeads = getActiveLeadsWithinMonth(state);
	const newLeadsUnassigned = newLeads.filter(lead => !lead.agent);
	const newLeadsByAgent = newLeads.filter(lead => lead.agent?.id === agentId);
	const unassigned = agentId === -1
	return {
		loading: state.leads.loading,
		allLeads: getActiveLeads(state),
		agentId,
		newLeads,
		newLeadsByAgent,
		newLeadsUnassigned,
		unassigned
	};
};

interface Props extends ReturnType<typeof mapStateToProps>, Mui.WithStyles {}

class Component extends React.Component<Props> {
	private is80PercentWebsiteCRM = (leads: Lead[]) => {
		const websiteCRMCount = leads.filter(lead => !lead.source).length;
		return websiteCRMCount / leads.length >= 0.8;
	};

	public render() {
		const { loading, allLeads, classes, newLeadsByAgent, agentId, newLeadsUnassigned, unassigned } = this.props;
		const newLeads = !agentId ? (!unassigned ? this.props.newLeads : newLeadsUnassigned) : newLeadsByAgent
		const leads = newLeads.length ? newLeads : allLeads;
		
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Grid container justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Typography variant="h3" display="inline">
								Lead Sources
							</Mui.Typography>
						</Mui.Grid>
						{!!newLeads.length && (
							<Mui.Grid item>
								<Mui.Typography variant="h6" display="inline">
									* Past 30 days
								</Mui.Typography>
							</Mui.Grid>
						)}
						{this.is80PercentWebsiteCRM(leads) && (
							<Mui.Grid item>
								<Mui.Tooltip
									classes={{ tooltip: classes.tooltip }}
									title={
										<RouterLinkWrapper
											to={urls.settingsIntegrations}
											className={classes.text}
										>
											Bring in your leads from other sources.
										</RouterLinkWrapper>
									}
									placement="bottom-end"
									interactive
								>
									<Mui.IconButton
										size="small"
										component={RouterLinkWrapper}
										to={urls.settingsIntegrations}
									>
										<FeatherIcon>
											<Icons.AlertTriangle />
										</FeatherIcon>
									</Mui.IconButton>
								</Mui.Tooltip>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent style={{ height: 350 }}>
							{!!loading && (
								<Mui.Grid container justifyContent="center" alignItems="center" direction="row"> 
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!loading && !leads.length && (
								<Mui.Grid container alignItems="center" style={{ height: "100%" }}>
									<Mui.Grid item>
										<Mui.Typography align="center">Start adding leads to see this chart.</Mui.Typography>
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!!leads.length && <Chart leads={leads} />}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const LeadSources = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
