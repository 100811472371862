import { TimeZoneType } from "type/time-zone";
import * as yup from "yup";
export const validationSchema = {
	phoneNumber: yup
		.string()
		.required("Phone number is required")
		.matches(/^\(\d{3}\)\s\d{3}-\d{4}/, "Please provide a 10 digit phone number"),
	timeZone: yup
		.string()
		.required("Please select a time zone")
		.test(
			"time-zone-validation",
			"Please select a valid time zone",
			(value) => {
			if (value && TimeZoneType.getById(value)) {
				return true;
			}
			return false;
		})
	}