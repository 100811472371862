import { TaskResource } from "api/resource/task";
import moment from "moment";

export const tasks: TaskResource[] = [
	{
		id: 1,
		leadId: 4,
		agentId: 1,
		creatorId: 1,
		taskTypeId: 3,
		taskStatusTypeId: 1,
		title: "Call back Catherine McGregor about scheduling property showing",
		description: "She's interested in Temescal neighborhood and properties near BART. Remind her that lorem ipsum blah blah blah",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().subtract(7, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 2,
		taskTypeId: 1,
		agentId: 1,
		creatorId: 2,
		taskStatusTypeId: 1,
		title: "Return paperwork to the inspection agency for 18161 Brandywine Lane",
		priority: true,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(30, "minutes").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 3,
		taskTypeId: 1,
		agentId: 2,
		creatorId: 2,
		taskStatusTypeId: 1,
		title: "2 Weeks From Today1",
		description: "Filled out Contact us from Friday 8/4",
		priority: true,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(14, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 4,
		leadId: 2,
		agentId: 3,
		creatorId: 3,
		taskTypeId: 4,
		taskStatusTypeId: 1,
		title: "1 Week From Today1",
		description: "Filled out Contact us from Friday 8/4",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(7, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 5,
		leadId: 3,
		agentId: 3,
		creatorId: 1,
		taskTypeId: 2,
		taskStatusTypeId: 3,
		title: "2 Weeks From Today 2",
		description: "He's interested in Temescal neighborhood and properties near BART. Remind him that lorem ipsum blah blah blah",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(14, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 6,
		leadId: 2,
		agentId: 1,
		creatorId: 2,
		taskTypeId: 1,
		taskStatusTypeId: 1,
		title: "1 Week From Today2",
		description: "Filled out Contact us from Friday 8/4",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(7, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 7,
		leadId: 2,
		agentId: 2,
		creatorId: 1,
		taskTypeId: 2,
		taskStatusTypeId: 1,
		title: "1 Week From Today3",
		description: "Filled out Contact us from Friday 8/4",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(7, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},
	{
		id: 8,
		leadId: 2,
		agentId: 3,
		creatorId: 1,
		taskTypeId: 3,
		taskStatusTypeId: 1,
		title: "1 Week From Today4",
		description: "Filled out Contact us from Friday 8/4",
		priority: false,
		startOn: moment().subtract(1, "M").format(),
		endOn: moment().add(7, "d").format(),
		createdOn: moment().subtract(1, "M").format(),
		updatedOn: moment().subtract(1, "M").format(),
	},

];