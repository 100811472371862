import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { CampaignsSettingsResource } from "api/resource/campaigns-settings";
import { loginReset } from "redux/slice/authentication";

export interface CampaignsSettingsState {
	loading?: boolean,
	error?: string | null,
	dynamicContentAddress?: string,
	leadId?: number,
};

const initialState: CampaignsSettingsState = {
	loading: false,
};

const fetchCampaignsSettings = createAsyncThunk("campaignsSettings/fetchCampaignsSettings", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignsSettingsLoading());
	const result = api.getCampaignsSettings();
	result
		.then((campaignsSettings) => thunkAPI.dispatch(campaignsSettingsLoaded(campaignsSettings)))
		.catch(error => thunkAPI.dispatch(campaignsSettingsFailed(error)));
	return result;
});

const updateCampaignsSettings = createAsyncThunk("campaignsSettings/updateCampaignsSettings", async (payload: {campaignsSettings: CampaignsSettingsResource}, thunkAPI) => {
	thunkAPI.dispatch(campaignsSettingsLoading());
	const result = api.updateCampaignsSettings(payload.campaignsSettings);
	result
		.then((campaignsSettings) => thunkAPI.dispatch(campaignsSettingsLoaded(campaignsSettings)))
		.catch(error => thunkAPI.dispatch(campaignsSettingsFailed(error)));
	return result;
});

export const campaignsSettingsSlice = createSlice({
	name: "campaignsSettings",
	initialState,
	reducers: {
		campaignsSettingsLoading: (state) => {
			state.loading = true;
		},
		campaignsSettingsLoaded: (state, action: PayloadAction<CampaignsSettingsResource>) => {
			state.loading = false;
			state.error = null;
			state.dynamicContentAddress = action.payload.dynamicContentAddress;
			state.leadId = action.payload.leadId;
		},
		campaignsSettingsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { campaignsSettingsLoading, campaignsSettingsLoaded, campaignsSettingsFailed } = campaignsSettingsSlice.actions;
export { fetchCampaignsSettings, updateCampaignsSettings};