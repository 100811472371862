import React from "react";
import * as Mui from "@material-ui/core";
import { getUploadSignature } from "redux/slice/upload-signature";
import * as env from "shared/env";
import { ClientParameters, CloudinaryEvent, CloudinaryUploadWidget, UploadFile, UploadParameters, WidgetParameters } from "./cloudinary";
import { FeatherIcon } from "component/shared/feather-icon";
import { Upload } from "model/upload";

interface Props {
	callback: (files: Upload[]) => void;
	multiple?: boolean;
	resourceType?: "auto" | "image" | "video" | "raw";
	maxFiles?: number;
	disabled?: boolean;
	label?: string;
	style?: React.CSSProperties;
	icon?: JSX.Element;
}

class Component extends React.Component<Props> {
	widget: CloudinaryUploadWidget | null = null;
	uploadParameters: UploadParameters = {
		resourceType: this.props.resourceType,
	};
	clientParameters: ClientParameters = {
		maxFileSize: 600000,
	}
	limitReachedText = this.props.maxFiles ? {
		"en": {
			"notifications": {
				"limit_reached": `You have reached the maximum of ${this.props.maxFiles} files.`,
			},
		}
	} : undefined
	componentDidMount() {
		const widgetParameters: WidgetParameters = {
			multiple: this.props.multiple || false,
			maxFiles: this.props.maxFiles,
		};
		const uploadPreset = env.live
			? env.cloudinaryUploadPreset || ""
			: "mock-upload-preset";
		getUploadSignature(uploadPreset).then((signedParameters) => {
			const config = {
				cloudName: env.cloudinaryCloudName || "",
				uploadPreset,
				...(signedParameters as object),
				...widgetParameters,
				...this.uploadParameters,
				...this.clientParameters,
				text: this.limitReachedText,
			};
			this.widget = window.cloudinary.createUploadWidget(
				config,
				(error, result) => {
					this.checkUploadResult(error, result);
				}
			);
		});
	}

	checkUploadResult = (error: Error, resultEvent: CloudinaryEvent) => {
		if (error) {
			this.setState({
				openSnackbar: true,
				snackbarMessage: "Upload failed.",
				snackbarSeverity: "error",
			});
		}
		switch(resultEvent.event) {
			case "queues-end": {
				let uploadFiles: UploadFile[] = [];
				if (resultEvent.info.files) {
					uploadFiles = resultEvent.info.files.filter((uploadFile: UploadFile) => uploadFile.status === "success");
					this.props.callback(uploadFiles.map((uploadFile) => {
						return {
							name: uploadFile.name,
							url: uploadFile.uploadInfo.secure_url,
						}
					}));
				}	
				break;
			}
			default: {
				return;
			}
		}
	};

	closeSaveMessage = () => {
		this.setState({
			openSnackbar: false,
		});
	};

	render() {
		const { label, style, icon } = this.props;
		return (
			<>
				{icon && !label &&
					<Mui.IconButton 
						id="upload_widget"
						size="small"
						onClick={() => this.widget && this.widget.open()}
						disabled={this.props.disabled}
					>
						<FeatherIcon>
							{icon}
						</FeatherIcon>
					</Mui.IconButton>
				}
				{label &&
					<Mui.Button
						id="upload_widget"
						className="cloudinary-button"
						onClick={() => this.widget && this.widget.open()}
						style={style}
						variant="contained"
						disabled={this.props.disabled}
						startIcon={icon &&
							<Mui.IconButton size="small">
								<FeatherIcon>
									{icon}
								</FeatherIcon>
							</Mui.IconButton>
						}
					>
						{label}
					</Mui.Button>
				}
			</>
		);
	}
}

export const PhotoUploadDialogButton = Component;
