import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { AnniversaryEdit } from "./anniversary-edit";
import { EmailMarketingType } from "type/email-marketing-type";


const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State {
	tab: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			tab: 0,
		};
	}
	
	public render() {
		const { user } = this.props;
		const { tab } = this.state;
		const title = "Automated Greetings";
		return (
			<DashboardLayout
				permitted={user && user.permissions.crmAnniversary}
				fullScreen={false}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Send />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={4}>
					{
						<>
							<Mui.Grid item>
								<Mui.Paper>
									<Mui.Tabs
										centered
										indicatorColor="secondary"
										textColor="secondary"
										value={tab}
										onChange={(event, tab) => this.setState({
											tab,
										})}
									>
										<Mui.Tab
											value={0}
											icon={
												<FeatherIcon>
													<Icons.Gift />
												</FeatherIcon>
											}
											label="Birthday"
										/>
										<Mui.Tab
											value={1}
											icon={
												<FeatherIcon>
													<Icons.DollarSign />
												</FeatherIcon>
											}
											label="Transaction Anniversary"
										/>
									</Mui.Tabs>
								</Mui.Paper>
							</Mui.Grid>
							<Mui.Grid item>
								{tab === 0 && (
									<AnniversaryEdit campaignType={EmailMarketingType.BIRTHDAY} />
								)}
								{tab === 1 && (
									<AnniversaryEdit campaignType={EmailMarketingType.TRANSACTION} />
								)}
							</Mui.Grid>
						</>
					}
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const CampaignAutomatedGreetingsPage = connect(mapDispatchToProps)(Component);