import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { RootState, getPayload } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import * as yup from "yup";
import { FeatherIcon } from "component/shared/feather-icon";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import { RichTextEditor } from "component/shared/rich-text-editor";
import { Select } from "component/shared/select";
import { OfficeBoard } from "model/office-board";
import { Office } from "model/office";
import { getBoardsForUser } from "redux/selector";
import { getOfficeBoardsForOffice } from "redux/selector";
import { updateOffice } from "redux/slice/offices";
import { createOfficeBoard, deleteOfficeBoard, updateOfficeBoard } from "redux/slice/office-boards";
import { validate } from "shared/yup";
import { styles } from "./style";
import { Row } from "./row";
import { SaveButton } from "component/shared/save-button";

interface Props
	extends OwnProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	office: Office;
}

interface State {
	name?: string;
	code?: string;
	priority?: string;
	address?: string;
	city?: string;
	state?: string;
	zip?: string;
	phone?: string;
	fax?: string;
	email?: string;
	photoSource: "photoUrl" | "uploadPhoto";
	photoUrl?: string;
	officeBoards: OfficeBoard[];
	header: string;
	description: string;
	submitted: boolean;
	photoUploadError: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const { office } = ownProps;
	const officeBoards = getOfficeBoardsForOffice(state, office.id);
	return {
		officeBoards,
		loading: state.offices.loading,
		boards: getBoardsForUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateOffice,
			createOfficeBoard,
			updateOfficeBoard,
			deleteOfficeBoard,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		const { office, officeBoards } = props;
		this.state = {
			name: office.name || "",
			code: office.code || "",
			priority: office.priority ? office.priority.toString() : "",
			address: office.address || "",
			city: office.city || "",
			state: office.state || "",
			zip: office.zip || "",
			phone: office.phone || "",
			fax: office.fax || "",
			email: office.email || "",
			photoSource: office.photoUrl ? "photoUrl" : "uploadPhoto",
			photoUrl: office.photoUrl || "",
			officeBoards: officeBoards && officeBoards.length > 0 ? officeBoards : [{officeCode: "", boardId: 0, officeId: office.id, id: 0}],
			header: office.header || "",
			description: office.description || "",
			submitted: false,
			photoUploadError: false,
		};
	}

	public componentDidMount() {
		this.setState({officeBoards: this.props.officeBoards});
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.officeBoards.length !== this.props.officeBoards.length) {
			this.setState({officeBoards: this.props.officeBoards});
		}
	}

	private submit = async () => {
		const { office } = this.props;
		const {
			name,
			code,
			address,
			city,
			state,
			zip,
			phone,
			fax,
			email,
			photoUrl,
			officeBoards,
			header,
			description,
		} = this.state;
		this.setState({ submitted: true });
		const errors = validate(this.validationSchema, this.state);
		if (!errors && email) {
			
			let priority;
			const priorityStr = this.state.priority;
			if (priorityStr) {
				const priorityInt = parseInt(priorityStr);
				if (!isNaN(priorityInt)) {
					priority = priorityInt;
				}
			}
			const officeResource = getPayload(await this.props.updateOffice(
				{ office:
					{
						...office,
						name: (name) ? name : office.name,
						code,
						priority: (priority) ? priority : office.priority,
						address: (address) ? address : office.address,
						city: (city) ? city : office.city,
						state,
						zip,
						phone,
						fax,
						email,
						photoUrl,
						header,
						description,
					}	
			}));	
			if (officeResource.id) {
				for (const officeBoard of officeBoards) {
					officeBoard.officeId = officeResource.id;
					if (officeBoard.officeCode && officeBoard.boardId) {
						if (officeBoard.id) {
							this.props.updateOfficeBoard({officeBoard});
						} else {
							this.props.createOfficeBoard({officeBoard});
						}
					} else {
						this.props.deleteOfficeBoard({officeBoard});
					}	
				}
			}
		};
	};

	private validationSchema = {
		email: yup.string().email("Invalid email.").required("Email is required."),
	};

	render() {
		const { boards, classes, loading } = this.props;
		const {
			name,
			code,
			priority,
			address,
			city,
			state,
			zip,
			phone,
			fax,
			email,
			photoSource,
			photoUrl,
			header,
			description,
			submitted,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Mui.Grid
				container
				className={classes.container}
				alignItems="center"
				spacing={1}
			>
				<>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Office Name"
								margin="dense"
								onChange={(event) => this.setState({ name: event.target.value })}
								value={name}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Office Code (optional)"
								margin="dense"
								onChange={(event) => this.setState({ code: event.target.value })}
								placeholder=""
								value={code}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Priority"
								margin="dense"
								onChange={(event) => this.setState({ priority: event.target.value })}
								value={priority}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Office Street Address"
								margin="dense"
								onChange={(event) => this.setState({ address: event.target.value })}
								value={address}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="City"
								margin="dense"
								onChange={(event) => this.setState({ city: event.target.value })}
								value={city}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="State"
								margin="dense"
								onChange={(event) => this.setState({ state: event.target.value })}
								value={state}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Zipcode"
								margin="dense"
								onChange={(event) => this.setState({ zip: event.target.value })}
								value={zip}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Office Phone"
								margin="dense"
								onChange={(event) => this.setState({ phone: event.target.value })}
								value={phone}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								fullWidth
								label="Office Fax"
								margin="dense"
								onChange={(event) => this.setState({ fax: event.target.value })}
								value={fax}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.TextField
								error={submitted && !!errors && !errors.email}
								FormHelperTextProps={{
									className: classes.errorMessage,
								}}
								fullWidth
								helperText={submitted && errors && errors.email}
								label="Office Email"
								margin="dense"
								onChange={(event) => this.setState({ email: event.target.value })}
								value={email}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Select
								fullWidth
								labelExtractor={(type) => type.label}
								margin="dense"
								onChange={(id) => {
									this.setState({ photoSource: id });
								}}
								options={[
									{
										id: "photoUrl",
										label: "Photo URL",
									},
									{
										id: "uploadPhoto",
										label: "Upload Photo",
									},
								]}
								optionRenderer={(type) => <Mui.ListItemText primary={type.label} />}
								placeholder="Office Photo"
								value={photoSource}
								valueExtractor={(type) => type.id}
								variant="outlined"
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							{photoSource === "photoUrl" && (
								<Mui.TextField
									fullWidth
									inputProps={{ maxLength: 100 }}
									margin="dense"
									onChange={(event) => this.setState({ photoUrl: event.target.value })}
									placeholder="Enter Photo URL (include http://)"
									value={photoUrl}
									variant="outlined"
								/>
							)}
						</Row>
					</Mui.Grid>
					{photoSource === "uploadPhoto" && (
						<Mui.Grid item xs={12}>
							<Row>
								<PhotoUploadDialogButton
									multiple={false}
									callback={(uploads) => {
										const upload = uploads.find((upload) => upload.url);
										if (upload && upload.url) {
											this.setState({
												photoSource: "photoUrl",
												photoUrl: upload.url,
											});
										} else {
											this.setState({
												photoUploadError: true,
											});
										}
									}}
									label="Upload Office Photo"
									resourceType="image"
								/>
								{this.state.photoUploadError && (
									<Mui.Typography className={classes.error}>
										Upload Error
									</Mui.Typography>
								)}
							</Row>
						</Mui.Grid>
					)}
					<Mui.Grid item />
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Typography>Office MLS IDs</Mui.Typography>
						</Row>
					</Mui.Grid>
					{this.state.officeBoards.map((officeBoard, index) => {
						const { officeCode, boardId } = officeBoard;
						return (
							<Mui.Grid item xs={12} key={index}>
								<Row action={
									<Mui.IconButton
										style={{visibility: this.props.officeBoards.length === 1 ? "hidden" : undefined}}
										onClick={() => {
											let officeBoards = [...this.state.officeBoards];
											officeBoards.splice(index, 1);
											this.setState({officeBoards}, () => {
												if (!officeBoards.length) {
													this.setState({officeBoards: [{officeCode: "", boardId: 0, officeId: 0, id: 0}]});
												}
											});
										}}
										size="small"
									>
										<FeatherIcon>
											<Icons.Trash />
										</FeatherIcon>
									</Mui.IconButton>
								}>
									<Mui.Grid container spacing={1}>
										<Mui.Grid item xs={6}>
											<Select
												fullWidth
												labelExtractor={(board) => board && board.shortName ? board.shortName : "Error - Select an MLS"}
												margin="dense"
												options={boards.sort((a, b) =>
													a.shortName > b.shortName ? 1 : a.shortName < b.shortName ? -1 : 0
												)}
												optionRenderer={(board) => (
													<Mui.ListItemText primary={board && board.shortName ? board.shortName : "Error - Select an MLS"} />
												)}
												onChange={(boardId) => {
													const officeBoards = [...this.state.officeBoards];
													officeBoards[index].boardId = boardId;
													this.setState({officeBoards: officeBoards});
												}}
												placeholder="Select an MLS"
												value={boardId}
												valueExtractor={(board) => board.id}
												variant="outlined"
											/>
										</Mui.Grid>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												fullWidth
												margin="dense"
												disabled={ !boardId}
												onChange={(event) => {
													const officeBoards = [...this.state.officeBoards];
													if (officeBoards[index].boardId) {
														officeBoards[index].officeCode = event.target.value;
														this.setState({
															officeBoards: officeBoards, 
														});	
													}
												}}
												placeholder="Office ID"
												value={officeCode}
												variant="outlined"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Row>
							</Mui.Grid>
						)
					})}
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Button
								onClick={() => {
									let officeBoards = [...this.state.officeBoards];
									officeBoards.push({
										officeCode: "",
										id: 0,
										boardId: 0,
										officeId: 0
									});
									this.setState({officeBoards: officeBoards});
								}}
								size="small"
								startIcon={<Icons.PlusCircle />}
							>
								Add MLS Id
							</Mui.Button>
						</Row>
					</Mui.Grid>	
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Typography>
								Header
							</Mui.Typography>
							<RichTextEditor
								onChange={(value) => this.setState({ header: value })}
								toolbarId="officeHeader"
								value={header}
							/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Typography>
								Office Description
							</Mui.Typography>
								<RichTextEditor
									onChange={(value) => this.setState({ description: value })}
									toolbarId="officeDescription"
									value={description}
								/>
						</Row>
					</Mui.Grid>
					<Mui.Grid item xs={12}>
						<Row>
							<Mui.Grid container justifyContent="flex-end">
								<Mui.Grid item>
									<SaveButton 
										loading={submitted && !!loading} 
										error={submitted && errors ? "Please fix the highlighted errors" : undefined}
										label={{
											primary: "Save",
											inProgress: "Saving...",
											completed: "Saved",
										}}
										snackBar
										onClick={() => {
											this.setState({submitted: true})
											this.submit();
										}}
									/>
								</Mui.Grid>
							</Mui.Grid>
						</Row>
						
					</Mui.Grid>
				</>
			</Mui.Grid>	
		);
	}
}

export const Content = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
