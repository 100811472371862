import React from "react";
import * as Mui from "@material-ui/core";
import { FixedSizeList } from "react-window";
import { Option, SingleValue, MultiValue, BaseProps } from "../types";
import { styles } from "./style";
import * as Icons from "react-feather";

interface Props extends BaseProps, Mui.WithStyles<typeof styles> {
	disabledOptions?: SingleValue[];
}

interface State {
	open: boolean;
	labelWidth?: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	private inputLabel = React.createRef<HTMLLabelElement>();

	public componentDidMount() {
		if (this.inputLabel.current && this.inputLabel.current.offsetWidth !== 0) {
			this.setState({ labelWidth: this.inputLabel.current.offsetWidth })
		}
	}

	public render() {
		const { variant, margin, fullWidth, label, required, disabled, expanded, helperText, error } = this.props;
		const { classes } = this.props;
		const { open, labelWidth } = this.state;
		return (
			<Mui.FormControl
				variant={variant}
				margin={margin}
				fullWidth={fullWidth}
				required={required}
				disabled={disabled}
			>
				{label &&
					<Mui.InputLabel 
						ref={this.inputLabel as any}
					>
						{label}
					</Mui.InputLabel>
				}
				{expanded ? (
					this.renderFixedSizeList()
				) : (
					<Mui.Select
						value={0}
						renderValue={()=> this.renderPlaceholder}
						open={open}
						onClose={() => this.setState({open: false})}
						onOpen={() => this.setState({open: true})}
						IconComponent={Icons.ChevronDown}
						inputProps={{
							classes: {
								icon: classes.icon,
								selectMenu: classes.selectMenu,
							},
						}}
						labelWidth={labelWidth ? labelWidth : 0}
						error={error}
					>
						{this.renderFixedSizeList()}
					</Mui.Select>
				)}
				{helperText && 
					<Mui.FormHelperText>{helperText}</Mui.FormHelperText>
				}
			</Mui.FormControl>
		);
	}

	private handleClick(value: SingleValue) {
		const { multiple, onChange } = this.props;
		if (multiple) {
			const { values } = this;
			const i = (values as SingleValue[]).indexOf(value);
			if (i === -1) {
				(values as SingleValue[]).push(value);
			} else {
				values.splice(i, 1);
			}
			onChange(values);
		} else {
			this.setState({open: false});
			onChange(value);
		}
	}

	private get values() {
		let { value } = this.props;
		let results: MultiValue = [];
		if (value) {
			if (value instanceof Array) {
				results = value;
			} else {
				results = [value] as typeof results;
			}
		}
		return results;
	}

	private get activeOptions(): Option[] {
		const { values } = this;
		return (values as SingleValue[]).map(value => this.getOption(value));
	}

	private getOption(value: SingleValue) {
		const { options, valueExtractor } = this.props;
		return options.find(option => valueExtractor(option) === value);
	}

	private get renderPlaceholder() {
		const {
			placeholder,
			multiple,
			labelExtractor,
			variant,
			classes
		} = this.props;
		const { activeOptions } = this;
		if (multiple || activeOptions.length === 0) {
			return (
				<Mui.Typography className={variant === "outlined" ? "" : classes.text}>
					{placeholder}
				</Mui.Typography>
			);
		} else {
			const option = activeOptions[0];
			return (
				<Mui.Typography className={variant === "outlined" ? "" : classes.text}>
					{labelExtractor(option)}
				</Mui.Typography>
			);
		}
	}

	private selected(option: Option) {
		const { activeOptions } = this;
		return activeOptions.includes(option);
	}

	private get activeOptionIndex() {
		const { options } = this.props;
		const { activeOptions } = this;
		const activeOption = activeOptions[0];
		const i = options.indexOf(activeOption);
		return Math.max(0, i);
	}

	private renderFixedSizeList() {
		const { placeholder, multiple, fullWidth, numberVisible, disabledOptions, scrollOffset = true } = this.props;
		const { options, valueExtractor, optionRenderer, classes } = this.props;
		const { activeOptionIndex } = this;

		const itemHeight = 48;
		const defaultOption = multiple ? 0 : 1;
		const itemCount = options.length + defaultOption;
		const visibleItems = Math.min(numberVisible ? numberVisible : 5, itemCount);
		const width = fullWidth ? "100%" : 338;
		// Add 1 pixel due to height inconsistencies 
		const height = visibleItems * itemHeight + 1;
		const initialScrollOffset = ((activeOptionIndex + defaultOption) - ((visibleItems - 1) / 2)) * itemHeight;
		return (
			<FixedSizeList
				itemSize={itemHeight}
				width={width}
				height={height}
				itemCount={itemCount}
				initialScrollOffset={scrollOffset ? initialScrollOffset : 0}
			>
				{({ index, style }) => {
					if (!multiple && index === 0) {
						return (
							<Mui.MenuItem
								style={style}
								onClick={() => {
									this.handleClick(undefined);
								}}
							>
								<em>{placeholder}</em>
							</Mui.MenuItem>
						);
					} else {
						const option = options[index - defaultOption];
						const value = valueExtractor(option);
						const selected = this.selected(option);
						const disabled = disabledOptions && disabledOptions.includes(value);
						return (
							<Mui.MenuItem
								style={style}
								classes={{
									selected: multiple ? classes.menuItemSelected : "",
								}}
								disabled={disabled}
								value={value as string | number}
								selected={selected}
								onClick={() => {
									this.handleClick(value);
								}}
							>
								{multiple &&
									<Mui.Checkbox
										checked={selected}
										className={classes.checkbox}
									/>
								}
								{optionRenderer(option)}
							</Mui.MenuItem>
						);
					}
				}}
			</FixedSizeList>
		)
	}
	
}

export const CustomSelect = Mui.withStyles(styles)(Component);