import React from "react";
import { BaseProps } from "./types";
import { NativeSelect } from "./native";
import { CustomSelect } from "./custom";
import { UAParser } from "ua-parser-js";

interface Props extends BaseProps {}

class Component extends React.Component<Props> {

	public render() {
		const agent = new UAParser().getResult();
		const native = agent.device.type === "mobile" || agent.device.type === "tablet";
		return (
			<>
				{native ? (
					<NativeSelect {...this.props} />
				) : (
					<CustomSelect {...this.props}/>
				)}
			</>
		);
	}
	
}

export const Select = Component;