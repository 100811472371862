import React from "react";
import * as Mui from "@material-ui/core";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { styles } from "./style";
import { Avatar } from "component/shared/avatar";
import * as Icon from "react-feather";
import { Agent } from "model/agent";

interface Props
	extends Mui.WithStyles<typeof styles> {
		prefix?: string;
		agent?: Agent;
		justifyContent?: Mui.GridJustification;
		addName?: boolean;
		addPhoto?: boolean;
		nameSub?: string;
		assigner?: Agent;
		preposition?: string;
		addAssignerPhoto?: boolean;
		addAssignerName?: boolean;
		clickable?: boolean;
	}

class Component extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);
	}

	public render() {
		const { 
			classes, 
			agent, 
			prefix, 
			justifyContent = undefined, 
			addName = true, 
			addPhoto = true, 
			nameSub,
			assigner,
			preposition,
			addAssignerName = true,
			addAssignerPhoto = true,
			clickable = true,
		} = this.props;
		const profile = `settings/broker/agent/${agent?.id}`;
	
		return (
			<>
				{agent ? (
					<Mui.Grid container alignItems="center" spacing={1} justifyContent={justifyContent}>
						<Mui.Grid item>
							<Mui.Typography>
								{prefix}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item className={classes.agentGrid}>
							{addPhoto && (
								<>
									{clickable ? (
										<RouterLinkWrapper to={profile} className={classes.avatarLink}>
											<Avatar src={agent.photoUrl} phrase={agent.label} />
										</RouterLinkWrapper>
									) : (
										<Avatar src={agent.photoUrl} phrase={agent.label} className={classes.avatarLink}/>
									)}
								</>
							)}
							{addName && (
								<>
									{clickable ? (
										<RouterLinkWrapper className={classes.agentLink} to={profile}>
											{nameSub ? nameSub : agent.name ? agent.name : agent.email}
										</RouterLinkWrapper>
									) : (
										<Mui.Typography>
											{nameSub ? nameSub : agent.name ? agent.name : agent.email}
										</Mui.Typography>
									)}
								</>
							
							)}
						</Mui.Grid>
						{assigner && (
							<Mui.Grid item>
								<Mui.Grid container alignItems="center" spacing={1} justifyContent={justifyContent}>
									<Mui.Grid item>
										<Mui.Typography>
											{preposition}
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item className={classes.agentGrid}>
										{addAssignerPhoto && (
											<>
												{clickable ? (
													<RouterLinkWrapper to={profile} className={classes.avatarLink}>
														<Avatar src={assigner.photoUrl} phrase={assigner.label} />
													</RouterLinkWrapper>
												) : (
													<Avatar src={assigner.photoUrl} phrase={assigner.label} className={classes.avatarLink}/>
												)}
											</>
										)}
										{addAssignerName && (
											<>
												{clickable ? (
													<RouterLinkWrapper className={classes.agentLink} to={profile}>
														{assigner.name ? assigner.name : assigner.email}
													</RouterLinkWrapper>
												) : (
													<Mui.Typography>
														{assigner.name ? assigner.name : assigner.email}
													</Mui.Typography>
												)}
											</>
										)}
									</Mui.Grid>
								</Mui.Grid>	
							</Mui.Grid>
						)}
					</Mui.Grid>
				):
					<Mui.Grid container alignItems="center" justifyContent={justifyContent}>
						<Mui.Grid item className={classes.iconGrid}>
								<Icon.User className={classes.unassignedIcon} size={36}/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography className={classes.unassignedText}>
								Unassigned
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				}								
			</>
		);
	}
}

export const AgentTag = Mui.withStyles(styles)((Component));
