import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { LeadLastActivityResource } from "api/resource/lead-last-activity";

export interface LeadLastActivitiesState {
    loading?: boolean;
    error?: string | null;
    byId: {
        [key: number]: LeadLastActivityResource;
    };
};

const initialState: LeadLastActivitiesState = {
    byId: {},
};

const fetchLeadLastActivities = createAsyncThunk("leadLastActivities/fetchLeadLastActivities", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadLastActivitiesLoading());
	const result = api.getLeadLastActivities();
	result
		.then((leadLastActivities) => thunkAPI.dispatch(leadLastActivitiesLoaded(leadLastActivities)))
		.catch(error => thunkAPI.dispatch(leadLastActivitiesFailed(error)));
	return result;
});

export const leadLastActivitiesSlice = createSlice({
	name: "leadLastActivities",
	initialState,
	reducers: {
		leadLastActivitiesLoading: (state) => {
			state.loading = true;
		},
		leadLastActivitiesLoaded: (state, action: PayloadAction< LeadLastActivityResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		leadLastActivitiesFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
    }
});

export const { leadLastActivitiesLoading, leadLastActivitiesLoaded, leadLastActivitiesFailed } = leadLastActivitiesSlice.actions;
export { fetchLeadLastActivities };