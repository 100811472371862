import { arrayToById } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { PostalCodeResource } from "api/resource/postal-code";

export interface PostalCodesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: PostalCodeResource;
	};
};

const initialState: PostalCodesState = {
	byId: {},
};

const fetchPostalCodes = createAsyncThunk("postalCodes/fetchPostalCodes", async (payload, thunkAPI) => {
	thunkAPI.dispatch(postalCodeLoading());
	const result = api.getPostalCodes();
	result
		.then((postalCode) => thunkAPI.dispatch(postalCodeLoaded(postalCode)))
		.catch(error => thunkAPI.dispatch(postalCodeFailed(error)));
	return result;
});

export const postalCodesSlice = createSlice({
	name: "Postal Code",
	initialState,
	reducers: {
		postalCodeLoading: (state) => {
			state.loading = true;
		},
		postalCodeLoaded: (state, action: PayloadAction<PostalCodeResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		postalCodeFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const {postalCodeLoading, postalCodeLoaded, postalCodeFailed} = postalCodesSlice.actions;
export { fetchPostalCodes };