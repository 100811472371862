import * as env from "shared/env";
import * as live from "./live";
import * as mock from "./mock";
import { LeadNote, NewLeadNote } from "model/lead-note";
import { LeadImport } from "model/lead-import";
import { LeadTag, NewLeadTag} from "model/lead-tag";
import { LeadPhone, NewLeadPhone } from "model/lead-phone";
import { NewLeadPipeline } from "model/lead-pipeline";
import { SavedListing, NewSavedListing } from "model/saved-listing";
import { SavedSearch, NewSavedSearch } from "model/saved-search";
import { Campaign, NewCampaign } from "model/campaign";
import { CampaignMessage, NewCampaignMessage } from "model/campaign-message";
import { CampaignCampaignMessage, NewCampaignCampaignMessage } from "model/campaign-campaign-message";
import { CampaignSubscription, NewCampaignSubscription } from "model/campaign-subscription";
import { LeadAnniversary, NewLeadAnniversary } from "model/lead-anniversary";
import { Task, NewTask } from "model/task";
import { IntegrationPartial } from "model/integration";
import { CampaignRoute, NewCampaignRoute } from "model/campaign-route";
import { CampaignCampaignMessageSendEmail } from "model/campaign-campaign-message-send-email";
import { CampaignsSettings } from "model/campaigns-settings";
import { Agent, NewAgent } from "model/agent";
import { AgentBoard, NewAgentBoard } from "model/agent-board";
import { NewOffice, Office } from "model/office";
import { OfficeBoard, NewOfficeBoard } from "model/office-board";
import { PagePartial } from "model/page";
import { Lead, NewLead } from "model/lead";
import { UserPartial } from "model/user";
import { UserAuth } from "model/user-auth";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import { NewRoundRobinRoute } from "model/round-robin-route";
import { MarketReportSubscription, NewMarketReportSubscription } from "model/market-report-subscription";
import { Market, NewMarket } from "model/market";
import { MarketReport } from "model/market-report";
import { MarketSettings } from "model/market-settings";

export const getUser = () => {
    return api().getUser();
}

export const saveUser = (user: UserPartial) => {
    return api().saveUser(user);
}

export const saveUserAuth = (userAuth: UserAuth) => {
    return api().saveUserAuth(userAuth);
}

export const getAdminMode = () => {
    return api().getAdminMode();
}

export const getResellerById = (id: number) => {
    return api().getResellerById(id);
}

export const getClient = (clientId: number) => {
    return api().getClient(clientId);
}


export const getPostalCodes = () => {
    return api().getPostalCodes();
}


export const getQuota = () => {
    return api().getQuota();
}

export const getLeads = () => {
    return api().getLeads();
}

export const createLead = (lead: NewLead) => {
	return api().createLead(lead);
}

export const updateLead = (lead: Lead) => {
	return api().updateLead(lead);
}

export const saveLeadImport = (leadImports: LeadImport[]) => {
    return api().saveLeadImport(leadImports);
}

export const getSavedListings = (leadId: number) => {
    return api().getSavedListings(leadId);
}

export const createSavedListing = (savedListing: NewSavedListing) => {
	return api().createSavedListing(savedListing);
}

export const updateSavedListing = (savedListing: SavedListing) => {
	return api().updateSavedListing(savedListing);
}

export const getSavedSearches = () => {
    return api().getSavedSearches();
}

export const createSavedSearch = (savedSearch: NewSavedSearch) => {
	return api().createSavedSearch(savedSearch);
}

export const updateSavedSearch = (savedSearch: SavedSearch) => {
	return api().updateSavedSearch(savedSearch);
}

export const getSavedSearchResultTotal = (savedSearchId: number) => {
    return api().getSavedSearchResultTotal(savedSearchId);
}

export const getEmailSents = (leadId: number) => {
    return api().getEmailSents(leadId);
}

export const getEmailClicks = (leadId: number) => {
    return api().getEmailClicks(leadId);
}

export const getLeadNotes = () => {
    return api().getLeadNotes();
}

export const createLeadNote = (leadNote: NewLeadNote) => {
	return api().createLeadNote(leadNote);
}

export const updateLeadNote = (leadNote: LeadNote) => {
	return api().updateLeadNote(leadNote);
}

export const getLeadTags = () => {
    return api().getLeadTags();
}
export const createLeadTag = (leadTag: NewLeadTag) => {
	return api().createLeadTag(leadTag);
}

export const deleteLeadTag = (leadTag: LeadTag) => {
    return api().deleteLeadTag(leadTag);
}

export const getLeadPipelines = () => {
    return api().getLeadPipelines();
}

export const createLeadPipeline = (leadPipeline: NewLeadPipeline) => {
    return api().createLeadPipeline(leadPipeline);
}

export const getLeadPhones = () => {
    return api().getLeadPhones();
}

export const createLeadPhone = (leadPhone: NewLeadPhone) => {
	return api().createLeadPhone(leadPhone);
}

export const updateLeadPhone = (leadPhone: LeadPhone) => {
	return api().updateLeadPhone(leadPhone);
}

export const getLeadAnniversaries = () => {
    return api().getLeadAnniversaries();
}

export const updateLeadAnniversary = (leadAnniversary: LeadAnniversary) => {
	return api().updateLeadAnniversary(leadAnniversary);
}

export const createLeadAnniversary = (leadAnniversary: NewLeadAnniversary) => {
	return api().createLeadAnniversary(leadAnniversary);
}

export const getLeadActivities = (leadId?: number) => {
    return api().getLeadActivities(leadId);
}

export const getLeadActivityStats = () => {
    return api().getLeadActivityStats();
}

export const getLeadLastActivities = () => {
    return api().getLeadLastActivities();
}

export const getCampaigns = () => {
    return api().getCampaigns();
}

export const updateCampaign = (campaign: Campaign) => {
    return api().updateCampaign(campaign);
}

export const createCampaign = (campaign: NewCampaign) => {
    return api().createCampaign(campaign);
}

export const getGeographicRoutes = () => {	
	return api().getGeographicRoutes();
}

export const createGeographicRoute = (geographicRoute: NewGeographicRoute) => {
	return api().createGeographicRoute(geographicRoute);
}

export const deleteGeographicRoute = (geographicRoute: GeographicRoute) => {
	return api().deleteGeographicRoute(geographicRoute);
}

export const getRoundRobinRoutes = () => {
	return api().getRoundRobinRoutes();
}

export const updateRoundRobinRoutes = (roundRobinRoutes: NewRoundRobinRoute[]) => {
	return api().updateRoundRobinRoutes(roundRobinRoutes);
}

export const getCampaignMessages = () => {
    return api().getCampaignMessages();
}

export const resendTextingOtpVerification = () => {
	return api().resendTextingOtpVerification();
}

export const createCampaignMessage = (campaignMessage: NewCampaignMessage) => {
    return api().createCampaignMessage(campaignMessage);
}

export const updateCampaignMessage = (campaignMessage: CampaignMessage) => {
    return api().updateCampaignMessage(campaignMessage);
}

export const deleteCampaignMessage = (campaignMessage: CampaignMessage) => {
    return api().deleteCampaignMessage(campaignMessage);
}

export const getCampaignCampaignMessages = () => {
    return api().getCampaignCampaignMessages();
}

export const createCampaignCampaignMessage = (campaignCampaignMessage: NewCampaignCampaignMessage) => {
    return api().createCampaignCampaignMessage(campaignCampaignMessage);
}

export const updateCampaignCampaignMessage = (campaignCampaignMessage: CampaignCampaignMessage) => {
    return api().updateCampaignCampaignMessage(campaignCampaignMessage);
}

export const updateCampaignCampaignMessages = (campaignCampaignMessages: CampaignCampaignMessage[]) => {
    return api().updateCampaignCampaignMessages(campaignCampaignMessages);
}

export const sendCampaignCampagaignMessageEmailTest = (campaignCampaignMessageSendEmail: CampaignCampaignMessageSendEmail) => {
    return api().sendCampaignCampagaignMessageEmailTest(campaignCampaignMessageSendEmail);
}

export const getCampaignSubscriptions = () => {
    return api().getCampaignSubscriptions();
}

export const createCampaignSubscription = (campaignSubscription: NewCampaignSubscription) => {
	return api().createCampaignSubscription(campaignSubscription);
};

export const updateCampaignSubscription = (campaignSubscription: CampaignSubscription) => {
	return api().updateCampaignSubscription(campaignSubscription);
};

export const getCampaignEmails = (leadId?: number, campaignId?: number, blast?: boolean) => {
    return api().getCampaignEmails(leadId, campaignId, blast);
}

export const getCampaignStats = () => {
    return api().getCampaignStats();
}

export const getCampaignSubscriptionStats = () => {
    return api().getCampaignSubscriptionStats();
}

export const getCampaignCampaignMessageStats = () => {
    return api().getCampaignCampaignMessageStats();
}

export const getCampaignRoutes = () => {
    return api().getCampaignRoutes();
}

export const createCampaignRoute = (campaignRoute: NewCampaignRoute) => {
	return api().createCampaignRoute(campaignRoute);
};

export const updateCampaignRoute = (campaignRoute: CampaignRoute) => {
	return api().updateCampaignRoute(campaignRoute);
};

export const deleteCampaignRoute = (campaignRoute: CampaignRoute) => {
    return api().deleteCampaignRoute(campaignRoute);
}

export const getCampaignsSettings = () => {
    return api().getCampaignsSettings();
}

export const updateCampaignsSettings = (campaignsSettings: CampaignsSettings) => {
    return api().updateCampaignsSettings(campaignsSettings);
}

export const getMarkets = () => {
    return api().getMarkets();
}

export const createMarket = (market: NewMarket) => {
    return api().createMarket(market);
}

export const updateMarket = (market: Market) => {
    return api().updateMarket(market);
}

export const getMarketSettings = () => {
    return api().getMarketSettings();
}

export const updateMarketSettings = (marketSettings: MarketSettings) => {
    return api().updateMarketSettings(marketSettings);
}

export const getMarketListings = () => {
    return api().getMarketListings();
}

export const getMarketReports = () => {
    return api().getMarketReports();
}

export const updateMarketReport = (marketReport: MarketReport, marketId: number) => {
    return api().updateMarketReport(marketReport, marketId);
}

export const getMarketReportSubscriptions = () => {
    return api().getMarketReportSubscriptions();
}

export const createMarketReportSubscription = (subscription: NewMarketReportSubscription) => {
    return api().createMarketReportSubscription(subscription);
}

export const updateMarketReportSubscription = (subscription: MarketReportSubscription) => {
    return api().updateMarketReportSubscription(subscription);
}

export const updateMarketSubscriptions = (marketId: number, subscriptions: (NewMarketReportSubscription | MarketReportSubscription)[]) => {
    return api().updateMarketSubscriptions(marketId, subscriptions);
}

export const getAgents = () => {
    return api().getAgents();
}

export const createAgent = (agent: NewAgent) => {
	return api().createAgent(agent);
}

export const updateAgent = (agent: Agent) => {
	return api().updateAgent(agent);
};

export const updateAgents = (agents: Agent[]) => {
	return api().updateAgents(agents);
};

export const deleteAgent = (agent: Agent) => {
    return api().deleteAgent(agent);
}

export const getOffices = () => {
    return api().getOffices();
}

export const updateOffice = (office: Office) => {
	return api().updateOffice(office);
}

export const createOffice = (office: NewOffice) => {
    return api().createOffice(office);
}

export const deleteOffice = (office: Office) => {
    return api().deleteOffice(office);
}

export const getCities = () => {
    return api().getCities();
}

export const getNeighborhoods = () => {
    return api().getNeighborhoods();
}

export const getAggregateStats = () => {
    return api().getAggregateStats();
}

export const getTasks = () => {
    return api().getTasks();
}
export const createTask = (task: NewTask) => {
	return api().createTask(task);
};

export const updateTask = (task: Task) => {
	return api().updateTask(task);
};

export const getIntegrations = () => {
    return api().getIntegrations();
}

export const saveIntegration = (integration: IntegrationPartial) => {
    return api().saveIntegration(integration);
}

export const deleteIntegration = (integration: IntegrationPartial) => {
    return api().deleteIntegration(integration);
}

export const testIntegration = (integration: IntegrationPartial) => {
    return api().testIntegration(integration);
}

export const getIntegrationCalendars = (integrationId: number) => {
    return api().getIntegrationCalendars(integrationId);
}

export const getSiteLinks = () => {
    return api().getSiteLinks();
}

export const getBoards = () => {
    return api().getBoards();
}

export const getAgentBoards = () => {
    return api().getAgentBoards();
}

export const createAgentBoard = (agentBoard: NewAgentBoard) => {
    return api().createAgentBoard(agentBoard);
}

export const updateAgentBoard = (agentBoard: AgentBoard) => {
    return api().updateAgentBoard(agentBoard);
}

export const deleteAgentBoard = (agentBoard: AgentBoard) => {
    return api().deleteAgentBoard(agentBoard);
}

export const getOfficeBoards = () => {
    return api().getOfficeBoards();
}

export const createOfficeBoard = (officeBoard: NewOfficeBoard) => {
	return api().createOfficeBoard(officeBoard);
}

export const updateOfficeBoard = (officeBoard: OfficeBoard) => {
	return api().updateOfficeBoard(officeBoard);
}

export const deleteOfficeBoard = (officeBoard: OfficeBoard) => {
    return api().deleteOfficeBoard(officeBoard);
}

export const getUploadSignature = (uploadPreset: string) => {
    return api().getUploadSignature(uploadPreset);
}

export const getGeocode = (address?: string) => {
    return api().getGeocode(address);
}

export const getPages = () => {
    return api().getPages();
}

export const savePage = (page: PagePartial) => {
    return api().savePage(page);
}

export const getLanguages = () => {
    return api().getLanguages();
}

const api = (): typeof live | typeof mock => {
    if (env.live) {
        return live;
    } else {
        return mock;
    }
}

