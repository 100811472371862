import React from "react";
import Plot from "react-plotly.js";
import { Lead } from "model/lead";
import { urls } from "routes/urls";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";

interface Props {
	leads: Lead[];
}

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps
{
}

interface LeadSourceFrequencies {
	[key: string]: number;
}

const WebsiteCrmSource = "Website/CRM";

class Component extends React.Component<Props> {

	

	private getLeadSourceFrequencies = (leads: Lead[]) => {
		const sources: LeadSourceFrequencies = {};
		leads.forEach(lead => {
			const { source } = lead;
			if (source) {
				if (sources[source]) {
					sources[source]++;
				} else {
					sources[source] = 1;
				}
			} else {
				if (sources[WebsiteCrmSource]) {
					sources[WebsiteCrmSource]++;
				} else {
					sources[WebsiteCrmSource] = 1;
				}
			}
		});
		return sources;
	};

	public render() {
		const { history } = this.props;
		const leadSourceFrequencies = this.getLeadSourceFrequencies(this.props.leads);
		const total = Object.values(leadSourceFrequencies).reduce(
			(sum, current) => sum + current,
			0,
		);
		return (
			<Plot
				data={[
					{
						type: "pie",
						hole: 0.6,
						values: Object.values(leadSourceFrequencies),
						labels: Object.keys(leadSourceFrequencies),
						text: Object.entries(leadSourceFrequencies).map(
							entry =>
								Math.round((entry[1] / total) * 100) + "% " + entry[0] + " Leads",
						),
						marker: {
							colors: ["#5eb95f", "#398ad6", "#41f4df", "#bee6b1"],
						},
						textinfo: "label",
						hoverinfo: "text",
						hoverlabel: {
							bordercolor: "#cdcdcd",
							font: {
								color: "#ffffff",
							},
						},
					},
				]}
				layout={{
					showlegend: false,
					margin: {
						t: 30,
						b: 30,
						l: 40,
						r: 40,
						pad: 0,
					},
					xaxis: {
						fixedrange: true,
					},
					yaxis: {
						fixedrange: true,
					},
				}}
				config={{
					displayModeBar: false,
					responsive: true,
				}}
				onHover={(event) => {
					// @ts-ignore
					const totalLeads = event.points[0].value;
					// @ts-ignore
					const label = event.points[0].label;
					if (totalLeads > 0 && label !== WebsiteCrmSource) {
						// @ts-ignore
						event.event.toElement.style.cursor = "pointer";
					}
				}}
				onUnhover={(event) => {
					// @ts-ignore
					event.event.toElement.style.cursor = "default";
				}}
				onClick={(event) => {
					// @ts-ignore
					const totalLeads = event.points[0].value;
				
					// @ts-ignore
					const label = event.points[0].label;
					if (totalLeads > 0 && label !== WebsiteCrmSource) {
						history.push(urls.leads(
							{
								leadsPageFilters: {
									source: label,
								},
							}
						));
					}
				}}
			/>
		);
	}
}

export const Chart = Router.withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
