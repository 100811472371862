import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { getAgentsWithLeads, getAgentById, getUser, getLeadsPageFilters } from "redux/selector";
import { setAgentValue } from "redux/slice/agents";
import Fuse from 'fuse.js';
import * as Icon from "react-feather";
import { Avatar } from "component/shared/avatar";
import { Agent } from "model/agent";
import { Theme, withTheme } from "@material-ui/core";

const mapStateToProps = (state: RootState) => {
  const agents = getAgentsWithLeads(state).sort((a, b) => a.name.localeCompare(b.name));
  const value = state.agents.value;
  const agent = getAgentById(state, value);
  const user = getUser(state);

  return {
    agents,
    value,
    agent,
    loading: state.agents.loading,
	user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAgentValue,
    },
    dispatch
  );

interface State {
  searchResults: Agent[];
  anchorEl: any;
  searchQuery: string;
}

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    Mui.WithStyles<typeof styles> {
		theme: Theme
	}

class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchResults: this.props.agents,
      anchorEl: null,
      searchQuery: "",
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.agents !== this.props.agents) {
      this.setState({
        searchResults: this.props.agents,
      });
    }
  }

  handleChange = (newValue: number) => {
    this.props.setAgentValue(newValue);
    this.setState({ anchorEl: null });
  };

  handleUnassign = () => {
	this.props.setAgentValue(-1);
    this.setState({ anchorEl: null });
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { agents } = this.props;
    const searchQuery = e.target.value;

    if (!searchQuery) {
      this.setState({ searchResults: agents, searchQuery: "" });
    } else {
      const fuseOptions = {
        keys: ['name', "email"],
        threshold: 0.3,
      };
      const fuse = new Fuse(agents, fuseOptions);
      const searchResults = fuse.search(searchQuery).map((result) => result.item);
      this.setState({ searchResults, searchQuery });
    }
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { agents } = this.props;
    const { searchResults } = this.state;

    if (searchResults.length === 0) {
      this.setState({ searchResults: agents });
    }

    this.setState({ anchorEl: event.currentTarget });
  };
  
  render() {
    const { value, classes, agent, agents, loading } = this.props;
    const { searchResults, anchorEl, searchQuery } = this.state;
	const unassigned = value === -1;

    const highlightSearchQuery = (text: string, query: string) => {
		const { theme } = this.props;
		const colorScheme = theme.palette.secondary.main;

		if (!query) {
			return text;
		};
		const regex = new RegExp(`(${escapeRegExp(query)})`, 'gi');
		return text.replace(regex, `<span style="color: ${colorScheme};">$1</span>`);
    };

    const escapeRegExp = (string: string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    return (
      <Mui.FormControl className={classes.form}>
        <Mui.Button 
          variant="outlined"
          color="primary"
          onClick={this.handleClick}
          endIcon={anchorEl ? (<Icon.ChevronUp size={16} className={classes.arrow}/>) : (<Icon.ChevronDown size={16} className={classes.arrow}/>)}
          disabled={loading || !agents}
        >
            <Mui.Grid container wrap="nowrap" alignItems="center" className={classes.nameContainer}>
				{agent ? (
					<Mui.Grid item>
						<Avatar phrase={agent.label || agent.email || ""} src={agent.photoUrl} />
					</Mui.Grid>
				):
					<Mui.Grid item>
						{unassigned ? <Icon.User className={classes.usersIcon} size={36}/> : <Icon.Users className={classes.usersIcon} size={36}/>}
					</Mui.Grid>
				}
              <Mui.Grid item>
				<Mui.Grid container direction="column">
					<Mui.Grid item>
						<Mui.Typography className={classes.viewLabel}>
							Viewing:
						</Mui.Typography>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.Typography noWrap className={classes.agentName}>
							{agent ? (agent.name || agent.email) : unassigned ? "Unassigned" : "All Agents"}
						</Mui.Typography>
					</Mui.Grid>
				</Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
        </Mui.Button>
        <Mui.Popover
          keepMounted
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => this.setState({anchorEl: null})}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={classes.popover}
        >
          <Mui.TextField 
            id="agent-search"
            onChange={this.handleSearch}
            placeholder="Search Agents"
            color="secondary"
            InputProps={{
              startAdornment: (
                <Icon.Search className={classes.searchIcon}/>
              ),
              style: { color: "lightgrey" },
            }}
            fullWidth
            className={classes.textField}
          />
          <Mui.MenuItem 
            value={0}
            onClick={() => this.handleChange(0)}
			className={classes.menuItem}
          >
            <Icon.Users className={classes.usersIcon} size={36}/>
            <Mui.Typography className={classes.agentNameItem}>
              All Agents
            </Mui.Typography>
          </Mui.MenuItem>
          {searchResults.map((agent, i) => (
            <Mui.MenuItem 
              key={i}
              value={agent.id} 
              onClick={() => {
                this.handleChange(agent.id)
              }}
			  className={agent.id === value ? classes.selected : classes.menuItem}
            >
              <Avatar phrase={agent.name || agent.email || ""} src={agent.photoUrl} />
              <Mui.Typography className={classes.agentNameItem}>
                <Mui.Grid dangerouslySetInnerHTML={{ __html: highlightSearchQuery(agent.name || agent.email || "", searchQuery) }} />
              </Mui.Typography>
            </Mui.MenuItem>
          ))}
          <Mui.MenuItem
			onClick={() => this.handleUnassign()}
			className={classes.menuItem}
		  >
            <Icon.User size={36} className={classes.usersIcon}/>
            <Mui.Typography className={classes.agentNameItem}>
              Unassigned
            </Mui.Typography>
          </Mui.MenuItem>
        </Mui.Popover>
      </Mui.FormControl>
    );
  }
}

export const AgentSelector = Mui.withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(Component))
);
