import * as React from "react";
import * as env from "shared/env";
import { Eureka } from "./eureka";
import { Mock } from "./mock";
import { BaseProps } from "./types";
import { Kestrel } from "./kestrel";
import * as Mui from "@material-ui/core";
import { Criterion, CriterionValue } from "model/criterion";
import { PropertyType } from "type/property-type";
import { ListingSearchFieldType } from "type/listing-search-field-type";

interface State {
	featuredOnly: CriterionValue;
	key: number;
}

interface Props extends BaseProps {
	hideSaveListingButton?: boolean;
	hideSaveButton?: boolean;
	disableRegistration?: boolean;
	hideStatusField?: boolean;
	hideHeartButton?: boolean;
	hideModal?: boolean;
}

class Component extends React.Component<Props, State> {

	
	constructor(props: Props) {
		super(props);
		const savedFeatureOnly = props.criteria?.find(obj => obj.name === "featuredOnlyYn")?.value;
		this.state = {
			featuredOnly: savedFeatureOnly ?? false,
			key: 0
		}
	}

	getCriteria(): Criterion[] {
        const { user } = this.props;
		const { featuredOnly } = this.state;
		let criteria = this.props.criteria || [];
		const i = criteria ? criteria.findIndex(c => c.name === "propertyType") : -1;
		if (i == -1) {
			const propertyType: Criterion = {
				label: ListingSearchFieldType.PROPERTY_TYPE.id,
				name: ListingSearchFieldType.PROPERTY_TYPE.id,
				value: [
					PropertyType.HOUSE.id,
					PropertyType.CONDO.id
				],
				area: false,
			}
			criteria.push(propertyType);
		}
		if (!user.kestrelVersion.all) {
			const i = criteria.findIndex(c => c.name === "featuredOnlyYn");
			if (i > -1) {
				criteria[i].value = featuredOnly;
			} else {
				const featured: Criterion = {
					name: "featuredOnlyYn",
					label: "Featured",
					value: featuredOnly,
					area: false
				}
				criteria.push(featured);
			}
		}
		return criteria;
    }
	
	public render() {
		const { user, onCriteriaChange, hideSaveListingButton, hideSaveButton, disableRegistration, hideStatusField, hideHeartButton } = this.props;
		const { featuredOnly, key } = this.state;
		if (env.live) {
			if (user.kestrelVersion.all) {
				return (
					<Kestrel
						{...this.props} 
						criteria={this.getCriteria()}
						onCriteriaChange={(criteria) => {
							if (onCriteriaChange) {
								onCriteriaChange(criteria);
							} 
						}} 
						hideSaveListingButton={hideSaveListingButton}
						hideSaveButton={hideSaveButton}
						disableRegistration={disableRegistration}
						hideStatusField={hideStatusField}
						hideHeartButton={hideHeartButton}
					/>
				)
			} else {
				return (
					<>
						<Mui.FormControlLabel
							label="Featured Listings Only"
							control={
								<Mui.Checkbox
									checked={featuredOnly === true}
									onChange={() => this.setState({ featuredOnly: !featuredOnly, key: key + 1 })}
								/>
							}
						/>
							<Eureka 
								key={key}
								{...this.props}
								criteria={this.getCriteria()}
								onCriteriaChange={(criteria) => {
									if (onCriteriaChange) {
										onCriteriaChange(criteria);
									} 
								}}
							/>
					</>
				)
			}
		}
		return <Mock {...this.props} />;
	}

}
export const ListingSearch = Component;
