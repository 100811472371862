class Type {

	public static HOUSE = new Type("SFR", "House", true);
	public static CONDO = new Type("CND", "Condo", true);
	public static LOTS_LAND = new Type("LL", "Lots / Land", true);
	public static MULTI_UNIT = new Type("RI", "Multi-Unit", true);
	public static MOBILE_HOME = new Type("MH", "Mobile Home", true);
	public static RENTAL = new Type("RNT", "Rental", true);
	public static COMMERCIAL = new Type("COM", "Commercial", true);
	public static FARM = new Type("FRM", "Farm", true);
	public static RESIDENTIAL = new Type("SFR,CND", "Residential", false);


	private constructor(
		public readonly id: string,
		public readonly label: string,
		public readonly active: boolean,
	) {
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static activeValues(): Type[] {
		return this.values().filter((type) => type.active === true);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as PropertyType };
