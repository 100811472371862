import React from "react";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import { User } from "model/user";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { validate } from "shared/yup";
import { styles } from "./style";
import { connect } from "react-redux";
import * as yup from "yup";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { saveUser } from "redux/slice/authentication";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { FullWidthField } from "./../full-width-field";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { normalizePhoneNumberInput } from "shared/phone-number-formatter";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
}, dispatch);

interface OwnProps {
	user: User;
}

interface Props extends 
	PageProps, 
	Router.RouteComponentProps, 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>{
}

interface State {
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity: string;
	showValidation: boolean;
	companyEmail: string;
	dailyEmail?: string;
	clientCellPhoneNumber?: string;
	receiveTextMessages?: boolean;
	receiveLeadActivity?: string;
	emailUpdatesForSoldListings?: boolean;
	sendEmailUpdatesForMlsChanges?: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {...props.user, 
			showValidation: false, 
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
			receiveTextMessages: (props.user.receiveTextMessages === undefined) ? 
				false : props.user.receiveTextMessages,
			receiveLeadActivity: props.user.receiveLeadActivity || "daily",
			emailUpdatesForSoldListings: (props.user.emailUpdatesForSoldListings === undefined) ? 
				true : props.user.emailUpdatesForSoldListings,
			sendEmailUpdatesForMlsChanges: (props.user.sendEmailUpdatesForMlsChanges === undefined) ? 
				true : props.user.sendEmailUpdatesForMlsChanges,
		};
	}
	private validationSchema = {
		clientCellPhoneNumber: yup
			.string()
			.test(
				"phone",
				"Invalid phone number",
				(value) => !value || value.length === 14
			),
		companyEmail: yup
			.string()
			.email("Invalid Email")
			.required("This field is required"),
		dailyEmail: yup
			.string()
			.email("Invalid Email")
			.required("This field is required"),
	};

	public save = () => {
		const { saveUser } = this.props;
		const errors = validate(this.validationSchema, this.state);
		if (!errors) {
			const user = {...this.state};
			const snackbarMessage = "Alert & Email settings saved";
			saveUser({user});
			this.setState({openSnackbar: true, snackbarMessage });
		} else {
			const snackbarMessage = "Not saved. Please correct email address formatting and try again.";
			this.setState({
				openSnackbar: true,
				showValidation: true,
				snackbarMessage
			});
		}
	}

	public cancel = () => {
		const { user } = this.props;
		this.setState({...user, 
			snackbarMessage: "",
			snackbarSeverity: "success",
			showValidation: false,
			openSnackbar: false});
	}

	public closeSaveMessage = () => {
		this.setState({ openSnackbar: false })
	}

	public render() {
		const { user } = this.props;
		const { 
			showValidation, 
			openSnackbar, 
			snackbarMessage, 
			clientCellPhoneNumber,
			dailyEmail,
			companyEmail,
			sendEmailUpdatesForMlsChanges,
			emailUpdatesForSoldListings,
			receiveTextMessages,
			receiveLeadActivity 
		} = this.state;

		const errors = validate(this.validationSchema, this.state);
		const title = "Alert & Email Basic Setup";
		return (
			<DashboardLayout
				permitted={user.admin || (user.type.agent && user.permissions.modifySubscriptions)}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}>
				<FullPageCard>
					<Mui.Grid container spacing={2} direction="column">
						<Mui.Grid item>
							<FullWidthField
								label="Main Company Contact"
								placeholder="jdoe@example.com"
								errorText={showValidation && errors && errors.companyEmail}
								value={companyEmail}
								onChange={event => this.setState({
									companyEmail: event.target.value
								})}
								sidebarNote={`This is the primary contact email address for your company. 
								By default all iHomefinder tools will sent notifications, leads and client communication to this address.`} />
						</Mui.Grid>

						<Mui.Grid item>
							<FullWidthField
								label="Email Update Return/From Address"
								placeholder="jdoe@example.com"
								errorText={showValidation && errors && errors.dailyEmail}
								value={dailyEmail}
								onChange={event => this.setState({ dailyEmail: event.target.value })} />
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthField
								label="Main company mobile number"
								placeholder="xxx-xxx-xxxx"
								errorText={showValidation && errors && errors.clientCellPhoneNumber}
								value={clientCellPhoneNumber}
								onChange={event => this.setState(prevState => ({
									clientCellPhoneNumber: normalizePhoneNumberInput(
										event.target.value,
										prevState.clientCellPhoneNumber,
									)
								}))} 
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Include Price Change Listings in email alerts"
								value={!!sendEmailUpdatesForMlsChanges}
								onChange={value => this.setState({ sendEmailUpdatesForMlsChanges: value })} />
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Include Sold Listings in email alerts"
								value={!!emailUpdatesForSoldListings}
								onChange={value => this.setState({ emailUpdatesForSoldListings: value })}
								sidebarNote="Sold listings are sent only if your MLS provides sold listing data." />
						</Mui.Grid>
						{user.permissions.textMessagingNotifications && 
							<Mui.Grid item>
								<YesNoRadioField
									label="Receive lead notifications via text message"
									value={!!receiveTextMessages}
									onChange={value => this.setState({ receiveTextMessages: value })}
									sidebarNote={
										`Texts are sent in addition to email notifications, and respect Advanced Routing configuration. 
										Texts are sent for the following request types: More Information, Schedule a Showing, Contact, Valuation, and Text Message Replies.`
									}
								/>
							</Mui.Grid>
						}
						<Mui.Grid item>
							{
							<FullWidthDropdown<string>
								label="Send Lead Activity Email"
								value={receiveLeadActivity || ""}
								onChange={value => {
									this.setState({
										receiveLeadActivity: value
									});
								}}>
								<Mui.MenuItem value="daily">Daily</Mui.MenuItem>
								<Mui.MenuItem value="weekly">Weekly</Mui.MenuItem>
								<Mui.MenuItem value="monthly">Monthly</Mui.MenuItem>
								<Mui.MenuItem value="none">Never</Mui.MenuItem>
							</FullWidthDropdown>
							}
						</Mui.Grid>
						<Mui.Grid item>

						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container spacing={1}>
								<Mui.Grid item style={{marginTop:24}}>
									<Mui.Button
										color="secondary"
										variant="contained"
										onClick={() => this.save()}
										style={{ marginRight: 32 }}
										>Save Changes</Mui.Button>
									<Mui.Button 
										variant="contained"
										onClick={() => this.cancel()}
										>Cancel</Mui.Button>
								</Mui.Grid>
								<Mui.Grid item>

								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar 
						open={openSnackbar} 
						autoHideDuration={6000}
						onClose={() => this.closeSaveMessage()}
						action={
							<Mui.IconButton 
								size="small" 
								aria-label="close" 
								color="inherit" 
								onClick={() => this.closeSaveMessage()}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}>
						<MuiAlert severity="info">
							<Mui.Typography>{snackbarMessage}</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		)
	}
}

export const ClientEmailSetup = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
