import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { ColdFusionIframe } from "component/shared/iframe/coldfusion";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { WidgetType } from "type/widget-type";
import { QuickSearchConfig } from "./widgets/quick-search";
import { AgentListingsConfig } from "./widgets/agent-listings";
import { ContactFormConfig } from "./widgets/contact-form";
import { FeaturedListingsConfig } from "./widgets/featured-listings";
import { OfficeListingsConfig } from "./widgets/office-listings";
import { ListingsConfig } from "./widgets/listings";
import { MarketReportSignupConfig } from "./widgets/market-report-signup";
import { ValuationFormConfig } from "./widgets/valuation-form";
import { RegistrationFormConfig } from "./widgets/registration-form";
import { GallerySliderConfig } from "./widgets/gallery-slider";
import { ListingSearchConfig } from "./widgets/listing-search";
import { LoginConfig } from "./widgets/login";
import { MarketsConfig } from "./widgets/markets";
import { WidgetSelector } from "./widget-selector";
import { MarketReportConfig } from "./widgets/market-report";


interface Props extends PageProps, Router.RouteComponentProps {
}

interface State {
	widgetType: WidgetType | "";
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			widgetType: "",
		}
	}

	private configuration() {
		const { widgetType } = this.state;
		switch(widgetType) {
			case WidgetType.LISTINGS:
				return <ListingsConfig />;
			case WidgetType.FEATURED_LISTINGS:
				return <FeaturedListingsConfig />;
			case WidgetType.AGENT_LISTINGS:
				return <AgentListingsConfig />;
			case WidgetType.OFFICE_LISTINGS:
				return <OfficeListingsConfig />;
			case WidgetType.MARKETS:
				return <MarketsConfig />;
			case WidgetType.MARKET_REPORT:
				return <MarketReportConfig/>
			case WidgetType.MARKET_REPORT_SIGNUP:
				return <MarketReportSignupConfig />;
			case WidgetType.VALUATION_FORM:
				return <ValuationFormConfig />;
			case WidgetType.LOGIN:
				return <LoginConfig />;
			case WidgetType.REGISTRATION_FORM:
				return <RegistrationFormConfig />;
			case WidgetType.GALLERY_SLIDER:
				return <GallerySliderConfig />;
			case WidgetType.LISTING_SEARCH:
				return <ListingSearchConfig />;
			case WidgetType.QUICK_SEARCH:
				return <QuickSearchConfig />;
			case WidgetType.CONTACT_FORM:
				return <ContactFormConfig />;
			default:
				return false;
		}
	}

	private isPermitted() {
		const { user } = this.props;
		const isKestrelAll = user.kestrelVersion.all;
		let permitted = false;
		if (user) {
			if (isKestrelAll && !user.websitePlatform.wordpress) {
				permitted = true;
			}
			if (!isKestrelAll) {
				if (user.permissions.sidebarWidgets) {
					permitted = true;
				}
			}
		}
		return permitted;
	}

	public render() {
		const { user } = this.props;
		const { widgetType } = this.state;
		const title = "Widgets";
		return (
			<DashboardLayout
				permitted={this.isPermitted()}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
				iframe={!user.kestrelVersion.all &&
					<ColdFusionIframe url="/WidgetSelector.cfm" />
				}
				fullScreen={false}
			>
				{user.kestrelVersion.all && (
					<Mui.Grid container spacing={3} direction="column">
						<Mui.Grid item xs>
							<Mui.Typography variant="h3">
								Widget Builder
							</Mui.Typography>
							<Mui.Typography >
								Add IDX widgets to any page on your site. Select and configure a widget, then paste the embed code into your page.
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs>
							<Mui.Card>
								<Mui.CardContent>
									<Mui.Grid container spacing={3} direction="column">
										<Mui.Grid item>
											<WidgetSelector user={user} onSelect={(widgetType) => this.setState({widgetType})} />
										</Mui.Grid>
										{widgetType && (
											<Mui.Grid item xs>
												{this.configuration()}
											</Mui.Grid>
										)}
									</Mui.Grid>
								</Mui.CardContent>
							</Mui.Card>
						</Mui.Grid>
					</Mui.Grid>

				)}
			</DashboardLayout>
		);
	}
}

export const SettingsWidgetsPage = (Component);
