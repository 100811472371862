import { Criterion, CriterionValue } from "model/criterion";
import { ListingsCriteria } from "model/listings-criteria";
import { ListingSearchFieldType } from "type/listing-search-field-type";

export const translateToCriterion = (listingCriteria: ListingsCriteria | undefined) => {
    const criteria: Criterion[] = [];
    for (let key in listingCriteria) {
		const typedKey = key as keyof typeof listingCriteria;
		const value = listingCriteria[typedKey];
		if (value !== undefined) {
			const criterion: Criterion = {
				name: key,
				label: key,
				value: value as boolean | string | number | string[] | number[],
			}
			const isAreaField = !!ListingSearchFieldType.isArea(key);
			if (isAreaField) {
				criterion.area = isAreaField;
			}
			criteria.push(criterion);
		}
	}
    return criteria;
}

export const translateToListingsCriteria = (criteria: Criterion[] | undefined) => {
	if (!criteria) {
		return
	}
    const listingsCriteria: Partial<Record<string, CriterionValue>> = {};
    for (let criterion of criteria) {
        listingsCriteria[criterion.name] = criterion.value;
    }
    return listingsCriteria as ListingsCriteria;
}
  