import React from "react";
import { connect } from "react-redux";
import { Left } from "../left";
import { RootState } from "redux/store";
import { getCallNotes, getLeadsByAgentId } from "redux/selector";
import moment from "moment";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
	return {
		agentId,
		callNotes: getCallNotes(state),
		leads: getLeadsByAgentId(state, agentId),
	};
};

const Component = (props: Props) => {
    let numCallsLoggedPast7Days = 0;

    if (props.agentId && props.agentId !== -1) {
        // Filter leads by agentId
        const agentLeads = props.leads.filter(lead => lead.agent?.id === props.agentId);

        // Get leadIds for those leads
        const agentLeadIds = agentLeads.map(lead => lead.id);

        // Filter callNotes for those leadIds and within the past 7 days
        numCallsLoggedPast7Days = props.callNotes.filter(
            ({ leadId, createdOn }) =>
                agentLeadIds.includes(leadId) &&
                moment().subtract(7, "d").isBefore(createdOn) && 
                moment(createdOn).isSameOrBefore(moment())
        ).length;
    } else if (props.agentId === -1) {
        // Filter callNotes for leads without an assigned agent
        numCallsLoggedPast7Days = props.callNotes.filter(
            ({ leadId, createdOn }) =>
                !props.leads.some(lead => lead.id === leadId && lead.agent?.id) &&
                moment().subtract(7, "d").isBefore(createdOn) && 
                moment(createdOn).isSameOrBefore(moment())
        ).length;
    } else {
        // Use existing logic if no agentId
        numCallsLoggedPast7Days = props.callNotes.filter(
            ({ createdOn }) =>
                moment().subtract(7, "d").isBefore(createdOn) && 
                moment(createdOn).isSameOrBefore(moment())
        ).length;
    }

    const text = numCallsLoggedPast7Days === 1 ? "call logged" : "calls logged";
    return <Left color="#ab47bc" value={numCallsLoggedPast7Days} text={text} />;
};


export const CallsLogged = connect(mapStateToProps)(Component);
