import React from "react";
import { Lead } from "model/lead";
import { Item } from "./item";
import * as Icons from "react-feather";
import { urls } from "routes/urls";
import { LeadAvatar } from "component/shared/lead-avatar";
import { User } from "model/user";
import Fuse from "fuse.js";
import { Campaign } from "model/campaign";
import { Market } from "model/market";
import { Agent } from "model/agent";
import { Office } from "model/office";
import { ViewType } from "type/view-type";
import * as env from "shared/env";

export const getFuse = (
	user: User,
	viewType: ViewType,
	leads: Lead[],
	campaigns: Campaign[],
	markets: Market[],
	agents: Agent[],
	offices: Office[],
) => {
	const items: Item[] = [
		...getPages(user, viewType),
		...getLeads(leads),
		...getCampaigns(campaigns),
		...getMarkets(markets),
		...getAgents(agents),
		...getOffices(offices),
	];
	const options: Fuse.IFuseOptions<Item> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: [
			{
				name: "line1",
				weight: .7,
			},
			{
				name: "tags",
				weight: .4,
			},
		],
	};
	const fuse = new Fuse(items, options);
	return fuse;
}

const getPages = (user: User, viewType: ViewType) => {
	let items: Item[] = [];
	const leadsPermitted = 
		(user.permissions.broker && user.permissions.leads && user.ownsLeads) || 
		(!user.permissions.broker && user.permissions.leads) || 
		(user.admin && user.permissions.broker && user.permissions.leads);
	items = [
		{
			line1: "Profile",
			icon: <Icons.User />,
			url: urls.settingsProfile,
			className: "ihf-profile",
		},
		// Leads
		leadsPermitted && {
			line1: "Lead Activity",
			icon: <Icons.Zap />,
			url: urls.leadActivity,
			className: "ihf-leadactivity",
		},
		leadsPermitted && {
			line1: "Leads",
			icon: <Icons.User />,
			url: urls.leads(),
			className: "ihf-searchleads",
			tags: [
				"contacts",
				"subscribers",
			],
		},
		leadsPermitted && {
			line1: "Export Leads",
			icon: <Icons.Download />,
			url: urls.leadExport,
			className: "ihf-leadexport",
		},
		// Markets
		(user.admin && user.permissions.markets) && {
			line1: "Markets",
			icon: <Icons.MapPin />,
			url: urls.markets,
			className: "ihf-savedsearchpages",
			tags: [
				"hotsheets",
				"toppicks",
				"saved searches",
			],
		},
		(user.admin && user.permissions.markets) && {
			line1: "Market Settings",
			icon: <Icons.MapPin />,
			url: urls.marketSettings,
			className: "ihf-savedsearchpages",
		},
		// Listings
		(user.permissions.leads && user.ownsLeads) &&{
			line1: "Active Listings",
			icon: <Icons.Home />,
			url: urls.listingsActive,
			className: "ihf-activelistings",
		},
		(user.permissions.leads && user.ownsLeads && user.permissions.soldPendingListings) && {
			line1: "Sold/Off-Market",
			icon: <Icons.Home />,
			url: urls.listingsOffMarket,
			className: "ihf-soldpending",
		},
		(user.permissions.leads && user.ownsLeads && user.permissions.supplementalListings) && {
			line1: "Supplemental",
			icon: <Icons.Home />,
			url: urls.listingsSupplemental,
			className: "ihf-supplemental",
		},
		// Reports
		(user.permissions.leads && user.ownsLeads) && {
			line1: "Reports Overview",
			icon: <Icons.BarChart2 />,
			url: urls.reportsOverview,
			className: "ihf-reportsoverview",
		},
		(user.permissions.leads && user.ownsLeads) && {
			line1: "Email Activity",
			icon: <Icons.BarChart2 />,
			url: urls.reportsEmailActivity,
			className: "ihf-emailactivity",
		},
		(user.permissions.leads && user.ownsLeads) && {
			line1: "Saved Searches",
			icon: <Icons.BarChart2 />,
			url: urls.reportsSavedSearches,
			className: "ihf-emailactivity-savedsearches",
		},
		(user.permissions.leads && user.ownsLeads) && {
			line1: "Saved Properties",
			icon: <Icons.BarChart2 />,
			url: urls.reportSavedListings,
			className: "ihf-emailactivity-savedprops",
		},
		(user.permissions.leads && user.ownsLeads) && {
			line1: "Leads & Contacts",
			icon: <Icons.BarChart2 />,
			url: urls.reportsLeads,
			className: "ihf-leadscontacts",
		},
		(user.admin) && {
			line1: "Mobile Statistics",
			icon: <Icons.BarChart2 />,
			url: urls.reportsMobile,
			className: "ihf-mobilestats",
		},
		// Setup
		(user.admin) && {
			line1: "Overview",
			icon: <Icons.Settings />,
			url: urls.settingsOverview,
			className: "ihf-setupoverview",
		},
		(user.permissions.standalone) && {
			line1: "IDX Page Links",
			icon: <Icons.Settings />,
			url: urls.settingsPageLinks,
			className: "ihf-SiteLinks",
		},
		(user.admin) && {
			line1: "My Account",
			icon: <Icons.Settings />,
			url: urls.settingsAccount,
			className: "ihf-myaccount",
		},
		(user.admin) && {
			line1: "Change Password",
			icon: <Icons.Settings />,
			url: urls.settingsPassword,
			className: "ihf-changepassword",
		},
		(user.admin) && (user.permissions.billing) && {
			line1: "Billing Information",
			icon: <Icons.Settings />,
			url: urls.settingsBilling,
			className: "ihf-billinginfo",
		},
		(user.admin) && {
			line1: "MLS Setup",
			icon: <Icons.Settings />,
			url: urls.settingsMls,
			className: "ihf-mlssetup",
		},
		(user.admin && user.permissions.version >= 9) && {
			line1: "Integrations",
			icon: <Icons.Settings />,
			url: urls.settingsIntegrations,
			className: "ihf-integrations",
		},
		(user.admin && user.permissions.plugin) && {
			line1: "WordPress Plugin",
			icon: <Icons.Settings />,
			url: urls.settingsPlugin,
			className: "ihf-oesettings",
		},
		(user.admin && user.permissions.standalone) && {
			line1: "Branding Setup",
			icon: <Icons.Settings />,
			url: urls.settingsBranding,
			className: "ihf-brandingsetup",
		},
		(user.admin && user.permissions.standalone) && {
			line1: "Templates",
			icon: <Icons.Settings />,
			url: urls.settingsTemplates,
			className: "ihf-templates",
		},
		(user.admin && user.permissions.standalone && user.permissions.sidebarWidgets) && {
			line1: "Widgets",
			icon: <Icons.Settings />,
			url: urls.settingsWidgets,
			className: "ihf-widgets",
		},
		(user.admin && user.permissions.standalone) && {
			line1: "CSS",
			icon: <Icons.Settings />,
			url: urls.settingsCss,
			className: "ihf-css",
		},
		(user.admin && user.permissions.standalone) && {
			line1: "Framing Setup",
			icon: <Icons.Settings />,
			url: urls.settingsFraming,
			className: "ihf-framing",
		},
		(user.admin && user.permissions.standalone) && {
			line1: "Sub-Heads",
			icon: <Icons.Settings />,
			url: urls.settingsSubHeads,
			className: "ihf-subheads",
		},
		(user.permissions.standalone && user.permissions.version <= 8) && {
			line1: "Page-Specific HTML",
			icon: <Icons.Settings />,
			url: urls.settingsPageSpecificBranding,
			className: "ihf-pagehtml",
		},
		(user.admin) && {
			line1: "Basic Setup",
			icon: <Icons.Settings />,
			url: urls.settingsEmail,
			className: "ihf-emailbasicsetup",
		},
		(user.admin && !user.permissions.plugin) && {
			line1: "Branding",
			icon: <Icons.Settings />,
			url: urls.settingsEmailBranding,
			className: "ihf-emailbranding",
		},
		(user.admin && user.permissions.version <= 8) && {
			line1: "Customization",
			icon: <Icons.Settings />,
			url: urls.settingsEmailCustomization,
			className: "ihf-emailcustomization",
		},
		(user.admin) && {
			line1: "Advanced Routing",
			icon: <Icons.Settings />,
			url: urls.settingsEmailRouting,
			className: "ihf-emailadvrouting",
		},
		(user.admin && (user.permissions.geographicRouting || user.permissions.roundRobinRouting)) && {
			line1: "Geographic Routing",
			icon: <Icons.Settings />,
			url: urls.settingsEmailGeographicRouting,
			className: "ihf-georouting",
		},
		(user.admin && (user.permissions.listingSearch || user.permissions.listingMapSearch)) && {
			line1: "Property Types",
			icon: <Icons.Settings />,
			url: urls.settingsPropertyTypes,
			className: "ihf-propertytypesprices",
		},
		(user.admin && (user.permissions.listingSearch || user.permissions.listingMapSearch)) && {
			line1: "Location Customization",
			icon: <Icons.Settings />,
			url: urls.settingsLocationCustomization,
			className: "paths.settingsLocationCustomization",
		},
		(user.admin && (user.permissions.listingSearch || user.permissions.listingMapSearch)) && {
			line1: "Search Cities",
			icon: <Icons.Settings />,
			url: urls.settingsSearchCities,
			className: "ihf-searchcities",
		},
		(user.admin && (user.permissions.listingSearch || user.permissions.listingMapSearch) && user.permissions.neighborhoods) && {
			line1: "Search Neighborhoods",
			icon: <Icons.Settings />,
			url: urls.settingsSearchNeighborhoods,
			className: "ihf-searchneighborhoods",
		},
		(user.admin && (user.permissions.listingSearch || user.permissions.listingMapSearch)) && {
			line1: "Open House Settings",
			icon: <Icons.Settings />,
			url: urls.settingsOpenHouse,
			className: "ihf-openhomesettings",
		},
		(user.admin && ((user.permissions.listingSearch && user.permissions.listingPolygonSearch) || user.permissions.listingMapSearch)) && {
			line1: "Map Search",
			icon: <Icons.Settings />,
			url: urls.settingsMapSearch,
			className: "ihf-mapsearch",
		},
		(user.admin && user.permissions.listingResults) && {
			line1: "Results & Featured",
			icon: <Icons.Settings />,
			url: urls.settingsListingResults,
			className: "ihf-resultsfeatured",
		},
		(user.admin && user.permissions.listingDetail) && {
			line1: "Property Details",
			icon: <Icons.Settings />,
			url: urls.settingsListingDetails,
			className: "ihf-propertydetails",
		},
		(user.admin && user.permissions.listingDetail) && {
			line1: "Mortgage Calc Settings",
			icon: <Icons.Settings />,
			url: urls.settingsMortgageCalculator,
			className: "ihf-mortgagecalculatorsettings"
		},
		(user.admin && user.permissions.leadUserRegistration && !user.permissions.leadForceUserRegistration) && {
			line1: "User Registration",
			icon: <Icons.Settings />,
			url: urls.settingsUserRegistration,
			className: "ihf-userregistration",
		},
		(user.admin) && {
			line1: "Contact Forms",
			icon: <Icons.Settings />,
			url: urls.settingsContactForms,
			className: "ihf-contactforms",
		},
		(user.admin) && {
			line1: "Property Organizer",
			icon: <Icons.Settings />,
			url: urls.settingsPropertyOrganizer,
			className: "ihf-propertyorganizer",
		},
		(user.admin && user.permissions.facebookApp) && {
			line1: "Facebook",
			icon: <Icons.Settings />,
			url: urls.settingsFacebook,
			className: "ihf-facebook",
		},
		(user.admin && user.permissions.mobileSite) && {
			line1: "Mobile",
			icon: <Icons.Settings />,
			url: urls.settingsMobile,
			className: "ihf-mobile",
		},
		(user.admin && user.permissions.broker) && {
			line1: "Basic Settings",
			icon: <Icons.Settings />,
			url: urls.settingsBroker,
			className: "ihf-brokersettings",
		},
		(user.admin && user.permissions.broker) && {
			line1: "Agents",
			icon: <Icons.Settings />,
			url: urls.settingsAgents,
			className: "ihf-agents",
		},
		(user.admin && user.permissions.broker) && {
			line1: "Offices",
			icon: <Icons.Settings />,
			url: urls.settingsOffices,
			className: "ihf-offices",
		},
		(user.admin && viewType.admin) && {
			line1: "Lead Import",
			icon: <Icons.Upload />,
			url: urls.settingsLeadImport,
			className: "ihf-leadImport",
		},
		// Communications
		(user.admin && user.permissions.campaigns) && {
			line1: "Campaigns",
			icon: <Icons.Send />,
			url: urls.campaigns,
		},
		(user.admin && user.permissions.campaigns) && {
			line1: "Automations",
			icon: <Icons.Send />,
			url: urls.campaignsRouting,
		},
		(user.admin && user.permissions.campaigns) && {
			line1: "Settings",
			icon: <Icons.Send />,
			url: urls.campaignsSettings,
		}		
	].filter(Boolean) as Item[];
	items = items.map((item) => {
		if (!item.line2) {
			item.line2 = "Page";
		}
		return item;
	});
	return items;
}

const getLeads = (leads: Lead[]): Item[] => {
	return leads.map((lead) => ({
		line1: lead.label,
		line2: "Lead",
		icon: <LeadAvatar diameter={40} lead={lead} />,
		url: urls.lead(lead),
		tags: [
			"lead",
			lead.id,
			lead.name,
			lead.email,
			lead.email2,
			lead.phone,
		],
	}));
}

const getCampaigns = (campaigns: Campaign[]): Item[] => {
	return campaigns.map((campaign) => ({
		line1: campaign.name,
		line2: "Campaign",
		icon: <Icons.Send />,
		url: urls.campaign(campaign),
		tags: [
			"campaign",
			campaign.id,
		],
	}));
}

const getMarkets = (markets: Market[]): Item[] => {
	return markets.map((market) => ({
		line1: market.name,
		line2: "Market",
		icon: <Icons.MapPin />,
		url: urls.market(market),
		tags: [
			"market",
			market.id,
		],
	}));
}

const getAgents = (agents: Agent[]): Item[] => {
	return agents.map((agent) => ({
		line1: agent.label,
		line2: "Agent",
		icon: <Icons.Award />,
		url: urls.settingsAgent(agent),
		tags: [
			"agent",
			agent.id,
			agent.name,
			agent.email,
		],
	}));
}

const getOffices = (offices: Office[]): Item[] => {
	return offices.map((office) => ({
		line1: office.name,
		line2: "Office",
		icon: <Icons.Globe />,
		url: urls.settingsOffice(office),
		tags: [
			"office",
			office.id,
		],
	}));
}