import React from "react";
import * as Mui from "@material-ui/core";
import { EditNoteDialog } from "../edit-note-dialog";
import { Lead } from "model/lead";
import { styles } from "./style";

interface Props extends Mui.WithStyles<typeof styles> {
	lead: Lead;
}

interface State {
	editNoteDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			editNoteDialogIsOpen: false,
		};
	}

	public addLineBreak = () => {
		let { note } = this.props.lead;
		const { classes } = this.props;
		if (note) {
			const defaultVal = note.split(/[\r\n]/g).map((text: string, index: number) => {
				if (text.length) {
					return (
						<React.Fragment key={index}>
							{text} <br />
						</React.Fragment>
					)
				} else {
					return (
						<React.Fragment key={index}>
							<br />
						</React.Fragment>
					)
				}
			})
			return <Mui.Typography className={classes.linebreak}>{defaultVal}</Mui.Typography>;
		}
	}

	public render() {
		const { classes, lead } = this.props;
		const { editNoteDialogIsOpen } = this.state;
		return (
			<>
				<EditNoteDialog
					open={editNoteDialogIsOpen}
					onClose={() => this.setState({ editNoteDialogIsOpen: false })}
					lead={lead}
				/>
				<Mui.Card>
					<Mui.CardHeader
						title="Notes"
						classes={{
							root: classes.root,
							title: classes.title,
							action: classes.action,
						}}
						action={
							<Mui.Button
								classes={{
									label: classes.buttonTitle,
								}}
								onClick={() => this.setState({ editNoteDialogIsOpen: true })}
							>
								Edit
							</Mui.Button>
						}
					/>
					<Mui.CardContent>
						{lead.note ? (
							this.addLineBreak()
						) : (
							<Mui.Typography color="textSecondary">
								Add lead notes here
							</Mui.Typography>
						)}
					</Mui.CardContent>
				</Mui.Card>
			</>
		);
	}
}

export const NotesCard = Mui.withStyles(styles)(Component);
