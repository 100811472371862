import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { styles } from "./styles";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Campaign, NewCampaign } from "model/campaign";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { DynamicContentType } from "type/dynamic-content";
import { Select } from "component/shared/select";
import { createCampaign, updateCampaign } from "redux/slice/campaigns";
import { getMarkets } from "redux/selector";
import * as yup from "yup";
import { validate } from "shared/yup";
import { EmailMarketingType } from "type/email-marketing-type";


interface Props extends
	OwnProps,
	WithMediaQuery,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface OwnProps {
	campaign?: Campaign;
	onSave?: () => void;
}

interface State {
	submitted: boolean,
	name: string,
	description: string,
	dynamicContentType: DynamicContentType | undefined,
	emailBranding: boolean,
	marketId: number | undefined;
}

const mapStateToProps = (state: RootState) => {
	return {
		markets: getMarkets(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaign, 
	updateCampaign
}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			submitted: false,
			name: "",
			description: "",
			dynamicContentType: DynamicContentType.LEAD_PROFILE,
			emailBranding: false,
			marketId: undefined,
		};
	}

	private validationSchema = {
		name: yup
		.string()
		.required("Name is required.")
	}

	componentDidMount() {
		const { campaign } = this.props;
		if (campaign) {
			this.setState({
				name: campaign.name,
				description: campaign.description ? campaign.description : "",
				emailBranding: campaign.emailBrandingYn ? campaign.emailBrandingYn : false,
				dynamicContentType: campaign.campaignType && campaign.campaignType,
				marketId: campaign.marketId,
			});
		}
	}

	private handleTextField(id: string, value: string) {
		this.setState({ ...this.state, [id]: value });
	}

	private handleCheckField(id: string, value: boolean) {
		this.setState({ ...this.state, [id]: value });
	}

	private handleMarketField(value: number) {
		this.setState({marketId: value});
	}

	private handleSave() {
		const errors = validate(this.validationSchema, { ...this.state });
		if (!errors) {
			if (this.props.campaign && window.confirm("Save Settings?")) {
				const campaign: Campaign = {
					...this.props.campaign,
					name: this.state.name,
					description: this.state.description,
					emailBrandingYn: this.state.emailBranding,
					campaignType: this.state.dynamicContentType,
					marketId: this.state.marketId,
					emailMarketingType: EmailMarketingType.DRIP,
				}
				this.props.updateCampaign({campaign});
			} else {
				const campaign: NewCampaign = {
					name: this.state.name,
					description: this.state.description,
					emailBrandingYn: this.state.emailBranding,
					campaignType: this.state.dynamicContentType,
					marketId: this.state.marketId,
					emailMarketingType: EmailMarketingType.DRIP,
				}
				this.props.createCampaign({campaign});
			}
			if (this.props.onSave) {
				this.props.onSave();
			}
		}
	}

	public render() {
		const { markets, classes, campaign } = this.props;
		const { submitted } = this.state;
		const errors = validate(this.validationSchema, { ...this.state });
		return (
			<>
				<Mui.Grid container direction="column" spacing={1} style={{marginTop: campaign && campaign.id && 15}}>
					<Mui.Grid item lg={6}>
						<Mui.TextField
							error={submitted && !!errors && !!errors.name}
							id="name"
							variant="outlined"
							margin="dense"
							value= {this.state.name}
							label="Name"
							fullWidth 
							onChange={
								(event) => this.handleTextField(event.target.id, event.target.value)
							}
						/>
					</Mui.Grid>	
					<Mui.Grid item lg={6}>
						<Mui.TextField
							id="description"
							variant="outlined"
							margin="dense"
							value= {this.state.description}
							label="Description"
							multiline
							minRows={4}
							fullWidth 
							onChange={
								(event) => this.handleTextField(event.target.id, event.target.value)
							}
						/>
					</Mui.Grid>	
					<Mui.Grid item lg={6}>
						<Mui.Grid container direction="row" alignItems="center" wrap="nowrap">
							<Mui.Grid item className={classes.dynamicContentContainer}>
								<Select
									fullWidth

									variant="outlined"
									margin="dense"
									placeholder="Select"
									label="Smart Content Based On"
									
									value={(this.state.dynamicContentType && this.state.dynamicContentType.id) || ""}
									options={DynamicContentType.values()}
									valueExtractor={type => type.id}
									labelExtractor={type => type.label}
									optionRenderer={type => <Mui.ListItemText primary={type.label} />}
									onChange={value => this.setState({dynamicContentType: DynamicContentType.getById(value)})}
								/>
							</Mui.Grid>
							<Mui.Grid item>
								<div className={classes.info} >
									<Mui.Tooltip title="Select the source for dynamically generated content such as new listings and statistics.">
										<FeatherIcon>
											<Icons.Info />
										</FeatherIcon>
									</Mui.Tooltip>
								</div>
								
							</Mui.Grid>
							
							
						</Mui.Grid>
					</Mui.Grid>
						
					{this.state.dynamicContentType === DynamicContentType.MARKET && 
						<Mui.Grid item lg={6}>
							<Select
								variant="outlined"
								margin="dense"
								placeholder="Select a Market"
								fullWidth
								value={this.state.marketId}
								options={markets}
								valueExtractor={type => type.id}
								labelExtractor={type => type.name}
								optionRenderer={type => <Mui.ListItemText primary={type.name} />}
								onChange={value => this.handleMarketField(value)}
							/>
						</Mui.Grid>	
					}
					<Mui.Grid item lg={6}>
						<Mui.FormControlLabel
							control={
								<Mui.Checkbox
									id="emailBranding"
									checked={this.state.emailBranding}
									onChange={(event) => this.handleCheckField(event.target.id, event.target.checked)}
								/>
							}
							label="Email Branding"	
						/>
					</Mui.Grid>	
					<Mui.Grid item lg={6}>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => {
								this.setState({submitted: true}, 
									() => this.handleSave());
							}}
						>
							{this.props.campaign && this.props.campaign.id ? 
								(<>
									Save
								</>) :
								(<>
									Add
								</>)
							}
							
						</Mui.Button>
					</Mui.Grid>
				</Mui.Grid>
				
			</>
		);
	}
}

export const EditCampaign = withMediaQuery("(max-width:600px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component))
);