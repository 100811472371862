import React from "react";
import * as Mui from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { X } from "react-feather";
import { styles } from "./style";
import { ListingSearch } from "component/shared/listing-search";
import { User } from "model/user";
import { Lead } from "model/lead";
import { bindActionCreators } from "redux";
import { createSavedSearch, updateSavedSearch } from "redux/slice/saved-searches";
import { connect } from "react-redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { Criterion } from "model/criterion";
import { SavedSearch } from "model/saved-search";

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	createSavedSearch,
	updateSavedSearch,
}, dispatch)

interface Props extends 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapDispatchToProps>
{
	user: User;
	lead: Lead;
	open: boolean;
	onClose: Function;
	savedSearch?: SavedSearch;
}

interface State {
	name?: string;
	criteria: Criterion[];
	saved: boolean;
	errorText: string;
}

const Transition = React.forwardRef((props: TransitionProps, ref) => {
	return <Mui.Slide direction="up" ref={ref} {...props} />;
});

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			name: props.savedSearch ? props.savedSearch.name : "",
			criteria: [],
			saved: false,
			errorText: ""
		}
	}

	private saveSearch() {
		const { savedSearch, lead, createSavedSearch, updateSavedSearch, onClose } = this.props;
		const { criteria, name } = this.state;
		if (savedSearch) {
			updateSavedSearch({ savedSearch: {
				...savedSearch,
				name,
				criteria,
			}})
		} else {
			createSavedSearch({ savedSearch: {
				leadId: lead.id,
				name,
				criteria,
				recieveEmails: true,
				inactive: false,
			}});
		}
		onClose();
	}

	public render() {
		const { user, lead, open, onClose, savedSearch } = this.props;
		const { name } = this.state;
		return (
			<Mui.Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
			>
				<Mui.DialogTitle>
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item>
							<Mui.Typography variant="h2" component="div">
								{`${savedSearch ? "Edit" : "Add a"} Saved Search`}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.IconButton color="inherit" 
								onClick={() => onClose()}
							>
								<FeatherIcon>
									<X />
								</FeatherIcon>
							</Mui.IconButton>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item xs={6}>
							<Mui.Typography variant="h2" component="div">
								{lead.name ? lead.name : lead.email}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={6}>
							<Mui.TextField
								value={name}
								variant="outlined"
								label="Saved Search Name (Optional) "
								fullWidth
								onChange={(e) => this.setState({name: e.currentTarget.value})}
								inputProps={{maxLength: 256}}
							/>
						</Mui.Grid>
						<Mui.Grid item xs={6}>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => {
									this.saveSearch();
								}}
							>
								Save & Close
							</Mui.Button>
						</Mui.Grid>
						<Mui.Grid item>
							<ListingSearch
								user={user}
								onCriteriaChange={(criteria) => this.setState({criteria})}
								criteria={savedSearch && savedSearch.criteria}
								hideSaveListingButton
								hideSaveButton
								disableRegistration
								hideHeartButton
								hideStatusField
								hideModal
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Mui.Dialog>
		);
	}
}

export const SavedSearchDialog = Mui.withStyles(styles)(connect(null, mapDispatchToProps)(Component));
