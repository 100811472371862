import { ClientResource } from "api/resource/client";
import { resellers } from "./resellers";

export const clients: ClientResource[] = [
	{
		id: 1,
		reseller: resellers[1],
	},
	{
		id: 2,
		reseller: resellers[2],
	},
];