import React from "react";
import * as Mui from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { User, UserPartial } from "model/user";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { KestrelVersion } from "type/kestrel-version";
import { ColorPicker } from "./color-picker";
import { ColorDropdown } from "./color-dropdown";
import { styles } from "./style";
import { WebsitePlatformType } from "type/website-platform";
import * as kestrelEmbedCode from "shared/kestrel-embed-code";
import { InputLayout } from "component/shared/input-Layout";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { getIntegrationByTypeId, getPageByTypeId } from "redux/selector"
import { fetchPages, savePage } from "redux/slice/pages";
import { Page } from "model/page";
import { CodeBlock } from "component/shared/code-block";
import { DefaultPagePath } from "./default-page-path";
import { Platform } from "./platform";
import { SectionHeading } from "./section-heading";
import { VersionDropdown } from "./version-dropdown";
import * as yup from "yup";
import { validate } from "../../../shared/yup";
import { darkModeDev } from "shared/env";
import { IntegrationType } from "type/integration-type";

interface Props
	extends PageProps,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {
	user: User;
}

interface OwnProps {
	user: User;
}

interface State {
	saving: boolean;
	kestrelVersion: KestrelVersion;
	colorScheme: string;
	darkMode: number;
	websitePlatform: WebsitePlatformType;
	baseUrl: string;
	snackBarMessage?: string;
	editDefaultPage?: boolean;
	defaultPagePath: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const defaultPage = getPageByTypeId(state, 1);
	const wixIntegration = getIntegrationByTypeId(state, IntegrationType.WIX_APP)
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
		defaultPage,
		wixIntegration
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			saveUser,
			savePage,
			fetchPages
		},
		dispatch);

}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		const { user, defaultPage } = props;
		this.state = {
			...user,
			saving: false,
			kestrelVersion: user.kestrelVersion,
			colorScheme: user.colorScheme || "",
			darkMode: user.darkMode,
			websitePlatform: user.websitePlatform,
			baseUrl: user.baseUrl,
			snackBarMessage: "",
			editDefaultPage: false,
			defaultPagePath: defaultPage ? defaultPage.path : "",
		};

	}

	private baseUrlInputRef = React.createRef<HTMLInputElement>();

	public componentDidMount() {
		this.props.fetchPages();
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.defaultPage?.path !== this.props.defaultPage?.path) {
			this.setState({defaultPagePath: this.props.defaultPage ? this.props.defaultPage.path : ""});
		}
	}

	public save() {
		const errors = validate(this.validationSchema, this.state);
		if (!errors) {
			const user: UserPartial = {
				...this.props.user,
				kestrelVersion: this.state.kestrelVersion,
				websitePlatform: this.state.websitePlatform,
				baseUrl: this.state.baseUrl,
				colorScheme: this.state.colorScheme,
				darkMode: this.state.darkMode
			};
			this.props.saveUser({user}); 
			if (!!this.props.defaultPage && !!this.state.defaultPagePath) {
				const updatedDefaultPage: Page = {
					...this.props.defaultPage,
					path: this.state.defaultPagePath,
				}
				this.props.savePage({page: updatedDefaultPage});
			}
			this.setState({saving: true});
			this.openSnackBar("Settings Saved");
		}
	}

	private openSnackBar(message: string) {
		this.setState({snackBarMessage: undefined}, () => {
			setTimeout(() => {
				this.setState({snackBarMessage: message});
			}, 250);
		});
	}

	private validationSchema = {
		colorScheme: yup
			.string()
			.matches(/#([a-f0-9]{3}){1,2}\b/i, { excludeEmptyString: true, message: 'Invalid Color' })
	}

	public render() {
		const {
			user,
			authLoading,
			authError,
			classes,
			wixIntegration
		} = this.props;
		const {
			kestrelVersion,
			colorScheme,
			darkMode,
			baseUrl,
			defaultPagePath,
			websitePlatform,
			snackBarMessage
		} = this.state;
		const title = user.kestrelVersion.all ? "Version & Platform" : "Version Settings";
		const errors = validate(this.validationSchema, this.state);
		return (
			<DashboardLayout
				permitted={user && user.permissions.listingDetail}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3} className={classes.formContainer}>
						<Mui.Grid item>
							<VersionDropdown value={kestrelVersion} onChange={(kestrelVersion) => this.setState({kestrelVersion})} wixIntegration={wixIntegration}/>
						</Mui.Grid>
						
						{user.permissions.kestrelAll && kestrelVersion.all ? (
							<>
								<Mui.Grid item>
									<SectionHeading text="Select a color scheme." />
									<InputLayout>
										<ColorPicker
											value={colorScheme}
											onChange={(colorScheme) => this.setState({ colorScheme })}
											errors={errors && errors.colorScheme ? errors.colorScheme : false}
										/>
									</InputLayout>
								</Mui.Grid>
								{darkModeDev && (
									<Mui.Grid item>
										<SectionHeading text="Dark Mode" />
										<Mui.ButtonGroup variant="contained" aria-label="outlined primary button group">
											<Mui.Button
												color={darkMode === 1 ? "secondary" : "default"}
												onClick={() => this.setState({darkMode: 1})}
											>
												Auto-Detect
											</Mui.Button>
											<Mui.Button
												color={darkMode === 2 ? "secondary" : "default"}
												onClick={() => this.setState({darkMode: 2})}
											>
												Off
											</Mui.Button>
											<Mui.Button
												color={darkMode === 3 ? "secondary" : "default"}
												onClick={() => this.setState({darkMode: 3})}
											>
												On
											</Mui.Button>
										</Mui.ButtonGroup>
									</Mui.Grid>
								)}
								<Mui.Grid item>
									<SectionHeading text={!wixIntegration ? "Select the platform for your website or choose other." : "Your website platform"} />
									<Platform websitePlatformType={websitePlatform} onChange={(websitePlatform) => this.setState({websitePlatform})} wixIntegration={wixIntegration}/>
								</Mui.Grid>
								{websitePlatform.wordpress && !wixIntegration ? (
									<>
										<Mui.Grid item>
											<SectionHeading title="Optima Express Registration Key" />
											<InputLayout>
												<Mui.TextField
													variant="outlined"
													fullWidth
													value={user.activationToken}
													InputProps={{
														endAdornment: (
															<Mui.InputAdornment position="end">
																<Mui.IconButton onClick={() => {
																	navigator.clipboard.writeText(user.activationToken);
																	this.openSnackBar("Copied to clipboard");
																}}>
																	<FeatherIcon>
																		<Icons.Copy />
																	</FeatherIcon>
																</Mui.IconButton>
															</Mui.InputAdornment>
														),
													}}
												/>
											</InputLayout>
											<br></br>
											<Mui.Grid container spacing={2}>
												<Mui.Grid item>
													<Mui.Button
														color="secondary"
														variant="contained"
														onClick={() => this.save()}
													>
														Save Changes
													</Mui.Button>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Button
														variant="contained"
														onClick={() =>
															this.setState({
																...user,
																saving: false,
																kestrelVersion: user.kestrelVersion,
																websitePlatform: user.websitePlatform,
																baseUrl: user.baseUrl,
																colorScheme: user.colorScheme,
																darkMode: user.darkMode,
																defaultPagePath: this.props.defaultPage ? this.props.defaultPage.path : "",
															})
														}
													>
														Cancel
													</Mui.Button>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
										
									</>
								) : (
									<>
										{(!websitePlatform.promoted) && (
											<Mui.Grid item>
												<SectionHeading text="Select an option or describe the kind of website you're using." />
												<InputLayout>
													<CustomAutocomplete
														options={WebsitePlatformType.autocomplete}
														onChange={(event, option) => {
															if (!option) {
																option = WebsitePlatformType.getOrCreateById("");
															}
															this.setState({websitePlatform: option});
														}}
														onInputChange={(event, value, reason) => {
															if (reason === "reset") {
																return;
															}
															this.setState({ websitePlatform: WebsitePlatformType.getOrCreateById(value) })
														}}
														optionLabelExtractor={(option) => option.label}
														inputValue={websitePlatform.label}
														fullWidth
													/>
												</InputLayout>
											</Mui.Grid>
										)}
										<Mui.Grid item>
											<SectionHeading text="Enter your website's URL." />
											<InputLayout>
												<Mui.TextField
													inputRef={this.baseUrlInputRef}
													fullWidth
													variant="outlined"
													placeholder="http://www.example.com"
													value={this.state.baseUrl}
													onChange={(event) => this.setState({baseUrl: event.target.value})}
													className={this.props.classes.input}
												/>
											</InputLayout>
										</Mui.Grid>
										<Mui.Grid item>
											<Mui.Grid container spacing={2}>
												<Mui.Grid item>
													<Mui.Button
														color="secondary"
														variant="contained"
														onClick={() => this.save()}
													>
														Save Changes
													</Mui.Button>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Button
														variant="contained"
														onClick={() =>
															this.setState({
																...user,
																saving: false,
																kestrelVersion: user.kestrelVersion,
																websitePlatform: user.websitePlatform,
																baseUrl: user.baseUrl,
																colorScheme: user.colorScheme,
																darkMode: user.darkMode,
																defaultPagePath: this.props.defaultPage ? this.props.defaultPage.path : "",
															})
														}
													>
														Cancel
													</Mui.Button>
												</Mui.Grid>
											</Mui.Grid>
						</Mui.Grid>
						{!wixIntegration && (
							<>
								<Mui.Grid item>
									<Mui.Typography variant="h3">
										Setup IDX On Your Website
									</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item>
										<SectionHeading
											title="Step 1"
											text="Copy and paste the following script into the head of your site." />
										<InputLayout>
											{user.websitePlatform &&
												<CodeBlock
													code={kestrelEmbedCode.getSiteEmbedCode(websitePlatform, user.activationToken)}
													language="html" />}
										</InputLayout>
									</Mui.Grid><Mui.Grid item>
										<SectionHeading
											title="Step 2"
											text="Add a new page on your website at the URL below." />
										<InputLayout>
											{this.state.baseUrl &&
												<DefaultPagePath baseUrl={baseUrl} path={defaultPagePath} onChange={(defaultPagePath) => this.setState({ defaultPagePath })} save={() => this.save()} />}
											{!this.state.baseUrl &&
												<Mui.Typography>
													<span className={classes.baseUrlRequired}>
														Required:
													</span>
													<span className={classes.baseUrlRequiredText}>Your base URL must be filled out to complete this step. </span>
													<Mui.Link
														component="button"
														variant="body1"
														style={{ verticalAlign: "top" }}
														onClick={() => this.baseUrlInputRef.current?.focus()}
													>
														Do this now.
													</Mui.Link>
												</Mui.Typography>}
										</InputLayout>
									</Mui.Grid>
									<Mui.Grid item>
										<SectionHeading
											title="Step 3"
											text="Copy and paste the following script to the body of the page." />
										<InputLayout>
											<CodeBlock
												code={kestrelEmbedCode.page(websitePlatform)}
												language={user.websitePlatform.textEmbedCode ? "text" : "html"} />
										</InputLayout>
									</Mui.Grid>
									<Mui.Grid item>
										<SectionHeading
											title="Step 4"
											text={<>
												Set up SEO pages as needed.<Mui.Link href="#">SEO Pages Setup</Mui.Link>.
											</>} />
									</Mui.Grid>
													</>
												)}
									</>
								)}
							</>
						) : (
							<Mui.Grid item>
								<InputLayout>
									<ColorDropdown
										value={colorScheme}
										onChange={(colorScheme) => this.setState({ colorScheme })}
									/>
								</InputLayout>
								<br></br>
								<Mui.Grid container spacing={2}>
									<Mui.Grid item>
										<Mui.Button
											color="secondary"
											variant="contained"
											onClick={() => this.save()}
										>
											Save Changes
										</Mui.Button>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											onClick={() =>
												this.setState({
													...user,
													saving: false,
													kestrelVersion: user.kestrelVersion,
													websitePlatform: user.websitePlatform,
													baseUrl: user.baseUrl,
													colorScheme: user.colorScheme,
													darkMode: user.darkMode,
													defaultPagePath: this.props.defaultPage ? this.props.defaultPage.path : "",
												})
											}
										>
											Cancel
										</Mui.Button>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
					<Mui.Snackbar
						open={!!snackBarMessage && !authLoading && !authError}
						message={snackBarMessage}
						autoHideDuration={6000}
						onClose={() => this.setState({ snackBarMessage: undefined })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ snackBarMessage: undefined })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>{snackBarMessage}</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
			</DashboardLayout>
		);
	}
}

export const SettingsVersionPage = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));
