import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		button: {
			paddingLeft: 15,
			paddingRight: 15,
		},
		closeButton: {
			position: "absolute",
			right: 8,
			top: 8,
			color: "#fff",
		},
		container: {
			[theme.breakpoints.down("sm")]: {
				height: "100%",
			},
		},
		errorMessage: {
			color: theme.palette.error.main,
			fontSize: "0.75rem",
			fontWeight: "bold",
		},
		iconContainer: {
			display: "flex",
			justifyContent: "center",
		},
		toolTip: {
			fontSize: 14,
		}
	});
};
