import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		form: {
			backgroundColor: "white", 
			width: "100%",
			maxWidth: 400,
			borderRadius: 5,
		},
		searchIcon: {
			padding: 5
		},
		agentName: {
			paddingLeft: 5
		},
		nameContainer: {
			overflow: "hidden", 
			textOverflow: "ellipsis", 
			whiteSpace: "nowrap"
		},
		usersIcon: {
			border: "3px solid #BDBDBD",
			borderRadius: 50,
			backgroundColor: "#BDBDBD",
			color: "#FAFAFA",
		},
		popover: {
			maxHeight: 350,
		},
		textField: {
			position: "sticky",
			top: "0",
			zIndex: 1,
			backgroundColor: "#fff",
		}
	});
};