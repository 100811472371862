import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as env from "shared/env";
import * as credentials from "shared/credentials";
import { MockIframe } from "../mock";
import { getUserUnsafe } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	url: string;
}

interface State {
	loading: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { loading: true };
	}

	private form = React.createRef<HTMLFormElement>();

	componentDidMount() {
		if (this.form.current) {
			this.form.current.submit();
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.url !== this.props.url) {
			if (this.form.current) {
				this.form.current.submit();
			}
		}
	}

	public render() {
		const { user, url } = this.props;
		if (!env.live) {
			return <MockIframe />;
		}
		const creds = credentials.get();
		if (!user || !creds) {
			throw new Error();
		}
		const { username, password } = creds;
		let formUrl;
		let redirectUrl;
		if (user.admin) {
			formUrl = `${env.javaUrl}/admin/client/${user.clientId}/login`;
			redirectUrl = `${env.javaUrl}/admin/client/${user.clientId}${url}`;
		}
		if (user.type.agent && !user.admin) {
			formUrl = `${env.javaUrl}/admin/agent/${user.clientId}/${
				user.agentId
			}/login`;
			redirectUrl = `${env.javaUrl}/admin/agent/${user.clientId}/${
				user.agentId
			}${url}`;
		}
		return (
			<>
				<iframe
					name="contentIframe"
					title="iframe"
					onLoad={() => this.setState({ loading: false })}
				/>
				<form ref={this.form} action={formUrl} method="POST" target="contentIframe">
					<input type="hidden" name="username" value={username} />
					<input type="hidden" name="password" value={password} />
					<input type="hidden" name="redirectUrl" value={redirectUrl} />
				</form>
				{this.state.loading && <LoadingIndicator />}
			</>
		);
	}
}

export const JavaIframe = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Component);
