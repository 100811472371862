import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { CriterionComponent } from "component/shared/criterion-component";
import { formatPrice } from "shared/price-formatter";
import { LeadActivity } from "model/lead-activity";
import { FeatherIcon } from "component/shared/feather-icon";
import { Moment } from "moment";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { getLeadById } from "redux/selector";
import { connect } from "react-redux";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { urls } from "routes/urls";
import moment from "moment";
import { getCampaignMessageById, getCampaignById, getUser } from "redux/selector";
import { AgentTag } from "component/shared/agent-tag";

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
		isAgentPage?: boolean,
}

interface OwnProps {
	activity: LeadActivity;
	showLeadName?: boolean;
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
	const { textMessageActivity } = props.activity;
	return {
		lead: getLeadById(state, props.activity.leadId),
		campaign: getCampaignById(state, textMessageActivity && textMessageActivity.campaignId),
		campaignMessage: getCampaignMessageById(state, textMessageActivity && textMessageActivity.campaignMessageId),
		user: getUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props> {
	public render() {
		const { classes, activity, lead, showLeadName, campaign, campaignMessage, user, isAgentPage } = this.props;
		const { listing, criteria, textMessageActivity, callForwardingEventActivity, message } = activity;
		const subHeaderText = `${activity.type.phrase} ${getTimeLabel(
			activity.createdOn,
		)}`;
		return (
			<Mui.Card className={classes.cardContainer}>
				<Mui.CardHeader
					avatar={
						<FeatherIcon>
							<activity.type.icon />
						</FeatherIcon>
					}
					title={
						showLeadName && lead && (
							<Mui.Link component={RouterLinkWrapper} to={urls.lead(lead)}>
								<Mui.Typography variant="h4">{lead.label}</Mui.Typography>
							</Mui.Link>
						)
					}
					subheader={<Mui.Typography variant="h4">
						{subHeaderText}
						{activity.dailyActivity && activity.dailyActivity.map((daily, index) => (
							<Mui.Grid key={index} item>
								<>{activity.type.phrase} </>
								{getTimeLabel(moment(daily))}
							</Mui.Grid>
						))}
					</Mui.Typography>}
				/>
				{(message || listing || criteria || textMessageActivity || callForwardingEventActivity) &&
					<Mui.CardContent>
						<Mui.Grid container spacing={2} direction="column">
							{!textMessageActivity && !callForwardingEventActivity && message &&
								<Mui.Grid item className={classes.card}>
									<Mui.Typography>
										Message: {message}
									</Mui.Typography>
								</Mui.Grid>
							}
							{textMessageActivity && listing && (
								<Mui.Grid item>
									<Mui.Typography>Recommended listing sent in text message:</Mui.Typography>
								</Mui.Grid>
							)}
							{listing &&
								<Mui.Grid item>
									<Mui.Grid container spacing={3}>
										<Mui.Grid item xs={12}>
											<Mui.CardMedia className={classes.image} image={listing.photoUrl || "https://www.idxhome.com/service/resources/images/listing/no-photo.jpg"} />
										</Mui.Grid>
										<Mui.Grid item xs={12}>
											<Mui.Grid container direction="column">
												<Mui.Grid item>
													<Mui.Link href={listing.detailUrl} target="_blank">
														<Mui.Typography>{listing.address[0]}</Mui.Typography>
														<Mui.Typography>{listing.address[1]}</Mui.Typography>
													</Mui.Link>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Grid container spacing={1}>
														{!!listing.bedrooms &&
															<Mui.Grid item>
																<Mui.Typography>
																	{listing.bedrooms} beds
																</Mui.Typography>
															</Mui.Grid>
														}
														{!!listing.bathrooms &&
															<Mui.Grid item>
																<Mui.Typography>
																	{listing.bathrooms} baths
																</Mui.Typography>
															</Mui.Grid>
														}
													</Mui.Grid>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Typography>{formatPrice(listing.price)}</Mui.Typography>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							}
							{criteria &&
								<Mui.Grid item>
									<CriterionComponent criteria={criteria} />
								</Mui.Grid>
							}
							{textMessageActivity && (
								<Mui.Grid item className={classes.card}>
									<Mui.Typography>
										{lead && (
											<Mui.Link component={RouterLinkWrapper} to={urls.lead(lead)}>
												{`${lead.label} `}
											</Mui.Link>
										)}
									
										 Responded with "{message}" {getTimeLabel(activity.createdOn)}.
										{` The last message sent was ${campaignMessage ? `"${campaignMessage.name}"` : "a message"} from the `}
										{campaign && (
											<Mui.Link
												component={RouterLinkWrapper}
												to={urls.campaign(campaign)}
											>
													{campaign.name}
											</Mui.Link >
										)}
										{` campaign ${getTimeLabel(textMessageActivity.dateSent)}`}
										 
									</Mui.Typography>
								</Mui.Grid>
							)}
							{callForwardingEventActivity && (
								<Mui.Grid item className={classes.card}>
									<Mui.Typography>
										{(lead?.label ? lead.label : lead?.phone) + ' called your texting phone number ' + getTimeLabel(activity.createdOn) + '. The call was forwarded to your call forwarding number ' + callForwardingEventActivity?.callForwardingNumber}
									</Mui.Typography>
								</Mui.Grid>
							)}
						</Mui.Grid>
						{user.permissions.broker && user.admin && !isAgentPage && (
							<AgentTag
								agent={lead && lead.agent}
								justifyContent="flex-end"
							/>
						)}
					</Mui.CardContent>
				}
			</Mui.Card>
		);
	}
};

const getTimeLabel = (moment: Moment) => {
	return moment.calendar("", {
		sameDay: "[today at] h:mma",
		nextDay: "[tomorrow at] h:mma",
		nextWeek: "[on] MM/DD/YYYY [at] h:mma",
		lastDay: "[yesterday at] h:mma",
		lastWeek: "[on] MM/DD/YYYY [at] h:mma",
		sameElse: "[on] MM/DD/YYYY [at] h:mma",
	});
};

export const ActivityCard = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
