import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { MarketResource } from "api/resource/market";
import { Market, NewMarket } from "model/market";
import { loginReset } from "redux/slice/authentication";
import { fetchMarketReportSubscriptions } from "redux/slice/market-report-subscriptions";
import { fetchMarketReports } from "redux/slice/market-reports";
import { arrayToById, objectToById } from "redux/normalize";

const initialState: MarketsState = {
	byId: {},
};

export interface MarketsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: MarketResource;
	};
};

const fetchMarkets = createAsyncThunk("markets/fetchMarkets", async (payload, thunkAPI) => {
	thunkAPI.dispatch(marketsLoading());
	const result = api.getMarkets();
	result
		.then(markets => thunkAPI.dispatch(marketsLoaded(markets)))
		.catch(error => thunkAPI.dispatch(marketsFailed(error)))
		.then(() => thunkAPI.dispatch(fetchMarketReportSubscriptions()));
	return result;
});

const createMarket = createAsyncThunk("markets/createMarket", async (payload: {market: NewMarket}, thunkAPI) => {
	thunkAPI.dispatch(marketsLoading());
	const result = api.createMarket(payload.market);
	result
		.then(market => thunkAPI.dispatch(marketLoaded(market)))
		.catch(error => thunkAPI.dispatch(marketsFailed(error)));
	return result;
});

const updateMarket = createAsyncThunk("markets/updateMarket", async (payload: {market: Market}, thunkAPI) => {
	thunkAPI.dispatch(marketsLoading());
	const result = api.updateMarket(payload.market);
	result
		.then(market => thunkAPI.dispatch(marketLoaded(market)))
		.catch(error => thunkAPI.dispatch(marketsFailed(error)));
	return result;
}); 

const updateMarketNoLoading = createAsyncThunk("markets/updateMarket", async (payload: {market: Market}, thunkAPI) => {
	const result = api.updateMarket(payload.market);
	result
		.then(market => thunkAPI.dispatch(marketLoaded(market)))
		.catch(error => thunkAPI.dispatch(marketsFailed(error)));
	return result;
}); 

export const marketsSlice = createSlice({
	name: "markets",
	initialState,
	reducers: {
		marketsLoading: (state) => {
			state.loading = true;
			state.error = null;
		},
		marketsLoaded: (state, action: PayloadAction<MarketResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		marketsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload;
		},
		marketLoaded: (state, action: PayloadAction<MarketResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId,
				...byId,
			}
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

const { marketsLoading, marketsLoaded, marketsFailed, marketLoaded } = marketsSlice.actions;
export { fetchMarkets, createMarket, updateMarket, updateMarketNoLoading };