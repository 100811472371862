import { configureStore, combineReducers, AsyncThunkAction, ThunkDispatch, Action } from "@reduxjs/toolkit";
import { sidebarMenuSlice } from "./slice/sidebar-menu";
import { authenticationSlice } from "./slice/authentication";
import { quotaSlice } from "./slice/quota";
import { leadsSlice } from "./slice/leads";
import { savedListingsSlice } from "./slice/saved-listings";
import { savedSearchesSlice } from "./slice/saved-searches";
import { savedSearchResultTotalsSlice } from "./slice/saved-search-result-totals";
import { leadNotesSlice } from "./slice/lead-notes";
import { leadImportsSlice } from "./slice/lead-import";
import { leadTagsSlice } from "./slice/lead-tags";
import { leadPhonesSlice } from "./slice/lead-phones";
import { leadPipelinesSlice } from "./slice/lead-pipelines";
import { leadAnniversariesSlice } from "./slice/lead-anniversaries";
import { leadActivitiesSlice } from "./slice/lead-activities";
import { geographicRouteslice } from "./slice/geographic-routes";
import { roundRobinRouteSlice } from "./slice/round-robin-routes";
import { postalCodesSlice } from "./slice/postal-code";
import { leadActivityStatsSlice } from "./slice/lead-activity-stats";
import { leadLastActivitiesSlice } from "./slice/lead-last-activities";
import { resellersSlice } from "./slice/resellers";
import { clientsSlice } from "./slice/clients";
import { campaignSlice } from "./slice/campaigns";
import { campaignMessagesSlice } from "./slice/campaign-messages";
import { campaignCampaignMessagesSlice } from "./slice/campaign-campaign-messages";
import { campaignSubscriptionsSlice } from "./slice/campaign-subscriptions";
import { campaignEmailsSlice } from "./slice/campaign-emails";
import { campaignStatsSlice } from "./slice/campaign-stats";
import { campaignCampaignMessageStatsSlice } from "./slice/campaign-campaign-message-stats";
import { campaignSubscriptionStatsSlice } from "./slice/campaign-subscription-stats";
import { campaignRoutesSlice } from "./slice/campaign-routes";
import { campaignsSettingsSlice } from "./slice/campaigns-settings";
import { marketsSlice } from "./slice/markets";
import { marketListingsSlice } from "./slice/market-listings";
import { marketReportsSlice } from "./slice/market-reports";
import { marketReportSubscriptionsSlice } from "./slice/market-report-subscriptions";
import { mobileDialogDateSlice } from "./slice/mobile-dialog-date";
import { emailSentsSlice } from "./slice/email-sents";
import { emailClicksSlice } from "./slice/email-clicks";
import { agentsSlice } from "./slice/agents";
import { boardSlice } from "./slice/boards";
import { officesSlice } from "./slice/offices";
import { citiesSlice } from "./slice/cities";
import { neighborhoodsSlice } from "./slice/neighborhoods";
import { listingsSlice } from "./slice/listings";
import { oneUsersSlice } from "./slice/one-users";
import { aggregateStatsSlice } from "./slice/aggregate-stats";
import { leadsPageSlice } from "./slice/leads-page";
import { tasksSlice } from "./slice/tasks";
import { tasksPageSlice } from "./slice/tasks-page";
import { integrationsSlice } from "./slice/integrations";
import { integrationCalendarsSlice } from "./slice/integration-calendars";
import { siteLinksSlice } from "./slice/site-links";
import { userAuthSlice } from "./slice/user-auth";
import { agentBoardsSlice } from "./slice/agent-boards";
import { officeBoardsSlice } from "./slice/office-boards";
import { pagesSlice } from "./slice/pages";
import { persistStore, persistReducer } from "redux-persist"
import { languagesSlice } from "./slice/languages";
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk";
import { adminModeSlice } from "./slice/admin-mode";
import { useDispatch } from "react-redux";
import { agentPageSlice } from "./slice/agent-page";
import { marketSettings } from "api/urls";
import { marketSettingsSlice } from "./slice/market-settings";

const rootReducer = combineReducers({
	adminMode: adminModeSlice.reducer,
	sidebarMenu: sidebarMenuSlice.reducer,
	authentication: authenticationSlice.reducer,
	quota: quotaSlice.reducer,
	leads: leadsSlice.reducer,
	savedListings: savedListingsSlice.reducer,
	savedSearches: savedSearchesSlice.reducer,
	savedSearchResultTotals: savedSearchResultTotalsSlice.reducer,
	leadNotes: leadNotesSlice.reducer,
	geographicRoutes: geographicRouteslice.reducer,
	roundRobinRoutes: roundRobinRouteSlice.reducer,
	leadImports: leadImportsSlice.reducer,
	leadTags: leadTagsSlice.reducer,
	leadPhones: leadPhonesSlice.reducer,
	leadPipelines: leadPipelinesSlice.reducer, 
	leadAnniversaries: leadAnniversariesSlice.reducer,
	leadActivities: leadActivitiesSlice.reducer,
	leadActivityStats: leadActivityStatsSlice.reducer, 
	leadLastActivities: leadLastActivitiesSlice.reducer,
	resellers: resellersSlice.reducer,
	clients: clientsSlice.reducer,
	campaigns: campaignSlice.reducer,
	campaignMessages: campaignMessagesSlice.reducer,
	campaignCampaignMessages: campaignCampaignMessagesSlice.reducer,
	campaignSubscriptions: campaignSubscriptionsSlice.reducer,
	campaignEmails: campaignEmailsSlice.reducer,
	campaignStats: campaignStatsSlice.reducer,
	postalCodes: postalCodesSlice.reducer,
	campaignCampaignMessageStats: campaignCampaignMessageStatsSlice.reducer,
	campaignSubscriptionStats: campaignSubscriptionStatsSlice.reducer,
	campaignRoutes: campaignRoutesSlice.reducer,
	campaignsSettings: campaignsSettingsSlice.reducer,
	markets: marketsSlice.reducer,
	marketListings: marketListingsSlice.reducer,
	marketReports: marketReportsSlice.reducer,
	marketReportSubscriptions: marketReportSubscriptionsSlice.reducer,
	marketSettings: marketSettingsSlice.reducer,
	mobileDialogDate: mobileDialogDateSlice.reducer,
	emailSents: emailSentsSlice.reducer, 
	emailClicks: emailClicksSlice.reducer,
	agents: agentsSlice.reducer, 
	offices: officesSlice.reducer,
	cities: citiesSlice.reducer,
	neighborhoods: neighborhoodsSlice.reducer,
	listings: listingsSlice.reducer,
	oneUsers: oneUsersSlice.reducer,
	aggregateStats: aggregateStatsSlice.reducer,
	leadsPage: leadsPageSlice.reducer,
	agentPage: agentPageSlice.reducer,
	tasks: tasksSlice.reducer,
	taskPage: tasksPageSlice.reducer,
	integrations: integrationsSlice.reducer,
	integrationCalendars: integrationCalendarsSlice.reducer,
	siteLinks: siteLinksSlice.reducer,
	userAuth: userAuthSlice.reducer,
	boards: boardSlice.reducer,
	agentBoards: agentBoardsSlice.reducer,
	officeBoards: officeBoardsSlice.reducer,
	pages: pagesSlice.reducer,
	languages: languagesSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Work around for incorrect action return type when using bindActionCreators
 * https://github.com/reduxjs/redux-thunk/pull/224
 */
export type ThunkResult<T extends (...args: any) => (...args: any) => any> = ReturnType<ReturnType<T>>;

export const getPayload = <Returned, ThunkArg, ThunkApiConfig extends {}>(result: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>) => {
	return (result as any).payload as Returned;
}

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["mobileDialogDate", "leadsPage", "agents"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const createConfigureStore = () => {
	const store = configureStore({
		reducer: persistedReducer,
		devTools: {
			// instance name shown on the monitor page
			name: "control-panel",
			// extra actions dispatched within this interval (in ms) will be collected
			// and sent at once
			latency: 500,
			// includes a stack trace for every dispatched action
			trace: true,
		},
		middleware: [thunk],
		enhancers: (defaultEnhancers) => [ ...defaultEnhancers],
	});
	
	const persistor = persistStore(store);
	return {store, persistor};
};

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
