import { objectToById } from "redux/normalize";
import * as api from "api/api";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { ResellerResource } from "api/resource/reseller";

export interface ResellersState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: ResellerResource;
	};
};

const initialState: ResellersState = {
	byId: {},
};

const fetchReseller = createAsyncThunk("resellers/fetchResellers", async (payload: {resellerId: number}, thunkAPI) => {
	thunkAPI.dispatch(resellersLoading());
	const result = api.getResellerById(payload.resellerId);
	result
		.then((reseller) => thunkAPI.dispatch(resellerLoaded(reseller)))
		.catch(error => thunkAPI.dispatch(resellerFailed(error)));
	return result;
});

export const resellersSlice = createSlice({
	name: "resellers",
	initialState,
	reducers: {
		resellersLoading: (state) => {
			state.loading = true;
		},
		resellerLoaded: (state, action: PayloadAction<ResellerResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		resellerFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	}
});

export const { resellersLoading, resellerLoaded, resellerFailed } = resellersSlice.actions;
export { fetchReseller };