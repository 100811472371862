import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		name: {
			display: "inline-block", 
			maxWidth: 200,
			overflow: "hidden",
			textOverflow: "ellipsis",
		}
	});
};