export const formatPhoneNumber = (value: string) => {
	if (/[^0-9()-.]/.test(value)) {
		return value;
    }
    const digits = value.replace(/\D+/g, "");
    const length = digits.length;
    const hasCountryCode = length > 10;
    const country = hasCountryCode ? digits.slice(0, length - 10) : '';
    const partAreaCode = digits.slice(length - 10, length - 7);
    const partA = digits.slice(length - 7, length - 4);
    const partB = digits.slice(length - 4);
	if (digits.length < 7) {
		return value;
	} else if (digits.length === 7) {
		return `${partA}-${partB}`
	} else if (hasCountryCode) {
		return `+${country} (${partAreaCode}) ${partA}-${partB}`
	} else {
		return `(${partAreaCode}) ${partA}-${partB}`
	}
};

export const normalizePhoneNumberInput = (
	value?: string,
	previousValue?: string
) => {
	if (!value || !previousValue) {
		return value;
	}

	const currentValue = value.replace(/[^\d]/g, "");
	const cvLength = currentValue.length;

	if (!previousValue || value.length > previousValue.length) {
		if (cvLength < 4) {
			return currentValue;
		}
		if (cvLength < 7) {
			return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
		}
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
			3,
			6
		)}-${currentValue.slice(6, 10)}`;
	}
};