import React from "react";
import * as Mui from "@material-ui/core";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { 
	KeyboardDatePicker, 
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Icons from "react-feather";
import moment, { Moment } from "moment";
import * as yup from "yup";
import { validate } from "shared/yup";

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: Function;
	onSend: (sendOn: moment.Moment) => void;
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	sendDate: Moment;
	sendTime: Moment;
}

const initialState: State = {
	sendDate: moment(),
	sendTime: moment(),
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}
	
	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
		}
	}
	
	private combineDateAndTime = (date: Moment, time: Moment) => {
		return date.set({
			hour: time.get("hour"),
			minute: time.get("minute"),
			second: 0,
			millisecond: 0,
		})
	};

	private get sendOn() {
		const { sendDate, sendTime } = this.state;
		return this.combineDateAndTime(sendDate, sendTime);
	}

	private onChangeSendDate = (date: Date | null) => {
		const sendDate = moment(date!);
		this.setState({ sendDate });
	}

	private onChangeSendTime = (date: Date | null) => {
		const sendTime = moment(date!);
		this.setState({ sendTime });
	}

	private validationSchema = {
		sendDate: yup
			.mixed()
			.required("Start date is required."),
		sendTime: yup
			.mixed()
			.required("Start time is required."),
	};

	public render() {
		const { classes, open, onClose } = this.props;
		const {
			sendDate,
			sendTime,
		} = this.state;
		const { sendOn } = this;
		const errors = validate(this.validationSchema, { ...this.state, sendOn });
		return (
			<Dialog open={open} onClose={() => onClose(sendOn)} scroll="paper">
				<Mui.DialogTitle>
					Send Later
				</Mui.DialogTitle>
				<Mui.DialogContent
					style={{
						padding: "8px 50px",
					}}
				>
					<Mui.Grid container alignItems="flex-start" spacing={1}>
						<Mui.Grid item xs={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									required
									maxDate={KeyboardDatePicker.defaultProps!.maxDate}
									error={errors && !!errors.sendDate}
									helperText={errors && errors.sendDate}
									invalidDateMessage="Invalid date format."
									autoOk
									disableToolbar
									variant="inline"
									inputVariant="outlined"
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									InputProps={{
										className: classes.adornedEnd,
									}}
									InputLabelProps={{
										classes: {
											asterisk: classes.labelAsterisk,
										}
									}}
									format="MM/dd/yyyy"
									fullWidth
									margin="dense"
									label="Send Date "
									placeholder="mm/dd/yyyy"
									onChange={date => this.onChangeSendDate(date)}
									value={sendDate}
									KeyboardButtonProps={{
										"aria-label": "change date",
										size: "small",
									}}
									keyboardIcon={<Icons.Calendar />}
									views={["date"]}
								/>
							</MuiPickersUtilsProvider>
						</Mui.Grid>
						<Mui.Grid item xs={6}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardTimePicker
									required
									error={errors && !!errors.sendTime}
									helperText={errors && errors.sendTime}
									autoOk
									variant="inline"
									inputVariant="outlined"
									invalidDateMessage="Invalid date format."
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									InputProps={{
										className: classes.adornedEnd,
									}}
									InputLabelProps={{
										classes: {
											asterisk: classes.labelAsterisk,
										}
									}}
									format="hh:mm aa"
									ampm={true}
									fullWidth
									margin="dense"
									label="Send Time "
									placeholder="00:00 AM"
									mask="__:__ _M"
									onChange={time => this.onChangeSendTime(time)}
									value={sendTime}
									KeyboardButtonProps={{
										"aria-label": "change time",
										size: "small",
									}}
									keyboardIcon={<Icons.Clock />}
									views={["hours", "minutes"]}
								/>
							</MuiPickersUtilsProvider>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container alignItems="flex-start" spacing={1}>
						<Mui.Grid container item style={{ marginTop: 16, marginBottom: 12 }}>
							<Mui.Grid container item xs={12}>
								<div style={{ flex: 1 }} />
								<Mui.Button
									className={classes.button}
									style={{ marginRight: 8 }}
									variant="contained"
									color="secondary"
									onClick={() => onClose(this.props.onSend(sendOn))}
									disabled={!!errors}
								>
									Send
								</Mui.Button>
								<Mui.Button
									className={classes.button}
									variant="outlined"
									color="secondary"
									onClick={() => onClose(sendOn)}
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		)
	}
}
export const EditSendDialog = Mui.withStyles(styles)(
	connect(
		mapDispatchToProps,
	)(Component)
);
