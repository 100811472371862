import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState, getPayload } from "redux/store";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import Unlayer, { HtmlExport } from "react-email-editor";
import { createCampaign } from "redux/slice/campaigns";
import { createCampaignMessage } from "redux/slice/campaign-messages";
import { createCampaignCampaignMessage } from "redux/slice/campaign-campaign-messages";
import { createCampaignSubscription } from "redux/slice/campaign-subscriptions";
import { createLead } from "redux/slice/leads";
import { FilterDialog } from "component/page/leads/filter-dialog";
import { getActiveLeads, getActiveFilteredLeads } from "redux/selector";
import { CustomAutocomplete } from "component/shared/auto-complete";
import * as yup from "yup";
import { validate } from "shared/yup";
import { LoadingPage } from "../loading";
import { getQuota } from "redux/selector";
import { fetchQuota } from "redux/slice/quota";
import { EmailMarketingType } from "type/email-marketing-type";
import { missingEmailCalendarIntegration } from "redux/selector";
import { resetLeadsPageFilters } from "redux/slice/leads-page";
import { TimeUnitType } from "type/time-unit";
import { unlayerOptions } from "shared/unlayer";
import { CampaignMessageType } from "type/campaign-message";
import moment from "moment";
import { EditSendDialog } from "./edit-send-dialog";
import { emailBlastDev } from "shared/env";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";
import { LeadStatus } from "type/lead-status";

const mapStateToProps = (state: RootState) => {
	return {
		allLeads: getActiveLeads(state),
		filteredLeads: getActiveFilteredLeads(state),
		missingEmailCalendarIntegration: missingEmailCalendarIntegration(state),
		quota: getQuota(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchQuota,
	createCampaign, 
	createCampaignMessage,
	createCampaignCampaignMessage,
	createCampaignSubscription, 
	createLead,
	resetLeadsPageFilters,
}, dispatch);

interface Props extends
	PageProps,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
}

interface State {
	editTaskDialogIsOpen: boolean;
	leadFilterDialogIsOpen: boolean;
	input: string;
	subject: string;
	leadIds: Set<number>;
	maxCharacters: boolean;
	sending: boolean; 
	saving: boolean;
}

const MAX_CHARACTERS = 256;

class Component extends React.Component<Props, State> {

	private unlayer: Unlayer | null = null;

	public constructor(props: Props) {
		super(props);
		this.state = {
			editTaskDialogIsOpen: false,
			leadFilterDialogIsOpen: false,
			input: "",
			subject: "",
			leadIds: new Set(),
			maxCharacters: false,
			sending: false, 
			saving: false,
		};
	}

	public componentDidMount() {
		this.props.fetchQuota();
		this.props.resetLeadsPageFilters();
	}

	private disableButton(type: "send" | "draft") {
		if (type === "send") {
			this.setState({ sending: true }, () => this.save(type));
		} else if (type==="draft") {
			this.setState({ saving: true }, () => this.save(type));
		}
	}

	private async save(type: "send" | "draft", sendOn?: moment.Moment) {
		const { history, createCampaign, createCampaignMessage, createCampaignCampaignMessage, createCampaignSubscription, allLeads } = this.props;
		const { subject, leadIds } = this.state;
		const unlayer = this.unlayer;
		let timeToSendNumber = 0;
		if (sendOn) {
			const start = moment(new Date());
			timeToSendNumber = moment.duration(sendOn.diff(start)).asMinutes();
		}
		if (unlayer) {
			const data = await new Promise<HtmlExport>(resolve => unlayer.exportHtml(resolve));
			const campaign = getPayload(await createCampaign({ campaign: {
				name: subject,
				emailMarketingType: EmailMarketingType.BLAST,
			}})); 
			if (!campaign.id) {
				throw new Error();
			}
			const campaignMessage = getPayload(await createCampaignMessage({ campaignMessage: {
				name: subject,
				subject,
				htmlContent: data.html,
				jsonContent: JSON.stringify(data.design),
				timeToSendUnit:  TimeUnitType.MINUTES,
				timeToSendNumber,
				messageType: CampaignMessageType.EMAIL,
				ownedByReseller: false,
				isInactiveYn: false,
			}}));
			if (campaign.id && campaignMessage.id) {
				getPayload(await createCampaignCampaignMessage({ campaignCampaignMessage: {
					campaignId: campaign.id,
					campaignMessageId: campaignMessage.id,
					messageOrder: 0,
				}}));
			}
			{
				const ids = Array.from(leadIds.values());
				const leads = ids.map((id) => allLeads.find((lead) => lead.id === id) )
				for (let i = 0; i < leads.length; i++) {
					const lead = leads[i];
					if (lead) {
						getPayload(await createCampaignSubscription({ subscription: {
							lead,
							// @ts-ignore
							campaign: {
								id: campaign.id,
							},
							status: CampaignSubscriptionStatus.ACTIVE,
						}}));
					}
				}
			}
			if (type === "send") {
				history.push(urls.campaignSentEmails);
			}
			if (type === "draft") {
				history.push(urls.campaignDraftEmail(campaign.id));
			}
			if (sendOn) {
				history.push(urls.campaignDraftEmails);
			}
		}
	}
	
	public render() {
		const { classes, user, filteredLeads, quota , missingEmailCalendarIntegration } = this.props;
		const { leadFilterDialogIsOpen, subject, leadIds, sending, saving, maxCharacters, editTaskDialogIsOpen } = this.state;
		if (!quota) {
			return <LoadingPage />;
		}
		const title = "New Email";
		const errors = validate(this.validationSchema, this.state);
		return (
			<>
				<FilterDialog
					open={leadFilterDialogIsOpen}
					onClose={() => this.setState({
						leadFilterDialogIsOpen: false,
					})}
					onSave={() => {
						this.addLeadIds(filteredLeads.map(lead => lead.id));
					}}
					count={filteredLeads.length}
					buttonText="Add"
					emailFilter={true}
				/>
				<EditSendDialog
					onSend={(sendOn: moment.Moment | undefined) => this.save('send', sendOn)}
					open={editTaskDialogIsOpen}
					onClose={() => this.setState({ editTaskDialogIsOpen: false })}
				/>
				<DashboardLayout
					permitted={user && user.permissions.blastCampaigns}
					fullScreen={false}
					title={title}
					header={
						<Mui.Typography variant="h1">
							<FeatherIcon>
								<Icons.Send />
							</FeatherIcon>
							{title}
						</Mui.Typography>
					}
				>
					<Mui.Grid container direction="column" spacing={4}>
						{
							<>
								<Mui.Grid item>
									<Mui.Card>
										<Mui.CardContent>
											<Mui.Grid container direction="column" spacing={2}>
												<Mui.Grid item>
													<Mui.TextField
														required
														label="Subject Line"
														placeholder="Enter subject line here"
														InputLabelProps={{ shrink: true }}
														value={subject}
														onChange={event => {
															const input = event.target.value;
															if (subject.length < MAX_CHARACTERS || input.length < subject.length) {
																this.setState({ subject: input, maxCharacters: false });
															} else {
																this.setState({ maxCharacters: true });
															}
															
														}}
														error={maxCharacters && !!errors && !!errors.subject}
														helperText={maxCharacters && errors && errors.subject}
														fullWidth
														margin="dense"
														variant="outlined"
													/>
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Grid container direction="column">
														<Mui.Grid item>
															<Mui.Grid container justifyContent="space-between">
																<Mui.Grid item>
																	<Mui.Button
																		color="secondary"
																		startIcon={
																			<FeatherIcon>
																				<Icons.Filter />
																			</FeatherIcon>
																		}
																		onClick={() => this.setState({
																			leadFilterDialogIsOpen: true,
																		})}
																	>
																		Create List
																	</Mui.Button>
																</Mui.Grid>
																{!!leadIds.size && (
																	<Mui.Grid item>
																		<Mui.Button
																			color="secondary"
																			onClick={() => this.setState({
																				leadIds: new Set(),
																			})}
																		>
																			Clear List
																		</Mui.Button>
																	</Mui.Grid>
																)}
															</Mui.Grid>
														</Mui.Grid>
														<Mui.Grid item>
															{this.renderLeadSelect}
														</Mui.Grid>
														<Mui.Grid item className={classes.helperText}>
															<Mui.Typography>
																{quota.blastCampaignMessageQueueCount} email{quota.blastCampaignMessageQueueCount > 1 ? "s" : ""} sent in the past 24 hours.
																{" "}
																	{!missingEmailCalendarIntegration && 	`Emails are limited to ${quota.blastCampaignMessageQueueLimit} per day for deliverability purposes.`}
																{" "}


																{((quota.blastCampaignMessageQueueRemaining - leadIds.size) < 0) && (
																	<strong>
																		Remove {leadIds.size - quota.blastCampaignMessageQueueRemaining} recipients to proceed.
																	</strong>
																)}
															</Mui.Typography>
														</Mui.Grid>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.CardContent>
										<Mui.CardActions>
											<Mui.Grid container spacing={2} justifyContent="space-between">
												<Mui.Grid item>
													<Mui.Grid container spacing={2}>
														<Mui.Grid item>
															{leadIds.size > 0 ? (
																<Mui.Grid container spacing={2}>
																	<Mui.Grid item>
																		<Mui.Button
																			variant="contained"
																			color="secondary"
																			disabled={sending || leadIds.size === 0 || maxCharacters || ((quota.blastCampaignMessageQueueRemaining - leadIds.size) < 0)}
																			onClick={() => this.disableButton("send")}
																			className={classes.button}
																		>
																			{!sending && "Send now"}
																			{!!sending && "Sending"}
																		</Mui.Button>
																	</Mui.Grid>
																	{emailBlastDev && (
																			<Mui.Grid item>
																			<Mui.Button
																				variant="contained"
																				color="secondary"
																				disabled={sending || leadIds.size === 0 || maxCharacters || ((quota.blastCampaignMessageQueueRemaining - leadIds.size) < 0)}
																				className={classes.button}
																				onClick={() => {this.setState({ editTaskDialogIsOpen: true })}}																		
																			>
																			{!sending && "Send Later"}
																			{!!sending && "Sending Later"}
																			</Mui.Button>
																		</Mui.Grid>
																	)}
																</Mui.Grid>
															) : (
																<Mui.Tooltip title="You must add leads to send the email.">
																	<Mui.Grid container spacing={2}>
																		<Mui.Grid item>
																			<Mui.Button
																				variant="contained"
																				color="secondary"
																				className={classes.button}
																				disabled
																			>
																				Send Now
																			</Mui.Button>
																		</Mui.Grid>
																		{emailBlastDev && (
																			<Mui.Grid item>
																				<Mui.Button
																					variant="contained"
																					color="secondary"
																					disabled
																				>
																					Send Later
																				</Mui.Button>
																			</Mui.Grid>
																		)}
																	</Mui.Grid>
																</Mui.Tooltip>
															)}
														</Mui.Grid>
														<Mui.Grid item>
															<Mui.Button
																component={RouterLinkWrapper}
																to={urls.campaignDraftEmails}
																variant="outlined"
																color="primary"
															>
																Delete
															</Mui.Button>
														</Mui.Grid>
													</Mui.Grid>
												</Mui.Grid>
												<Mui.Grid item>
												{leadIds.size > 0 ? (
													<Mui.Tooltip title="A draft can't be saved once you add leads.">
														<div>
															<Mui.Button
																variant="contained"
																color="secondary"
																disabled
															>
																Save Draft
															</Mui.Button>
														</div>
													</Mui.Tooltip>
												) : (
													<Mui.Button
														variant="contained"
														color="secondary"
														disabled={saving || leadIds.size > 0 || maxCharacters}
														onClick={() => this.disableButton("draft")}
														className={classes.button}
													>
														{!saving && "Save Draft"}
														{!!saving && "Saving Draft"}
													</Mui.Button>
												)}
												</Mui.Grid>
											</Mui.Grid>
										</Mui.CardActions>
									</Mui.Card>
								</Mui.Grid>
								<Mui.Grid item>
									<Mui.Card>
										<Mui.CardContent>
											<Unlayer
												ref={unlayer => this.unlayer = unlayer}
												options={unlayerOptions}
											/>
										</Mui.CardContent>
									</Mui.Card>
								</Mui.Grid>
							</>
						}
					</Mui.Grid>
				</DashboardLayout>
			</>
		);
	}

	private addLeadIds(ids: number[]) {
		const { leadIds } = this.state;
		ids.forEach((id) => {
			leadIds.add(id);
		});
		this.setState({
			leadIds, 
		});
	}

	private removeLeadId(id: number) {
		const { leadIds } = this.state;
		leadIds.delete(id);
		this.setState({
			leadIds, 
		});
	}
	
	private validationSchema = {
		leadIds: yup.mixed(),
		subject: yup.string().test(
			"check-max-subject-line-characters",
			"Subject line cannot exceed 256 characters.",
			(subject) => {
				let pass = true;
				if (subject && subject.length >= (MAX_CHARACTERS)) {
					pass = false;
				}
				return pass;
			}
		)
	};

	private get renderLeadSelect() {
		const { classes, allLeads } = this.props;
		const { input, leadIds } = this.state;
		const { options } = this;
		const leads = allLeads.filter((lead) => {
			return leadIds.has(lead.id);
		});
		const errors = validate(this.validationSchema, this.state);
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<CustomAutocomplete
						options={options}
						optionLabelExtractor={(option) => option.label}
						fullWidth
						margin="dense"
						label="Sent to"
						placeholder="Select Leads"
						textInputHeight={false}
						error={!!errors && !!errors.leadIds}
						helperText={errors && errors.leadIds}
						onInputChange={(event, value, reason) => {
							if (reason === "reset") {
								value = "";
							}
							this.setState({
								input: value
							})
						}}
						onChange={(event, option) => {
							if (option) {
								const id = option.id as number;
								if (id) {
									this.addLeadIds([ id ]);
								} else {
									this.createLead(option.email);
								}
							}
						}}
						inputValue={input}
					/>
				</Mui.Grid>
				{!!leads.length && (
					<Mui.Grid className={classes.tags} item>
						<Mui.Grid container spacing={1}>
							{leads.map((lead) => (
								<Mui.Grid key={lead.id} item>
									<Mui.Chip
										label={lead.label}
										onDelete={(event) => {
											this.removeLeadId(lead.id);
										}}
									/>
								</Mui.Grid>
							))}
						</Mui.Grid>
					</Mui.Grid>
				)}
			</Mui.Grid>
		);
	}

	private get options() {
		let { allLeads } = this.props;
		const { input } = this.state;
		const options = allLeads.map(lead => ({
			id: lead.id,
			email: lead.email,
			label: lead.label,
		}));
		if (input) {
			const schema = yup.string().email();
			const valid = schema.isValidSync(input);
			if (valid) {
				const exists = allLeads.some(lead => lead.email === input);
				if (!exists) {
					options.unshift({
						id: 0,
						email: input,
						label: `Create "${input}"`,
					});
				}
			}
		}
		return options;
	}

	private async createLead(email: string) {
		const { createLead } = this.props;
		const lead = await getPayload(createLead({ lead: {
			email: email,
			status: LeadStatus.ACTIVE,
			label: email,
		}}));
		if (lead.id) {
			this.addLeadIds([ lead.id ]);
		}
	}

}

export const CampaignNewEmailPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);