import React from "react";
import * as Mui from "@material-ui/core";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { MarketListing } from "model/market-listing";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { styles } from "./styles";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Market } from "model/market";
import { Criterion } from "model/criterion";
import { createListingIdWithBoardId } from "shared/listing-id";

interface Props extends 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapDispatchToProps> {
	marketListings: MarketListing[];
	market: Market;
	onCriteriaChange: (criterion: Criterion, noLoading?: boolean) => void;
}

interface TableRow {
	id: string;
	value?: string;
	address?: string;
	price?: string;
	propertyType?: string;
	order: number;
} 

interface State {
	marketListings: MarketListing[];
	dragging: string | null;
	data: TableRow[];
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {

	cellRefs = {
		cellRef0: React.createRef<HTMLTableCellElement>(),
		cellRef1: React.createRef<HTMLTableCellElement>(),
		cellRef2: React.createRef<HTMLTableCellElement>(),
		cellRef3: React.createRef<HTMLTableCellElement>(),
		cellRef4: React.createRef<HTMLTableCellElement>(),
		cellRef5: React.createRef<HTMLTableCellElement>(),
		cellRef6: React.createRef<HTMLTableCellElement>(),
	};

	public constructor(props: Props) {
		super(props)
		this.state = {
			marketListings: this.props.marketListings,
			dragging: null,
			data: [],
		}
	}

	cellRef = React.createRef<HTMLTableCellElement>();

	public componentDidMount() {
		const { market } = this.props;
		const data = this.data(market.criteria);
		if (data) {
			this.setState({data});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		const { market } = this.props;
		if (prevProps.market !== market) {
			const data = this.data(market.criteria);
			if (data) {
				this.setState({data});
			}
		}
	}

	private data(criteria: Criterion[]): TableRow[] {
		const tableRows: TableRow[] = [];
		criteria.forEach((criterion, index) => {
			const listingIds = criterion.value as string[];
			listingIds.forEach((id) => {
				const marketListing = this.props.marketListings.find(marketListing => createListingIdWithBoardId(marketListing.value, marketListing.boardId) === id);
				if (marketListing) {
					tableRows.push({
						id,
						value: marketListing.value,
						address: marketListing.listing?.addressOneLine,
						price: marketListing.listing?.priceLabel,
						propertyType: marketListing.listing?.propertyType?.id,
						order: index + 1,
					});
				}
			});
		});

		return tableRows;
	}

	private onDragEnd = (result: DropResult) => {
		const { destination, source } = result;
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}
		const items = [...this.state.data];
		const [reordered] = items.splice(source.index, 1);
		items.splice(destination.index, 0, reordered);
		const criterion = {...this.props.market.criteria[0]};
		criterion.value = items.map(item => item.id);
		this.setState({ data: items }, () => {
			this.save(criterion, true);
		});
	}

	private save(criterion: Criterion, noLoading?: boolean) {
		this.props.onCriteriaChange(criterion, noLoading);
	}
	
	render() {
		const { classes, marketListings } = this.props;
		const { data } = this.state; 
		return (
			<Mui.Grid item>
				<Mui.Typography variant="h2" >
					{`Manual Saved Search Listings (${data.length})`}
				</Mui.Typography>
				<DragDropContext onDragEnd={(param) => this.onDragEnd(param)}>
					<Mui.Table>
						<Mui.TableHead>
							<Mui.TableRow>
								<Mui.TableCell ref={this.cellRefs.cellRef0}></Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef1} align="left">Listing Info</Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef2} align="left">Address</Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef3} align="left">Price</Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef4} align="left">Type</Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef5} align="left">Order</Mui.TableCell>
								<Mui.TableCell ref={this.cellRefs.cellRef6} align="left"></Mui.TableCell>
							</Mui.TableRow>
						</Mui.TableHead>
						<Droppable droppableId="droppable">
							{provided => (
								<Mui.TableBody
									innerRef={provided.innerRef}
									{...provided.droppableProps}
								>
									{data.map((row, index) => (
										<Draggable draggableId={row.id.toString()} index={index} key={row.id}>
											{(provided, snapshot) => (
												<Mui.TableRow
													className={classes.tableRow}
													innerRef={provided.innerRef}
													{...provided.draggableProps}
													style={{
														...provided.draggableProps.style, 
														backgroundColor: snapshot.isDragging ? "white" : "none",
													}}
													{...marketListings.length > 1 && {...provided.dragHandleProps}}
												>
													<Mui.TableCell style={{width: this.cellRefs.cellRef0.current?.offsetWidth}} align="left">{marketListings.length > 1 && <DragIndicatorIcon style={{color: "lightgray"}}/>}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef1.current?.offsetWidth}} component="th" scope="row">{row.value}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef2.current?.offsetWidth}} align="left">{row.address ? row.address : "N/A"}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef3.current?.offsetWidth}} align="left">{row.price ? row.price : "N/A"}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef4.current?.offsetWidth}} align="left">{row.propertyType ? row.propertyType : "N/A"}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef5.current?.offsetWidth}} align="left">{index + 1}</Mui.TableCell>
													<Mui.TableCell style={{width: this.cellRefs.cellRef6.current?.offsetWidth}} align="left">
														<Mui.Tooltip title="Remove Listing">
															<Icon.Trash 
																className={classes.trashCan} 
																onClick={() => {
																	if (window.confirm(`Delete ${row.value}?`)) {
																		const criterion = {...this.props.market.criteria[0]};
																		criterion.value = [...(criterion.value as string[])];
																		const index = criterion.value.findIndex(item => item === row.id)
																		criterion.value.splice(index, 1);
																		this.save(criterion);
																	}
																}}
															/>
														</Mui.Tooltip>
													</Mui.TableCell>
												</Mui.TableRow>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</Mui.TableBody>
							)}
						</Droppable>
					</Mui.Table>
				</DragDropContext>
			</Mui.Grid>	
		)
	}
}

export const MarketListingTableRow = Mui.withStyles(styles)(
	connect(undefined, mapDispatchToProps)(Component)
	);