import * as React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { Criterion } from "model/criterion";
import { BaseProps } from "../types";
import { PropertyType } from "type/property-type";

interface Props extends BaseProps {
}

interface State {
	maxListPrice: number;
	propertyType: string;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			maxListPrice: 0,
			propertyType: "SFR",
		};
	}

	private getCriteria() {
		const {
			maxListPrice,
			propertyType,
		} = this.state;
		const criteria: Criterion[] = [
			{
				name: "maxListPrice",
				value: maxListPrice,
				label: "",
			},
			{
				name: "propertyType",
				value: propertyType,
				label: "",
			},
		];
		return criteria;
	}

	public render() {
		const {
			onSaveSearch,
			onCriteriaChange,
			onSaveListing,
			onUnsaveListing,
			savedListings = [],
			// criteria,
		} = this.props;
		const {
			maxListPrice,
			propertyType,
		} = this.state;
		const listings = new Map<string, boolean>();
		[
			"1001686854_2",
			"1001686855_2",
			"1001686856_2",
			"1001686857_2",
		].forEach(id => listings.set(id, false));
		savedListings.forEach(id => listings.set(id, true));
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Mock Listing Search</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.TextField
						label="Max Price"
						fullWidth
						variant="outlined"
						value={maxListPrice}
						onChange={(event) => {
							this.setState({ maxListPrice: parseInt(event.currentTarget.value) }, () => {
								if (onCriteriaChange) {
									const criteria = this.getCriteria();
									onCriteriaChange(criteria);
								}
							});
						}}
					/>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.FormControl variant="outlined" fullWidth>
						<Mui.TextField
							label="Property Type"
							select
							fullWidth
							variant="outlined"
							value={propertyType}
							onChange={(event) => {
								this.setState({ propertyType: event.target.value }, () => {
									if (onCriteriaChange) {
										const criteria = this.getCriteria();
										onCriteriaChange(criteria);
									}
								});
							}}
						>
							{PropertyType.values().map((value, index) => 
								<Mui.MenuItem key={index} value={value.id}>{value.label}</Mui.MenuItem>)
							}	
						</Mui.TextField>
					</Mui.FormControl>
				</Mui.Grid>
				{onSaveSearch && (
					<Mui.Grid item>
						<Mui.Button 
							variant="contained" 
							color="secondary"
							onClick={() => {
								const criteria = this.getCriteria();
								onSaveSearch(criteria);
							}}
						>
							Save Search
						</Mui.Button>
					</Mui.Grid>
				)}
				{(onSaveListing && onUnsaveListing) && (
					<Mui.Grid item>
						<Mui.Grid container spacing={1}>
							{Array.from(listings).map(([id, saved]) => (
								<Mui.Grid item key={id}>
									<Mui.Button 
										variant="contained" 
										color="primary"
										onClick={() => saved ? onUnsaveListing(id) : onSaveListing(id)}
										startIcon={(
											<FeatherIcon>
												{saved ? (
													<Icons.Heart color="red" fill="red" />
												) : (
													<Icons.Heart color="white" />
												)}
											</FeatherIcon>
										)}
									>
										Listing {id}
									</Mui.Button>
								</Mui.Grid>
							))}
						</Mui.Grid>
					</Mui.Grid>
				)}
			</Mui.Grid>
		)
	}

}
export const Mock = Component;
