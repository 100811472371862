import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { Campaign } from "model/campaign";
import { timeDateFormatter } from "shared/date-formatter";
import { ColumnLabel } from "component/shared/column-label";
import { CampaignCopyButton } from "component/shared/campaign-copy-button";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getCampaignEmailsByCampaignId, getCampaignEmailsStat, getCampaignSubscriptionsForCampaign } from "redux/selector";
import moment, { Moment } from "moment";
import { CampaignEmail } from "model/campaign-email";


export type CampaignEmailDeliveriesStat = {
	sent: number,
	total: number
};

const getCampaignEmailDeliveries = (state: RootState, campaignEmails: CampaignEmail[], campaignId: number) : CampaignEmailDeliveriesStat => {
	const campaignSubscriptions = getCampaignSubscriptionsForCampaign(state, campaignId);
	const total: CampaignEmailDeliveriesStat = {
		sent: 0,
		total: campaignSubscriptions.length
	};
	campaignEmails.forEach((campaignEmail) => {
		if (campaignEmail.deliveredOn || campaignEmail.failedOn) {
			total.sent += 1;
		}
	});
	return total;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const campaignId = ownProps.campaign.id;
	const campaignEmails = getCampaignEmailsByCampaignId(state, campaignId);
	const campaignEmailsStat = getCampaignEmailsStat(campaignEmails);
	const capmaignEmailDeliveries = getCampaignEmailDeliveries(state, campaignEmails, campaignId);
	
	return {
		campaignEmails,
		campaignEmailsStat,
		capmaignEmailDeliveries,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface OwnProps {
	campaign: Campaign;
	campaignSubscriptionsCount: number;
}

type Props = OwnProps & Mui.WithStyles<typeof styles> & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Component extends React.Component<Props> {

	private isSendingStart = () => {
		let result = false;
		if (this.props.capmaignEmailDeliveries.sent === 0) {
			result = true;
		}
		return result;
	}

	private isSendingInProgress = () => {
		let result = false;
		if (this.props.capmaignEmailDeliveries.sent > 0 &&
			this.props.capmaignEmailDeliveries.sent !== this.props.capmaignEmailDeliveries.total) {
			result = true;
		}
		return result;
	}

	private isSendingComplete = () => {
		let result = false;
		if (this.props.capmaignEmailDeliveries.sent > 0 &&
			(this.props.capmaignEmailDeliveries.sent === this.props.capmaignEmailDeliveries.total) ) {
			result = true;
		}
		return result;
	}

	private getMinMaxEmailSent = (minMax: "min" | "max"): Moment | undefined => {
		const dates: Moment[] = [];
		this.props.campaignEmails.forEach((campaignEmail) => {
			if (campaignEmail.deliveredOn) {
				dates.push(campaignEmail.deliveredOn)
			}
		});
		if (dates.length > 0) {
			if (minMax === "min") {
				return dates.reduce((a, b) => { return a < b ? a : b; })
			} else if (minMax === "max") {
				return dates.reduce((a, b) => { return a > b ? a : b; })
			}
		} else {
			return undefined;
		}
	}
	
	renderCardLeft = () => {
		const { classes, campaign, capmaignEmailDeliveries, campaignEmailsStat } = this.props;
		const campaignCampaignMessage = campaign.campaignCampaignMessages[0];
		const campaignMessage = campaignCampaignMessage.campaignMessage;
		if (!campaignCampaignMessage) {
			return null;
		}
		if (!campaignMessage) {
			return null;
		}
		const minDate = this.getMinMaxEmailSent("min");
		const maxDate = this.getMinMaxEmailSent("max");
		return (
			<>
				<Mui.Grid item container direction="column" xs={12}>
					<Mui.Grid container direction="row">
						<Mui.Grid item md={12} lg={12} xs={12}>
							<ColumnLabel className={classes.label}>					
								Subject
							</ColumnLabel>
						</Mui.Grid>
						<Mui.Grid item md={12} lg={12} xs={12}>
							<Mui.Typography>
								{campaignMessage.subject}
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>

				<Mui.Grid item container direction="column" xs={12}>
					<Mui.Grid container direction="row">
						<Mui.Grid item md={2} xs={6} sm={3}>
							<ColumnLabel className={classes.label}>					
								{this.isSendingStart() && 
									<>
										Starting...
									</>
								}
								{this.isSendingInProgress() && 
									<>
										Sending...
									</>
								}
								{this.isSendingComplete() && 
									<>
										Completed
									</>
								}
							</ColumnLabel>
						</Mui.Grid>
						<Mui.Grid item md={5} xs={6} sm={9}>
							<Mui.Typography>
								{this.isSendingStart() && 
									<>
										{timeDateFormatter(campaign.dateModified || campaign.dateCreated)}
									</>
								}
								{this.isSendingInProgress() &&
									<>
										Started
										{
											minDate && minDate.format("YYYYMMDD") === moment().format("YYYYMMDD") ?
											minDate.format(" [at] h:mm A ") : ""
										}
										{
											minDate && minDate.format("YYYYMMDD") !== moment().format("YYYYMMDD") ?
											minDate.format(" MMMM D [at] h:mm A") : ""
										}
									</>
								}
								{this.isSendingComplete() &&
									<>
										{
											(minDate && maxDate) && (minDate.format("YYYYMMDD") === maxDate.format("YYYYMMDD")) ? 
											minDate.format("h:mm A [-] h:mm A [on] MM/DD/YY ") : ""
											
										}
										{
											(minDate && maxDate) && (minDate.format("YYYYMMDD") !== maxDate.format("YYYYMMDD")) ? 
											minDate.format("MM/DD/YY [-] ") + 
											maxDate.format("MM/DD/YY ") : ""
											
										}
									</>
								}	
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item md={2} xs={6} sm={3}>
							<ColumnLabel className={classes.label}>
								{this.isSendingComplete() ?
									(<>Recipients</>) : 
									<>Progress</>
								}
							</ColumnLabel>
						</Mui.Grid>
						<Mui.Grid item md={3} xs={6} sm={9}>
							<Mui.Typography>
								{this.isSendingComplete() ?
									(<>
										{capmaignEmailDeliveries.total}
									</>) :
									<>
										{capmaignEmailDeliveries.sent} of {capmaignEmailDeliveries.total + " "} Sent
									</>
								}
									 
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				
				<Mui.Grid item container direction="column" xs={12}>
					<Mui.Grid container direction="row">
						<Mui.Grid item md={2} xs={6} sm={3}>
							<ColumnLabel className={classes.label}>
								% Opens
							</ColumnLabel>
						</Mui.Grid>
						<Mui.Grid item md={5} xs={6} sm={9}>
							<Mui.Typography>
								{(campaignEmailsStat.openRate * 100).toFixed()}%
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item md={2} xs={6} sm={3}>
							<ColumnLabel className={classes.label}>	
								% Clicked				
							</ColumnLabel>
						</Mui.Grid>
						<Mui.Grid item md={3} xs={6} sm={9}>
							<Mui.Typography>
								{(campaignEmailsStat.clickRate * 100).toFixed()}%
							</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}

	renderCardRight = () => {
		const { campaign } = this.props;
		return (
			<>
				<Mui.Grid item>
					<Mui.Button
						component={RouterLinkWrapper}
						to={urls.campaignSentEmail(campaign.id)}
						variant="contained"
						color="secondary"
					>
						View
					</Mui.Button>
				</Mui.Grid>
				<Mui.Grid item>
					<CampaignCopyButton campaign={campaign} />
				</Mui.Grid>
			</>
		);
	}

	public render() {
		const { campaign } = this.props;
		const campaignCampaignMessage = campaign.campaignCampaignMessages[0];
		if (!campaignCampaignMessage) {
			return null;
		}
		const campaignMessage = campaignCampaignMessage.campaignMessage;
		if (!campaignMessage) {
			return null;
		}
		return (
			<Mui.Card>
				<Mui.CardContent>
					<Mui.Grid container spacing={3} alignItems="center">
						<Mui.Grid item lg={8} xs={12}>
							<Mui.Grid container alignItems="stretch" spacing={3}>
								{this.renderCardLeft()}
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item lg={4} xs={12}>
							<Mui.Grid container justifyContent="center" alignItems="center" spacing={3}>
								{this.renderCardRight()}
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					
				</Mui.CardContent>
			</Mui.Card>
		);
	}

}

export const Card = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);