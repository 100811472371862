import React from "react"; 
import * as Mui from "@material-ui/core";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";

interface Props {
	children: React.ReactNode[];
	pageSize?: number;
}

interface State {
	page: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props)
		this.state = {
			page: 0,
		}
	}

	private get pageSize() {
		let { pageSize } = this.props;
		if (!pageSize) {
			pageSize = 10;
		}
		return pageSize;
	}

	private get pageCount() {
		const { children } = this.props;
		const { pageSize } = this;
		return Math.floor(children.length / pageSize);
	}

	private get previousEnabled() {
		const { page } = this.state;
		return page > 0;
	}

	private get nextEnabled() {
		let { page } = this.state;
		let { pageCount } = this;
		return page + 1 < pageCount;
	}

	private paginate(page: number) {
		this.setState({ page });
		this.scroll();
	}

	private previous() {
		const { page } = this.state;
		const { previousEnabled } = this;
		if (previousEnabled) {
			this.paginate(page - 1);
		}
	}

	private next() {
		const { page } = this.state;
		const { nextEnabled } = this;
		if (nextEnabled) {
			this.paginate(page + 1);
		}
	}

	private scroll() {
		window.scrollTo(0, 0);
	}

	public render() {
		let { children } = this.props;
		let { pageSize, previousEnabled, nextEnabled, pageCount } = this;
		const { page } = this.state;
		const offset = page * pageSize;
		const start = offset;
		const end = offset + pageSize;
		children = children.slice(start, end);
		if (!children.length) {
			return null;
		}
		return (
			<>
				{children.map((child, index) => (
					<React.Fragment key={index}>
						{child}
					</React.Fragment>
				))}
				{(pageCount > 1) &&
					<Mui.Grid container justifyContent="space-between">
						<Mui.Grid item>
							<Mui.Typography>Page {page + 1} of {pageCount}</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.IconButton
								disabled={!previousEnabled}
								onClick={() => this.previous()
							}>
								<FeatherIcon>
									<Icons.ChevronLeft />
								</FeatherIcon>
							</Mui.IconButton>
							<Mui.IconButton
								disabled={!nextEnabled}
								onClick={() => this.next()}
							>
								<FeatherIcon>
									<Icons.ChevronRight />
								</FeatherIcon>
							</Mui.IconButton>
						</Mui.Grid>
					</Mui.Grid>
				}
			</>
		)
	}
}

 export const Pagination = Component;