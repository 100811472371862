import { normalize, schema } from "normalizr";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { LeadActivityStatsResource } from "api/resource/lead-activity-stats";

export interface LeadActivityStatsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: LeadActivityStatsResource;
	};
};

const initialState: LeadActivityStatsState = {
	byId: {}
};

const fetchLeadActivityStats = createAsyncThunk("leadActivityStats/fetchLeadActivityStats", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadActivityStatsLoading());
	const result = api.getLeadActivityStats();
	result
		.then((leadActivityStats) => thunkAPI.dispatch(leadActivityStatsLoaded(leadActivityStats)))
		.catch(error => thunkAPI.dispatch(leadActivityStatsFailed(error)));
	return result;
});

export const leadActivityStatsSlice = createSlice({
	name: "leadActivityStats",
	initialState,
	reducers: {
		leadActivityStatsLoading: (state) => {
			state.loading = true;
		},
		leadActivityStatsLoaded: (state, action: PayloadAction<LeadActivityStatsResource[]>) => {
			const leadActivityStat = new schema.Entity("leadActivityStats");
			const leadActivityStats = new schema.Array(leadActivityStat);
			const normalized = normalize(action.payload, leadActivityStats);
			const byId = normalized.entities.leadActivityStats || {};
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		leadActivityStatsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { leadActivityStatsLoading, leadActivityStatsLoaded, leadActivityStatsFailed } = leadActivityStatsSlice.actions;
export { fetchLeadActivityStats };