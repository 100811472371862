import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { MarketSettingsResource } from "api/resource/market-settings";
import { loginReset } from "redux/slice/authentication";

export interface MarketSettingsState {
    loading?: boolean,
    error?: string | null,
    listingCarouselColumns?: number;
	communitiesLayout?: string;
};

const initialState: MarketSettingsState = {
    loading: false,
};

const fetchMarketSettings = createAsyncThunk("marketSettings/fetchMarketSettings", async (payload, thunkAPI) => {
    thunkAPI.dispatch(marketSettingsLoading());
    const result = api.getMarketSettings();
    result
        .then((marketSettings) => thunkAPI.dispatch(marketSettingsLoaded(marketSettings)))
        .catch(error => thunkAPI.dispatch(marketSettingsFailed(error)));
    return result;
});

const updateMarketSettings = createAsyncThunk("marketSettings/updateMarketSettings", async (payload: {marketSettings: MarketSettingsResource}, thunkAPI) => {
    thunkAPI.dispatch(marketSettingsLoading());
    const result = api.updateMarketSettings(payload.marketSettings);
    result
        .then((marketSettings) => thunkAPI.dispatch(marketSettingsLoaded(marketSettings)))
        .catch(error => thunkAPI.dispatch(marketSettingsFailed(error)));
    return result;
});

export const marketSettingsSlice = createSlice({
    name: "marketSettings",
    initialState,
    reducers: {
        marketSettingsLoading: (state) => {
            state.loading = true;
        },
        marketSettingsLoaded: (state, action: PayloadAction<MarketSettingsResource>) => {
            state.loading = false;
            state.error = null;
			state.communitiesLayout = action.payload.communitiesLayout;
			state.listingCarouselColumns = action.payload.listingCarouselColumns;
        },
        marketSettingsFailed: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(loginReset, (state) => {
            return {
                ...initialState,
                error: state.error,
            };
        });
    },
});

export const { marketSettingsLoading, marketSettingsLoaded, marketSettingsFailed } = marketSettingsSlice.actions;
export { fetchMarketSettings, updateMarketSettings}; 