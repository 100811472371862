import React from "react";
import * as env from "shared/env";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { AuthLayout } from "component/layout/auth";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";
import { login } from "redux/slice/authentication";
import { getUserUnsafe, getAuthError } from "redux/selector";
import { styles } from "./style";
import { getClientById } from "redux/selector"
import queryString from "query-string";
import { getResellerById } from "redux/selector";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { logout } from "redux/slice/authentication";

const mapStateToProps = (state: RootState, ownProps: Router.RouteComponentProps) => {
	const resellerId = getResellerId(ownProps)
	const clientId = getClientId(ownProps)
	return {
		user: getUserUnsafe(state),
		authError: getAuthError(state),
		reseller: getResellerById(state, resellerId),
		client: getClientById(state, clientId),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	login,
	logout,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles> 
{
}

interface State {
	username: string;
	password: string;
	showPassword: boolean;
}

const getResellerId = (props: Router.RouteComponentProps) => {
	const query = queryString.parse(props.location.search);
	let result: number | undefined;
	if (typeof query.resellerId === "string") {
		result = parseInt(query.resellerId);
	}
	return result;
}

const getClientId = (props: Router.RouteComponentProps) => {
	const query = queryString.parse(props.location.search);
	let result: number | undefined;
	if (typeof query.clientId === "string") {
		result = parseInt(query.clientId);
	}
	return result;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			showPassword: false,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.user && this.props.user) {
			const url = urls.landing(this.props.user);
			this.props.history.push(url);
		}
	}

	private login(event: React.FormEvent<HTMLElement>) {
		event.preventDefault();
		const { username, password } = this.state;
		if (username && password) {
			this.props.login({username, password});
		}
	}
	
	private get submittable() {
		const { username, password } = this.state;
		return username.length > 0 && password.length > 0;
	}
	
	private get errorMessage() {
		const { authError } = this.props;
		switch(authError) {
			case "Incorrect credentials":
				return "The username or password you entered is incorrect."
		}
		return null;
	}
	
	public render() {
		const { classes, reseller, user } = this.props;
		const { username, password, showPassword } = this.state;

		if (user) {
			return (
				<AuthLayout
					title="Sign In"
				>
					<Mui.Typography>You are already signed in as</Mui.Typography>
					<Mui.Typography>{user.company}</Mui.Typography>
					<Mui.Button
						variant="contained"
						color="primary"
						className={classes.login}	
						onClick={()=> this.props.logout()}			
					>
						Sign Out
					</Mui.Button>
					<Mui.Button
						variant="contained"
						color="secondary"
						className={classes.login}
						component={RouterLinkWrapper}
						to={urls.landing(user)}				
					>
						Back to dashboard
					</Mui.Button>
				</AuthLayout>
			);
		} else {
			return (
				<AuthLayout
					title="Sign In"
					header="Sign In To Your Account"
				>
					<>
						<form
							name="loginForm"
							noValidate
							className={classes.form}
							onSubmit={(event: React.FormEvent<HTMLElement>) => this.login(event)}
						>
							<Mui.TextField
								className={classes.textField}
								label="Username"
								type="username"
								name="username"
								value={username}
								onChange={(event) => this.setState({username: event.target.value})}
								variant="outlined"
								required
								fullWidth
							/>
							<Mui.TextField
								className={classes.textField}
								label="Password"
								type={showPassword ? "text" : "password"}
								name="password"
								value={password}
								onChange={(event) => this.setState({password: event.target.value})}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										this.login(event);
									}
								}}
								variant="outlined"
								required
								fullWidth
								InputProps={{
									endAdornment: (
										<Mui.InputAdornment position="end">
											<Mui.IconButton onClick={(event) => this.setState({showPassword: !showPassword})}>
												<FeatherIcon>
													{showPassword ? <Icons.Eye /> : <Icons.EyeOff />}
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.InputAdornment>
									)
								}}
								FormHelperTextProps={{
									className: classes.error
								}}
								helperText={this.errorMessage}
								
							/>
							<Mui.Link
								href={env.javaUrl + "/admin/client/login/recover?resellerId=" + (getResellerId(this.props) || 0)}
								target="_blank"
							>
								Forgot Password?
							</Mui.Link>
							<Mui.Button
								variant="contained"
								color="primary"
								className={classes.login}
								disabled={!this.submittable}
								type="submit"
							>
								Sign In
							</Mui.Button>
						</form>
						{(!reseller || reseller.direct) &&
							<div className={classes.createAccountContainer}>
								<span>Don't have an account?</span>
								<Mui.Link href="https://www.ihomefinder.com/products/">
									Create an account
								</Mui.Link>
							</div>
						}
					</>
				</AuthLayout>
			);
		}
	}
}

export const LoginPage = Mui.withStyles(styles)(Router.withRouter(connect(mapStateToProps, mapDispatchToProps)(Component)));
