import { LeadStatus } from "type/lead-status";
import { Sort } from "component/shared/table";
import { LeadColumnType } from "type/lead-column";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";

export interface LeadsPageState {
	filters: LeadsPageFiltersState;
	sort: Sort; 
	pageSize: number; 
	page: number;
	columnTypeIds: string[];
};

export interface LeadsPageFiltersState {
	agent?: number[],
	type?: string[],
	status?: string[],
	bookmarked?: boolean,
	subscribed?: boolean,
	source?: string[],
	ranking?: number[],
	tag?: string[],
	pipeline?: number[],
};

const initialState: LeadsPageState = {
	filters: {
		status: [LeadStatus.ACTIVE.id],
	},
	sort: {
		id: "createdOn", 
		desc: true,
	},
	pageSize: 20, 
	page: 0,
	columnTypeIds: [], 
};

export const leadsPageSlice = createSlice({
	name: "leadsPage",
	initialState,
	reducers: {
		setLeadsPageFilters: (state, action: PayloadAction<LeadsPageFiltersState>) => {
			state.filters = action.payload;
		},
		resetLeadsPageFilters: (state) => {
			state.filters = initialState.filters;
		},
		updateLeadsPageSort: (state, action: PayloadAction<Sort>) => {
			state.sort = action.payload;
		},
		updateLeadsPagePageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		updateLeadsPagePage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		updateLeadsPageColumns: (state, action: PayloadAction<LeadColumnType[]>) => {
			state.columnTypeIds = action.payload.map(leadColumnType => leadColumnType.id);
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
    }
});

export const {setLeadsPageFilters, resetLeadsPageFilters, updateLeadsPageColumns, updateLeadsPagePage, updateLeadsPagePageSize, updateLeadsPageSort} = leadsPageSlice.actions;
