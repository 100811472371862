import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { LeadSourceType } from "type/lead-source-type";
import { getCampaignRoutes } from "redux/selector";
import { getActiveDripCampaigns } from "redux/selector";
import { getLeads } from "redux/selector"
import { CampaignRoute } from "model/campaign-route";
import { createCampaignRoute, updateCampaignRoute } from "redux/slice/campaign-routes";
import * as yup from "yup";
import { validate } from "shared/yup";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		campaignRoutes: getCampaignRoutes(state),
		campaigns: getActiveDripCampaigns(state),
		leads: getLeads(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaignRoute,
	updateCampaignRoute
}, dispatch);

interface OwnProps {
	open: boolean,
	edit?: boolean,
	campaignRoute?: CampaignRoute,
	onClose: () => void,
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;


interface State {
	leadSourceInput?: string,
	campaignInput?: string,
	campaignId?: number,
	inputsUpdated: boolean,
	submitted: boolean,
}

interface CampaignOptions {
	id: number,
	label: string,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			leadSourceInput: "",
			campaignInput: "",
			inputsUpdated: false,
			submitted: false,
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setExistingRoutes();
		}
		if (prevProps.open && !this.props.open) {
			this.resetForm();
		}
	}

	private setExistingRoutes() {
		const { campaignRoute } = this.props
		if (campaignRoute && !this.state.inputsUpdated) {
			const leadSource = LeadSourceType.getById(campaignRoute.leadSource ? campaignRoute.leadSource : "");
			this.setState({
				leadSourceInput: leadSource && leadSource.label,
				campaignInput: campaignRoute.campaign.name,
				campaignId: campaignRoute.campaign.id,
			}, 
				() => this.setState({inputsUpdated: true})
			);
		}
		if (!campaignRoute && !this.state.inputsUpdated) {
			this.resetForm(true)
		}
	}

	private resetForm(updated?: boolean) {
		this.setState({
			campaignInput: "",
			leadSourceInput: "",
			inputsUpdated: updated ? updated : false,
		})
	}

	private get leadSourceOptions() {
		const { campaignRoutes, leads } = this.props;
		const leadSources = new Set(LeadSourceType.values().map(value => value.label));
		campaignRoutes.forEach((campaignRoute) => {
			if (!LeadSourceType.getById(campaignRoute.leadSource ? campaignRoute.leadSource : "")) {
				campaignRoute.leadSource && leadSources.add(campaignRoute.leadSource)
			}
			
		});
		leads.forEach((lead) => lead.source && leadSources.add(lead.source));
		return Array.from(leadSources).sort((a, b) => {
			return a.toLowerCase().localeCompare(b.toLowerCase());
		});
	}

	private get campaignOptions() {
		const { campaigns } = this.props; 
		const campaignOptions: CampaignOptions[] = campaigns.map(campaign => (
			{
				id: campaign.id,
				label: campaign.name,
			}
		)).filter(Boolean) as CampaignOptions[];
		return campaignOptions;
	}
	
	private save() {
		const { leadSourceInput, campaignId } = this.state;
		const { campaignRoute } = this.props;
		const errors = validate(this.validationSchema, this.state);
		const campaign = campaignId && this.getCampaign(campaignId);
		if (!errors && campaign) {
			let leadSource = LeadSourceType.getByLabel(leadSourceInput ? leadSourceInput : "")?.id;
			if (!leadSource && leadSourceInput !== "") {
				leadSource = leadSourceInput;
			}
			if (campaignRoute && campaignRoute.id) {
				const id = campaignRoute.id;
				this.props.updateCampaignRoute({ campaignRoute: {
					id,
					campaign,
					leadSource,
				}});
			} else {
				this.props.createCampaignRoute({ campaignRoute: {
					campaign,
					leadSource,
				}});
			}
			this.close();
		}
	}

	private close() {
		this.props.onClose();
		this.setState({submitted: false});
	}

	private getCampaign(campaignId: number) {
		return this.props.campaigns.find((campaign) => campaign.id === campaignId);
	}

	private hasDefault() {
		let result = false;
		this.props.campaignRoutes.forEach((campaignRoute) => {
			if (!campaignRoute.leadSource) {
				result = true;
			}
		});
		return result;
	}

	private validationSchema = {
		leadSourceInput: yup
			.string()
			.test(
				"check-lead-source",
				"Please select lead source.", 
				() => {
					// only require if there is already default
					let result = true;
					if (this.hasDefault()) {
						if (!this.state.leadSourceInput) {
							result = false;
						}
					}
					return result;
				}
			),
		campaignInput: yup.string().required("Please choose a campaign"),
	};

	public render() {
		const { open, classes, edit} = this.props;
		const { leadSourceInput, campaignInput, submitted } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Dialog
				open={open} 
				onClose={() => this.close()} 
				scroll="paper" 
				fullWidth={true} maxWidth="md" 
				classes={{scrollPaper: classes.scrollPaper }}
			>
				<Mui.DialogTitle>
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid item xs={12}>{edit ? "Edit" : "Add A"} Rule</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item>
							<CustomAutocomplete
								options={this.leadSourceOptions}
								optionsDisabled={this.props.campaignRoutes.map(campaignRoute => {
									const knownLeadSource = LeadSourceType.getById(campaignRoute.leadSource ? campaignRoute.leadSource : "")?.label
									if (knownLeadSource) {
										return knownLeadSource
									} else {
										 return campaignRoute.leadSource;
									 }
								})}
								textInputHeight={true}
								label="If source is:"
								optionLabelExtractor={(option) => option}
								fullWidth
								margin="dense"
								placeholder={this.hasDefault() ? "Select a source" : "Default (website leads & leads with no external source)"}
								onInputChange={(event, value, reason) => {
									if (this.state.inputsUpdated) {
										this.setState({
											leadSourceInput: value,
										});
									}
}
								}
								onChange={(event, option) => {
									if (option && option.label) {
										this.setState({ 
											leadSourceInput: option.label,
										});		
									}
								}}
								inputValue={leadSourceInput}
								helperText={submitted && errors && errors.leadSourceInput}
								error={submitted && errors && !!errors.leadSourceInput}
								onFocus={(event) => {
									this.setState({leadSourceInput: ""})
}
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<CustomAutocomplete
								options={this.campaignOptions}
								textInputHeight={true}
								label="Assign to:"
								optionLabelExtractor={(option) => option.label}
								fullWidth
								margin="dense"
								placeholder="Select a campaign"
								onInputChange={(event, value, reason) => {
									if (this.state.inputsUpdated) {
										this.setState({
											campaignInput: value,
										});
									}
								}}
								onChange={(event, option) => {
									if (option && option.label) {
										this.setState({ 
											campaignId: option.id,
										});		
									}
								}}
								inputValue={campaignInput}
								helperText={submitted && errors && errors.campaignInput}
								error={submitted && errors && !!errors.campaignInput}
								onFocus={(event) => {
									this.setState({campaignInput: ""})
}
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								onClick={() => {
									this.setState({submitted: true}, 
										() => this.save()
									);									
								}}
							>
								Save
							</Mui.Button>
							<Mui.Button
								variant="outlined"
								color="secondary"
								onClick={() => this.close()} 
								className={classes.cancel}
							>
								Cancel
							</Mui.Button>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		);
	}

}

export const AddRoute = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);