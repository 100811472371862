import React, { Ref } from "react";
import * as Router from "react-router-dom";
import { urls } from "routes/urls";

interface Props extends Router.LinkProps, Router.RouteComponentProps {
	to: string,
	wrappedComponentRef: Ref<HTMLAnchorElement>,
}

class Component extends React.Component<Props> {

    render() {
        const { to, wrappedComponentRef, location, staticContext, ...rest } = this.props;
		let path: string | object;
		
        if (location.pathname === to) {
            path = {
                pathname: urls.redirect, 
                state: {
                    path: to,
                },
            }
        } else {
            path = to; 
        }
        return <Router.Link {...rest} innerRef={wrappedComponentRef} to={path}/>;
    }
}

const ComponentWithRouter = Router.withRouter(Component);

export const RouterLinkWrapper = React.forwardRef<HTMLAnchorElement, Partial<Router.LinkProps>>(
	(props, ref) => (
		<ComponentWithRouter to={props.to as any} {...props} wrappedComponentRef={ref} />
	)
)