import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { ThemeProvider } from "@material-ui/styles";
import { getTheme } from "./theme";
import * as Router from "react-router-dom";
import { routes } from "routes/routes";
import { NotFoundPage } from "./component/page/not-found";
import { getUserUnsafe } from "redux/selector";
import { login, monitorCredentials, authenticate } from "redux/slice/authentication";
import { urls } from "routes/urls";
import { fetchAdminMode } from "redux/slice/admin-mode";
import { fetchQuota } from "redux/slice/quota";
import { fetchLeads } from "redux/slice/leads";
import { fetchLeadLastActivities } from "redux/slice/lead-last-activities";
import { fetchLeadTags } from "redux/slice/lead-tags";
import { fetchLeadPhones } from "redux/slice/lead-phones";
import { fetchLeadAnniversaries } from "redux/slice/lead-anniversaries";
import { fetchLeadPipelines } from "redux/slice/lead-pipelines";
import { fetchLeadNotes } from "redux/slice/lead-notes";
import { fetchCampaigns } from "redux/slice/campaigns";
import { fetchCampaignSubscriptionStats } from "redux/slice/campaign-subscription-stats";
import { fetchCampaignMessages } from "redux/slice/campaign-messages";
import { fetchCampaignCampaignMessages } from "redux/slice/campaign-campaign-messages";
import { fetchCampaignSubscriptions } from "redux/slice/campaign-subscriptions";
import { fetchCampaignEmails } from "redux/slice/campaign-emails";
import { fetchCampaignStats } from "redux/slice/campaign-stats";
import { fetchCampaignCampaignMessageStats } from "redux/slice/campaign-campaign-message-stats";
import { refreshCampaignEmails } from "redux/slice/campaign-emails"
import { fetchCampaignsSettings } from "redux/slice/campaigns-settings";
import { fetchCampaignRoutes } from "redux/slice/campaign-routes";
import { fetchMarkets } from "redux/slice/markets";
import { fetchMarketReports } from "redux/slice/market-reports";
import { fetchMarketReportSubscriptions } from "redux/slice/market-report-subscriptions";
import { fetchSavedSearches } from "redux/slice/saved-searches";
import { fetchPostalCodes } from "redux/slice/postal-code";
import { fetchAgents } from "redux/slice/agents";
import { fetchRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { fetchGeographicRoutes } from "redux/slice/geographic-routes";
import { fetchOffices } from "redux/slice/offices";
import { fetchCities } from "redux/slice/cities";
import { fetchNeighborhoods } from "redux/slice/neighborhoods";
import { fetchTasks } from "redux/slice/tasks";
import { fetchOneUser } from "redux/slice/one-users";
import { fetchSiteLinks } from "redux/slice/site-links";
import { fetchIntegrations } from "redux/slice/integrations";
import { fetchAgentBoards } from "redux/slice/agent-boards";
import { fetchOfficeBoards } from "redux/slice/office-boards";
import * as appcues from "shared/appcues";
import * as profitwell from "shared/profit-well";
import ReactGA from "react-ga";
import * as env from "shared/env";
import { LoadingPage } from "component/page/loading";
import { LoginPage } from "component/page/login";
import { getLeads } from "redux/selector";
import { getMarkets } from "redux/selector";
import { fetchBoards } from "redux/slice/boards";
import { fetchLanguages } from "redux/slice/languages";
import { fetchMarketListings } from "redux/slice/market-listings";
import { getBoardsForUser } from "redux/selector";
import { fetchMarketSettings } from "redux/slice/market-settings";
import sessionTimer from "shared/session-timer";
import { SessionExpiredDialog } from "component/shared/session-expired-dialog";
import * as ada from "shared/ada-chatbot";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
		leadsLoading: state.leads.loading,
		marketsLoading: state.markets.loading,
		leads: getLeads(state),
		markets: getMarkets(state),
		boards : getBoardsForUser(state)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	login,
	monitorCredentials,
	authenticate,
	fetchAdminMode,
	fetchOneUser,
	fetchQuota,
	fetchLeads,
	fetchLeadLastActivities,
	fetchLeadTags,
	fetchLeadPhones,
	fetchLeadAnniversaries,
	fetchLeadPipelines,
	fetchLeadNotes,
	fetchCampaigns,
	fetchCampaignSubscriptionStats,
	fetchCampaignMessages,
	fetchCampaignCampaignMessages,
	fetchCampaignSubscriptions,
	fetchCampaignEmails,
	refreshCampaignEmails,
	fetchCampaignStats,
	fetchCampaignCampaignMessageStats,
	fetchCampaignsSettings,
	fetchCampaignRoutes,
	fetchMarkets,
	fetchMarketSettings,
	fetchMarketListings,
	fetchMarketReports,
	fetchMarketReportSubscriptions,
	fetchSavedSearches,
	fetchPostalCodes,
	fetchAgents,
	fetchRoundRobinRoutes,
	fetchGeographicRoutes,
	fetchBoards,
	fetchOffices,
	fetchCities,
	fetchNeighborhoods,
	fetchTasks,
	fetchSiteLinks,
	fetchIntegrations,
	fetchAgentBoards,
	fetchOfficeBoards,
	fetchLanguages,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps
{
}

interface State {
	sessionHasExpired: boolean;
}

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { sessionHasExpired: false };
	}

	public componentDidMount() {
		this.props.monitorCredentials();
		this.initializeReactGA();
		const interval = setInterval(() => {
			const { user, leadsLoading, marketsLoading, leads, markets} = this.props;
			if (user
				&& user.reseller.direct === true
				&& (!user.permissions.leads || !leadsLoading)
				&& (!user.permissions.markets || !marketsLoading)
			) {
				const leadsTotal = leads.length; 
				const marketsTotal = markets.length;
				clearInterval(interval);
				appcues.identify(user, leadsTotal, marketsTotal);
			}
		}, 1000);
		this.setSessionTimer();
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.user && this.props.user) {
			ada.toggleChat(this.props.user);
			profitwell.start(this.props.user);
			this.refreshData();
		}
		if (prevProps.user && !this.props.user) {
			this.props.history.push(urls.logout(prevProps.user.reseller));
			ada.toggleChat(this.props.user);
			appcues.reset();
		}
		if (this.props.location.pathname !== prevProps.location.pathname) {
			if (this.props.location.pathname !== urls.redirect) {
				if (env.googleAnalytics && this.props.user?.reseller.direct === false) {
					ReactGA.pageview(this.props.location.pathname);
				}
			}
		}
	}

	private initializeReactGA() {
		if (env.googleAnalytics && this.props.user?.reseller.direct === false) {
			ReactGA.initialize(env.googleAnalytics);
			ReactGA.pageview(this.props.location.pathname);
		}
	}

	quotaInterval?: NodeJS.Timeout;

	private refreshData() {
		const { user } = this.props;
		if (user) {
			// One Agents need OnePermissions as well as One Clients
			// so this check needs to be outside the user.admin
			// check
			if (user.permissions.oneCrm) {
				this.props.fetchOneUser();
			}
			if (user.permissions.leads) {
				this.props.fetchLeads();
			}
			if (!this.quotaInterval) {
				this.quotaInterval = setInterval(() => {
					if (this.props.user) {
						this.props.fetchQuota();
					}
				}, 60 * 1000);
			}
			if (user.permissions.leadLastActive) {
				this.props.fetchLeadLastActivities();
			}
			if (user.permissions.crmTags) {
				this.props.fetchLeadTags();
			}
			if (user.permissions.crmPhones) {
				this.props.fetchLeadPhones();
			}
			if (user.permissions.crmAnniversary) {
				this.props.fetchLeadAnniversaries();
			}
			if (user.permissions.crmPipeline) {
				this.props.fetchLeadPipelines();
			}
			if (user.permissions.crmNotes) {
				this.props.fetchLeadNotes();
			}
			if (user.permissions.crmTasks) {
				this.props.fetchTasks();
			}
			if (user.permissions.roundRobinRouting) {
				this.props.fetchRoundRobinRoutes();
			}
			if (user.permissions.geographicRouting) {
				this.props.fetchGeographicRoutes();
				this.props.fetchPostalCodes();
			}
			if (user.permissions.campaigns) {
				this.props.fetchCampaigns();
				this.props.fetchCampaignStats();
				this.props.fetchCampaignCampaignMessageStats();
				this.props.fetchCampaignMessages();
				this.props.fetchCampaignCampaignMessages();
				this.props.fetchCampaignSubscriptions();
				this.props.fetchCampaignEmails({blast: true});
				this.props.fetchCampaignsSettings();
				this.props.fetchCampaignRoutes();
				this.props.fetchCampaignSubscriptionStats();
				this.props.fetchCampaignEmails({blast: true});
			}
			if (user.permissions.leadSaveSearch) {
				this.props.fetchSavedSearches();
			}
			
			this.props.fetchBoards();
			this.props.fetchCities();
			this.props.fetchNeighborhoods();
			this.props.fetchAdminMode();
			this.props.fetchSiteLinks();
			this.props.fetchIntegrations();
			this.props.fetchLanguages();
			if (user.permissions.markets) {
				this.props.fetchMarketListings();
				this.props.fetchMarketReports();
				this.props.fetchMarkets();
				this.props.fetchMarketSettings();
			}
			this.props.fetchAgents();
			this.props.fetchAgentBoards();
			if (user.permissions.broker) {
				this.props.fetchOffices();
				this.props.fetchOfficeBoards();
			}

		}
	}

	public setSessionTimer() {
		const timer = sessionTimer();
		const limit = 15;
		timer.start();
		timer.activityListener(() => {
			const minutes = Math.floor(timer.get() / 60);
			if (minutes >= limit) {
				this.setState({ sessionHasExpired: true });
			}
			timer.reset();
		});
	}
	
	public render() {
		const { user } = this.props;
		const theme = user ? getTheme(user.reseller.primaryColor, user.reseller.secondaryColor) : getTheme();
		return (
			<ThemeProvider theme={theme}>
				<Router.Switch>
					{routes.map((route, index) => (
						<Router.Route
							key={index}
							path={route.path}
							exact
							render={(props) => {
								if (route.authenticated) {
									if (user === undefined) {
										return <LoadingPage />;
									}
									if (user === null) {
										return <LoginPage />;
									}
								}
								return <route.component {...props} user={user} />
							}}
						/>
					))}
					<Router.Route component={NotFoundPage} />
				</Router.Switch>
				<SessionExpiredDialog
					open={this.state.sessionHasExpired}
					onClick={() => window.location.reload()}
				/>
			</ThemeProvider>
		);
	}

}

export const Base = Router.withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
