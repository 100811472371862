import React from "react";
import { FullPageCard } from "component/shared/full-page-card";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { Manual } from "component/page/market/criteria/manual";
import { Criterion } from "model/criterion";
import { bindActionCreators, Dispatch } from "redux";
import { createMarket } from "redux/slice/markets";
import { fetchMarketReports } from "redux/slice/market-reports";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { RootState, getPayload } from "redux/store";
import { urls } from "routes/urls";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { User } from "model/user";
import { ListingSearch } from "component/shared/listing-search";
import { fetchMarketListings } from "redux/slice/market-listings";
import { styles } from "./styles"

interface OwnProps {
	user: User;
	closeAddMarketDialog: () => void;
	helperTextStyle: string;
}

interface Props extends 
	OwnProps, 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles>,
	Router.RouteComponentProps
{
}

interface State {
	name: string;
	type: "search" | "manual";
	criteria: Criterion[];
	saved: boolean;
	errorText: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createMarket,
	fetchMarketReports,
	fetchMarketListings
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		error: state.markets.error,
	};
};

class Component extends React.Component<Props, State> {
	
	constructor(props: Props) {
		super(props);
		this.state = {
			name: "",
			type: "search",
			criteria: [],
			saved: false,
			errorText: ""
		}
	}

	private toggleSearchType() {
		const type = this.state.type === "search" ? "manual" : "search"
		this.setState({type, criteria: []});
	}

	private async save() {
		const { name, criteria } = this.state;
		const { createMarket, history, fetchMarketListings, fetchMarketReports } = this.props;
		
		if (name && criteria) {
			this.setState({saved: true});
			const marketResource = getPayload(await createMarket({ market: {
				name,
				criteria,
			}}));
			await fetchMarketReports();
			await fetchMarketListings();
			history.push(urls.market(marketResource));
		}
		if (!name) {
			this.setState({errorText: "Please provide a name for the market"})
		}
	}
	
	public render() {
		const { closeAddMarketDialog, helperTextStyle, classes, user, error } = this.props;
		const { type, name, saved, errorText } = this.state;
		return (
			<Mui.Dialog fullScreen open>				
				<Mui.Grid container>
					<Mui.Box sx={{ flexGrow: 1 }}>
						<Mui.AppBar color="primary" position="static">
							<Mui.Toolbar>
								<Mui.Grid container justifyContent="space-between" alignItems="center">
									<Mui.Grid item>
										<Mui.Typography variant="h2" component="div">
											New Market
										</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.IconButton
											edge="start"
											color="inherit"
											onClick={() => closeAddMarketDialog()}
											aria-label="close"
											>
											<Icons.X />
										</Mui.IconButton>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Toolbar>
						</Mui.AppBar>
					</Mui.Box>
				</Mui.Grid>
					{saved ? (
						<Mui.Grid
							container
							direction="column"
							justifyContent="center"
							alignContent="center"
							className={classes.loadingContainer}
						>
							<Mui.Grid item>
								<LoadingIndicator />
							</Mui.Grid>
							<Mui.Grid item>
								{error ? "Error" : "Saving..."}
							</Mui.Grid>
						</Mui.Grid>
						) : (
							<FullPageCard>
								<Mui.Grid container direction="column" spacing={2}>
									<Mui.Grid item xs={6}>
										<Mui.TextField
											value={name}
											variant="outlined"
											required
											label="Name"
											fullWidth
											onChange={(e) => this.setState({name: e.currentTarget.value})}
											helperText={!name && errorText}
											FormHelperTextProps={{
												className: helperTextStyle
											}}
											inputProps={{maxLength: 256}}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={6}>
										<Mui.Box>
											<Mui.FormControl fullWidth variant="outlined" className={classes.typeSelect}>
												<Mui.InputLabel>Type</Mui.InputLabel>
												<Mui.Select
													required
													label="Type"
													value={type}
													onChange={() => this.toggleSearchType()}
												>
													<Mui.MenuItem value="search">Search</Mui.MenuItem>
													<Mui.MenuItem value="manual">Manual (add listing by MLS #)</Mui.MenuItem>
												</Mui.Select>
											</Mui.FormControl>
										</Mui.Box>
									</Mui.Grid>
									<Mui.Grid item xs={6}>
										<Mui.Grid container spacing={2} alignItems="center">
											<Mui.Grid item xs={4}>
												<Mui.Button
													disabled={!name}
													variant="contained"
													color="secondary"
													fullWidth
													onClick={() => this.save()}
												>
													Save & Continue
												</Mui.Button>
											</Mui.Grid>
											<Mui.Grid item xs={3}>
												<Mui.Button
													variant="contained"
													color="default"
													fullWidth
													onClick={() => closeAddMarketDialog()}
												>
													Cancel
												</Mui.Button>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									<Mui.FormControl>
										{type === "manual" && (
											<Mui.Grid item>
												<Manual onUpdate={(criterion) => {
													this.setState({ criteria: [criterion] });
												}}/>
											</Mui.Grid>
										)}
										{type === "search" && (
											<ListingSearch
												disableRegistration={true}
												hideSaveButton={true}
												hideStatusField={true}
												user={user}
												onCriteriaChange={(criteria) => {
													this.setState({ criteria })
												}} 
												hideHeartButton={true}
												hideModal={true}
											/> 
										)}
									</Mui.FormControl>
								</Mui.Grid>
							</FullPageCard>
						)
					}
				
			</Mui.Dialog>
		)
}
	}

	export const NewMarketDialog = Mui.withStyles(styles)(
		Router.withRouter(
			connect(mapStateToProps, mapDispatchToProps)(Component)
		)
	);
	