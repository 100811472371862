import { Theme, createStyles } from "@material-ui/core";
export const styles = (theme: Theme) => {
    return createStyles({
        dialogContent: {
			minWidth: 325, 
			minHeight: 225,
		},
		checkbox: {
			marginRight: 8,
		},
		autocomplete: {
			minWidth: 275,
		},
		autocompleteItem: {
			display: "flex",
			flexDirection: "column",
			alignItems: "stretch",
		},
		selectAll: {
			alignSelf: "flex-end",
		},
		addButton: {
			marginRight: 16,
		}
    });
};
