import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		form: {
			backgroundColor: "transparent", 
			width: "100%",
			maxWidth: 400,
			borderRadius: 5,
		},
		arrow: {
			color: "grey"
		},
		searchIcon: {
			padding: 5
		},
		viewLabel: {
			color: "lightgrey",
			fontSize: "10px",
			paddingLeft: "5px",
			float: "left",
		},
		agentName: {
			paddingLeft: 5,
			color: "white",
			position: "relative",
			bottom: "5px",
			whiteSpace: "nowrap",
			overflow: 'hidden', 
			textOverflow: 'ellipsis',
			maxWidth: "180px",
		},
		agentNameItem: {
			paddingLeft: 5,
			color: "white",
		},
		nameContainer: {
			overflow: 'hidden', 
			textOverflow: "ellipsis", 
			whiteSpace: "nowrap",
			alignItems: "center"
		},
		usersIcon: {
			border: `3px solid ${theme.palette.secondary.main}`,
			borderRadius: 50,
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		popover: {
			maxHeight: 350,
		},
		menuItem: {
			backgroundColor: "#525A6F",
			"&:hover": {
				backgroundColor: "#3C4252"
			},
		},
		selected: {
			backgroundColor: "#3C4252",
			"&:hover": {
				backgroundColor: "#3C4252"
			},
		},
		textField: {
			position: 'sticky',
			top: '0',
			zIndex: 1,
			backgroundColor: '#525A6F',
		}
	});
};