import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { styles } from "./styles";
import { getUser } from "redux/selector";
import { Agent } from "model/agent";
import { Info } from "component/shared/profile/info";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agent: Agent;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = getUser(state);
	return {
		user
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{},
		dispatch
	);
};

class Component extends React.Component<Props> {
	render() {
		const { user, agent, classes } = this.props;
		return (
			<>
				<Mui.Grid container spacing={2}>
					<Mui.Grid item lg={4} sm={6} xs={12}>
						<Mui.Card elevation={2}>
							<Mui.CardHeader 
								title="Profile" 
								classes={{
									root: classes.root,
									title: classes.title,
								}} 
							/>
							<Mui.CardContent style={{padding: 25, paddingTop: 10}}>
								<Info agent={agent} user={user} />
							</Mui.CardContent>
						</Mui.Card>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const SingleAgentProfile = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));