import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { styles } from "./style";
import { getRoundRobinRoutes, getUser } from "redux/selector";
import { AgentTag } from "component/shared/agent-tag";

interface Props
	extends Mui.WithStyles<typeof styles>,
		ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const user = getUser(state);
	const roundRobinRoutes = getRoundRobinRoutes(state);
	return {
		roundRobinRoutes,
		user
	};
};

class Component extends React.Component<Props> {
	public render() {
		const { roundRobinRoutes, user } = this.props;
		const position = user.roundRobinPosition;
		const nextAssigned = roundRobinRoutes[position ? position - 1 : position];

		let agent;
		if (nextAssigned) {
			agent = nextAssigned.agent;
		}
		const enabled = user.roundRobinRouting;

		if (!enabled || !agent) {
			return null;
		}
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography variant="h3">Round Robin</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Card>
						<Mui.CardContent>
							<AgentTag 
								prefix="Next round robin lead assigned to"
								agent={agent}
								justifyContent="center"
							/>
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
	);
}
}

export const RoundRobin = Mui.withStyles(styles)(
	connect(mapStateToProps)(Component)
);
