import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		tableRow: {
			transition: ".1s",
			borderTop: "1px solid lightgray",
			borderBottom: "1px solid lightgray",
			transform: "none",
			"&:hover": {
				backgroundColor: "#f7f7f7",
				"& $trashCan": {
					color: "darkgray",
					"&:hover": {
						color: "gray"
					}
				}
			},
			"&:focus": {
				backgroundColor: "#f7f7f7",
			}
		},
		tableCell: {
			minWidth: "100px",
			maxWidth: "100px"
		},
		trashCan: {
			transition: ".1s",
			cursor: "pointer",
			color: "transparent",
		},
	});
};
