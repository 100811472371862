import { Theme, createStyles } from "@material-ui/core"

export const styles = (theme: Theme) => {
	return createStyles({
		tooltip: {
			fontSize: "1em",
		},
		tooltipIcon: {
			marginLeft: 8
		},
		disabledSwitch: {
			visibility: "hidden",
		},
		switchLabel: {
			display: "flex",
			alignItems: "center"
		},
		errorMessage: {
			color: theme.palette.error.main,
			fontWeight: "bold",
		},
	})
}