import React from "react";
import * as Mui from "@material-ui/core";
import { ExpansionTable } from "./expansion-table";
import { styles } from "./style";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Lead } from "model/lead";
import { CampaignSubscription } from "model/campaign-subscription";
import { getDripCampaignSubscriptionsForLead, getActiveDripCampaigns } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { fetchCampaignEmails } from "redux/slice/campaign-emails";

interface OwnProps extends Mui.WithStyles<typeof styles> {
	lead: Lead;
}

interface State {}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		campaigns: getActiveDripCampaigns(state),
		subscriptions: getDripCampaignSubscriptionsForLead(state, ownProps.lead.id),
		loading: state.campaigns.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchCampaignEmails,
}, dispatch);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

class Component extends React.Component<Props, State> {

	public componentDidMount() {
		const { lead, fetchCampaignEmails } = this.props;
		fetchCampaignEmails({leadId:lead.id});
	}

	private sortSubscriptions = (
		sub1: CampaignSubscription,
		sub2: CampaignSubscription,
	) => {
		const statusEnum: { [key: string]: number } = {
			Active: 0,
			Inactive: 1,
			Complete: 2,
		};

		const compare = (a: string | number, b: string | number) => {
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			} else {
				return 0;
			}
		};

		return (
			compare(statusEnum[sub1.status.label], statusEnum[sub2.status.label]) ||
			compare(sub1.campaign.name, sub2.campaign.name)
		);
	};

	public render() {
		const { subscriptions, loading } = this.props;
		if (!!loading) {
			return (
				<Mui.Grid container justifyContent="center">
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				</Mui.Grid>
			)
		} else if (!loading && subscriptions && subscriptions.length) {
			return subscriptions
				.sort((sub1, sub2) => this.sortSubscriptions(sub1, sub2))
				.map((subscription, index) => (
					<ExpansionTable subscription={subscription} key={index} index={index} />
				));
		} else {
			return (
				<Mui.Typography>
					No current campaigns. Add one to stay in touch.
				</Mui.Typography>
			);
		}
	}
}

export const CampaignSubscriptionsPanel = Mui.withStyles(styles)(
	connect<StateProps, DispatchProps, OwnProps, RootState>(
		mapStateToProps,
		mapDispatchToProps,
	)(Component),
);
