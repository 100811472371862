import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";import * as Mui from "@material-ui/core";
import { Dialog } from "component/shared/dialog";
import { styles } from "../../style";
import * as yup from "yup";
import { validate } from "shared/yup";
import { AgentSearch } from "component/shared/agent-search";
import { getAgentsWithLeads, getLeadsById } from "redux/selector";
import { updateLead } from "redux/slice/leads";


const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		agents: getAgentsWithLeads(state),
		leads: getLeadsById(state, ownProps.leadIds),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	onClose: Function,
	leadIds: number[],
}

interface State {
	agentId?: number;
	submitted: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			submitted: false,
		};
	}

	private validationSchema = {
		agentId: yup
			.string()
			.required("Please select an agent."),
	};

	private assignAgent() {
		const { leads, agents, updateLead } = this.props;
		const { agentId } = this.state;
		const errors = validate(this.validationSchema, this.state);

		if (!errors && agentId) {
			leads.forEach((lead) => {
				const agent = agents.find(item => item.id === agentId);
				if (agent) {
					if (!lead.agent || (lead.agent.id !== agent.id)) {
						updateLead({ lead: {
							...lead,
							agent,
						}});
					}
				}
			});
			this.onClose();
		} else {
			this.setState({ submitted: true });
		}
	}

	private onClose() {
		this.props.onClose();
		this.setState({ agentId: undefined, submitted: false });
	}

	private onSelectAgent = (agentSelectedId: number) => {
		this.setState({agentId: agentSelectedId as number});
	}

	render() {
		const { classes } = this.props;
		const { agentId, submitted } = this.state;
		return (
			<Dialog
				open={true}
				onClose={() => {
					this.onClose()
				}}
				fullWidth={true}
				maxWidth="xs"
			>
				<Mui.DialogTitle>Assign to Agent</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.Grid container alignItems="center" spacing={1}>
						<Mui.Grid item xs={12}>
							<Mui.DialogContentText className={classes.dialogText}>Assign all selected contacts to an agent.</Mui.DialogContentText>
						</Mui.Grid>
						<Mui.Grid container item xs={12} justifyContent="center">
							<AgentSearch
								showUnassiged={false}
								onSelectAgent={this.onSelectAgent}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
				<Mui.Button
						onClick={() => {
							this.assignAgent();
						}}
						variant="contained"
						color="secondary"
						disabled={submitted && !agentId}
					>
						Save
					</Mui.Button>
					<Mui.Button 
						onClick={() => {
							this.onClose();
						}}
						variant="outlined"
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		)
	}

}

export const BulkAssignToAgentDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);