import React from "react";
import * as Mui from "@material-ui/core";
import { Market } from "model/market";
import {styles} from "../styles";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";

interface Props extends Mui.WithStyles<typeof styles> {
	market: Market;
	marketboost?: boolean;
}

interface State {
	anchorEl: HTMLElement | null
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props)
		this.state = {
			anchorEl: null,
		}
	}
  render() {
	const { classes, market, marketboost} = this.props;
	const {anchorEl} = this.state;
	return (
		<>
			{(market.listingReport && !market.openHomesReportSupported && !market.marketReportSupported && market.listingReport.pageUrl) && ( 
				<Mui.Button
					variant="outlined" 
					href={market.listingReport.pageUrl}
					target="_blank"
					className={classes.dropdownButton}
				>
					<Mui.Typography>View Page</Mui.Typography>
				</Mui.Button>
			)}
			{(market.openHomesReport && !market.listingReportSupported && market.openHomesReportSupported && !market.marketReportSupported && market.openHomesReport.pageUrl) && ( 
				<Mui.Button
					variant="outlined" 
					href={market.openHomesReport.pageUrl}
					target="_blank"
					className={classes.dropdownButton}
				>
					<Mui.Typography>View Page</Mui.Typography>
				</Mui.Button>
			)}
			{(market.marketReport && !market.listingReportSupported && !market.openHomesReportSupported && market.marketReportSupported && market.marketReport.pageUrl) && ( 
				<Mui.Button
					variant="outlined" 
					href={market.marketReport.pageUrl}
					target="_blank"
					className={classes.dropdownButton}
				>
					<Mui.Typography>View Page</Mui.Typography>
				</Mui.Button>
			)}
			{market.reports.length > 1 && (
				<>
					<Mui.Button
						onClick={e => this.setState({anchorEl: e.currentTarget})}
						className={classes.dropdownButton}
					>
						<Mui.Typography>View Page</Mui.Typography>
							<FeatherIcon fontSize="small">
								<Icons.ChevronDown/>
							</FeatherIcon>
					</Mui.Button>
					<Mui.Popper anchorEl={anchorEl} open={!!anchorEl} style={{ zIndex: 1200 }}>
						<Mui.ClickAwayListener
							onClickAway={() => this.setState({anchorEl: null})}
							>
							<Mui.Paper>
								<Mui.MenuList>
									{market.listingReportSupported && market.listingReport?.pageUrl && (
										<Mui.MenuItem>
											<Mui.Link
												href={market.listingReport.pageUrl}
												target="_blank"
												>
												{market.listingReport.type.label}
											</Mui.Link>
										</Mui.MenuItem>
									)}
									{market.openHomesReportSupported && market.openHomesReport?.pageUrl && marketboost && (
										<Mui.MenuItem>
											<Mui.Link
												href={market.openHomesReport.pageUrl}
												target="_blank"
												>
												{market.openHomesReport.type.label}
											</Mui.Link>
										</Mui.MenuItem>
									)}
									{market.marketReportSupported && market.marketReport?.pageUrl && marketboost && (
										<Mui.MenuItem>
											<Mui.Link
												href={market.marketReport.pageUrl}
												target="_blank"
												>
												{market.marketReport.type.label}
											</Mui.Link>
										</Mui.MenuItem>
									)}
								</Mui.MenuList>
							</Mui.Paper>
						</Mui.ClickAwayListener>
					</Mui.Popper>
				</>
			)}
		</>
	)
  }
}

export const LinkButton = Mui.withStyles(styles)(Component)
