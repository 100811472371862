import React from "react";
import { connect } from "react-redux";
import { Left } from "../left";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { getLatestLeadPipelinesForLeads } from "redux/selector";
import moment from "moment";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
    let leads = getActiveLeads(state);

    if (agentId && agentId !== -1) {
        leads = leads.filter(lead => lead.agent?.id === agentId);
    } else if (agentId === -1) {
        leads = leads.filter(lead => !lead.agent?.id);
    }
	return {
		leads,
		leadPipelines: getLatestLeadPipelinesForLeads(
			state,
			leads.map(lead => lead.id),
		),
	};
};

const Component = (props: Props) => {
	const numLeadsUpdatedpast7Days = props.leadPipelines.filter(
		pipeline =>
			pipeline &&
			moment().subtract(7, "d") < pipeline.createdOn &&
			pipeline.createdOn <= moment(),
	).length;
	const text = numLeadsUpdatedpast7Days === 1 ? "lead updated" : "leads updated";
	return <Left color="#5eb95f" value={numLeadsUpdatedpast7Days} text={text} />;
};

export const LeadsUpdated = connect(mapStateToProps)(Component);
