import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { NewLeadTag, LeadTag } from "model/lead-tag";
import { loginReset } from "redux/slice/authentication";
import { LeadTagResource } from "api/resource/lead-tag";

export interface LeadTagsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: LeadTagResource;
	};
	byLeadId: {
		[key: number]: number[];
	};
};

const initialState: LeadTagsState = {
	byId: {},
	byLeadId: {},
};

const fetchLeadTags = createAsyncThunk("leadTags/fetchLeadTags", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadTagsLoading());
	const result = api.getLeadTags();
	result
		.then((leadTags) => thunkAPI.dispatch(leadTagsLoaded(leadTags)))
		.catch(error => thunkAPI.dispatch(leadTagsFailed(error)));
	return result;
});

const createLeadTag = createAsyncThunk("leadTags/createLeadTags", async (payload: { leadTag: NewLeadTag }, thunkAPI) => {
	thunkAPI.dispatch(leadTagsLoading());
	const result = api.createLeadTag(payload.leadTag);
	result
		.then((leadTag) => thunkAPI.dispatch(leadTagLoaded(leadTag)))
		.catch(error => thunkAPI.dispatch(leadTagsFailed(error)));
	return result;
});

const deleteLeadTag = createAsyncThunk("leadTags/deleteLeadTag", async (payload: { leadTag: LeadTag }, thunkAPI) => {
	const result = api.deleteLeadTag(payload.leadTag);
	result
		.then(() => thunkAPI.dispatch(leadTagDeleted(payload.leadTag)))
		.catch(error => thunkAPI.dispatch(leadTagsFailed(error)));
	return result;
});

export const leadTagsSlice = createSlice({
	name: "leadTags",
	initialState,
	reducers: {
		leadTagsLoading: (state) => {
			state.loading = true;
		},
		leadTagsLoaded: (state, action: PayloadAction<LeadTagResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
		},
		leadTagLoaded: (state, action: PayloadAction<LeadTagResource>) => {
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			const byId = objectToById(action.payload, "id")
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId
			};
			state.byLeadId = byLeadId
		},
		leadTagDeleted: (state, action: PayloadAction<any>) => {
			const id = action.payload.id;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			}
			delete result.byId[id];
			return result;
		},
		leadTagsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
    }
});

export const { leadTagsLoading, leadTagsLoaded, leadTagLoaded, leadTagDeleted, leadTagsFailed } = leadTagsSlice.actions;
export { fetchLeadTags, createLeadTag, deleteLeadTag };