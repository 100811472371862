import { LeadStatus } from "type/lead-status";
import { Sort } from "component/shared/table";
import { AgentColumnType } from "type/agent-column-type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";

export interface AgentPageState {
	filters: AgentPageFiltersState;
	sort: Sort; 
	pageSize: number; 
	page: number;
	columnTypeIds: string[];
};

export interface AgentPageFiltersState {
	avatar?: string[],
	label?: string[],
	id?: number[],
	role?: boolean,
	lastLogin?: string[],
	billableUser?: boolean,
	leads?: number[],
	geographicRouting?: number[],
	roundRobin?: boolean,
};

const initialState: AgentPageState = {
	filters: {

	},
	sort: {
		id: "createdOn", 
		desc: true,
	},
	pageSize: 20, 
	page: 0,
	columnTypeIds: [], 
};

export const agentPageSlice = createSlice({
	name: "agentPage",
	initialState,
	reducers: {
		setAgentPageFilters: (state, action: PayloadAction<AgentPageFiltersState>) => {
			state.filters = action.payload;
		},
		resetAgentPageFilters: (state) => {
			state.filters = initialState.filters;
		},
		updateAgentPageSort: (state, action: PayloadAction<Sort>) => {
			state.sort = action.payload;
		},
		updateAgentPagePageSize: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		updateAgentPagePage: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		updateAgentPageColumns: (state, action: PayloadAction<AgentColumnType[]>) => {
			state.columnTypeIds = action.payload.map(agentColumnType => agentColumnType.id);
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
    }
});

export const {setAgentPageFilters, resetAgentPageFilters, updateAgentPageColumns, updateAgentPagePage, updateAgentPagePageSize, updateAgentPageSort} = agentPageSlice.actions;
