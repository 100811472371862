import React from "react";
import * as Mui from "@material-ui/core";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { Lead } from "model/lead";
import { styles } from "./style";
import * as yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { getActiveLeadEmails, getNonActiveLeadEmails } from "redux/selector";
import { Avatar } from "component/shared/avatar";
import { getAgentsWithLeads } from "redux/selector";
import { validate } from "shared/yup";
import { Dialog } from "component/shared/dialog";
import { LeadPhoneType } from "type/lead-phone";
import { LeadPhone, NewLeadPhone } from "model/lead-phone";
import { LeadAnniversary } from "model/lead-anniversary";
import { LeadAnniversaryType } from "type/lead-anniversary";
import { LeadTagType } from "type/lead-tag";
import { LeadTag } from "model/lead-tag";
import { Select } from "component/shared/select";
import { User } from "model/user";
import { deleteLeadTag } from "redux/slice/lead-tags";
import moment, { Moment } from "moment";
import { updateLead } from "redux/slice/leads";
import { updateLeadPhone, createLeadPhone } from "redux/slice/lead-phones";
import { updateLeadAnniversary, createLeadAnniversary } from "redux/slice/lead-anniversaries";
import { createLeadTag } from "redux/slice/lead-tags";

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		agents: getAgentsWithLeads(state),
		activeLeadEmails: getActiveLeadEmails(state),
		nonActiveLeadEmails: getNonActiveLeadEmails(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	deleteLeadTag,
	updateLead,
	updateLeadPhone,
	createLeadPhone,
	updateLeadAnniversary,
	createLeadAnniversary,
	createLeadTag,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{
	open: boolean;
	onClose: Function;
	user: User;
	lead: Lead;
	leadPhones?: LeadPhone[];
	leadAnniversaries?: LeadAnniversary[];
	typeTags?: LeadTag[];
}

interface State {
	submitted: boolean;
	name: string;
	email: string;
	email2: string;
	phone: string;
	leadPhones: LeadPhoneState[];
	address: string;
	city: string;
	state: string;
	zip: string;
	addEmail2: boolean;
	agentId: number;
	birthday: LeadAnniversary | null;
	transactionAnniversary: LeadAnniversary | null;
	typeIds: string[];
}
interface LeadPhoneState {
	id?: number;
	leadId: number;
	value: string;
	type: LeadPhoneType;
	createdOn?: Moment;
	updatedOn?: Moment;
	deleted?: boolean;
}

const initialState: State = {
	submitted: false,
	name: "",
	email: "",
	email2: "",
	phone: "",
	leadPhones: [],
	address: "",
	city: "",
	state: "",
	zip: "",
	addEmail2: false,
	agentId: 0,
	birthday: null,
	transactionAnniversary: null,
	typeIds: [],
};

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
			const { lead, leadAnniversaries, typeTags } = this.props;
			const { name, email, email2, phone, address, city, state, zip, agent } = lead;
			const agentId = agent && agent.id;

			let leadPhones = this.props.leadPhones as LeadPhone[];
			leadPhones.map(leadPhone => {
				return {
					id: leadPhone.id,
					value: leadPhone.value,
					type: leadPhone.type,
					deleted: (leadPhone.deleted === undefined) ? false : leadPhone.deleted,
				}
			});
			let birthday = null;
			let transactionAnniversary = null;
			if (leadAnniversaries) {
				leadAnniversaries.forEach(anniversary => {
					if (!anniversary.deleted) {
						const { id } = anniversary.type;
						const anniversaryType = LeadAnniversaryType.getById(id);
						if (anniversaryType) {
							switch(anniversaryType.label) {
								case "Birthday":
									birthday = anniversary;
									break;
								case "Transaction Anniversary":
									transactionAnniversary = anniversary;
									break;
							}
						}
					}
				});
			}
			const typeIds = typeTags && typeTags.map(tag => tag.text);

			this.setState({
				name: name || "",
				email: email || "",
				email2: email2 || "",
				phone: phone || "",
				leadPhones,
				address: address || "",
				city: city || "",
				state: state || "",
				zip: zip || "",
				addEmail2: !!email2,
				agentId: agentId || 0,
				birthday: birthday || null, 
				transactionAnniversary: transactionAnniversary || null,
				typeIds: typeIds || [],
			});	
		}
	}

	private addLeadPhone = () => {
		let leadPhones = [...this.state.leadPhones];
		const { lead } = this.props;
		const newLeadPhone: LeadPhoneState = {
			deleted: false,
			value: "",
			type: LeadPhoneType.MOBILE,
			leadId: lead.id,
		};
		leadPhones.push(newLeadPhone);
		this.setState({ leadPhones });
	};

	private removeLeadPhone = (index: number) => {
		let leadPhones = [...this.state.leadPhones];
		leadPhones[index].deleted = true;
		this.setState({ leadPhones });
	};

	private validationSchema = {
		addEmail2: yup.boolean(),
		email: yup
			.string()
			.email("Invalid email.")
			.required("Email is required.")
			.test(
				"check-for-existing-active-email",
				"A lead with that email already exists.",
				(email) => {
					let pass = true;
					if (!this.props.lead || this.props.lead.email !== email) {
						if (email && this.props.activeLeadEmails.includes(email)) {
							pass = false;
						}
					}
					return pass;
				}
			)
			.test(
				"check-for-existing-non-active-email",
				"A deleted or inactive lead with that email already exists. You can reactivate that lead.",
				(email) => {
					let pass = true;
					if (!this.props.lead || this.props.lead.email !== email) {
						if (email && this.props.nonActiveLeadEmails.includes(email)) {
							pass = false;
						}
					}
					return pass;
				}
			),
		email2: yup.string().when("addEmail2", {
			is: true,
			then: yup.string().email("Invalid email."),
		}),
	};

	private onChangePhone = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number) => {
		let leadPhones = [...this.state.leadPhones];
		leadPhones[index].value = event.target.value as string;
		this.setState({ leadPhones });
	};

	private onChangeBirthday = (date: Date | null) => {
		let birthday = { ...this.state.birthday } as LeadAnniversary;
		const { lead } = this.props;
		if (!birthday || !birthday.id) {
			birthday = {
				...birthday,
				leadId: lead && lead.id,
				type: LeadAnniversaryType.getById(1),
				updatedOn: moment(),
			} as LeadAnniversary;
		}
		birthday.startDate = moment(date!);
		this.setState({ birthday });
	};

	private onChangeTransactionAnniversary = (date: Date | null) => {
		let transactionAnniversary = {
			...this.state.transactionAnniversary,
		} as LeadAnniversary;
		const { lead } = this.props;
		if (!transactionAnniversary || !transactionAnniversary.id) {
			transactionAnniversary = {
				...transactionAnniversary,
				leadId: lead && lead.id,
				type: LeadAnniversaryType.getById(2),
				updatedOn: moment(),
			} as LeadAnniversary;
		}
		transactionAnniversary.startDate = moment(date!);
		this.setState({ transactionAnniversary });
	};

	private getLeadTypeTags() {
		const { typeIds } = this.state;
		return typeIds.map((typeId) => {
			const result = {
				text: typeId,
			};
			return result;
		});
	}

	private async submit() {
		const { onClose, lead, agents, typeTags, updateLead, updateLeadPhone, createLeadPhone, updateLeadAnniversary, createLeadTag, createLeadAnniversary, leadAnniversaries: anniversaries } = this.props;
		const {
			name,
			email,
			email2,
			phone,
			leadPhones,
			address,
			city,
			state,
			zip,
			birthday,
			transactionAnniversary,
			agentId,
		} = this.state;
		this.setState({ submitted: true });
		const errors = validate(this.validationSchema, this.state);
		const agent = agents.find(agent => agent.id === agentId);
		const leadTypeTags = this.getLeadTypeTags();
		const leadAnniversaries: LeadAnniversary[] = [];

		if (birthday) {
			if (!birthday.startDate) {
				birthday.startDate = moment();
				birthday.deleted = false;
				birthday.lead = lead;
			}
			leadAnniversaries.push(birthday);
		} else {
			const existingBirthday = anniversaries?.find(anniversary => anniversary.type.label === "Birthday");
			if (existingBirthday) {
				existingBirthday.deleted = true;
				leadAnniversaries.push(existingBirthday);
			}
		}
		
		if (transactionAnniversary) {
			if (!transactionAnniversary.startDate) {
				transactionAnniversary.startDate = moment();
				transactionAnniversary.deleted = false;
				transactionAnniversary.lead = lead;
			}
			leadAnniversaries.push(transactionAnniversary);
		} else {
			const existingTransaction = anniversaries?.find(anniversary => anniversary.type.label === "Transaction Anniversary");
			if (existingTransaction) {
				existingTransaction.deleted = true;
				leadAnniversaries.push(existingTransaction);
			}
		}

		if (!errors && email) {
			if (typeTags) {
				typeTags.forEach((tag) => {
					this.props.deleteLeadTag({leadTag:tag});
				});
			}
			updateLead({ lead: { 
				...lead,
				id: lead && lead.id,
				name, 
				email, 
				email2, 
				phone, 
				address, 
				city, 
				state, 
				zip, 
				agent,
			}});
			let newLeadPhones: NewLeadPhone[] = [];
			let updatedLeadPhones: LeadPhone[] = [];
			for (const phone of leadPhones) {
				if (phone && phone.id && phone.createdOn && phone.updatedOn) {
					updatedLeadPhones.push({
						id: phone.id,
						leadId: phone.leadId,
						type: phone.type,
						updatedOn: phone.updatedOn,
						createdOn: phone.createdOn,
						value: phone.value,
						deleted: phone.deleted,
					});
				} else {
					newLeadPhones.push({
						leadId: phone.leadId,
						type: phone.type,
						value: phone.value,
						deleted: phone.deleted,
					});
				}
			}
			
			for (const phone of updatedLeadPhones) {
				updateLeadPhone({ leadPhone: phone });					
			}
			for (const phone of newLeadPhones) {
				getPayload(await createLeadPhone({ leadPhone: phone }));
			}
			for (const anniversary of leadAnniversaries) {
				if (anniversary.id) {
					updateLeadAnniversary({ leadAnniversary: anniversary });
				} else {
					createLeadAnniversary({ leadAnniversary: {
						...anniversary,
						lead,
					} });
				}
			}
			
			for (const anniversary of leadAnniversaries) {
				if (anniversary.id) {
					updateLeadAnniversary({ leadAnniversary: anniversary });
				} else {
					createLeadAnniversary({ leadAnniversary: anniversary });
				}
			}
			for (const tag of leadTypeTags) {
				createLeadTag({ leadTag: {
					leadId: lead.id,
					text: tag.text,
				} });
			}
			onClose();
		}
	};

	public render() {
		const { open, onClose, lead, agents, user, classes } = this.props;
		const {
			submitted,
			name,
			email,
			email2,
			phone,
			leadPhones,
			address,
			city,
			state,
			zip,
			addEmail2,
			birthday,
			transactionAnniversary,
			typeIds,
		} = this.state;
		const errors = validate(this.validationSchema, this.state);
		const phones = leadPhones.filter(phone => !phone.deleted).length < 3;
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					<Mui.Grid container direction="column">
						<Mui.Grid item>
							<Mui.Typography>
								{lead && "Edit Contact"}
								{!lead && "Add Contact"}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container justifyContent="center">
								<Mui.Grid item>
									<Avatar diameter={80} phrase={this.state.name || this.state.email} />
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column">
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.User />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={name}
										onChange={event => this.setState({ name: event.target.value })}
										placeholder="Name"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.Mail />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={email}
										type="text"
										onChange={event => this.setState({ email: event.target.value })}
										error={submitted && !!errors && !!errors.email}
										helperText={submitted && errors && errors.email}
										FormHelperTextProps={{
											className: classes.errorMessage,
										}}
										placeholder="Email"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1}>
									{!addEmail2 && (
										<Mui.IconButton
											size="small"
											onClick={() => this.setState({ addEmail2: true })}
										>
											<FeatherIcon>
												<Icons.PlusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									)}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						{addEmail2 && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center" spacing={1}>
									<Mui.Grid item xs={1} />
									<Mui.Grid item xs={10}>
										<Mui.TextField
											variant="outlined"
											margin="dense"
											fullWidth
											value={email2}
											type="email"
											required
											onChange={event => this.setState({ email2: event.target.value })}
											helperText={submitted && errors && errors.email2}
											placeholder="Email"
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1}>
										<Mui.IconButton
											size="small"
											onClick={() => this.setState({ addEmail2: false })}
										>
											<FeatherIcon>
												<Icons.MinusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						)}
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.Phone />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={phone}
										onChange={event => this.setState({ phone: event.target.value })}
										placeholder="Phone Number"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1}>
									{user.permissions.crmPhones && phones && (
										<Mui.IconButton size="small" onClick={() => this.addLeadPhone()}>
											<FeatherIcon>
												<Icons.PlusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									)}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						{user.permissions.crmPhones && leadPhones.filter(phone => !phone.deleted).map((phone, index) => (
							<Mui.Grid container item key={index}>
								<Mui.Grid container alignItems="center" spacing={1}>
									<Mui.Grid container item xs={1} justifyContent="center" />
									<Mui.Grid item xs={6}>
										<Mui.TextField
											variant="outlined"
											margin="dense"
											fullWidth
											value={phone.value || ""}
											onChange={event => this.onChangePhone(event, index)}
											placeholder="Phone Number"
										/>
									</Mui.Grid>
									<Mui.Grid item xs={4}>
										<Select
											variant="outlined"
											margin="dense"
											fullWidth
											placeholder="Phone Type"
											value={phone.type}
											options={LeadPhoneType.values()}
											valueExtractor={type => type}
											labelExtractor={type => type.label}
											optionRenderer={type => <Mui.ListItemText primary={type.label} />}
											onChange={value => {
												let leadPhones = [...this.state.leadPhones];
												leadPhones[index].type = LeadPhoneType.getById(value.id) || LeadPhoneType.MOBILE;										
												this.setState({ leadPhones });
											}}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1}>
										<Mui.IconButton
											size="small"
											onClick={() => this.removeLeadPhone(index)}
										>
											<FeatherIcon>
												<Icons.MinusCircle />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
						))}
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid container item xs={1} justifyContent="center">
									<FeatherIcon>
										<Icons.MapPin />
									</FeatherIcon>
								</Mui.Grid>
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={address}
										onChange={event => this.setState({ address: event.target.value })}
										placeholder="Street Address"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid item xs={1} />
								<Mui.Grid item xs={10}>
									<Mui.TextField
										variant="outlined"
										margin="dense"
										fullWidth
										value={city}
										onChange={event => this.setState({ city: event.target.value })}
										placeholder="City"
									/>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid container item>
							<Mui.Grid container alignItems="center" spacing={1}>
								<Mui.Grid item xs={1} />
								<Mui.Grid item xs={10}>
									<Mui.Grid container spacing={1}>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												variant="outlined"
												margin="dense"
												fullWidth
												value={state}
												onChange={event => this.setState({ state: event.target.value })}
												placeholder="State"
											/>
										</Mui.Grid>
										<Mui.Grid item xs={6}>
											<Mui.TextField
												variant="outlined"
												margin="dense"
												fullWidth
												defaultValue={zip}
												onChange={event => this.setState({ zip: event.target.value })}
												placeholder="Postal Code"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid item xs={1} />
							</Mui.Grid>
						</Mui.Grid>
						{user.permissions.crmAnniversary && (
							<>
								<Mui.Grid container item>
									<Mui.Grid container alignItems="center" spacing={1}>
										<Mui.Grid container item xs={1} justifyContent="center">
											<FeatherIcon>
												<Icons.Gift />
											</FeatherIcon>
										</Mui.Grid>
										<Mui.Grid item xs={10}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													variant="inline"
													inputVariant="outlined"
													InputProps={{
														className: classes.adornedEnd,
													}}
													format="MM/dd"
													fullWidth
													margin="dense"
													label="Birthday"
													placeholder="mm/dd"
													value={birthday && birthday.startDate}
													onChange={date => this.onChangeBirthday(date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
														size: "small",
													}}
													keyboardIcon={<Icons.Calendar />}
													views={["date"]}
												/>
											</MuiPickersUtilsProvider>
										</Mui.Grid>
										{birthday && (
											<Mui.Grid item xs={1}>
												<Mui.IconButton
													size="small"
													onClick={() => this.setState({ birthday: null })}
												>
													<FeatherIcon>
														<Icons.MinusCircle />
													</FeatherIcon>
												</Mui.IconButton>
											</Mui.Grid>
										)}
										<Mui.Grid item xs={1} />
									</Mui.Grid>
								</Mui.Grid>
								<Mui.Grid container item>
									<Mui.Grid container alignItems="center" spacing={1}>
										<Mui.Grid container item xs={1} justifyContent="center">
											<FeatherIcon>
												<Icons.Key />
											</FeatherIcon>
										</Mui.Grid>
										<Mui.Grid item xs={10}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													variant="inline"
													inputVariant="outlined"
													InputProps={{
														className: classes.adornedEnd,
													}}
													format="MM/dd"
													fullWidth
													margin="dense"
													label="Transaction Anniversary"
													placeholder="mm/dd"
													value={transactionAnniversary && transactionAnniversary.startDate}
													onChange={date => this.onChangeTransactionAnniversary(date)}
													KeyboardButtonProps={{
														"aria-label": "change date",
														size: "small",
													}}
													keyboardIcon={<Icons.Calendar fontSize="small" />}
													views={["date"]}
												/>
											</MuiPickersUtilsProvider>
										</Mui.Grid>
										{transactionAnniversary && (
											<Mui.Grid item xs={1}>
												<Mui.IconButton
													size="small"
													onClick={() => this.setState({ transactionAnniversary: null })}
												>
													<FeatherIcon>
														<Icons.MinusCircle />
													</FeatherIcon>
												</Mui.IconButton>
											</Mui.Grid>
										)}
										<Mui.Grid item xs={1} />
									</Mui.Grid>
								</Mui.Grid>
							</>
						)}
						{user && user.permissions.broker && user.admin && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item xs={1}>
										<FeatherIcon>
											<Icons.Award />
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item xs={10}>
										<Select
											fullWidth
											variant="outlined"
											margin="dense"
											placeholder="Assign an agent"
											value={this.state.agentId}
											options={Array.from(agents)}
											valueExtractor={agent => agent.id}
											labelExtractor={agent => agent.name}
											optionRenderer={agent => <Mui.ListItemText primary={agent.name} />}
											onChange={value => this.setState({ agentId: value })}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1} />
								</Mui.Grid>
							</Mui.Grid>
						)}
						{user && user.permissions.crmTags && (
							<Mui.Grid container item>
								<Mui.Grid container alignItems="center">
									<Mui.Grid item xs={1}>
										<FeatherIcon>
											<Icons.Target />
										</FeatherIcon>
									</Mui.Grid>
									<Mui.Grid item xs={10}>
										<Select
											multiple
											fullWidth
											variant="outlined"
											margin="dense"
											placeholder="Assign a type"
											value={typeIds}
											options={LeadTagType.leadTypeValues()}
											valueExtractor={type => type.id}
											labelExtractor={type => type.label}
											optionRenderer={type => <Mui.ListItemText primary={type.label} />}
											onChange={values => this.setState({ typeIds: values })}
										/>
									</Mui.Grid>
									<Mui.Grid item xs={1} />
								</Mui.Grid>
							</Mui.Grid>
						)}
						<Mui.Grid container item style={{ marginTop: 16, marginBottom: 12 }}>
							<Mui.Grid item xs={1} />
							<Mui.Grid container item xs={10}>
								<div style={{ flex: 1 }} />
								<Mui.Button
									className={classes.button}
									style={{ marginRight: 8 }}
									variant="contained"
									color="secondary"
									onClick={() => this.submit()}
									disabled={submitted && !!errors}
								>
									Save
								</Mui.Button>
								<Mui.Button
									className={classes.button}
									variant="outlined"
									color="secondary"
									onClick={() => onClose()}
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
							<Mui.Grid item xs={1} />
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		);
	}
}

export const EditLeadDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);