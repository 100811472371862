import React from "react";
import { Provider } from "react-redux";
import { createConfigureStore } from "redux/store";
import * as Router from "react-router-dom";
import { Base } from "./Base";
import { PersistGate } from "redux-persist/integration/react"
import { ScrollToTop } from "./scroll-to-top";


const { persistor, store } = createConfigureStore()

class Component extends React.Component {

	public render() {
		return (
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<Router.BrowserRouter>
						<ScrollToTop />
						<Base />
					</Router.BrowserRouter>
				</PersistGate>
			</Provider>
		);
	}
}

export const App = Component;