class Type {

	public static UNASSIGNED = new Type(-1, "unassigned");
	public static ALL_AGENTS = new Type(0, "all agents");

	private constructor(
		public readonly id: number,
		public readonly label: string,
	) {
	}

	public get unassigned() {
		return this === Type.UNASSIGNED;
	}

	public get allAgents() {
		return this === Type.ALL_AGENTS;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(label: string) {
		return this.values().find(value => value.label === label);
	}

}

export { Type as AgentSearchSelectionType };