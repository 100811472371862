import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";

import * as Icons from "react-feather";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";

interface OwnProps {
	campaignSubscriptionStatus: CampaignSubscriptionStatus,
	onChange: (campaignSubscriptionStatus: CampaignSubscriptionStatus) => void,
}

type Props = OwnProps & Mui.WithStyles<typeof styles>;

interface State {
	anchorElement: HTMLElement | null
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			anchorElement: null,
		};
	}

	private handleStatusClick(anchorElement: HTMLElement) {
		this.setState({anchorElement});
	}

	private handleActive() {
		const { campaignSubscriptionStatus } = this.props;
		if (campaignSubscriptionStatus === CampaignSubscriptionStatus.ACTIVE) {
			this.props.onChange(CampaignSubscriptionStatus.INACTIVE);
		} else {
			this.props.onChange(CampaignSubscriptionStatus.ACTIVE);
		}
		this.setState({anchorElement: null});
	}

	private handleRemove() {
		if (window.confirm("Delete this subscription?")) {
			this.setState({anchorElement: null});
			this.props.onChange(CampaignSubscriptionStatus.DELETED)
		}
	}

	public render() {
		const { campaignSubscriptionStatus, classes } = this.props;
		return (
			<>
				<Mui.Button 
					variant="text"
					aria-controls="simple-menu1"
					aria-haspopup="true"
					onClick={(event) => this.handleStatusClick(event.currentTarget)}
					style={{padding: 0, textTransform: "capitalize"}}
					>
						{campaignSubscriptionStatus.label}
						<Icons.ChevronDown size={16} className={classes.actionsIcon} />
				</Mui.Button>
				<Mui.Menu
					id="simple-menu2"
					anchorEl={this.state.anchorElement}
					open={Boolean(this.state.anchorElement)}
					onClose={() => this.setState({anchorElement: null})}
				>
					<Mui.MenuItem onClick={() => this.handleActive()}>
						{campaignSubscriptionStatus === CampaignSubscriptionStatus.ACTIVE &&
							<>Deactivate</>
						}
						{campaignSubscriptionStatus === CampaignSubscriptionStatus.INACTIVE && 
							<>Activate</>
						}
					</Mui.MenuItem>
					<Mui.MenuItem onClick={() => this.handleRemove()}>
						Remove
					</Mui.MenuItem>
				</Mui.Menu>
			</>
		);
	}
}

export const StatusMenu = Mui.withStyles(styles)(Component);