import React from "react";
import { Table, Sort } from "component/shared/table";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { Lead } from "model/lead";
import { urls } from "routes/urls";
import { styles } from "./style";
import * as env from "shared/env";
import { LeadAvatar } from "component/shared/lead-avatar";
import { LeadStatus } from "type/lead-status";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { updateLead } from "redux/slice/leads";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Select } from "component/shared/select";
import { setLeadsPageFilters, updateLeadsPageSort, updateLeadsPagePageSize, updateLeadsPagePage, updateLeadsPageColumns } from "redux/slice/leads-page"
import { getLeadsPageFilters, getLeadsPageSort, getLeadsPagePageSize, getLeadsPagePage, getLeadsPageColumns, getActiveLeads } from "redux/selector";
import { Tag } from "./tag";
import { Bookmark } from "component/shared/bookmark";
import { numberedDateFormatter } from "shared/date-formatter";
import { LeadColumnType } from "type/lead-column";
import { Type } from "./type";
import { User } from "model/user";
import { AddLeadDialog } from "component/shared/add-lead-dialog";
import { BulkActions } from "./bulk-actions";
import { DeleteLeadDialog } from "./deleteDialog";
import { formatPhoneNumber } from "shared/phone-number-formatter";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { Ranking } from "component/shared/ranking";
import { BulkDelete } from "./bulk-delete";
import { calculateLeadRankSize } from "shared/lead-rank-size";


const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const user = ownProps.user;
	let columnTypes = user.permissions.crm ? getLeadsPageColumns(state) : [];
	if (!columnTypes.length) {
		columnTypes.push(LeadColumnType.AVATAR);
		columnTypes.push(LeadColumnType.LABEL);
		if (!user.permissions.crm) {
			columnTypes.push(LeadColumnType.STATUS);
		}
		if (!user.permissions.crm) {
			columnTypes.push(LeadColumnType.CREATED_ON);
		}
		if (user.permissions.crm) {
			columnTypes.push(LeadColumnType.LAST_ACTIVE_ON);
		}
		columnTypes.push(LeadColumnType.SUBSCRIBED);
		if (user.permissions.crmPipeline) {
			columnTypes.push(LeadColumnType.PIPELINE);
		}
		if (user.permissions.crmTags) {
			columnTypes.push(LeadColumnType.TYPE);
		}
		if (user.permissions.crm && env.leadRanking) {
			columnTypes.push(LeadColumnType.RANKING);
		}
		columnTypes.push(LeadColumnType.SOURCE);
		if (user.permissions.crmTags) {
			columnTypes.push(LeadColumnType.BOOKMARKED);
		}
		if (user.permissions.crmTags) {
			columnTypes.push(LeadColumnType.TAGS);
		}
		if (user.permissions.broker) {
			columnTypes.push(LeadColumnType.AGENT);
		}
	}
	return {
		allActiveLeads: getActiveLeads(state),
		filters: getLeadsPageFilters(state),
		sort: getLeadsPageSort(state),
		pageSize: getLeadsPagePageSize(state), 
		page: getLeadsPagePage(state),
		columnTypes,
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateLead,
	setLeadsPageFilters,
	updateLeadsPageSort,
	updateLeadsPagePageSize, 
	updateLeadsPagePage, 
	updateLeadsPageColumns,
}, dispatch);

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>,
	WithMediaQuery
{}

interface OwnProps {
	user: User;
	leads: Lead[];
	onLeadCreated: (leadId: number) => void;
}

interface State {
	deleteLeadDialogIsOpen: boolean;
	leadToDelete: Lead | null;
	addLeadDialogIsOpen: boolean;
	selectedLeadIds: Set<number>;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			deleteLeadDialogIsOpen: false,
			leadToDelete: null,
			addLeadDialogIsOpen: false,
			selectedLeadIds: new Set(),
		};
	}
		
	private get columnOptions() {
		const { user } = this.props;
		const results: LeadColumnType[] = [];
		results.push(LeadColumnType.AVATAR);
		results.push(LeadColumnType.LABEL);
		results.push(LeadColumnType.CREATED_ON);
		if (user.permissions.leadLastActive) {
			results.push(LeadColumnType.LAST_ACTIVE_ON);
		}
		results.push(LeadColumnType.STATUS);
		results.push(LeadColumnType.SUBSCRIBED);
		if (user.permissions.crmPipeline) {
			results.push(LeadColumnType.PIPELINE);
		}
		if (user.permissions.crmTags) {
			results.push(LeadColumnType.TYPE);
		}
		if (user.permissions.crm && env.leadRanking) {
			results.push(LeadColumnType.RANKING);
		}
		results.push(LeadColumnType.SOURCE);
		if (user.permissions.crmTags) {
			results.push(LeadColumnType.BOOKMARKED);
		}
		if (user.permissions.broker) {
			results.push(LeadColumnType.AGENT);
		}
		if (user.permissions.crmTags) {
			results.push(LeadColumnType.TAGS);
		}
		return results;
	}

	private showDeleteLeadDialog = (lead: Lead) => {
		this.setState({
			leadToDelete: lead,
			deleteLeadDialogIsOpen: true,
			
		});
	};

	private hideDeleteLeadDialog = () =>
		this.setState({
			leadToDelete: null,
			deleteLeadDialogIsOpen: false,
		});

	private deleteLead = () => {
		const { leadToDelete } = this.state;
		if (leadToDelete) {
			this.props.updateLead({ lead: {
				...leadToDelete, 
				status: LeadStatus.DELETED,
			}})
		}
		this.hideDeleteLeadDialog();
	};

	private updateSort = (sort: Sort) => {
		this.props.updateLeadsPageSort(sort);
	}

	private updatePageSize = (pageSize: number) => {
		this.props.updateLeadsPagePageSize(pageSize);
	}

	private updatePage = (page: number) => {
		this.props.updateLeadsPagePage(page);
	}

	public render() {
		const {
			user,
			leads,
			allActiveLeads,
			onLeadCreated,
			sort,
			pageSize,
			page,
			columnTypes,
			filters,
			mediaQuery: isMobile,
		} = this.props;
		const { selectedLeadIds } = this.state;
		const leadRankData = calculateLeadRankSize([...allActiveLeads]);
		return (
			<>
				<AddLeadDialog
					open={this.state.addLeadDialogIsOpen}
					onClose={() => this.setState({ addLeadDialogIsOpen: false })}
					onLeadCreated={onLeadCreated}
					user={user}
				/>
				<DeleteLeadDialog
					deleteLeadDialogIsOpen={this.state.deleteLeadDialogIsOpen}
					hideDeleteLeadDialog={this.hideDeleteLeadDialog}
					deleteLead={this.deleteLead}
				/>
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Grid
							container
							item
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Mui.Grid item>
								<Mui.Grid container spacing={3}>
									<Mui.Grid item>
										<Mui.Button
											variant="contained"
											color="secondary"
											onClick={() => this.setState({ addLeadDialogIsOpen: true })}
										>
											<Mui.Typography>Add New</Mui.Typography>
										</Mui.Button>
									</Mui.Grid>
									{(user.permissions.crmBulkActions && !isMobile && !!selectedLeadIds.size) && (
										<Mui.Grid item>
											<BulkActions 
												leadIds={Array.from(selectedLeadIds)}
												user={user}
											/>
										</Mui.Grid>
									)}
									{(!!selectedLeadIds.size && !isMobile) && (
										<Mui.Grid item>
											<BulkDelete leadIds={Array.from(selectedLeadIds)}/>
										</Mui.Grid>
									)}
								</Mui.Grid>
							</Mui.Grid>
							{!isMobile && (
								<Mui.Grid item>
									{(user.permissions.crm ? (
										<Select
											multiple
											placeholder="Displayed Columns"
											value={columnTypes}
											options={this.columnOptions}
											valueExtractor={(type) => type}
											labelExtractor={(type) => type.label}
											numberVisible={7}
											optionRenderer={(type) => (
												<Mui.ListItemText primary={type.label} />
											)}
											onChange={(values) => {
												const types = values as LeadColumnType[];
												this.props.updateLeadsPageColumns(types);
											}}
										/>
									) : (
										<Select
											multiple
											placeholder="Lead Status"
											value={filters.status}
											options={LeadStatus.values()}
											valueExtractor={(type) => type.id}
											labelExtractor={(type) => type.label + " Leads"}
											optionRenderer={(type) => (
												<Mui.ListItemText primary={type.label} />
											)}
											onChange={(values) => {
												this.props.setLeadsPageFilters({
													...filters,
													status: values,
												});
											}}
										/>
									))}
								</Mui.Grid>
							)}
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						{isMobile ? (
							<Table
								items={allActiveLeads}
								columns={[
									{
										id: "avatar",
										hidden: !columnTypes.includes(LeadColumnType.AVATAR),
										width: 70,
										value: (lead) => {
											return (
												<div className={this.props.classes.centeredCell}>
													<LeadAvatar lead={lead} />
												</div>
											);
										},
									},
									{
										id: "label",
										hidden: !columnTypes.includes(LeadColumnType.LABEL),
										title: LeadColumnType.LABEL.label,
										sort: (lead1, lead2) => {
											return lead1.label.toLowerCase() < lead2.label.toLowerCase() ? -1 : 1;
										},
										value: (lead) => {
											const url = urls.lead(lead);
											return (
												<Mui.Grid container direction="column">
													<Mui.Link component={RouterLinkWrapper} to={url}>
														{lead.label}
													</Mui.Link>
													{lead.phone && (
														<Mui.Typography style={{ color: "#555", fontSize: 14 }}>
															{formatPhoneNumber(lead.phone)}
														</Mui.Typography>
													)}
												</Mui.Grid>
											);
										},
									},
								]}
								pageSize={pageSize}
								onPageSizeChange={(pageSize) => {
									this.updatePageSize(pageSize);
								}}
								page={page}
								onPageChange={(page) => {
									this.updatePage(page);
								}}
								sort={sort}
								onSortChange={(sort) => {
									this.updateSort(sort);
								}}
							/>
						) : (
							<Table
								items={leads}
								columns={[
									{
										id: "avatar",
										hidden: !columnTypes.includes(LeadColumnType.AVATAR),
										width: 70,
										value: (lead) => {
											return (
												<div className={this.props.classes.centeredCell}>
													<LeadAvatar lead={lead} />
												</div>
											);
										},
									},
									{
										id: "label",
										hidden: !columnTypes.includes(LeadColumnType.LABEL),
										title: LeadColumnType.LABEL.label,
										sort: (lead1, lead2) => {
											const leadOne = lead1.label.toLowerCase();
											const leadTwo = lead2.label.toLowerCase();
											return (leadOne < leadTwo ? -1 : (leadOne > leadTwo ? 1 : 0))
										},
										value: (lead) => {
											const url = urls.lead(lead);
											return (
												<Mui.Grid container direction="column">
													<Mui.Link component={RouterLinkWrapper} to={url}>
														{lead.label}
													</Mui.Link>
													{lead.phone && (
														<Mui.Typography style={{ color: "#555", fontSize: 14 }}>
															{formatPhoneNumber(lead.phone)}
														</Mui.Typography>
													)}
												</Mui.Grid>
											);
										},
									},
									{
										id: "bookmarked",
										hidden: !user.permissions.crmTags || !columnTypes.includes(LeadColumnType.BOOKMARKED),
										width: 70,
										value: (lead) => {
											return <Bookmark leadId={lead.id}/>;
										},
									},
									{
										id: "status",
										hidden: !columnTypes.includes(LeadColumnType.STATUS),
										title: LeadColumnType.STATUS.label,
										width: 70,
										sort: (lead1, lead2) => {
											return lead1.status.label.localeCompare(lead2.status.label);
										},
										value: (lead) => {
											return lead.status.label;
										},
									},
									{
										id: "pipeline",
										hidden: !user.permissions.crmPipeline || !columnTypes.includes(LeadColumnType.PIPELINE),
										title: LeadColumnType.PIPELINE.label,
										sort: (lead1, lead2) => {
											const pipeline1 = lead1.pipeline;
											const pipeline2 = lead2.pipeline;
											if (!pipeline1 && !pipeline2) {
												return 0;
											} else if (!pipeline1) {
												return 1;
											} else if (!pipeline2) {
												return -1;
											}
											return pipeline1.type.label < pipeline2.type.label ? -1 : 1;
										},
										value: (lead) => {
											if (!lead.pipeline) {
												return null;
											}
											return lead.pipeline.type.label;
										},
									},
									{
										id: "type",
										hidden: !user.permissions.crmTags || !columnTypes.includes(LeadColumnType.TYPE),
										title: LeadColumnType.TYPE.label,
										value: (lead) => {
											return <Type leadId={lead.id} />
										},
									},
									{
										id: "createdOn",
										hidden: !columnTypes.includes(LeadColumnType.CREATED_ON),
										title: LeadColumnType.CREATED_ON.label,
										sort: (lead1, lead2) => {
											return lead2.createdOn.valueOf() - lead1.createdOn.valueOf();
										},
										value: (lead) => {
											return numberedDateFormatter(lead.createdOn);
										},
									},
									{
										id: "lastActiveOn",
										hidden: !user.permissions.leadLastActive || !columnTypes.includes(LeadColumnType.LAST_ACTIVE_ON),
										title: LeadColumnType.LAST_ACTIVE_ON.label,
										sort: (lead1, lead2) => {
											const lastActiveOn1 = lead1.lastActiveOn;
											const lastActiveOn2 = lead2.lastActiveOn;
											if (!lastActiveOn1 && !lastActiveOn2) {
												return 0;
											} else if (!lastActiveOn1) {
												return 1;
											} else if (!lastActiveOn2) {
												return -1;
											}
											return lastActiveOn2.valueOf() - lastActiveOn1.valueOf();
										},
										value: (lead) => {
											if (!lead.lastActiveOn) {
												return null;
											}
											return numberedDateFormatter(lead.lastActiveOn);
										},
									},
									{
										id: "subscribed",
										hidden: !columnTypes.includes(LeadColumnType.SUBSCRIBED),
										title: LeadColumnType.SUBSCRIBED.label,
										width: 100,
										sort: (lead1, lead2) => {
											return (lead2.subscribed ? 1 : 0) - (lead1.subscribed ? 1 : 0);
										},
										value: (lead) => {
											if (!lead.subscribed) {
												return null;
											}
											return (
												<FeatherIcon>
													<Icons.Check />
												</FeatherIcon>
											);
										},
									},
									{
										id: "rating",
										hidden: !columnTypes.includes(LeadColumnType.RANKING),
										title: LeadColumnType.RANKING.label,
										sort: (lead1, lead2) => {
											return (lead1.ranking || 0) > (lead2.ranking || 0) ? -1 : 1;
										},
										value: (lead) => {
											return <Ranking 
												leadId={lead.id} 
												sortedLeadsByRanking={leadRankData.sortedLeads} 
												ratingSize={leadRankData.ratingSize} 
											/>;
										},
									},
									{
										id: "source",
										hidden: !columnTypes.includes(LeadColumnType.SOURCE),
										title: LeadColumnType.SOURCE.label,
										sort: (lead1, lead2) => {
											const source1 = lead1.source;
											const source2 = lead2.source;
											if (!source1 && !source2) {
												return 0;
											} else if (!source1) {
												return 1;
											} else if (!source2) {
												return -1;
											}
											return source1 < source2 ? -1 : 1;
										},
										value: (lead) => {
											return lead.source;
										},
									},
									{
										id: "agent",
										hidden: !user.permissions.broker || !columnTypes.includes(LeadColumnType.AGENT),
										title: LeadColumnType.AGENT.label,
										sort: (lead1, lead2) => {
											const agent1 = lead1.agent;
											const agent2 = lead2.agent;
											if (!agent1 && !agent2) {
												return 0;
											} else if (!agent1) {
												return 1;
											} else if (!agent2) {
												return -1;
											}
											return agent1.label.toLowerCase() < agent2.label.toLowerCase() ? -1 : 1;
										},
										value: (lead) => {
											const { agent } = lead;
											if (!agent) {
												return null;
											}
											return (
												<Mui.Link
													component={RouterLinkWrapper}
													to={urls.settingsAgent(agent)}
												>
													{agent.label}
												</Mui.Link>
											);
										},
									},
									{
										id: "tags",
										hidden: !user.permissions.crmTags || !columnTypes.includes(LeadColumnType.TAGS),
										title: LeadColumnType.TAGS.label,
										getProps: () => {
											return {
												style: {
													flexFlow: "wrap",
													justifyContent: "flex-start",
													alignItems: "center",
												},
											};
										},
										value: (lead) => {
											return (
												<Tag leadId={lead.id}/>
											);
										},
									},
									{
										id: "delete",
										width: 70,
										showOnHover: true,
										value: (lead) => {
											return (
												<Mui.IconButton onClick={() => this.showDeleteLeadDialog(lead)}>
													<FeatherIcon>
														<Icons.Trash />
													</FeatherIcon>
												</Mui.IconButton>
											);
										},
									},
								]}
								pageSize={pageSize}
								onPageSizeChange={(pageSize) => {
									this.updatePageSize(pageSize);
								}}
								page={page}
								onPageChange={(page) => {
									this.updatePage(page);
								}}
								sort={sort}
								onSortChange={(sort) => {
									this.updateSort(sort);
								}}
								onSelect={(leads) => {
									leads.forEach((lead) => selectedLeadIds.add(lead.id));
									this.setState({ selectedLeadIds });
								}}
								onUnselect={(leads) => {
									leads.forEach((lead) => selectedLeadIds.delete(lead.id));
									this.setState({ selectedLeadIds });
								}}
								isSelected={(lead) => selectedLeadIds.has(lead.id)}
							/>
						)}
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const LeadTable = withMediaQuery("(max-width:600px)")(
	Mui.withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(Component),
	),
);