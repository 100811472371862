import React from "react";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import { LeadRanking } from "type/lead-ranking";
import { Stars } from "./stars";
import { Lead } from "model/lead";

interface OwnProps {
	leadId: number;
	sortedLeadsByRanking: Lead[]; //must 
	ratingSize: number;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const { leadId, sortedLeadsByRanking, ratingSize } = ownProps;
    let ranking = LeadRanking.NO_RANKING;
    let rating = 0;
    for (let i = 0; i < sortedLeadsByRanking.length; i++) {
        const lead = sortedLeadsByRanking[i];
        if (lead.ranking && lead.ranking > 0) {
            rating++;
            if (lead.id === leadId) {
                if (rating > (ratingSize / 3) * 2) {
                    ranking = LeadRanking.LOW;
                } else if (rating > ratingSize / 3) {
                    ranking = LeadRanking.MEDIUM;
                } else {
                    ranking = LeadRanking.HIGH;
                }
                break;
            }
        }
    }
    return {
        leadRanking: ranking,
    };
};

interface Props extends ReturnType<typeof mapStateToProps> {
	leadId: number;
}

interface OwnProps {
	leadId: number;
}

class Component extends React.Component<Props> {
	public render() {
		const { leadRanking } = this.props;
		return <Stars leadRanking={leadRanking} />;
	}
}

export const Ranking = connect(mapStateToProps)(Component);