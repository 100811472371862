/* eslint-disable no-console */
import { objectToById } from "redux/normalize";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { SavedSearchResultTotalResource } from "api/resource/saved-search-result-total";

export interface SavedSearchResultTotalsState {
	byId: {
		[key: number]: SavedSearchResultTotalResource;
	};
};

const initialState: SavedSearchResultTotalsState = {
	byId: {},
};

const fetchSavedSearchResultTotal = createAsyncThunk("savedSearchResultTotals/fetchSavedSearchResultTotals", async (payload: {savedSearchId: number}, thunkAPI) => {
	const result = api.getSavedSearchResultTotal(payload.savedSearchId);
	result
		.then((savedSearchResultTotal) => thunkAPI.dispatch(savedSearchResultTotalLoaded(savedSearchResultTotal)))
		.catch(error => console.error(error));
	return result;
});

export const savedSearchResultTotalsSlice = createSlice({
	name: "savedSearchResultTotals",
	initialState,
	reducers: {
		savedSearchResultTotalLoaded: (state, action: PayloadAction<any>) => {
			const byId = objectToById(action.payload, "id");
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, () => {
			return {...initialState};
		});
	},
});

export const { savedSearchResultTotalLoaded } = savedSearchResultTotalsSlice.actions;
export { fetchSavedSearchResultTotal };
