import React from "react";
import * as Mui from "@material-ui/core";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { getUserUnsafe } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { InputLayout } from "component/shared/input-Layout";
import { KestrelVersion } from "type/kestrel-version";
import { Integration } from "model/integration";

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface OwnProps {
	value: KestrelVersion;
	onChange: (value: KestrelVersion) => void;
	wixIntegration?: Integration
}

interface Props extends 
	ReturnType<typeof mapStateToProps>, 
	Mui.WithStyles<typeof styles>,
	OwnProps {
	}

class Component extends React.Component<Props> {

	private hideOption(kestrelVersion: KestrelVersion) {
		const { value, user } = this.props;
		let result = false;
		if (user && user.permissions.kestrelAll) {
			result = value !== KestrelVersion.DETAIL && kestrelVersion.detail;
		} else {
			result = kestrelVersion.all;
		}
		return result;
	};
	
	public render() {
		const { value, onChange, classes, wixIntegration } = this.props;	
		return (
			<InputLayout>
				{!wixIntegration ? (
					<Mui.FormControl fullWidth variant="outlined">
						<Mui.InputLabel>
							Layout Version
						</Mui.InputLabel>
						<Mui.Select 
							label="Layout Version"
							value={value.id}
							onChange={(event) => onChange(KestrelVersion.getByIdOrDefault(event.target.value as number))}
						>
								{KestrelVersion.valuesSorted().map((type) => (
									<Mui.MenuItem
										key={type.id} 
										value={type.id}
										className={this.hideOption(type) ? classes.hiddenMuiMenuItem : undefined}
									>
										{type.label}
									</Mui.MenuItem>
								))}
						</Mui.Select>
					</Mui.FormControl>
					) : 
				(
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid item>
							<Mui.InputLabel>Layout Version</Mui.InputLabel>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Typography>v10</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				)}
			</InputLayout>
		);
	}
}

export const VersionDropdown = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
