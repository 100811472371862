import React from "react";
import * as Mui from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";

type Props = TextFieldProps;

interface State {
	copied: boolean;
}

class Component extends React.Component<Props, State> {

	private input = React.createRef<HTMLInputElement>();

	public constructor(props: Props) {
		super(props);
		this.state = {
			copied: false,
		};
	}

	public render() {
		const { copied } = this.state;
		return (
			<Mui.TextField
				{...this.props}
				inputRef={this.input}
				onFocus={() => this.select()}
				style={{
					backgroundColor: "#f5f5f5",
				}}
				InputProps={{
					...this.props.InputProps,
					endAdornment: (
						<Mui.Tooltip
							placement="left"
							title={copied ? "Copied!" : "Copy to clipboard"}
							disableFocusListener
						>
							<Mui.IconButton
								onClick={() => this.copy()}
							>
								<FeatherIcon fontSize="small">
									<Icons.Copy />
								</FeatherIcon>
							</Mui.IconButton>
						</Mui.Tooltip>
					),
				}}
			/>
		);
	}

	private select() {
		if (this.input.current) {
			this.input.current.select();
		}
	}

	private copy() {
		this.select();
		document.execCommand("copy");
		this.setState({ copied: true });
		setTimeout(() => {
			this.setState({ copied: false });
		}, 3000);
		const selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
		}
	}

}

export const CopyField = Component;