import React from "react";
import * as Mui from "@material-ui/core";
import Fuse from "fuse.js";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { DashboardLayout } from "component/layout/dashboard";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { Agent } from "model/agent";
import { getAgents } from "redux/selector";
import { RootState } from "redux/store";
import * as env from "shared/env";
import { PageProps } from "shared/page-props";
import { AgentTable } from "./agent-table";
import { styles } from "./style";

interface Props
	extends ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		PageProps,
		Router.RouteComponentProps,
		Mui.WithStyles<typeof styles> {}

interface State {
	term: string;
}

const mapStateToProps = (state: RootState) => {
	const agents = getAgents(state);
	const options: Fuse.IFuseOptions<Agent> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: ["id", "firstName", "lastName", "email"],
	};
	return {
		agents,
		fuse: new Fuse(agents, options),
		loading: state.agents.loading,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({}, dispatch);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
		};
	}

	public render() {
		const { classes, fuse, loading, user } = this.props;
		let { agents } = this.props;
		const { term } = this.state;
		const maxForTeams = user.permissions.broker && user.permissions.crm;
		const title = maxForTeams ? "Manage Team" : "Manage Agents";
		if (term) {
			agents = fuse.search(term).map((result) => result.item);
		}
		const placeholder = maxForTeams ? "Search for team member" : "Search for agent"
		return (
			<DashboardLayout
				permitted={user && user.permissions.broker}
				title={title}
				header={
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item xs={3}>
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.Settings />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Hidden xsDown>
							<Mui.Grid item xs={6}>
								<Mui.InputBase
									placeholder={placeholder}
									fullWidth
									onChange={(event) => {
										this.setState({
											term: event.target.value,
										});
									}}
									value={term}
									className={classes.searchBar}
								/>
							</Mui.Grid>
							<Mui.Grid item xs={3} className={classes.filter}>
								<Mui.Button
									className="ihf-exportagents"
									variant="contained"
									color="secondary"
									style={{ marginLeft: "auto" }}
									href={env.coldFusionUrl + "/idx/admin/agent_export.cfm"}
								>
									Export
								</Mui.Button>
							</Mui.Grid>
						</Mui.Hidden>
					</Mui.Grid>
				}
			>
					<Mui.Grid container direction="column" spacing={3}>
						{loading ? (
							<Mui.Grid item>
								<LoadingIndicator />
							</Mui.Grid>
						) : (
							<Mui.Grid container direction="column" spacing={2}>
								<Mui.Hidden smUp>
									<Mui.Grid item container justifyContent="space-between" alignItems="center">
										<Mui.Grid item xs={5}>
											<Mui.InputBase
												placeholder="Search for lead"
												fullWidth
												onChange={(event) => {
													this.setState({
														term: event.target.value,
													});
												}}
												className={classes.searchBar}
												style={{ border: "1px solid gray" }}
											/>
										</Mui.Grid>
										{user.permissions.crm && (
											<Mui.Grid item xs={3} className={classes.filter}>
												<Mui.Button
													className="ihf-exportagents"
													variant="contained"
													color="secondary"
													style={{ marginLeft: "auto" }}
													href={env.coldFusionUrl + "/idx/admin/agent_export.cfm"}
												>
													Export
												</Mui.Button>
											</Mui.Grid>
										)}
									</Mui.Grid>
								</Mui.Hidden>
								<Mui.Grid item>
									<AgentTable agents={agents} maxForTeams={maxForTeams}/>
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const SettingsAgentsPage = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
