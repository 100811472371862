class Type {
	public static LIST = new Type("list", "List");
	public static GRID = new Type("grid", "Grid");

	private constructor(
		public readonly id: string,
		public readonly label: string
	) {}

	public static get default(): Type {
		return this.LIST;
	}

	public get grid() {
		return this === Type.GRID;
	}

	public get list() {
		return this === Type.LIST;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => {
			return value.id === id;
		});
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as MarketColumnDisplayType };