import React from "react";
import * as env from "shared/env";
import * as credentials from "shared/credentials";
import { MockIframe } from "../mock";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { getUserUnsafe } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

interface Props extends 
	ReturnType<typeof mapStateToProps> {
	url: string;
}

interface State {
	loading: boolean;
}

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { loading: true };
	}
	
	public render() {
		const { url, user } = this.props;
		const creds = credentials.get();
		if (!env.live) {
			return <MockIframe />;
		}
		if (!creds) {
			throw new Error();
		}
		const { username, password } = creds;
		let frameUrl = env.coldFusionUrl + "/idx";
		if (!user) {
			return null;
		}
		if (user.admin) {
			frameUrl += "/admin";
		} else {
			frameUrl += "/agentlogin";
		}
		frameUrl += url;
		frameUrl += frameUrl.includes("?") ? "&" : "?";
		frameUrl += "username=" + encodeURIComponent(username) + 
			"&password=" + encodeURIComponent(password);
		if (!user.admin) {
			frameUrl += "&ClientID=" + user.clientId;
		}
		frameUrl += "&framed=true";
		return (
			<>
				<iframe
					src={frameUrl}
					title="iframe"
					onLoad={() => this.setState({ loading: false })}
				/>
				{this.state.loading && <LoadingIndicator />}
			</>
		);
	}
}

export const ColdFusionIframe = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Component);
