import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { DashboardLayout } from "component/layout/dashboard";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { styles } from "./style";
import { bindActionCreators, Dispatch } from "redux";
import { User } from "model/user";
import { saveUser } from "redux/slice/authentication";
import { updateAgents } from "redux/slice/agents";
import { RootState } from "redux/store";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import { AgentSortOrderType } from "type/agent-sort-order";
import { Agent } from "model/agent";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "./card";
import { deepCompare } from "shared/deep-compare";
import { getAgentsByCustomSort } from "redux/selector";
interface Props 
extends PageProps, Mui.WithStyles<typeof styles>, 
    Router.RouteComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
        user: User,
}

interface State {
    sendAgentsLeadTextNotifications: boolean;
    hideAgentSelector: boolean;
    saving: boolean;
    logoImageSource: "upload" | "url";
    logoUrl: string;
    openSnackbar: boolean;
    snackBarMessage: string;
    snackbarSeverity?: Color;
    agentSortOrder: AgentSortOrderType;
    agentOrder: Agent[];

}

const mapStateToProps = (state: RootState) => {
    const agents = getAgentsByCustomSort(state);
    return {
        authLoading: state.authentication.loading,
        authError: state.authentication.error,
        agents,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
			updateAgents,
            saveUser,
        },
        dispatch
    );

class Component extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            saving: false,
            sendAgentsLeadTextNotifications: props.user.sendAgentsLeadTextNotifications || false,
            hideAgentSelector: props.user.hideAgentSelector || false,
            logoImageSource: "url",
            openSnackbar: false,
            logoUrl: props.user.controlPanelUrl || "",
            snackBarMessage: "",
            agentSortOrder: props.user.agentSortOrder, 
            agentOrder: [...props.agents],
        };
    }

    private onDragEnd = (result: any) => {
        const { destination, source, draggableId } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const items = Array.from(this.state.agentOrder);
        const [reordered] = items.splice(source.index, 1);
	
        items.splice(destination.index, 0, reordered);
        this.setState({
            agentOrder: items,
        }, );
    };

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const { agents } = this.props;
		if (!deepCompare(prevProps.agents, agents) ) {
			this.setState({agentOrder: agents});
		}
	}

    public save() {
        const { saveUser, updateAgents } = this.props;
        const { agentSortOrder, agentOrder } = this.state;
        const updateUser = {
            ...this.props.user,
            sendAgentsLeadTextNotifications: this.state.sendAgentsLeadTextNotifications,
            hideAgentSelector: this.state.hideAgentSelector,
            controlPanelUrl: this.state.logoUrl,
            agentSortOrder: this.state.agentSortOrder,
        };
        saveUser({user: updateUser});

		const agentsToUpdate = agentOrder.map(agent => {
			const { ...agentData } = agent;
			agentData.sortOrder = undefined;
			return agentData;
		});
		updateAgents({agents: agentsToUpdate});
        this.setState({ saving: true });
    }

    public render() {
        const { authError, authLoading, user, agents } = this.props;
        const {
            sendAgentsLeadTextNotifications,
            hideAgentSelector,
            saving,
            logoImageSource,
            logoUrl,
            openSnackbar,
            snackBarMessage,
            snackbarSeverity,
            agentSortOrder,
            agentOrder
        } = this.state;
        const title = "Agent/Office Basic Settings";

        return (
			<DashboardLayout
				permitted={user && user.permissions.broker}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
                <FullPageCard>
					<Mui.Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} lg={8} xl={6}>
						<Mui.Grid item>
							<YesNoRadioField
								label="Send Agents Lead Notifications via Text"
								value={sendAgentsLeadTextNotifications}
								default={false}
								onChange={(value) =>
									this.setState({
										sendAgentsLeadTextNotifications: value,
									})
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Hide the Agent Selector on Lead Capture Forms"
								value={hideAgentSelector}
								default={false}
								onChange={(value) =>
									this.setState({
										hideAgentSelector: value,
									})
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthDropdown<"upload" | "url">
								label="Control Panel Logo"
								onChange={(value) =>
									this.setState({
										logoImageSource: value,
									})
								}
								value={logoImageSource}
							>
								<Mui.MenuItem value="url">Image URL</Mui.MenuItem>
								<Mui.MenuItem value="upload">Upload Image</Mui.MenuItem>
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							{logoImageSource === "url" && (
								<Mui.TextField
									fullWidth
									inputProps={{ maxLength: 100 }}
									label="Enter Image URL"
									onChange={(event) =>
										this.setState({ logoUrl: event.target.value })
									}
									placeholder="Enter Image URL (include http://)"
									value={logoUrl}
									variant="outlined"
								/>
							)}
							{logoImageSource === "upload" && (
								<PhotoUploadDialogButton
									multiple={false}
									callback={(uploads) => {
										const upload = uploads.find((upload) => upload.url);
										if (upload && upload.url) {
											this.setState({
												logoImageSource: "url",
												logoUrl: upload ? upload.url : "",
												openSnackbar: true,
												snackBarMessage: "Upload Successful",
												snackbarSeverity: "success",
											})
										} else {
											this.setState({
												openSnackbar: true,
												snackBarMessage: "Error",
												snackbarSeverity: "error",
											})
										}
									}}
									label="Upload Image"
									style={{ marginBottom: 4, marginTop: 8 }}
									resourceType="image"
								/>
							)}
						</Mui.Grid>
						{user.kestrelVersion.all && (
							<Mui.Grid item>
								<FullWidthDropdown<string>
									label="Agent List Order Options for Agent Roster"
									onChange={(value) =>
										this.setState({
											agentSortOrder: AgentSortOrderType.getByIdOrDefault(value)
										})
									}
									value={agentSortOrder.id}
								>
									{AgentSortOrderType.values().map((value) => (
										<Mui.MenuItem key={value.id} value={value.id}>
											{value.label}
										</Mui.MenuItem>
									))}
								</FullWidthDropdown>
							</Mui.Grid>
						)}
						
						{agentSortOrder.custom && user.kestrelVersion.all && (
							<Mui.Grid item>
								<DragDropContext onDragEnd={this.onDragEnd}>    
									<Droppable droppableId="droppable">
										{(provided) => (
											<Mui.Grid container direction="column" spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
												{agentOrder.map((agent: Agent, index) => (
													<Draggable key={agent.id} draggableId={agent.id.toString()} index={index}>
														{(provided) => (
															<Mui.Grid item
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															> 
																<Card 
																	position={index + 1} 
																	agent={agent} 
																	onDelete={() => {
																		const results = [...agents];
																		results.splice(index, 1);
																		this.setState({agentOrder: results})
																	}}
																/>
															</Mui.Grid>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</Mui.Grid>
										)}
									</Droppable>
								</DragDropContext>
							</Mui.Grid>
						)}
						<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
							<Mui.Grid item>
								<Mui.Button
									color="secondary"
									disabled={saving}
									onClick={() => this.save()}
									style={{ marginRight: 32 }}
									variant="contained"
								>
									Save Changes
								</Mui.Button>
								<Mui.Button
									onClick={() =>
										this.setState({
											saving: this.state.saving,
										})
									}
									variant="contained"
								>
									Cancel
								</Mui.Button>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Snackbar
							open={saving && !authLoading && !authError}
							message="Settings saved"
							autoHideDuration={6000}
							onClose={() => this.setState({ saving: false })}
							action={
								<Mui.IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={() => this.setState({ saving: false })}
								>
									<Icons.X fontSize="small" />
								</Mui.IconButton>
							}
						>
							<MuiAlert severity="info">
								<Mui.Typography>Settings saved</Mui.Typography>
							</MuiAlert>
						</Mui.Snackbar>
                    </Mui.Grid>
                </FullPageCard>
            </DashboardLayout>
        );
    }
}

export const SettingsBrokerPage = (Mui.withStyles(styles)(
    Router.withRouter(
        connect( mapStateToProps, mapDispatchToProps)(Component)
    )
));
