import React from "react"
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import { Market } from "model/market";
import { connect } from "react-redux";
import { getUserUnsafe } from "redux/selector";
import { updateMarket } from "redux/slice/markets";
import { updateMarketReport } from "redux/slice/market-reports";
import { SaveButton } from "component/shared/save-button";
import { RootState } from "redux/store";
import { styles } from "./style";
import { Select } from "component/shared/select";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		market: Market;
	}

interface State {
	description: string, 
	showOnIndex: boolean, 
	sortOrder: string,
	saved: boolean,
	photoSource: "photoUrl" | "uploadPhoto",
	photoUrl: string,
	openSnackbar: boolean,
	snackbarMessage: string,
	snackbarSeverity: string,
	submitted: boolean,
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	updateMarketReport,
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.markets.loading,
		user: getUserUnsafe(state)
	};
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		
		super(props);
		this.state = {
			description: this.props.market.description ? this.props.market.description : "",
			showOnIndex: !!this.props.market.showOnIndex,
			sortOrder: this.props.market.sortOrder ? this.props.market.sortOrder.toString() : "",
			saved: false,
			photoSource: this.props.market.photoUrl ? "photoUrl" : "uploadPhoto",
			photoUrl: this.props.market.photoUrl ? this.props.market.photoUrl : "",
			openSnackbar: false,
			snackbarMessage: "",
			snackbarSeverity: "",
			submitted: false,

		}
	}

	private save() {
		const { market } = this.props;
		const { description, showOnIndex, sortOrder, photoUrl } = this.state;
		if (market.listingReport) {
			this.props.updateMarket({ market: {
				...market,
				description: description,
				sortOrder: !!sortOrder ? parseInt(sortOrder) : undefined,
				showOnIndex: showOnIndex,
				photoUrl: photoUrl
			}});
		}
	}

	render() {
		const { loading, classes, user } = this.props;
		const { description, showOnIndex, sortOrder, saved, photoSource, photoUrl } = this.state;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item >
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Short Description</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								variant="outlined"
								fullWidth
								size="small"
								value={description}
								onChange={(event) => {
									this.setState({description: event.target.value})
								}}
							/>
							<Mui.Typography>(appears on Markets Index page)</Mui.Typography>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Sort Order</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
						<Mui.TextField
								variant="outlined"
								type="number"
								fullWidth
								size="small"
								value={sortOrder}
								onChange={(event) => {
									this.setState({sortOrder: event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.FormControlLabel
						control={
							<Mui.Switch 
								checked={showOnIndex} 
								onChange={() => this.setState({showOnIndex: !showOnIndex})} 
								name="link" 
							/>
						}
						label="Display on Market Index Result Page"
					/>
				</Mui.Grid>
				{user?.kestrelVersion.all && (
					<>
						<Mui.Grid item>
							<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
								<Mui.Grid item xs={2}>
									<Mui.Typography>Upload Photo</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									<Select
										fullWidth
										labelExtractor={(type) => type.label}
										margin="dense"
										onChange={(value) => {
											
											this.setState({ photoSource: value });
										}}
										options={[
											{
												id: "photoUrl",
												label: "Photo URL",
											},
											{
												id: "uploadPhoto",
												label: "Upload Photo",
											},
										]}
										optionRenderer={(type) => (
											<Mui.ListItemText primary={type.label} key={type.id} />
										)}
										label="Market Photo"
										value={photoSource}
										valueExtractor={(type) => type.id}
										variant="outlined"
									/>
									<Mui.Typography>(Upload a thumbnail photo for the communities page.)</Mui.Typography>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
								<Mui.Grid item xs={2}>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									{photoSource === "photoUrl" && (
										<Mui.TextField
											fullWidth
											inputProps={{ maxLength: 100 }}
											label="Enter Photo URL"
											margin="dense"
											onChange={(event) => {
												this.setState({ photoUrl: event.target.value });
											}}
											placeholder="Enter Photo URL (include http://)"
											value={photoUrl}
											variant="outlined"
										/>
									)}
									{photoSource === "uploadPhoto" && (
										<PhotoUploadDialogButton
											multiple={false}
											callback={(uploads) => {
												const upload = uploads.find((upload) => upload.url);
												if (upload && upload.url) {										
													this.setState({
														photoSource: "photoUrl",
														photoUrl: upload.url,
														openSnackbar: true,
														snackbarMessage: "Upload Successful",
														snackbarSeverity: "success",
													});
													
												} else {
													this.setState({
														openSnackbar: true,
														snackbarMessage: "Error",
														snackbarSeverity: "error",
													});
												}
											}}
											label="Upload Market Photo"
											style={{ marginBottom: 4, marginTop: 8 }}
											resourceType="image"
										/>
									)}
									{photoUrl && (
										<Mui.Grid item>
											<img alt="" src={photoUrl} className={classes.mainImage} />
										</Mui.Grid>
									)}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</>
				)}
				<Mui.Grid item className={classes.saveRow}>
					<SaveButton 
						loading={!!loading && saved} 
						label={{
							primary: "Save",
							inProgress: "Saving...",
							completed: "Saved",
						}}
						snackBar={true}
						onClick={() => {
							this.setState({saved: true});
							this.save();
						}}
						onReset={() => this.setState({saved: false})}
					/>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const GeneralContent = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));