import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { JavaIframe } from "component/shared/iframe/java";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { styles } from "./style";
import { marketSettingsDev } from "shared/env";
import { getmarketSettings } from "redux/selector";
import { Content } from "./content";

interface Props
extends PageProps, Mui.WithStyles<typeof styles>,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
}
interface State {}

const mapStateToProps = (state: RootState) => {
	const marketSettings = getmarketSettings(state);
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
		loading: state.marketSettings.loading,
		marketSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{},
		dispatch
	);

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props)
		this.state = {}
	}

	public render() {
		const { user } = this.props;
		const title = "Market Settings";
		return (
			<>	
				{!marketSettingsDev && (
					<DashboardLayout
						permitted={user && user.permissions.markets}
						title={title}
						header={
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.MapPin />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						}
						iframe={<JavaIframe url="/hotSheetSettings" />}
					/>
				)}
				{marketSettingsDev && (
					<DashboardLayout
						permitted={user && user.permissions.markets}
						title={title}
						header={
							<Mui.Typography variant="h1">
								<FeatherIcon>
									<Icons.MapPin />
								</FeatherIcon>
								{title}
							</Mui.Typography>
						}
					>
						<Content {...this.props} />
					</DashboardLayout>
				)}
			</>
		);
	}

}

export const MarketSettingsPage = Mui.withStyles(styles)(
	connect( mapStateToProps, mapDispatchToProps)(Component)
);