import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { 
	KeyboardDatePicker, 
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import * as Icons from "react-feather";
import moment, { Moment } from "moment";
import { Select } from "component/shared/select";
import { FeatherIcon } from "component/shared/feather-icon";
import { TaskType } from "type/task-type";
import { TaskStatus } from "type/task-status";
import { updateTask, createTask } from "redux/slice/tasks";
import * as yup from "yup";
import { validate } from "shared/yup";
import { getActiveLeads, getAgents, getLeadById, getUser } from "redux/selector";
import { Task } from "model/task";
import { Lead } from "model/lead";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { agentTasksDev } from "shared/env";
import { Agent } from "model/agent";
import { AgentTag } from "../agent-tag";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const { task } = ownProps;
	let leadLabel = "";

	if (task && task.leadId) {
		const lead = getLeadById(state, task.leadId);
		if (lead) {
			leadLabel = lead.label;
		}
	}

	return {
		leads: getActiveLeads(state),
		leadLabel,
		user: getUser(state),
		agents: getAgents(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateTask,
	createTask,
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: Function;
	task?: Task | null;
	lead?: Lead;
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	submitted: boolean;
	title: string;
	description: string;
	leadInput: string;
	leadId: number;
	agentId: number | undefined;
	taskTypeId: number;
	taskStatusTypeId: number;
	priority: boolean;
	startDate: Moment;
	startTime: Moment;
	dueDate: Moment | null;
	dueTime: Moment | null;
}

const initialState: State = {
	submitted: false,
	title: "",
	description: "",
	leadInput: "",
	leadId: 0,
	agentId: 0,
	taskTypeId: 0,
	taskStatusTypeId: 1,
	priority: false,
	startDate: moment(),
	startTime: moment(),
	dueDate: null,
	dueTime: null,
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}
	
	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState(initialState);
			const { task, lead, leadLabel, user } = this.props;
			if (task) {
				const { title, description, leadId, agentId, type, status, priority, startOn, endOn } = task;

				this.setState({
					submitted: false,
					title: title || "",
					description: description || "",
					leadId: leadId || 0,
					agentId: user.admin ? agentId || 0 : user.agentId,
					leadInput: leadLabel || "",
					taskTypeId: type ? type.id : 0,
					taskStatusTypeId: status.id || 1,
					priority: priority || false,
					startDate: startOn || moment(),
					startTime: startOn || moment(),
					dueDate: endOn || null,
					dueTime: endOn || null,
				})
			} else if (lead) {
				this.setState({ 
					leadId: lead.id,
					leadInput: lead.label,
				})
			}
		}
	}
	
	private combineDateAndTime = (date: Moment, time: Moment) => {
		return date.set({
			hour: time.get('hour'),
			minute: time.get('minute'),
			second: 0,
			millisecond: 0,
		})
	};

	private get startOn() {
		const { startDate, startTime } = this.state;
		return this.combineDateAndTime(startDate, startTime);
	}

	private get endOn() {
		const { dueDate, dueTime } = this.state;
		if (dueDate && dueTime) {
			return this.combineDateAndTime(dueDate, dueTime);
		}
		return null;
	}

	private onChangeStartDate = (date: Date | null) => {
		const startDate = moment(date!);
		this.setState({ startDate });
	}

	private onChangeStartTime = (date: Date | null) => {
		const startTime = moment(date!);
		this.setState({ startTime });
	}

	private onChangeDueDate = (date: Date | null) => {
		const dueDate = date ? moment(date) : null;
		this.setState({ dueDate });
	}

	private onChangeDueTime = (date: Date | null) => {
		const dueTime = date ? moment(date) : null;
		this.setState({ dueTime });
	}

	private validationSchema = {
		title: yup
			.string()
			.max(
				100,
				({max}) => `Title must be less than ${max} characters (currently ${this.state.title.length} characters).`,
			)
			.required("Title is required."),
		priority: yup.boolean(),
		startDate: yup
			.mixed()
			.required("Start date is required."),
		startTime: yup
			.mixed()
			.required("Start time is required."),
		dueDate: yup
			.mixed()
			.required("End date is required.")
			.test(
				"check-not-before-start-date",
				"Due date cannot be before start date.",
				(value) => {
					const date: Moment = value;
					if (date) {
						return date.format("YYYYMMDD") >= this.state.startDate.format("YYYYMMDD")
					} else {
						return true;
					}
				}
			),
		dueTime: yup
			.mixed()
			.required("End time is required."),
		endOn: yup
			.mixed()
			.test(
				"check-due-time",
				"Due date and time must be after start date and time.",
				(value) => {
					const date: Moment = value;
					if (date) {
						return date.isAfter(this.startOn)
					} else {
						return true
					}
				}
			)
			.required("Due date is required."),
		leadId: yup
			.number()
			.test(
				"check-lead-id",
				"Please select a valid lead.", 
				(id) => {
					if (this.state.leadInput && id === 0) {
						return false
					} else {
						return true
					}
				}
			),
		taskTypeId: yup
			.number()
			.moreThan(
                0,
                "Please select a task type."
            )
			.required("Task type is required."),
	};

	private submit = () => {
		const { title, description, leadId, agentId, taskTypeId, taskStatusTypeId, priority } = this.state;
		const { startOn, endOn } = this;
		const { task, user } = this.props;
		const type = TaskType.getById(taskTypeId);
		const status = TaskStatus.getById(taskStatusTypeId);
		this.setState({ submitted: true });

		const errors = validate(this.validationSchema, { ...this.state, startOn, endOn });
		if (!errors && endOn && status) {
			if (task && task.id) {
				this.props.updateTask({ task: {
					id: task.id,
					title, 
					description: description ? description : undefined, 
					leadId: leadId ? leadId : undefined,
					agentId: agentId ? agentId : undefined,
					creatorId: user.agentId,
					type, 
					status, 
					priority, 
					startOn,
					endOn,
					createdOn: task.createdOn,
					updatedOn: moment(),
				}})
			} else {
				this.props.createTask({ task: {
					title, 
					description: description ? description : undefined, 
					leadId: leadId ? leadId : undefined,
					agentId: user.admin ? agentId : user.agentId,
					creatorId: user.agentId,
					type, 
					status, 
					priority, 
					startOn,
					endOn,
				}})
			}
			this.props.onClose();
		}
		
	}

	private togglePriority(disabled: boolean) {
		const { priority } = this.state

		if (disabled) {
			return null
		} else {
			this.setState({ priority: !priority })
		}
	}

	private isAdmin(id: number | undefined) {
		const { agents } = this.props;
		if (id) {
			return agents.find(agent => agent.id === id)?.admin
		}
		return false;
	}
	  
	public render() {
		const { classes, open, onClose, leads, task, user, agents, leadLabel } = this.props;
		const {
			submitted,
			title,
			description,
			leadInput,
			leadId,
			taskTypeId,
			agentId,
			priority,
			startDate,
			startTime,
			dueDate,
			dueTime,
		} = this.state;
		const singleAgent = agents.length === 1;
		const { startOn, endOn } = this;
		const errors = validate(this.validationSchema, { ...this.state, startOn, endOn });
		let assigner;
		let disabled: boolean = false;
		if (task) {
			assigner = agents.find(agent => agent.id === task.creatorId);
			disabled = !user.admin && task.creatorId !== user.agentId;
		}

		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					{task ? disabled ? "Assigned Task" : "Edit Task" : "Add Task"}
				</Mui.DialogTitle>
				<Mui.DialogContent
					style={{
						padding: "8px 50px",
					}}
				>
					<Mui.Grid container spacing={1} direction="column">
						<Mui.Grid item xs={12}>
							<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
								<Mui.TextField
									required
									error={submitted && !!errors && !!errors.title}
									helperText={submitted && errors && errors.title}
									label="Title"
									placeholder="Enter task title here"
									value={!disabled ? title : task?.title || ""}
									onChange={event => this.setState({ title: event.target.value })}
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									InputLabelProps={{
										shrink: true,
										classes: {
											asterisk: classes.labelAsterisk,
										}
									}}
									fullWidth
									margin="dense"
									variant="outlined"
								/>
							</Mui.Tooltip>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} direction="column">
						<Mui.Grid item xs={12}>
							<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
								<Mui.TextField
									label="Description"
									placeholder="Enter task description here"
									value={!disabled ? description : task?.description || ""}
									onChange={event => this.setState({ description: event.target.value })}
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
									margin="dense"
									variant="outlined"
									multiline={true}
									minRows={5}
									maxRows={9}
								/>
							</Mui.Tooltip>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} direction="column">
						<Mui.Grid item xs={12}>
							<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
								<Mui.FormControl 
									fullWidth 
									margin="dense" 
									variant="outlined" 
									error={submitted && !!errors && !!errors.taskTypeId}
									required
									>
										<Select
											fullWidth
											variant="outlined"
											placeholder="Select a task type"
											label="Task Type"
											margin="dense"
											required
											value={!disabled ? taskTypeId : task?.type?.id || 0}
											options={TaskType.values()}
											valueExtractor={task => task.id}
											labelExtractor={task => task.name}
											optionRenderer={task => <Mui.ListItemText primary={task.name} />}
											onChange={value => this.setState({ taskTypeId: value })}
											error={submitted && !!errors && !!errors.taskTypeId}
											/>
									{submitted && !!errors && !!errors.taskTypeId && (
										<Mui.FormHelperText className={classes.errorMessage}>
											{errors.taskTypeId}
										</Mui.FormHelperText>
									)}
								</Mui.FormControl>
							</Mui.Tooltip>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid container spacing={1} direction="column">
						<Mui.Grid item xs={12}>
							{!disabled ? (
								<CustomAutocomplete
									options={!agentId || this.isAdmin(agentId) ? leads : leads.filter(lead => lead.agent?.id === agentId)}
									textInputHeight={true}
									label="Associated Lead"
									optionLabelExtractor={(option) => option.label}
									fullWidth
									margin="dense"
									placeholder="Enter lead here"
									onInputChange={(event, value, reason) => {
										if (reason !== "reset") {
											this.setState({
												leadInput: value,
												leadId: 0,
											});
										}
									}}
									onChange={(event, option) => {
										if (option && option.label) {
											this.setState({ 
												leadId: option.id as number, 
												leadInput: option.label,
												// Set agentId based on the selected lead's agent
												agentId: option.agent ? option.agent.id : 0,
											});		
										}
									}}
									error={submitted && !!errors && !!errors.leadId}
									helperText={submitted && errors && errors.leadId}
									FormHelperTextProps={{
										className: classes.errorMessage,
									}}
									inputValue={leadInput}
								/>
							):
							<Mui.Tooltip title={"Cannot edit tasks assigned to you by an Administrator."}>
								<Mui.TextField
									fullWidth
									margin="dense"
									variant="outlined"
									label="Associated Lead"
									value={leadLabel}
								/>
							</Mui.Tooltip>
							}
						</Mui.Grid>
					</Mui.Grid>
					{user.admin && agentTasksDev && !singleAgent && (
						<Mui.Grid container spacing={1} direction="column">
								<Mui.Tooltip title="The agent you choose will only give you associated leads that are owned by that agent.">
							<Mui.Grid item xs={12}>
									<Select
										fullWidth
										variant="outlined"
										margin="dense"
										placeholder="Unassigned"
										value={agentId}
										options={agents}
										valueExtractor={task => task.id}
										labelExtractor={(task) => task.name ? task.name : task.email}
										optionRenderer={task => <Mui.ListItemText primary={task.name ? task.name : task.email} />}
										onChange={value => {
											this.setState({ agentId: value, leadId: 0, leadInput: "" });
										}}
										label="Select Assignee"
									/>
							</Mui.Grid>
								</Mui.Tooltip>
						</Mui.Grid>
					)}
					<Mui.Grid container alignItems="flex-start" spacing={1}>
						<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
							<Mui.Grid item xs={6}>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											required
											maxDate={endOn || KeyboardDatePicker.defaultProps!.maxDate}
											error={submitted && errors && !!errors.startDate}
											helperText={submitted && errors && errors.startDate}
											invalidDateMessage="Invalid date format."
											autoOk
											disableToolbar
											variant="inline"
											inputVariant="outlined"
											FormHelperTextProps={{
												className: classes.errorMessage,
											}}
											InputProps={{
												className: classes.adornedEnd,
											}}
											InputLabelProps={{
												classes: {
													asterisk: classes.labelAsterisk,
												}
											}}
											format="MM/dd/yyyy"
											fullWidth
											margin="dense"
											label="Start Date "
											placeholder="mm/dd/yyyy"
											onChange={date => this.onChangeStartDate(date)}
											value={!disabled ? startDate : task?.startOn}
											KeyboardButtonProps={{
												"aria-label": "change date",
												size: "small",
											}}
											keyboardIcon={<Icons.Calendar />}
											views={["date"]}
											/>
									</MuiPickersUtilsProvider>
							</Mui.Grid>
						</Mui.Tooltip>
						<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
							<Mui.Grid item xs={6}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardTimePicker
										required
										error={submitted && errors && !!errors.startTime}
										helperText={submitted && errors && errors.startTime}
										autoOk
										variant="inline"
										inputVariant="outlined"
										invalidDateMessage="Invalid date format."
										FormHelperTextProps={{
											className: classes.errorMessage,
										}}
										InputProps={{
											className: classes.adornedEnd,
										}}
										InputLabelProps={{
											classes: {
												asterisk: classes.labelAsterisk,
											}
										}}
										format="hh:mm aa"
										ampm={true}
										fullWidth
										margin="dense"
										label="Start Time "
										placeholder="00:00 AM"
										mask="__:__ _M"
										onChange={time => this.onChangeStartTime(time)}
										value={!disabled ? startDate : task?.startOn}
										KeyboardButtonProps={{
											"aria-label": "change time",
											size: "small",
										}}
										keyboardIcon={<Icons.Clock />}
										views={["hours", "minutes"]}
										/>
								</MuiPickersUtilsProvider>
							</Mui.Grid>
						</Mui.Tooltip>
					</Mui.Grid>
					<Mui.Grid container alignItems="flex-start" spacing={1}>
						<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
							<Mui.Grid item xs={6}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										required
										minDate={this.startOn}
										error={submitted && errors && (!!errors.dueDate)}
										helperText={submitted && errors && (errors.dueDate)}
										invalidDateMessage="Invalid date format."
										autoOk
										disableToolbar
										variant="inline"
										inputVariant="outlined"
										FormHelperTextProps={{
											className: classes.errorMessage,
										}}
										InputProps={{
											className: classes.adornedEnd,
										}}
										InputLabelProps={{
											classes: {
												asterisk: classes.labelAsterisk,
											}
										}}
										format="MM/dd/yyyy"
										fullWidth
										margin="dense"
										label="Due Date"
										placeholder="mm/dd/yyyy"
										onChange={date => this.onChangeDueDate(date)}
										value={!disabled ? dueDate : task?.endOn}
										KeyboardButtonProps={{
											"aria-label": "change date",
											size: "small",
										}}
										keyboardIcon={<Icons.Calendar />}
										views={["date"]}
										/>
								</MuiPickersUtilsProvider>
							</Mui.Grid>
						</Mui.Tooltip>
						<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
							<Mui.Grid item xs={6}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardTimePicker
										required
										error={submitted && errors && (!!errors.dueTime || !!errors.endOn)}
										helperText={submitted && errors && (errors.dueTime || errors.endOn)}
										autoOk
										variant="inline"
										inputVariant="outlined"
										invalidDateMessage="Invalid date format."
										FormHelperTextProps={{
											className: classes.errorMessage,
										}}
										InputProps={{
											className: classes.adornedEnd,
										}}
										InputLabelProps={{
											classes: {
												asterisk: classes.labelAsterisk,
											}
										}}
										format="hh:mm aa"
										fullWidth
										margin="dense"
										label="Due Time"
										placeholder="00:00 AM"
										mask="__:__ _M"
										onChange={time => this.onChangeDueTime(time)}
										value={!disabled ? dueDate : task?.endOn}
										KeyboardButtonProps={{
											"aria-label": "change date",
											size: "small",
										}}
										keyboardIcon={<Icons.Clock />}
										views={["hours", "minutes"]}
										/>
								</MuiPickersUtilsProvider>
							</Mui.Grid>
						</Mui.Tooltip>
						<Mui.Grid container alignItems="center" wrap="nowrap" spacing={1} justifyContent="flex-end">
							<Mui.Grid item>
								Priority: 
							</Mui.Grid>
							<Mui.Tooltip title={disabled ? "Cannot edit tasks assigned to you by an Administrator." : ""}>
								<Mui.Grid item>
									<Mui.IconButton
										size="small"
										onClick={() => this.togglePriority(disabled)}
									>
										<FeatherIcon>
											<Icons.Flag color={priority ? "red" : "black"}/>
										</FeatherIcon>
									</Mui.IconButton>
								</Mui.Grid>
							</Mui.Tooltip>
						</Mui.Grid>
						<Mui.Grid container item style={{ marginTop: 16, marginBottom: 12 }}>
							{!disabled ? (
								<Mui.Grid container item xs={12}>
									<div style={{ flex: 1 }} />
									<Mui.Button
										className={classes.button}
										style={{ marginRight: 8 }}
										variant="contained"
										color="secondary"
										onClick={() => this.submit()}
										disabled={submitted && !!errors}
									>
										Save
									</Mui.Button>
									<Mui.Button
										className={classes.button}
										variant="outlined"
										color="secondary"
										onClick={() => onClose()}
									>
										Cancel
									</Mui.Button>
								</Mui.Grid>
							):
								<AgentTag
									prefix="Assigned by"
									agent={assigner}
									clickable={false}
									justifyContent="flex-end"
								/>
							}
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
		)
	}
}
export const AddEditTaskDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
