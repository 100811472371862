import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import * as Router from "react-router-dom";
import { styles } from "./style";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getLeads } from "redux/selector";
import { Item } from "./item";
import { getFuse } from "./items";
import { getUserUnsafe } from "redux/selector";
import { getActiveDripCampaigns } from "redux/selector";
import { getMarkets } from "redux/selector";
import { getAgents } from "redux/selector";
import { getOffices } from "redux/selector";
import { FeatherIcon } from "component/shared/feather-icon";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { Dialog } from "component/shared/dialog";
import { getViewType } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	const viewType = getViewType(state);
	const leads = getLeads(state);
	const campaigns = getActiveDripCampaigns(state);
	const markets = getMarkets(state);
	const agents = getAgents(state);
	const offices = getOffices(state);
	if (!user) {
		throw new Error();
	}
	const leadsPermitted = 
		(user.permissions.broker && user.permissions.leads && user.ownsLeads) || 
		(!user.permissions.broker && user.permissions.leads) || 
		(user.admin && user.permissions.broker && user.permissions.leads);
	const fuse = getFuse(
		user,
		viewType,
		leadsPermitted ? leads : [],
		user.admin ? campaigns : [],
		user.admin ? markets : [],
		user.admin ? agents : [],
		user.admin ? offices : [],
	);
	return {
		user,
		fuse,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps,
	Mui.WithStyles<typeof styles>
{
	open: boolean;
	onClose: Function;
}

interface State {
	term: string;
	selectedIndex: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
			selectedIndex: 0,
		};
	}

	public render() {
		const { open, onClose, classes, fuse, user } = this.props;
		const { selectedIndex, term } = this.state;
		const items = fuse.search(term, {
			limit: 6,
		}).map(result => result.item);
		const placeholder = "Enter a page, lead, or other search term.";
		return (
			<Dialog
				open={open}
				onClose={() => onClose()}
				fullWidth={true}
				maxWidth="sm"
				classes={{
					container: classes.container,
				}}
			>
				<Mui.DialogTitle disableTypography style={{backgroundColor: "initial"}}>
					<Mui.TextField
						autoFocus
						label={term && placeholder}
						placeholder={placeholder}
						value={term}
						style={{ width: "calc(100% - 36px)" }}
						InputProps={{
							startAdornment: (
								<Mui.InputAdornment position="start">
									<FeatherIcon>
										<Icons.Search />
									</FeatherIcon>
								</Mui.InputAdornment>
							),
						}}
						onChange={(event) => this.setState({
							term: event.target.value,
							selectedIndex: 0,
						})}
						onKeyDown={(event) => this.onKeyDown(event, items)}
					/>
					<Mui.IconButton
						className={classes.closeButton}  
						onClick={() => onClose()}
					>
						<FeatherIcon>
							<Icons.X />
						</FeatherIcon>
					</Mui.IconButton>
				</Mui.DialogTitle>
				{term && 
					<Mui.DialogContent>
						{!!items.length &&
							<Mui.List>
								{items.map((item, index) => (
									<Mui.ListItem
										key={index}
										button
										component={RouterLinkWrapper}
										to={item.url}
										className={item.className}
										selected={selectedIndex === index}
									>
										<Mui.ListItemAvatar>
											{item.line2 === "Lead" ? (
												item.icon
											) : (
												<Mui.Avatar>
													<FeatherIcon>
														{item.icon}
													</FeatherIcon>
												</Mui.Avatar>
											)}
										</Mui.ListItemAvatar>
										<Mui.ListItemText primary={item.line1} secondary={item.line2} />
									</Mui.ListItem>
								))}
							</Mui.List>
						}
						{!items.length &&
							<Mui.DialogContentText>No results</Mui.DialogContentText>
						}
					</Mui.DialogContent>
				}
				<Mui.DialogActions className={classes.actions}>
					<span className={classes.action}>
						<span className={classes.actionIcon}>↑↓</span>
						to navigate
					</span>
					<span className={classes.action}>
						<span className={classes.actionIcon}>↩</span>
						to select
					</span>
					<span className={classes.action}>
						<span className={classes.actionIcon}>esc</span>
						to dismiss
					</span>
				</Mui.DialogActions>
			</Dialog>
		);
	}

	private onKeyDown(event: React.KeyboardEvent, items: Item[]) {
		let { selectedIndex } = this.state;
		const up = event.keyCode === 38;
		const down = event.keyCode === 40 || event.keyCode === 9;
		const enter = event.keyCode === 13;
		if (up || down || enter) {
			event.preventDefault();
			if (up || down) {
				if (up) {
					selectedIndex--;
				}
				if (down) {
					selectedIndex++;
				}
				if (selectedIndex < 0) {
					selectedIndex = items.length - 1;
				}
				if (selectedIndex > items.length - 1) {
					selectedIndex = 0;
				}
				this.setState({selectedIndex})
			}
			if (enter) {
				const item = items[selectedIndex];
				if (item) {
					const { onClose, history } = this.props;
					history.push(item.url);
					onClose();
				}
			}
		}
	}

}

export const QuickSearchDialog = Mui.withStyles(styles)(
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);
