import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { getPayload, RootState } from "redux/store";
import { Agent } from "model/agent";
import { Select } from "component/shared/select";
import { getAgentBoardsForAgent, getAgents, getLanguages, getOffices, getUser } from "redux/selector";
import { MlsSelector } from "./mls-selector";
import { updateAgent } from "redux/slice/agents";
import { AgentBoard } from "model/agent-board";
import { createAgentBoard, deleteAgentBoard, updateAgentBoard } from "redux/slice/agent-boards";
import { SaveButton } from "component/shared/save-button";
import { AgentStaffType } from "type/agent-staff";
import { Collapsible } from "component/shared/collapsible";
import * as MuiIcons from "@material-ui/icons";
import * as Icons from "react-feather";
import { styles } from "./styles";
import * as yup from "yup";
import { validate } from "shared/yup";
import * as env from "shared/env";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
	{}

interface OwnProps {
	agent: Agent;
}

interface State {
	code: string;
	officeId: number;
	agentBoards: AgentBoard[];
	languageIds: number[];
	staffTypeId: number;
	agentCode: string;
	hasOwnSite: boolean;
	redirectUrl: string;
	enableAgentLink: boolean;
	ownsLeads: boolean;
	lockListings: boolean;
	lockSubscribers: boolean;
	lockBio: boolean;
	showOnSignupDropDown: boolean;
	sendEmailUpdatesToAgent: boolean;
	saving: boolean;
	submitted: boolean;
	showImageOnPage?: boolean;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		user: getUser(state),
		offices: getOffices(state),
		agentBoards: getAgentBoardsForAgent(state, ownProps.agent.id),
		languages: getLanguages(state),
		loading: (state.agents.loading || state.agentBoards.loading) || false,
		agents: getAgents(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			updateAgent,
			createAgentBoard,
			updateAgentBoard,
			deleteAgentBoard,
		},
		dispatch
	);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			code: this.props.agent.code || "",
			officeId: this.props.agent.office?.id || 0,
			agentCode: this.props.agent.code || "",
			agentBoards: this.props.agentBoards,
			languageIds: this.props.agent.languageIds || [],
			staffTypeId: this.props.agent.staffType?.id || 0,
			hasOwnSite: this.props.agent.hasOwnSite,
			redirectUrl: this.props.agent.agentPageUrl || "",
			enableAgentLink: this.props.agent.enableAgentLink || false,
			ownsLeads: this.props.agent.ownsLeads || false,
			lockListings: !this.props.agent.allowModifyListings || false,
			lockBio: !this.props.agent.allowModifyBio || false,
			lockSubscribers: !this.props.agent.allowModifySubscribers || false,
			showOnSignupDropDown: this.props.agent.showOnSignupDropDown,
			sendEmailUpdatesToAgent: this.props.agent.sendEmailUpdatesToAgent || false,
			showImageOnPage: this.props.agent.showImageOnPage || false,
			saving: false,
			submitted: false,
		};
	}

	private async save() {
		const { agent, offices } = this.props;
		const { 
			code, 
			officeId,
			languageIds,
			staffTypeId,
			sendEmailUpdatesToAgent, 
			agentBoards: selectedAgentBoards, 
			redirectUrl, 
			hasOwnSite, 
			ownsLeads,
			lockListings,
			lockBio,
			lockSubscribers,
			enableAgentLink, 
			showOnSignupDropDown,
			showImageOnPage 
		  } = this.state;
		  this.setState({saving: true, submitted: true})
		  const errors = validate(this.validationSchema, { ...this.state, staffTypeId });
		  let agentResource
		  if (!errors && staffTypeId) {
			  agentResource = getPayload(await this.props.updateAgent( 
				  { agent: 
					{
						...agent,
						code,
						office: offices.find((office) => office.id === officeId),
						languageIds,
						staffType: AgentStaffType.getById(staffTypeId),
						ownsLeads, 
						hasOwnSite,
						redirectUrl,
						showImageOnPage,
						enableAgentLink,
						allowModifyBio: !lockBio,
						allowModifySubscribers: !lockSubscribers,
						allowModifyListings: !lockListings,
						showOnSignupDropDown,
						sendEmailUpdatesToAgent
					}
				})
				);
		}
			if (agentResource && agentResource.id) {
				const agentId = agentResource.id;
				{
					const agentBoards = selectedAgentBoards.filter(agentBoard => agentBoard.id && agentBoard.agentId && agentBoard.agentCode);
					for (const agentBoard of agentBoards) {
						await this.props.updateAgentBoard({agentBoard, agentId});					
					}
				}
				{
					const agentBoards = selectedAgentBoards.filter(agentBoard => agentBoard.id && !agentBoard.agentId);
					for (const agentBoard of agentBoards) {
						await this.props.deleteAgentBoard({agentBoard});					
					}
				}
				{
					const agentBoards = selectedAgentBoards.filter(agentBoard => !agentBoard.id);
					for (const agentBoard of agentBoards) {
						await this.props.createAgentBoard({agentBoard, agentId});					
					}
				}
				this.setState({agentBoards: this.props.agentBoards});
			}
		this.setState({saving: false})
	}

	private validationSchema = {
		staffTypeId: yup
			.number()
			.moreThan(
                0,
                "Please select a staff type."
            )
			.required("Staff type is required."),
	}

	render() {
		const { agent, offices, languages, user, classes, agents } = this.props;
		const { 
			code, 
			officeId, 
			agentBoards, 
			languageIds,
			staffTypeId,
			redirectUrl, 
			hasOwnSite, 
			enableAgentLink,
			ownsLeads,
			lockListings, 
			lockBio,
			lockSubscribers,
			showOnSignupDropDown, 
			sendEmailUpdatesToAgent,
			showImageOnPage,
			saving,
			submitted
		} = this.state;
		const errors = validate(this.validationSchema, { ...this.state, staffTypeId });
		const limitTwoAgentsWithLeads = user.permissions.limitTwoAgentsWithLeads && agents.filter(agent => agent.ownsLeads).length === 2;
		return (
			<Mui.Grid container spacing={1}>
				<Mui.Grid item xs={12}>
					{offices.length > 0 && (
						<Select
							fullWidth
							valueExtractor={(office) => office && office.id}
							labelExtractor={(office) => office && office.name}
							margin="dense"
							onChange={(event) => {
								this.setState({officeId: event});
							}}
							options={offices}
							optionRenderer={(office) => (
								<Mui.ListItemText primary={office.name} key={office.id} />
							)}
							label="Office Member"
							value={officeId}
							variant="outlined"
						/>
					)}
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Mui.TextField
						fullWidth
						label="Agent Code For Display (optional)"
						margin="dense"
						onChange={(event) => {
							this.setState({agentCode: event.target.value})
						}}
						placeholder="Agent Code For Display (optional)"
						value={code}
						variant="outlined"
					/>
				</Mui.Grid>
				<Mui.Grid item xs={12}>
					<Select
						fullWidth
						valueExtractor={(lang) => lang && lang.id}
						labelExtractor={(lang) => lang && lang.name}
						margin="dense"
						multiple
						onChange={(languageIds) => {
							this.setState({languageIds: languageIds});
						}}
						options={languages.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0)}
						optionRenderer={(lang) => (
							<Mui.ListItemText primary={lang.name} key={lang.id} />
						)}
						label="Languages"
						value={languageIds}
						variant="outlined"
						placeholder={languages.filter(language => languageIds.includes(language.id)).map(language => language.name).join(", ")}
					/>
				</Mui.Grid>
				{user.admin && 
					<Mui.Grid item xs={12}>
						<Mui.FormControl
							error={submitted && !!errors && !!errors.staffTypeId}
							required
							fullWidth
						>
							<Select
								fullWidth
								valueExtractor={(type) => type && type.id}
								labelExtractor={(type) => type && type.label}
								margin="dense"
								onChange={(event) => {
									this.setState({staffTypeId: event, submitted: false})
								}}
								options={AgentStaffType.values().map((value) => value)}
								optionRenderer={(type) => (
									<Mui.ListItemText primary={type.label} key={type.id} />
									)}
									label="Agent/Staff Type"
									placeholder="Select a Type"
									value={staffTypeId}
									variant="outlined"
									required
									error={submitted && !!errors && !!errors.staffTypeId}
							/>
							{submitted && !!errors && !!errors.staffTypeId && (
								<Mui.FormHelperText className={classes.errorMessage}>
									{errors.staffTypeId}
								</Mui.FormHelperText>
							)}
						</Mui.FormControl>
					</Mui.Grid>
				} 
				{ownsLeads && (
					<>
						<Mui.Grid item xs={12}>
							<Mui.Typography>Agent MLS IDs</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={12}>
							<MlsSelector agent={agent} agentBoards={agentBoards} onChange={(agentBoards) => this.setState({agentBoards})}/>
						</Mui.Grid>
					</>
				)}
				<Mui.Grid item xs={12} />
				{user.admin && 
					<Mui.Grid item xs={12}>
						Settings
					</Mui.Grid>
				} 
				{(user.ownsLeads || user.admin) && (
					<Mui.Tooltip title={limitTwoAgentsWithLeads && !agent.ownsLeads ? "Limited to 2 agents" : ""}>
						<Mui.Grid item xs={12}>
							<Mui.FormControlLabel
								control={
									<Mui.Switch 
									checked={ownsLeads} 
									onChange={() => this.setState({ownsLeads: !ownsLeads})} 
									name="link" 
									disabled={limitTwoAgentsWithLeads && !agent.ownsLeads}
									/>
								}
								label="Agent/Lead Owner (billable user)"
								/>
						</Mui.Grid>
					</Mui.Tooltip>
				)}
				{user.admin && 
					<Mui.Grid item xs={12}>
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={enableAgentLink} 
									onChange={() => this.setState({enableAgentLink: !enableAgentLink})} 
									name="link" 
								/>
							}
							label="Enable Link on Roster"
						/>
					</Mui.Grid>
				}
				{user.admin && 
					<Mui.Grid item xs={12}>
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={hasOwnSite} 
									onChange={() => this.setState({hasOwnSite: !hasOwnSite})} 
									name="website" 
								/>
							}
							label="Link to agent's own website instead of Bio"
						/>
					</Mui.Grid>
				}
				<Mui.Grid item xs={12}>
					<Mui.Collapse in={hasOwnSite}>
						<Mui.TextField
							fullWidth
							margin="dense"
							onChange={(event) =>
								this.setState({ redirectUrl: event.target.value })
							}
							label="Website URL"
							required
							value={redirectUrl}
							variant="outlined"
						/>
					</Mui.Collapse>
				</Mui.Grid>
				{(ownsLeads || user.admin) && (
					<Mui.Grid item xs={12}>
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={showOnSignupDropDown} 
									onChange={() => this.setState({showOnSignupDropDown: !showOnSignupDropDown})} 
									name="website" 
									disabled={!ownsLeads}
									classes={{thumb: !ownsLeads ? classes.disabledSwitch : undefined}}
								/>
							}
							label={
								<div className={classes.switchLabel}>
									Show on Signup Agent Selector
									{!ownsLeads && (
										<Mui.Tooltip title={`Must Enable "Agent/Lead Owner".`}
											classes={{tooltip: classes.tooltip}}
										>
											<Icons.AlertTriangle size={20} className={classes.tooltipIcon} />
										</Mui.Tooltip>
									)}
								</div>
							}
						/>
					</Mui.Grid>	
				)}
				{(ownsLeads || user.admin) && (
					<Mui.Grid item xs={12}>
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={sendEmailUpdatesToAgent} 
									onChange={() => this.setState({sendEmailUpdatesToAgent: !sendEmailUpdatesToAgent})} 
									name="website" 
									disabled={!ownsLeads}
									classes={{thumb: !ownsLeads ? classes.disabledSwitch : undefined}}
								/>
							}
							label={
								<div className={classes.switchLabel}>
									Receive a Copy of Email Updates Sent To Leads
									{!ownsLeads && (
										<Mui.Tooltip title={`Must Enable "Agent/Lead Owner".`}
											classes={{tooltip: classes.tooltip}}
										>
											<Icons.AlertTriangle size={20} className={classes.tooltipIcon} />
										</Mui.Tooltip>
									)}
								</div>
							}
						/>
					</Mui.Grid>	
				)}
				{(user.admin && env.showImageOnPageDev) && (
					<Mui.Grid item xs={12}>
						<Mui.FormControlLabel
							control={
								<Mui.Switch 
									checked={showImageOnPage} 
									onChange={() => this.setState({showImageOnPage: !showImageOnPage})} 
									name="website" 
								/>
							}
							label={
								<div className={classes.switchLabel}>
									Show Image on Page
								</div>
							}
						/>
					</Mui.Grid>	
				)}
				{user.admin && !agent.admin && (
					<Mui.Grid item xs={12}>
						<Collapsible 
							label="Agent Restrictions" 
							open={(!agent.allowModifyBio || !agent.allowModifyListings || !agent.allowModifySubscribers)}
						>
							<Mui.Typography variant="body2" style={{paddingBottom: 10}}>
								Locking the following will prevent non-administrator agents from modifying the specified settings.
							</Mui.Typography>
							<Mui.Grid item xs={12}>
								<Mui.FormControlLabel
									control={
										<Mui.Switch 
											checked={ownsLeads ? lockSubscribers : false} 
											onChange={() => this.setState({lockSubscribers: !lockSubscribers})} 
											name="lock-email" 
											checkedIcon={<MuiIcons.Lock />}
											disabled={!ownsLeads}
											classes={{thumb: !ownsLeads ? classes.disabledSwitch : undefined}}
										/>
									}
									label={
										<div className={classes.switchLabel}>
											Disable Modification of Lead Subscriptions
											{!ownsLeads && (
												<Mui.Tooltip title={`Must Enable "Agent/Lead Owner".`}
													classes={{tooltip: classes.tooltip}}
												>
													<Icons.AlertTriangle size={20} className={classes.tooltipIcon} />
												</Mui.Tooltip>
											)}
										</div>
									}
								/>
							</Mui.Grid>	
							<Mui.Grid item xs={12}>
								<Mui.FormControlLabel
									control={
										<Mui.Switch 
											checked={ownsLeads ? lockListings: false} 
											onChange={() => this.setState({lockListings: !lockListings})} 
											name="lock-listing" 
											checkedIcon={<MuiIcons.Lock />}
											disabled={!ownsLeads}
											classes={{thumb: !ownsLeads ? classes.disabledSwitch : undefined}}
										/>
									}
									label={
										<div className={classes.switchLabel}>
											Disable Modification of Listings
											{!ownsLeads && (
												<Mui.Tooltip title={`Enable "Agent/Lead Owner" to use this option.`}
													classes={{tooltip: classes.tooltip}}
												>
													<Icons.AlertTriangle size={20} className={classes.tooltipIcon} />
												</Mui.Tooltip>
											)}
										</div>
									}
								/>
							</Mui.Grid>	
							<Mui.Grid item xs={12}>
								<Mui.FormControlLabel
									control={
										<Mui.Switch 
											checked={ownsLeads ? lockBio : false} 
											onChange={() => this.setState({lockBio: !lockBio})} 
											name="lock-bio" 
											checkedIcon={<MuiIcons.Lock />}
											disabled={!ownsLeads}
											classes={{thumb: !ownsLeads ? classes.disabledSwitch : undefined}}
										/>
									}
									label={
										<div className={classes.switchLabel}>
											Disable Modification of Bio
											{!ownsLeads && (
												<Mui.Tooltip title={`Must Enable "Agent/Lead Owner".`}
													classes={{tooltip: classes.tooltip}}
												>
													<Icons.AlertTriangle size={20} className={classes.tooltipIcon} />
												</Mui.Tooltip>
											)}
										</div>
									}
								/>
							</Mui.Grid>	
						</Collapsible>
					</Mui.Grid>
				)}
				<Mui.Grid item xs={12}>
					{!errors ? (
						<SaveButton 
							loading={saving}
							snackBar
							label={{
								primary: "Save",
								inProgress: "Saving...",
								completed: "Saved"
							}}
							onClick={() => this.save()}				
						/>
					):
						<Mui.Button 
							onClick={() => this.save()} 
							color="secondary" 
							variant="contained"
						>
							Save
						</Mui.Button>
					}
				</Mui.Grid>
			</Mui.Grid>
			
		);
	}
}

export const AgentSettingsTab = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));