import React from "react";
import * as Mui from "@material-ui/core";
import { saveUser } from "redux/slice/authentication";
import { bindActionCreators, Dispatch } from "redux";
import { UserPartial } from "model/user";
import { connect } from "react-redux";
import { getUserUnsafe } from "redux/selector";
import { RootState } from "redux/store";
import { Switch } from "component/shared/switch";

interface State {
	submitted: boolean;
	listingAgentRouting: boolean,
}

interface Props	extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
}

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
		authError: state.authentication.error
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
		saveUser,
	}, dispatch
);

class Component extends React.Component<Props, State> {
	
	public constructor(props: Props) {
		super(props);
		this.state = {...props.user, 
			submitted: false, 
			listingAgentRouting: this.props.user ? this.props.user.listingAgentRouting : false,
		}
	}

	private save() {
		const { saveUser } = this.props;
		const { listingAgentRouting } = this.state;
		const user: UserPartial = {
			listingAgentRouting,
		}
		saveUser({user});
	}

	public render() {
		const { listingAgentRouting, submitted } = this.state
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Switch 
						checked={!!listingAgentRouting}
						label="Always assign Featured Property leads to the listing agent"
						description="Overrides other lead routing"
						onChange={(checked) => this.setState({listingAgentRouting: checked}, () => this.save())} 
					/>
				</Mui.Grid>
			</Mui.Grid>			
		);
	}
}

export const FeaturedTab = connect(mapStateToProps, mapDispatchToProps)(Component);