import React from "react"
import * as Mui from "@material-ui/core";
import { bindActionCreators, Dispatch } from "redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { User } from "model/user";
import { Market } from "model/market";
import { connect } from "react-redux";
import { updateMarket } from "redux/slice/markets";
import { updateMarketReport } from "redux/slice/market-reports";
import { SaveButton } from "component/shared/save-button";
import { RootState } from "redux/store";
import { styles } from "./style";
import * as yup from "yup";
import { validate } from "shared/yup";

interface Props extends 
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles> {
		market: Market;
		user: User;
		marketboost?: boolean;
	}

interface State {
	customUrl: string;
	webIntroText: string, 
	emailIntroText: string, 
	navigationDisplay: boolean, 
	header: string, 
	subheader: string, 
	footer: string
	saved: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	updateMarketReport,
}, dispatch);

const mapStateToProps = (state: RootState) => {
	return {
		marketsLoading: state.markets.loading ,
		reportloading: state.marketReports.loading 
	};
}

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		const { market } = this.props
		const report = market.listingReport;
		this.state = {
			customUrl: report && report.customUrl ? report.customUrl : "",
			webIntroText: report && report.webpageIntroText ? report.webpageIntroText : "",
			emailIntroText: report && report.emailIntroText ? report.emailIntroText : "",
			navigationDisplay: !!(report && report.displayInNavigation),
			header: market.header ? market.header : "",
			subheader: market.subhead ? market.subhead : "",
			footer: market.subhead ? market.subhead : "",
			saved: false,
		}
	}

	private async save() {
		const { market } = this.props;
		const { emailIntroText, footer, header, navigationDisplay, subheader, webIntroText, customUrl } = this.state;
		const errors = validate(this.validationSchema, this.state);
		if (market.listingReport && !errors) {
			await this.props.updateMarket({ market: {
				...market,
				header: header,
				footer: footer,
				subhead: subheader,
			}});
			this.props.updateMarketReport({ marketReport: {
				...market.listingReport,
				displayInNavigation: navigationDisplay,
				emailIntroText: emailIntroText,
				webpageIntroText: webIntroText,
				customUrl: customUrl,
			}});
			
		}
	}

	private validationSchema = {
		customUrl: yup
			.string()
			.url("Please enter a valid URL"),
	};

	render() {
		const { user, market, marketsLoading, reportloading, classes, marketboost } = this.props;
		const { emailIntroText, footer, header, navigationDisplay, saved, subheader, webIntroText, customUrl } = this.state;
		const errors = validate(this.validationSchema, this.state);
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item >
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Page URL</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.Link
								href={market.listingReport?.pageUrl}
								target="_blank"
							>
								{market.listingReport?.pageUrl}
							</Mui.Link>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item >
					{user.permissions.plugin && user.permissions.marketReport && (
						<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
							<Mui.Grid item xs={2}>
								<Mui.Typography>Custom URL</Mui.Typography>
							</Mui.Grid>
							<Mui.Grid item xs={9}>
								<Mui.TextField
									error={saved && errors && !!errors.customUrl}
									helperText={saved && errors && errors.customUrl}
									fullWidth
									size="small"
									variant="outlined"
									value={customUrl}
									onChange={(event) => this.setState({customUrl: event.target.value})}
								/>
							</Mui.Grid>
						</Mui.Grid>
					)}
				</Mui.Grid>
				<Mui.Grid item >
					<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
						<Mui.Grid item xs={2}>
							<Mui.Typography>Webpage Intro Text</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item xs={9}>
							<Mui.TextField
								multiline
								minRows={5}
								variant="outlined"
								fullWidth
								value={webIntroText}
								onChange={(event) => {
									this.setState({webIntroText:event.target.value})
								}}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				{marketboost && (
					<>
						<Mui.Grid item>
							<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
								<Mui.Grid item xs={2}>
									<Mui.Typography>Email Intro Text</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									<Mui.TextField
										multiline
										minRows={5}
										variant="outlined"
										fullWidth
										value={emailIntroText}
										onChange={(event) => {
											this.setState({emailIntroText: event.target.value})
										}}
									/>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
								<Mui.Grid item xs={2}>
									<Mui.Typography>Display in Navigation</Mui.Typography>
								</Mui.Grid>
								<Mui.Grid item xs={9}>
									<Mui.Checkbox
										checked={navigationDisplay}
										onChange={(event) => {
											this.setState({navigationDisplay: !navigationDisplay})
										}}
									/>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
							</>
						)}
						<Mui.Grid item>
							{(user.kestrelVersion.off || user.kestrelVersion.detail) && !user.permissions.plugin && (
								<Mui.Accordion variant="outlined">
									<Mui.AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#f5f5f5" }}>
										<Mui.Typography >Custom Branding</Mui.Typography>
									</Mui.AccordionSummary>
									<Mui.AccordionDetails>
										<Mui.Grid container direction="column" spacing={2}>
											<Mui.Grid item>
												<Mui.Typography>Global Branding will be used by default. Enter custom branding below only to display alternate branding on this page.</Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item >
												<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
													<Mui.Grid item xs={2}>
														<Mui.Typography>Header</Mui.Typography>
													</Mui.Grid>
													<Mui.Grid item xs={9}>
														<Mui.TextField
															value={header}
															multiline
															minRows={5}
															variant="outlined"
															fullWidth
															onChange={(event) => this.setState({header: event.target.value})}
														/>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
											<Mui.Grid item>
												<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
													<Mui.Grid item xs={2}>
														<Mui.Typography>Subheader</Mui.Typography>
													</Mui.Grid>
													<Mui.Grid item xs={9}>
														<Mui.TextField
															value={subheader}
															multiline
															minRows={5}
															variant="outlined"
															fullWidth
															onChange={(event) => this.setState({subheader: event.target.value})}
														/>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
											<Mui.Grid item >
												<Mui.Grid container direction="row" justifyContent="space-between" wrap="nowrap">
													<Mui.Grid item xs={2}>
														<Mui.Typography>Footer</Mui.Typography>
													</Mui.Grid>
													<Mui.Grid item xs={9}>
														<Mui.TextField
															value={footer}
															multiline
															minRows={5}
															variant="outlined"
															fullWidth
															onChange={(event) => this.setState({footer: event.target.value})}
														/>
													</Mui.Grid>
												</Mui.Grid>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.AccordionDetails>
								</Mui.Accordion>
							)}
						</Mui.Grid>
				<Mui.Grid item className={classes.saveRow}>
					<SaveButton
						error={saved && errors ? "Please check the listing report form for errors" : undefined}
						loading={(!!reportloading || !!marketsLoading) && saved} 
						label={{
							primary: "Save", 
							inProgress: "Saving...", 
							completed: "Saved" 
						}}
						snackBar={true}
						onClick={() => {
							this.setState({saved: true});
							this.save();
						}}
						onReset={() => this.setState({saved: false})} 
					/>
				</Mui.Grid>
			</Mui.Grid>
		)
	}
}

export const ListingReportContent = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));