import React from "react";
import * as Mui from "@material-ui/core";
import { AddDialog } from "./add-dialog"
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { Table } from "component/shared/table";
import { Avatar } from "component/shared/avatar";
import { getUserUnsafe } from "redux/selector";
import { FeatherIcon } from "component/shared/feather-icon";
import { Agent } from "model/agent";
import { Switch } from "component/shared/switch";
import { saveUser } from "redux/slice/authentication";
import { UserPartial } from "model/user";
import { deleteGeographicRoute, saveGeographicRoute } from "redux/slice/geographic-routes";
import { Routes } from "./routes";

interface Props	extends OwnProps,
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Router.RouteComponentProps {

}

const mapStateToProps = (state: RootState) => {
	const user = getUserUnsafe(state);
	return {
		user,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveUser,
	deleteGeographicRoute,
	saveGeographicRoute,
}, dispatch);

interface OwnProps {
	geographicRoutes: GeographicRoute[];
}

interface State {
	dialogOpen: boolean;
	goegraphicRoute: GeographicRoute | null;
	enabled: boolean;
	rowEdit: number;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			dialogOpen: false,
			goegraphicRoute: null,
			enabled: props.user && props.user.geographicRouting ? true : false,
			rowEdit: -1,
		};
	}

	private getAgentRoutes = () => {
		const { geographicRoutes } = this.props;
		const agents = geographicRoutes.reduce<Agent[]>((agents, route) => {
			const agentId = route.agent.id;
			const index = agents.findIndex((agent) => agent.id === agentId)
			if (index === -1) {
				agents.push(route.agent);
			}
			return agents;
		}, []);
		return agents.map((agent) => {
			const routes = geographicRoutes.filter((route) => route.agent.id === agent.id)
			return {
				agent: agent,
				routes: routes,
			};
		});
	}

	private saveUser() {
		const { saveUser } = this.props;
		const { enabled } = this.state;
		const user: UserPartial = {
			geographicRouting: enabled,
		}
		saveUser({user});
	}

	public render() {
		const { saveGeographicRoute, deleteGeographicRoute, geographicRoutes } = this.props;
		const { dialogOpen,	enabled, rowEdit } = this.state;
		const agentRoutes = this.getAgentRoutes();
		return (
			<>
				<Mui.Grid container direction="column" spacing={2}>
					<Mui.Grid item>
						<Mui.Typography>
						Route all new leads on properties in a given postal code to a single agent. 
						Agents can have multiple postal codes assigned to them, but each postal code may be assigned to only one agent.
						</Mui.Typography>	
					</Mui.Grid>
					<Mui.Grid item>
						<Switch 
							checked={enabled}
							onChange={(checked) => {
								this.setState({enabled: checked}, () => this.saveUser());
							}} 
							label="Enabled"
						/>
					</Mui.Grid>
					<Mui.Grid item> 
						<Mui.Typography variant="h4">
							Routing Rules
						</Mui.Typography>
					</Mui.Grid>						
					<Mui.Grid item style={!enabled ? {pointerEvents: "none", opacity: .5}: {}}>
						<Mui.Button
							variant="contained"
							color="secondary"
							onClick={() => this.setState({ dialogOpen: true})}
							startIcon={
								<FeatherIcon>
									<Icons.PlusCircle />
								</FeatherIcon>
							}
						>
								Add
						</Mui.Button>
					</Mui.Grid>
					<Mui.Grid item style={!enabled ? {opacity: .5}: {}}>
						<Mui.ClickAwayListener onClickAway={() => this.setState({ rowEdit: -1 })} >
							<Table
								hidePagination={agentRoutes.length < 10}
								items={agentRoutes}
								columns={[
									{
										id: "avatar",
										width: 36,
										value: (agentRoute) => {
											return (
												<Avatar 
													phrase={agentRoute.agent.label || ""} 
													src={agentRoute.agent.photoUrl} 
												/>
											);
										},
									},
									{
										id: "label",
										width: 200,
										value: (agentRoute) => {
											return (
												<Mui.Typography>
													{agentRoute.agent.label}
												</Mui.Typography>
											);
										},
									},
									{
										id: "postlcodes",
										value: (agentRoute, column, row) => {
											return (
												<Routes 
													rowEdit={rowEdit}
													row={row}
													disabledOptions={geographicRoutes}
													geographicRoutes={agentRoute.routes}
													onCreate={(postalCode) => {
														const geographicRoute: NewGeographicRoute = {
															agent: agentRoute.agent,
															postalCode,
														}
														saveGeographicRoute({geographicRoute});
													}}
													onDelete={(route) => {
														if (window.confirm(`Delete postal code ${route.postalCode} for ${agentRoute.agent.label}?`)) {
															deleteGeographicRoute({
																geographicRoute: route,
															});
														}
													}}
													onToggle={(value) => this.setState({rowEdit: value})}
												/>
											);
										},
									},
									{
										id: "actions",
										showOnHover: true,
										width: 150,
										align: "right",
										value: (agentRoute, column, row) => {
											return (
												<>
													<Mui.IconButton
														onClick={() => {
															this.setState({rowEdit: rowEdit < 0 ? row : -1});
														}}
													>
														<FeatherIcon>
															{rowEdit === row ? (
																<Icons.Edit3 />
															) : (
																<Icons.Edit2 />
															)}
														</FeatherIcon>
													</Mui.IconButton>
													<Mui.Tooltip title="Remove Rule">
														<Mui.IconButton
															onClick={() => {
																if (window.confirm(`Delete geographic routes for ${agentRoute.agent.label}?`)) {
																	agentRoute.routes.forEach((geographicRoute) => {
																		deleteGeographicRoute({geographicRoute});
																	})
																}
															}}
														>
															<FeatherIcon>
																<Icons.Trash />
															</FeatherIcon>
														</Mui.IconButton>
													</Mui.Tooltip>
												</>
											);
										},
									}
								]}
							/>
						</Mui.ClickAwayListener>
					</Mui.Grid>
				</Mui.Grid>
				<AddDialog
					open={dialogOpen}
					agentRoutes={agentRoutes}
					onClose={() => this.setState({ dialogOpen: false })}
					onSave={(geographicRoute) => saveGeographicRoute({geographicRoute})}
				/>
			</>
		);
	}
}

export const GeographicTab = (
	Router.withRouter(
		connect(mapStateToProps, mapDispatchToProps)(Component)
	)
);