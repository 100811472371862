import React from "react";
import Fuse from "fuse.js";
import * as Mui from "@material-ui/core";
import { ExpansionTable } from "./expansion-table";
import { Lead } from "model/lead";
import { MarketReportSubscription } from "model/market-report-subscription";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getMarkets } from "redux/selector";
import { getMarketReportSubscriptionsForLead } from "redux/selector";
import { LoadingIndicator } from "component/shared/loading-indicator";

const mapStateToProps = (state: RootState, ownProps: any) => {
	const subscriptions = getMarketReportSubscriptionsForLead(state, ownProps.lead.id)
	const options: Fuse.IFuseOptions<MarketReportSubscription> = {
		shouldSort: true,
		threshold: 0.5,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: [
			"report.market.name"
		]
	};
	return {
		markets: getMarkets(state),
		fuse: new Fuse(subscriptions, options),
		subscriptions: subscriptions,
		loading: state.marketReportSubscriptions.loading,
		
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	lead: Lead;
}

interface State {
	term?: string,
}




class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			term: "",
		};
	}
	public render() {
		let { fuse, subscriptions, loading } = this.props;
		const { term } = this.state;
		if (!!loading) {
			return (
				<Mui.Grid container justifyContent="center">
					<Mui.Grid item>
						<LoadingIndicator />
					</Mui.Grid>
				</Mui.Grid>
			)
		} else if (subscriptions && subscriptions.length) {

			if (term) {
				subscriptions = fuse.search(term).map((result) => result.item);
			}

			return (
				<>
					<Mui.InputBase
						placeholder="Search for Subscription"
						fullWidth
						onChange={(event) => {
							this.setState({
								term: event && event.target.value,
							});
						}}
						value={term}
					/>
					{
						subscriptions.sort((a:any, b:any) => a?.report?.market?.name.localeCompare(b?.report?.market?.name)).map((subscription, index) => (
						
							<ExpansionTable
								subscription={subscription}
								key={index}
								index={index}
							/>
						))
					}
				</>
			)

			
		} else {
			return (
				<Mui.Typography>
					No current reports. Add one to stay in touch.
				</Mui.Typography>
			)
		}
	}
};

export const MarketReportSubscriptionsPanel = connect(mapStateToProps, mapDispatchToProps)(Component);
