import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { styles } from './style';

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	checked: boolean;
	onChange: (value: boolean) => void;
	label?: string;
	description?: string;
}

interface State {
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators({
	}, dispatch);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {			
		};
	}

	render() {
		const { classes, checked, onChange, label, description } = this.props;
		return (
			<Mui.FormControlLabel
				className={description && classes.label}
				control={
					<Mui.Switch 
						checked={checked} 
						onChange={() => onChange(!checked)} 
						name="featured" 
						classes={{root: description && classes.switch}}
					/>
				}
				label={
					<>
						{label && (
							<Mui.Typography>
								{label}
							</Mui.Typography>
						)}
						{description && (
							<Mui.Typography variant="caption">
								{description}
							</Mui.Typography>
						)}
					</>
				}
			/>
		);
	}
}

export const Switch = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));