import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { Agent, NewAgent } from "model/agent";
import { loginReset } from "redux/slice/authentication";
import { arrayToById, objectToById } from "redux/normalize";
import { AgentResource } from "api/resource/agent";
import { getGeographicRoutes, getRoundRobinRoutes } from "redux/selector";
import { RootState } from "redux/store";
import { updateRoundRobinRoutes } from "redux/slice/round-robin-routes";
import { deleteGeographicRoute } from "./geographic-routes";

export interface AgentsState {
  loading?: boolean;
  error?: string | null;
  byId: {
    [key: number]: AgentResource;
  };
  value: number;
}

const initialState: AgentsState = {
  byId: {},
  value: 0,
};

const fetchAgents = createAsyncThunk("agents/fetchAgents", async (payload, thunkAPI) => {
    thunkAPI.dispatch(agentsLoading());
	const result = api.getAgents();
	result
		.then((agents) => thunkAPI.dispatch(agentsLoaded(agents)))
		.catch((error) => thunkAPI.dispatch(agentsFailed(error)));
	return result;
}); 
const createAgent = createAsyncThunk("agents/createAgent", async (payload: {agent: NewAgent}, thunkAPI) => {
	const result = api.createAgent(payload.agent);
	result
		.then((agent) => thunkAPI.dispatch(agentLoaded(agent)))
		.catch((error) => thunkAPI.dispatch(agentsFailed(error)));
	return result;
});
const updateAgent = createAsyncThunk("agents/saveAgent", async (payload: {agent: Agent}, thunkAPI) => {
    thunkAPI.dispatch(agentsLoading());
	const result = api.updateAgent(payload.agent);
	result
		.then((agent) => {
			thunkAPI.dispatch(agentLoaded(agent));
			if (!agent.ownsLeads) {
				const state = thunkAPI.getState() as RootState;
				{
					const roundRobinRoutes = [...getRoundRobinRoutes(state)];
					const index = roundRobinRoutes.findIndex((route) => route.agent.id === agent.id);
					if (index > -1) {
						roundRobinRoutes.splice(index, 1);
						thunkAPI.dispatch(updateRoundRobinRoutes({ roundRobinRoutes: roundRobinRoutes }));
					}
				}
				{
					const geoRoutes = [...getGeographicRoutes(state)];
					const route = geoRoutes.find((route) => route.agent.id === agent.id);
					if (route) {
						thunkAPI.dispatch(deleteGeographicRoute({ geographicRoute: route }));
					}
				}
			}
		})
		.catch((error) => thunkAPI.dispatch(agentsFailed(error)));
	return result;
});
const deleteAgent = createAsyncThunk("agents/deleteAgent", async (payload: {agent: Agent}, thunkAPI) => {
	const result = api.deleteAgent(payload.agent);
	result
		.then((agent) => thunkAPI.dispatch(agentDeleted(agent)))
		.catch((error) => thunkAPI.dispatch(agentsFailed(error)));
	return result;
});
const updateAgents = createAsyncThunk("agents/updateAgents", async (payload: {agents: Agent[]}, thunkAPI) => {
	const result = api.updateAgents(payload.agents);
	result
		.then((agents: AgentResource[]) => thunkAPI.dispatch(agentsLoaded(agents)))
		.catch((error: any) => thunkAPI.dispatch(agentsFailed(error)));
	return result; 
}); 

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    agentsLoading: (state) => {
      state.loading = true;
    },
    agentsLoaded: (state, action: PayloadAction<AgentResource[]>) => {
      state.loading = false;
      state.error = null;
      state.byId = arrayToById(action.payload, "id");
    },
    agentsFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    agentLoaded: (state, action: PayloadAction<AgentResource>) => {
      const byId = objectToById(action.payload, "id");
      state.loading = false;
      state.error = null;
      state.byId = {
        ...state.byId,
        ...byId,
      };
    },
    agentDeleted: (state, action: PayloadAction<AgentResource>) => {
			const {id} = action.payload;
			const result = {
				...state,
				loading: false,
				error: null,
				byId: {
					...state.byId,
				}
			};
			 delete result.byId[id];
			 return result;
    },
    resetError: (state) => {
      state.error = null;
    },
    setAgentValue: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
  extraReducers(builder) {
	builder.addCase(loginReset, (state) => {
		return {
			...initialState,
			error: state.error,
		};
	});
  },
});

export const {
  resetError,
  agentsFailed,
  agentDeleted,
  agentLoaded,
  agentsLoaded,
  agentsLoading,
  setAgentValue,
} = agentsSlice.actions;
export {
  fetchAgents,
  createAgent,
  updateAgent,
  deleteAgent,
  updateAgents,
};