import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { ViewType } from "type/view-type";

export interface AdminModeState {
	loading?: boolean;
	error?: string | null;
	enabled?: boolean | null;
	view?: string;
};

const initialState: AdminModeState = {};

const fetchAdminMode = createAsyncThunk("campaigns/fetchCampaigns", async (payload, thunkAPI) => {
	thunkAPI.dispatch(adminModeLoading());
	const result = api.getAdminMode()
	result
		.then((enabled) => thunkAPI.dispatch(adminModeLoaded(enabled)))
		.catch((error) => thunkAPI.dispatch(adminModeFailed(error)));
	return result;
});

export const adminModeSlice = createSlice({
	name: "adminMode",
	initialState,
	reducers: {
		adminModeLoading: (state) => {
			state.loading = true;
		},
		adminModeLoaded: (state, action: PayloadAction<boolean>) => {
			state.loading = false;
			state.error = null;
			state.enabled = action.payload;
		},
		adminModeFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		adminModeSetView:(state, action: PayloadAction<ViewType>) => {
			state.view = action.payload.id;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			const error = state.error;
			let result = {
				...initialState,
				error,
			}
		   return result;
		});
	},
});


export const { adminModeSetView, adminModeFailed, adminModeLoaded, adminModeLoading } = adminModeSlice.actions
export { fetchAdminMode };

