import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style"
import * as MuiIcons from "@material-ui/icons"

interface OwnProps {
	label: string;
	open?: boolean;
}

interface State {
	open: boolean;
}

interface Props extends OwnProps,
	Mui.WithStyles<typeof styles> {
	}

	class Component extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: this.props.open || false,
        };
    }

    private toggle = () => {
        this.setState((prevState) => ({
            open: !prevState.open,
        }));
    };

    render() {
        const { classes, children, label } = this.props;
        const { open } = this.state;

        return (
			<>
				<div
					className={classes.container}
					onClick={this.toggle}
				>
					<Mui.Typography>
						{label}
					</Mui.Typography>
					{open ? (
						<MuiIcons.ExpandLess className={classes.icon} />
					) : (
						<MuiIcons.ExpandMore
						 className={classes.icon} />
					)}
				</div>
				<Mui.Collapse in={open}>
					<div className={classes.content}>
						{children}
					</div>
				</Mui.Collapse>
			</>
        );
    }
}

export const Collapsible = Mui.withStyles(styles)(
	(Component)
);
