import React from "react";
import * as Mui from "@material-ui/core";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { styles } from "./style";
import { connect } from "react-redux";
import { Dialog } from "component/shared/dialog";
import { getAgentsWithLeads } from "redux/selector";
import { getPostalCodes } from "redux/selector";
import { Select } from "component/shared/select";
import { GeographicRoute, NewGeographicRoute } from "model/geographic-route";
import { saveGeographicRoute } from "redux/slice/geographic-routes";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { Agent } from "model/agent";

const mapStateToProps = (state: RootState) => {
	return {
		loading: state.leads.loading,
		agents: getAgentsWithLeads(state),
		postalCodes: getPostalCodes(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	saveGeographicRoute
}, dispatch);

interface OwnProps {
	agentRoutes: {
		agent: Agent;
		routes: GeographicRoute[];
	}[];
	open: boolean;
	onClose: () => void;
	onSave: (geographicRoute: NewGeographicRoute) => void;
}

interface Props extends
	OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{}

interface State {
	submitted: boolean;
	selectedPostalCodes: string[];
	agentId: number;
}

const initialState: State = {
	submitted: false,
	selectedPostalCodes: [],
	agentId: 0
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	private agentRoutExists(agent: Agent) {
		const { agentRoutes } = this.props;
		return agentRoutes.some((agentRoute) => agentRoute.agent.id === agent.id)
	}

	public render() {
		const { agentId, selectedPostalCodes } = this.state;
		const { open, agentRoutes, agents, postalCodes, classes, onSave, onClose} = this.props;
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper">
				<Mui.DialogTitle>
					Add Geographic Lead Routing Rule
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container spacing={5} direction="column">
						<Mui.Grid item>
							<Select
								fullWidth
								variant="outlined"
								margin="dense"
								placeholder="Select an agent"
								value={agentId}
								options={agents}
								valueExtractor={agent => agent.id}
								labelExtractor={agent => agent.name}
								optionRenderer={agent => <Mui.ListItemText primary={agent.name} />}
								onChange={(value: number) => this.setState({agentId: value})}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Autocomplete
								multiple
								freeSolo
								id="postal-code"
								options={postalCodes.map((option) => option?.postalCode)}
								getOptionDisabled={(option) => {
									const disabledPostalCodes = agentRoutes.flatMap(agentRoute => agentRoute.routes.map(route => route.postalCode));
									return option ? disabledPostalCodes.includes(option) : false;
								}}
								renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										variant="outlined"
										label={option}
										{...getTagProps({ index })}
									/>
								))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Postal Codes"
										placeholder="Add..."
									/>
								)}
								onChange={(event, values) => {
									this.setState({ 
										selectedPostalCodes: values as string[],
									})
								}}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Button
								variant="contained"
								color="secondary"
								className={classes.button}
								onClick={() => {
									selectedPostalCodes.forEach((postalCode) => {
										const agent = this.props.agents.find((agent) => agent.id === agentId)
										if (agent) {
											const geographicRoute: NewGeographicRoute = {
												agent: agent,
												postalCode: postalCode,
											}
											onSave(geographicRoute);
											onClose();
										}
									})
								}}
							>
								Add
							</Mui.Button>
							<Mui.Button
								variant="outlined"
								color="secondary"
								onClick={() => onClose()}
							>
								Cancel
							</Mui.Button>
							
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Dialog>
			
		)
	}
}
export const AddDialog = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);
