import { Lead } from "model/lead";

export const calculateLeadRankSize = (leads: Lead[]) => {
	let ratingSize = 0;
	const sortedLeads = leads.sort((lead1, lead2) => (lead1.ranking || 0) > (lead2.ranking || 0) ? -1 : 1);
	sortedLeads.forEach(lead => {
		if (lead.ranking && lead.ranking > 0) {
			ratingSize++;
		}
	});
	return {
		sortedLeads,
		ratingSize,
	};
}