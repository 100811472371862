import React from "react";
import * as Mui from "@material-ui/core";
import { DashboardLayout } from "component/layout/dashboard";
import { RoundRobinTab } from "./round-robin-tab"
import { GeographicTab } from "./geographic-tab"
import { FeaturedTab } from "./featured-tab"
import { connect } from "react-redux";
import { getRoundRobinRoutes } from "redux/selector";
import { getGeographicRoutes } from "redux/selector";
import { RootState } from "redux/store";
import { bindActionCreators, Dispatch } from "redux";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { getUserUnsafe } from "redux/selector";

interface Props
	extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps{}


const mapStateToProps = (state: RootState) => {
	const roundRobinRoutes = getRoundRobinRoutes(state);
	const geographicRoutes = getGeographicRoutes(state);
	const user = getUserUnsafe(state);
	return { roundRobinRoutes, geographicRoutes, user }
}

interface State {
	addAgentIsOpen: boolean
	tab: number;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({}, dispatch);


class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addAgentIsOpen: false,
			tab: 0
		};
	}

	public render() {
		const title = "Lead Routing";
		let { roundRobinRoutes, geographicRoutes } = this.props;
		const { tab } = this.state;
		const { user } = this.props;
		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={'Lead Routing'}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.User />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<Mui.Grid container direction="column" spacing={2} lg={8} xl={6}>
					<Mui.Grid item>
						<Mui.Tabs
							indicatorColor="secondary"
							textColor="secondary"
							value={tab}
							onChange={(event, tab) => this.setState({
								tab,
							})}
						>
							<Mui.Tab
								value={0}
								label="Round Robin"
							/>
							<Mui.Tab
								value={1}
								label="Geographic"
							/>
							<Mui.Tab
								value={2}
								label="Featured"
							/>
						</Mui.Tabs>
					</Mui.Grid>
					<Mui.Grid item>
						{tab === 0 && (
							<RoundRobinTab roundRobinRoutes={roundRobinRoutes}/>
						)}
						{tab === 1 && (
							<GeographicTab geographicRoutes={geographicRoutes}/>
						)}
						{tab === 2 && (
							<FeaturedTab />
						)}
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}

}

export const SettingsLeadRoutingPage = (
	connect(mapStateToProps, mapDispatchToProps)(Component)
);