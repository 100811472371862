import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		centeredCell: {
			display: "flex",
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
		},
		reactTable: {
			"& .rt-tr-group .delete": {
				visibility: "hidden",
			},
			"& .rt-tr-group:hover .delete": {
				visibility: "visible",
			},
			"& .-pagination": {
				"& .-pageJump": {
					display: "none",
				},
				"& .-totalPages": {
					display: "none",
				}
			},
			
		},
		dialogText: {
			paddingTop: 5, 
			color: theme.palette.primary.main,
		},
		dialogContent: {
			[theme.breakpoints.down("xs")]: {
				display: "flex",
				alignItems: "flex-end",
				textAlign: "center",
			},
		},
		dialogActions: {
			[theme.breakpoints.down("xs")]: {
				height: "50%",
				justifyContent: "center",
				alignItems: "flex-start",
				paddingLeft: 30,
				paddingRight: 30,
				"& button": {
					flex: 1,
				},
			},
		},
		formControl: {
			minWidth: 200,

		},
		link: {
			"&:hover": {
				cursor: "pointer",
			}
		},
		tagChip: {
			margin: 2,
		},
		email: {
			color: "#3C4252"
		},
		chip: {
			margin: 2
		}
	});
};
