import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Router from "react-router-dom";
import { updateCampaign } from "redux/slice/campaigns";
import { Campaign } from "model/campaign";
import { CampaignStatus } from "type/campaign-status";

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateCampaign,
}, dispatch);

interface Props extends
	Router.RouteComponentProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	campaign: Campaign;
}

class Component extends React.Component<Props> {

	private async delete() {
		const { campaign, updateCampaign } = this.props;
		if (window.confirm("Delete this draft email?")) {
			updateCampaign({ campaign: {
				...campaign,
				status: CampaignStatus.INACTIVE,
			}});
		}
	}
	
	public render() {
		return (
			<Mui.Button
				variant="contained"
				color="secondary"
				onClick={() => this.delete()}
			>
				Delete
			</Mui.Button>
		);
	}

}

export const CampaignDeleteButton = Router.withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);