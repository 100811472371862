import { normalize, schema } from "normalizr";
import { arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { SavedListing, NewSavedListing } from "model/saved-listing";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { SavedListingResource } from "api/resource/saved-listing";

export interface SavedListingsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: SavedListingState;
	};
	byLeadId: {
		[key: number]: number[];
	};
};

export interface SavedListingState {
	id: number;
	leadId: number;
	createdOn: string;
	rating?: 0 | 1 | 2 | 3 | 4 | 5;
	comments?: string;
	modifiedOn?: string;
	listing?: string;
	deleted?: boolean;
};

const initialState: SavedListingsState = {
	byId: {},
	byLeadId: {},
};

const fetchSavedListings = createAsyncThunk("savedListings/fetchSavedListings", async (payload: {leadId: number}, thunkAPI) => {
	thunkAPI.dispatch(savedListingsLoading());
	const result = api.getSavedListings(payload.leadId);
	result
		.then((savedListings) => thunkAPI.dispatch(savedListingsLoaded(savedListings)))
		.catch(error => thunkAPI.dispatch(savedListingsFailed(error)));
	return result;
});

const createSavedListing = createAsyncThunk("savedListings/createSavedListing", async (payload: { savedListing: NewSavedListing }, thunkAPI) => {
	thunkAPI.dispatch(savedListingsLoading());
	const result = api.createSavedListing(payload.savedListing);
	result
		.then((savedListing) => thunkAPI.dispatch(savedListingLoaded(savedListing)))
		.catch(error => thunkAPI.dispatch(savedListingsFailed(error)));
	return result;
});

const updateSavedListing = createAsyncThunk("savedListings/updateSavedListing", async (payload: { savedListing: SavedListing }, thunkAPI) => {
	thunkAPI.dispatch(savedListingsLoading());
	const result = api.updateSavedListing(payload.savedListing);
	result
		.then((savedListing) => thunkAPI.dispatch(savedListingLoaded(savedListing)))
		.catch(error => thunkAPI.dispatch(savedListingsFailed(error)));
	return result;
});

export const savedListingsSlice = createSlice({
	name: "savedListings",
	initialState,
	reducers: {
		savedListingsLoading: (state) => {
			state.loading = true;
		},
		savedListingsLoaded: (state, action: PayloadAction<SavedListingResource[]>) => {
			const listing = new schema.Entity("listings");
			const savedListing = new schema.Entity("savedListings", {
				listing,
			});
			const savedListings = new schema.Array(savedListing);
			const normalized = normalize(action.payload, savedListings);
			const byId = normalized.entities.savedListings || {};
			const byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
		},
		savedListingLoaded: (state, action: PayloadAction<SavedListingResource>) => {
			const listing = new schema.Entity("listings");
			const savedListing = new schema.Entity("savedListings", {
				listing
			})
			const normalized = normalize(action.payload, savedListing);
			const byId = normalized.entities.savedListings || {};
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
		},
		savedListingsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { savedListingLoaded, savedListingsFailed, savedListingsLoaded, savedListingsLoading } = savedListingsSlice.actions;
export { fetchSavedListings, createSavedListing, updateSavedListing };