import React from "react";
import * as Mui from "@material-ui/core";
import * as Icons from "react-feather";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Avatar } from "component/shared/avatar";
import { FeatherIcon } from "component/shared/feather-icon";
import { Table } from "component/shared/table";
import { Agent } from "model/agent";
import { deleteAgent } from "redux/slice/agents";
import { updateTask } from "redux/slice/tasks";
import { RootState } from "redux/store";
import { numberedDateFormatter } from "shared/date-formatter";
import { AgentColumnType } from "type/agent-column-type";
import { DeleteAgentDialog } from "./delete-dialog";
import { styles } from "./style";
import { AddEditAgentDialog } from "../add-edit-agent-dialog";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getGeographicRoutes, getLeads, getRoundRobinRoutes, getTasks, getUser, getAgentTableColumns } from "redux/selector";
import { Select } from "component/shared/select";
import { updateAgentPageColumns } from "redux/slice/agent-page";
import { CopyID } from "component/shared/copy-id";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const maxForTeams = ownProps.maxForTeams;
	let columnTypes = getAgentTableColumns(state);
	if (!columnTypes.length) {
		columnTypes.push(AgentColumnType.AVATAR);
		columnTypes.push(AgentColumnType.LABEL);
		columnTypes.push(AgentColumnType.ID);
		columnTypes.push(AgentColumnType.ROLE);
		columnTypes.push(AgentColumnType.LAST_LOGIN);
		columnTypes.push(AgentColumnType.BILLABLE_USER);
		columnTypes.push(AgentColumnType.LEADS);
		if (maxForTeams) {
			columnTypes.push(AgentColumnType.ROUND_ROBIN);
			columnTypes.push(AgentColumnType.GEOGRAPHIC_ROUTING);
		}
	}
	return {
		columnTypes,
		user: getUser(state),
		tasks: getTasks(state),
		leads: getLeads(state),
		roundRobinRoutes: getRoundRobinRoutes(state),
		geographicRoutingRoutes: getGeographicRoutes(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			deleteAgent,
			updateTask,
			updateAgentPageColumns
		},
		dispatch
	);

interface Props	extends OwnProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps>,
		Mui.WithStyles<typeof styles> {}

interface OwnProps {
	agents: Agent[];
	maxForTeams?: boolean;
}

interface State {
	addAgentDialogIsOpen: boolean;
	agentToDelete: Agent | null;
	agentToEdit: Agent | null;
	deleteAgentDialogIsOpen: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			addAgentDialogIsOpen: false,
			agentToDelete: null,
			agentToEdit: null,
			deleteAgentDialogIsOpen: false,
		};
	}

	private showAddAgentDialog = () => 
		this.setState({ addAgentDialogIsOpen: true, agentToEdit: null });

	private showEditAgentDialog = (agent: Agent) =>
		this.setState({ addAgentDialogIsOpen: true, agentToEdit: agent });

	private hideAddAgentDialog = () =>
		this.setState({ addAgentDialogIsOpen: false });

	private showDeleteAgentDialog = (agent: Agent) => {
		this.setState({
			agentToDelete: agent,
			deleteAgentDialogIsOpen: true,
		});
	};

	private hideDeleteAgentDialog = () =>
		this.setState({
			agentToDelete: null,
			deleteAgentDialogIsOpen: false,
		});

	private deleteAgent = () => {
		const { agentToDelete } = this.state;
		const { tasks } = this.props;
		
		if (agentToDelete) {
			tasks.map(task => {
				if (task.agentId === agentToDelete.id) {
					this.props.updateTask({ task: {
						...task,
						agentId: 0
					}})
				}
			})
			this.props.deleteAgent({ agent: {
				...agentToDelete,
			}});
		}
		this.hideDeleteAgentDialog();
	};

	private get columnOptions() {
		const { maxForTeams } = this.props;
		const results: AgentColumnType[] = [];
		results.push(AgentColumnType.AVATAR);
		results.push(AgentColumnType.LABEL);
		results.push(AgentColumnType.ID);
		results.push(AgentColumnType.ROLE);
		results.push(AgentColumnType.LAST_LOGIN);
		results.push(AgentColumnType.BILLABLE_USER);
		results.push(AgentColumnType.LEADS);
		if (maxForTeams) {
			results.push(AgentColumnType.ROUND_ROBIN);
			results.push(AgentColumnType.GEOGRAPHIC_ROUTING);
		}
		return results;
	}

	public render() {
		const { agents, classes, columnTypes, user, maxForTeams, leads, roundRobinRoutes, geographicRoutingRoutes } = this.props;
		const {
			addAgentDialogIsOpen,
			agentToDelete,
			deleteAgentDialogIsOpen,
		} = this.state;

		return (
			<>
				{addAgentDialogIsOpen && (
					<AddEditAgentDialog
						agents={agents}
						open={addAgentDialogIsOpen}
						onClose={this.hideAddAgentDialog}
					/>
				)}
				{agentToDelete && (
					<DeleteAgentDialog
						agent={agentToDelete}
						deleteAgent={this.deleteAgent}
						deleteAgentDialogIsOpen={deleteAgentDialogIsOpen}
						hideDeleteAgentDialog={this.hideDeleteAgentDialog}
					/>
				)}
				<Mui.Grid container direction="column" spacing={4}>
					<Mui.Grid item>
						<Mui.Grid
							container
							item
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Mui.Grid item>
								<Mui.Button
									variant="contained"
									color="secondary"
									onClick={() => this.showAddAgentDialog()}
								>
									<Mui.Typography>Add New</Mui.Typography>
								</Mui.Button>
							</Mui.Grid>
							<Mui.Grid item>
							<Select
								multiple
								placeholder="Displayed Columns"
								value={columnTypes}
								options={this.columnOptions}
								valueExtractor={(type) => type}
								labelExtractor={(type) => type.label}
								numberVisible={7}
								optionRenderer={(type) => (
									<Mui.ListItemText primary={type.label} />
								)}
								onChange={(values) => {
									const types = values as AgentColumnType[];
									this.props.updateAgentPageColumns(types);
								}}
							/>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Grid item>
						<Table
							items={agents}
							columns={[
								{
									id: "avatar",
									hidden: !columnTypes.includes(AgentColumnType.AVATAR),
									width: 70,
									value: (agent) => {
										return (
											<Mui.Link 
												className={this.props.classes.centeredCell}
												component={RouterLinkWrapper}
												to={urls.agentProfile(agent.id)}
											>
												<Avatar phrase={agent.label} src={agent.photoUrl} />
											</Mui.Link>
										);
									},
								},
								{
									id: "label",
									hidden: !columnTypes.includes(AgentColumnType.LABEL),
									title: AgentColumnType.LABEL.label,
									sort: (agent1, agent2) => {
										return (agent1.lastName || "").toLowerCase() < (agent2.lastName || "").toLowerCase()
											? -1
											: 1;
									},
									value: (agent) => {
										return (
											<Mui.Grid container direction="column">
												<Mui.Grid item>
													<Mui.Link
														className={classes.link}
														component={RouterLinkWrapper} 
														to={urls.agentProfile(agent.id)}
													>
														{agent.label}
													</Mui.Link>	
												</Mui.Grid>
												<Mui.Grid item>
													<Mui.Link 
														href={`mailto:${agent.email}`}
														className={classes.email}
													>
														{agent.email}
													</Mui.Link>
												</Mui.Grid>
											</Mui.Grid>
										);
									},
								},
								{
									id: AgentColumnType.ID.id,
									hidden: !columnTypes.includes(AgentColumnType.ID),
									title: AgentColumnType.ID.label,
									value: (agent) => {
										return (
											<CopyID id={agent.id} text="Copy Agent ID"/>
										);
									},
								},
								{
									id: AgentColumnType.ROLE.id,
									hidden: !columnTypes.includes(AgentColumnType.ROLE),
									title: AgentColumnType.ROLE.label,
									value: (agent) => {
										return (
											<Mui.Typography>
												{agent.admin ? "Admin" : ""}
											</Mui.Typography>
										);
									},
								},
								{
									id: AgentColumnType.BILLABLE_USER.id,
									hidden: !columnTypes.includes(AgentColumnType.BILLABLE_USER),
									title: (
										<div>
											{AgentColumnType.BILLABLE_USER.label}
											<br />
											<Mui.Typography style={{fontSize: 12}}>(billable user)</Mui.Typography>
										</div>
									),
									width: 180,
									value: (agent) => {
										if (agent.ownsLeads) {
											return (
												<Icons.Check/>
											)
										}
									}
								},
								{
									id: AgentColumnType.LEADS.id,
									hidden: !columnTypes.includes(AgentColumnType.LEADS),
									title: AgentColumnType.LEADS.label,
									value: (agent) => {
										const leadsByAgent = leads.filter(lead => lead.agent?.id === agent.id);
										if (leadsByAgent.length && agent.ownsLeads) {
											return (
												leadsByAgent.length
											)
										}
									}
								},
								{
									id: AgentColumnType.ROUND_ROBIN.id,
									hidden: !maxForTeams || !columnTypes.includes(AgentColumnType.ROUND_ROBIN),
									title: AgentColumnType.ROUND_ROBIN.label,
									value: (agent) => {
										const added = roundRobinRoutes.find(route => route.agent.id === agent.id);
										if (added) {
											return (
												<Icons.Check/>
											)
										}
									}
								},
								{
									id: AgentColumnType.GEOGRAPHIC_ROUTING.id,
									hidden: !maxForTeams || !columnTypes.includes(AgentColumnType.GEOGRAPHIC_ROUTING),
									width: 180,
									title: AgentColumnType.GEOGRAPHIC_ROUTING.label,
									value: (agent) => {
										const routes = geographicRoutingRoutes.filter(route => route.agent.id === agent.id);
										if (routes.length) {
											return (
												<>
													{routes.map((route, index) => (
														<Mui.Chip
															className={classes.chip}
															key={index}
															label={route.postalCode}
														/>
													))}
												</>
											)
										}
									}
								},
								{
									id: AgentColumnType.LAST_LOGIN.id,
									hidden: !columnTypes.includes(AgentColumnType.LAST_LOGIN),
									title: AgentColumnType.LAST_LOGIN.label,
									value: (agent) => {
										let lastLoginDate = agent.lastLoginDate;
										if (!lastLoginDate) {
											return null;
										}
										return numberedDateFormatter(lastLoginDate);
									},
								},
								{
									id: AgentColumnType.ACTION.id,
									title: AgentColumnType.ACTION.label,
									width: 180,
									value: (agent) => {
										const url = agent.agentPageUrl;
										return url ? (
											<Mui.Grid container alignItems="center" direction="row" spacing={5}>
												{/*
												//TODO: Handle login
												<Mui.Grid item>
													<Mui.Typography align="center">-------</Mui.Typography>
												</Mui.Grid>*/}
												<Mui.Grid item>
													<Mui.Link href={url} target="_blank" rel="noopener">
														View Profile Page
													</Mui.Link>
												</Mui.Grid>
											</Mui.Grid>
										) : null;
									},
								},
								{
									id: "delete",
									width: 70,
									showOnHover: true,
									value: (agent) => {
										return (
											<>
												{user.admin && (
													<Mui.IconButton
														onClick={() => {
															this.showDeleteAgentDialog(agent);
														}}
														disabled={(user.agentId === agent.id)}
													>
														<FeatherIcon>
															<Icons.Trash />
														</FeatherIcon>
													</Mui.IconButton>
												)}
											</>
										);
									},
								},
							]}
						/>
					</Mui.Grid>
				</Mui.Grid>
			</>
		);
	}
}

export const AgentTable = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
