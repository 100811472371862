import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { styles } from './style';
import { User } from 'model/user';
import { CustomLabelRadioField } from 'component/shared/custom-label-radio-field';
import { MarketColumnDisplayType } from 'type/market-column-setting-type';
import { MarketSettings } from 'model/market-settings';
import { fetchMarketSettings, updateMarketSettings } from 'redux/slice/market-settings';
import { getmarketSettings } from 'redux/selector';
import { SaveButton } from 'component/shared/save-button';

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
		user: User,
	}

interface OwnProps {
	user: User;
}

interface State {
	marketColumnDisplay: string,
	edited: boolean,
	marketDisplayGrid?: boolean,
	marketReportSliderColumns: number
	openSnackbar: boolean;
	snackbarMessage: string;
	saved: boolean;
}

const mapStateToProps = (state: RootState) => {
	const marketSettings = getmarketSettings(state);
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
		loading: state.marketSettings.loading,
		marketSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			fetchMarketSettings,
			updateMarketSettings
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			marketColumnDisplay: (this.props.marketSettings?.communitiesLayout) ? this.props.marketSettings.communitiesLayout : MarketColumnDisplayType.default.id,
			edited: false,
			marketDisplayGrid: true,
			marketReportSliderColumns: this.props.marketSettings && this.props.marketSettings.listingCarouselColumns ? this.props.marketSettings.listingCarouselColumns : 3,
			openSnackbar: false, 
			snackbarMessage: "",
			saved: false,
		}
	}

	public save() {
		const {
			marketColumnDisplay,
			marketReportSliderColumns
		} = this.state;
		const user = {
			...this.state,
			marketColumnDisplayId: marketColumnDisplay,
		};
		this.setState({saved: true})
		const updatedSettings: MarketSettings = {
			listingCarouselColumns: marketReportSliderColumns,
			communitiesLayout: marketColumnDisplay
		}
		this.props.updateMarketSettings({marketSettings: updatedSettings});
	}
	

	public componentDidMount() {
		this.update();
	}

	private update() {
		if (this.props.marketSettings) {
			if (this.props.marketSettings.listingCarouselColumns) {
				this.setState({
					marketReportSliderColumns: this.props.marketSettings.listingCarouselColumns,
				})
			}	else if (this.props.marketSettings.communitiesLayout) {
				this.setState({
					marketColumnDisplay: this.props.marketSettings.communitiesLayout,
				})
			} 
		};
	}

	render() {
		const { user, loading } = this.props;
		const { marketColumnDisplay, marketReportSliderColumns, saved, } = this.state;
		return (
			<Mui.Card>
				<Mui.CardContent>
					<Mui.Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2} lg={8} xl={6}>
						{!user.kestrelVersion.all && (
							<Mui.Grid item>
								<Mui.Grid container alignItems="stretch" justifyContent="space-between" spacing={2}>
									<Mui.Grid item>
										<Mui.Typography>Market Report Listing Slider Columns</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item>
										<Mui.TextField 
											value={marketReportSliderColumns}
											onChange={event => this.setState({marketReportSliderColumns: parseInt(event.target.value)})}
											InputProps={{type: "number", inputProps: {min: 0}}} 
											variant="outlined" 
										/>
									</Mui.Grid> 
								</Mui.Grid>
							</Mui.Grid>
						)}
						{user.kestrelVersion.all && (
							<Mui.Grid item > 
								<CustomLabelRadioField
									label={"Display Market Index Results As"}
									onChange={(value) => {
										this.setState({
											marketColumnDisplay:
												MarketColumnDisplayType.getByIdOrDefault(value).id,
										});
									}}
									options={MarketColumnDisplayType.values().map((option) => ({
										label: option.label,
										value: option.id,
									}))}
									row={true}
									value={marketColumnDisplay}
								/>
							</Mui.Grid>		
						)} 
						<Mui.Grid item>
							<SaveButton 
								loading={!!loading && saved} 
								label={{
									primary: "Save", 
									inProgress: "Saving...", 
									completed: "Saved" }} 
								snackBar={true}
								onClick={() => {
									this.setState({saved: true});
									this.save();
								}}
								onReset={() => this.setState({saved: false})}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.CardContent>
			</Mui.Card> 
		);
	}
}

export const Content = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));