type TimerCallback = (time: number) => void;

const sessionTimer = () => {
	let timer: NodeJS.Timeout | null = null;
	let listeners: TimerCallback[] = [];
	let time = 0;
	const events = [
		"load",
		"mousemove",
		"mousedown",
		"touchstart",
		"click",
		"keydown",
		"scroll",
	];

	const notifyListeners = () => {
		listeners.forEach(listener => listener(time));
	};

	const start = () => {
		stop(); // Ensures the timer doesn't run multiple instances
		timer = setInterval(() => {
			time++;
			notifyListeners();
		}, 1000);
	};

	const stop = () => {
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
		listeners = [];
		time = 0;
	};

	const reset = () => {
		time = 0;
	};

	const get = () => time;

	const registerListener = (callback: TimerCallback) => {
		if (!listeners.includes(callback)) {
			listeners.push(callback);
		}
	};

	const unregisterListener = (callback: TimerCallback) => {
		listeners = listeners.filter(listener => listener !== callback);
	};

	const activityListener = (callback: () => void) => {
		events.forEach(event => window.addEventListener(event, callback));
	};

	start();

	return {
		start,
		stop,
		reset,
		get,
		registerListener,
		unregisterListener,
		activityListener,
	};
};

export default sessionTimer;
