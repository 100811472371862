import { EurekaCriterion } from "./types"
import { Criterion } from "model/criterion";

/*
This code takes an array of EurekaCriterion returned from Eureka 
and translates it to an array of criterion as seen on criteria field on savedSearch
map coordinates are converted
*/
export const createCriteria = (criteria: EurekaCriterion[]) => {
	const accumulator = reduceCriteria(criteria);
	if (accumulator["boundaryWKT"]) {
		delete accumulator["mapBounds"];
	}
	if (accumulator["mapBounds"]) {
		accumulator["boundaryWKT"] = accumulator["mapBounds"];
		delete accumulator["mapBounds"];
	}
	return Object.entries(accumulator).map(
		([key, value]) => ({
			name: key,
			value: value as Criterion["value"],
			label: "",
		})
	) as Criterion[];
}

/*
This is the helper code that takes an array of EurekaCriterion 
and reduces it to a searchProfile (object of key value pairs). 
Values are coma separated string if there are more than 1. 
*/
export const reduceCriteria = (criteria: EurekaCriterion[]) => {
	const reducer = (accumulator: any, criterion: EurekaCriterion) => {
		const name = criterion.name;
		const value = criterion.value;
		if (accumulator[name]) {
			if (typeof accumulator[name] === "object") {
				accumulator[name].push(value);
			} else {
				const values = [];
				values.push(accumulator[name])
				values.push(value);
				accumulator[name] = values;
			}
		} else {
			accumulator[name] = value;
		}
		return accumulator
	} 
	return criteria.reduce(reducer, {});
}

/*
This code takes an array of criterion as seen on criteria field on savedSearch
and translates it to a searchProfile that is sent to Eureka. 
SearchProfile is an object of key value pairs. 
Values are coma separated string if there are more than 1. 
*/
export const createSearchProfile = (criteria: Criterion[]) => {
	let results: {[key: string]: number | string | boolean} = {};
	criteria.forEach(criterion => {
		const name = criterion.name;
		const value = criterion.value;
		if (typeof value === "object") {
			let comaSeparatedValue = ""; 
			value.forEach((values: any) => {
				if (comaSeparatedValue.length) {
					comaSeparatedValue = comaSeparatedValue + "," + values;
				} else {
					comaSeparatedValue = "" + values;
				}
				results[name] = comaSeparatedValue;
			});
		} else {
			results[name] = value;
		}
	})
	results["status"] = "active";
	return results;
}