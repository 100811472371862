import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		header: {
			position: "relative"
		},
		flex: {
			flex: 1
		},
	});
}