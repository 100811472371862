import React from "react";
import * as Mui from "@material-ui/core";

interface Color {
	value: string;
	label: string;
}

const colors: Color[] = [
	{
		value: "#999999",
		label: "Gray",
	},
	{
		value: "#d9534f",
		label: "Red",
	},
	{
		value: "#5cb85c",
		label: "Green",
	},
	{
		value: "#f0ad4e",
		label: "Orange",
	},
	{
		value: "#428bca",
		label: "Blue",
	},
	{
		value: "#5bc0de",
		label: "Light Blue",
	},
	{
		value: "#006dcc",
		label: "Blue Gradient",
	},
];

interface OwnProps {
	value: string;
	onChange: (value: string) => void;
}

interface Props extends OwnProps {}

interface State {
}

class Component extends React.Component<Props, State> {

	public render() {
		const { value, onChange } = this.props;
		return (
			<Mui.FormControl fullWidth variant="outlined" style={{marginTop: 10}}>
				<Mui.InputLabel>
					Color Scheme
				</Mui.InputLabel>
				<Mui.Select 
					fullWidth
					variant="outlined"
					value={value}
					input={
						<Mui.OutlinedInput 
							label="Color Scheme"
						/>
					}
					onChange={event => onChange(event.target.value as string)}
				>
					{colors.map((color, index) => (
						<Mui.MenuItem key={index} value={color.value}>
							{color.label}
						</Mui.MenuItem>
					))}
				</Mui.Select>
			</Mui.FormControl>
		);
	}
}

export const ColorDropdown = Component;
