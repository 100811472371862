import { arrayToById, arrayToByForeignId } from "redux/normalize";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { EmailSentResource } from "api/resource/email-sent";

export interface EmailSentsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: string]: EmailSentResource;
	};
	byLeadId: {
		[key: number]: string[];
	};
	bySavedSearchId: {
		[key: number]: string[];
	};
	byMarketReportId: {
		[key: number]: string[];
	};
};

const initialState: EmailSentsState = {
	byId: {},
	byLeadId: {},
	bySavedSearchId: {},
	byMarketReportId: {},
};

const fetchEmailSents = createAsyncThunk("emailSents/fetchEmailSents", async (payload: { leadId: number }, thunkAPI) => {
	thunkAPI.dispatch(emailSentsLoading());
	const result = api.getEmailSents(payload.leadId);
	result
		.then((emailSents) => thunkAPI.dispatch(emailSentsLoaded(emailSents)))
		.catch(error => thunkAPI.dispatch(emailSentsFailed(error)));
	return result;
});
 
export const emailSentsSlice = createSlice({
	name: "emailSents",
	initialState,
	reducers: {
		emailSentsLoading: (state) => {
			state.loading = true;
		},
		emailSentsLoaded: (state, action: PayloadAction<EmailSentResource[]>) => {
			const byId = arrayToById(action.payload, "id");
			const byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
			const bySavedSearchId = arrayToByForeignId(action.payload, "id", "savedSearchId");
			const byMarketReportId = arrayToByForeignId(action.payload, "id", "marketReportId");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
			state.bySavedSearchId = bySavedSearchId;
			state.byMarketReportId = byMarketReportId;
		},
		emailSentsFailed: (state, action: PayloadAction<Error>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
        default: (state) => {
            return state;
        }
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { emailSentsFailed, emailSentsLoaded, emailSentsLoading } = emailSentsSlice.actions;
export { fetchEmailSents };