import React from "react";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import Plot from "react-plotly.js";
import { LeadPipeline } from "model/lead-pipeline";
import { LeadPipelineType } from "type/lead-pipeline";
import moment from "moment";
import { RootState } from "redux/store";
import { urls } from "routes/urls";

interface Props {
	leadPipelines: LeadPipeline[];
}

const mapStateToProps = (state: RootState) => {
	return {
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Router.RouteComponentProps
{
}

class Component extends React.Component<Props> {
	public render() {
		const { leadPipelines, history } = this.props;
		let leadPipelineFrequencies = {
			uncontacted: 0,
			attemptedContact: 0,
			nurturing: 0,
			underContract: 0,
			closedYTD: 0,
		};

		leadPipelines.forEach(pipeline => {
			if (pipeline) {
				switch(pipeline.type) {
					case LeadPipelineType.UNCONTACTED:
						leadPipelineFrequencies.uncontacted++;
						break;
					case LeadPipelineType.ATTEMPTED_CONTACT:
						leadPipelineFrequencies.attemptedContact++;
						break;
					case LeadPipelineType.NURTURING:
						leadPipelineFrequencies.nurturing++;
						break;
					case LeadPipelineType.UNDER_CONTRACT:
						leadPipelineFrequencies.underContract++;
						break;
					case LeadPipelineType.CLOSED:
						if (pipeline.createdOn > moment().subtract(1, "y")) {
							leadPipelineFrequencies.closedYTD++;
						}
						break;
				}
			}
		});

		const {
			uncontacted,
			attemptedContact,
			nurturing,
			underContract,
			closedYTD,
		} = leadPipelineFrequencies;
		const total = Object.values(leadPipelineFrequencies).reduce(
			(sum, current) => sum + current,
		);
		const minHeight = total * 0.2; // Minimum height is 20% of total height
		const data = [
			{
				type: "funnelarea",
				values: [
					minHeight + uncontacted,
					minHeight + attemptedContact,
					minHeight + nurturing,
					minHeight + underContract,
					minHeight + closedYTD,
				],
				text: [
					"Uncontacted: " + uncontacted,
					"Attempted Contact: " + attemptedContact,
					"Nurturing: " + nurturing,
					"Under Contract: " + underContract,
					"Closed YTD: " + closedYTD,
				],
				labels: [
					Math.round((uncontacted / total) * 100) + "% Uncontacted",
					Math.round((attemptedContact / total) * 100) + "% Attempted Contact",
					Math.round((nurturing / total) * 100) + "% Nurturing",
					Math.round((underContract / total) * 100) + "% Under Contract",
					Math.round((closedYTD / total) * 100) + "% Closed YTD",
				],
				marker: {
					colors: ["#e95759", "#fdcc27", "#fa8a33", "#fe64af", "#4dcdac"],
					line: {
						width: 0,
					},
				},
				textinfo: "text",
				textfont: {
					color: "#ffffff",
				},
				hoverinfo: "x+label",
				hoverlabel: {
					bordercolor: "#cdcdcd",
					font: {
						color: "#ffffff",
					},
				},
			},
		];
		return (
			<Plot
				// @ts-ignore
				data={data}
				layout={{
					showlegend: false,
					margin: {
						t: 10,
						b: 10,
						l: 10,
						r: 10,
						pad: 0,
					},
					xaxis: {
						fixedrange: true,
					},
					yaxis: {
						fixedrange: true,
					},
				}}
				config={{
					displayModeBar: false,
					responsive: true,
				}}
				onHover={(event) => {
					// @ts-ignore
					const parts = event.points[0].text.split(":");
					const value = parseInt(parts[1]);
					if (value > 0) {
						// @ts-ignore
						event.event.toElement.style.cursor = "pointer";
					}
				}}
				onUnhover={(event) => {
					// @ts-ignore
					event.event.toElement.style.cursor = "default";
				}}
				onClick={(event) => {
					// @ts-ignore
					const parts = event.points[0].text.split(":");
					const label = parts[0];
					const value = parseInt(parts[1]);
					const leadType = LeadPipelineType.getByLabel(label);
					if (leadType && value > 0) {
						history.push(urls.leads(
							{
								leadsPageFilters: {
									pipeline: [leadType.id],
								},
							}
						));
					}
				}}
			/>
		);
	}
}

export const Chart = Router.withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);
