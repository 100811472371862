import React from "react";
import { connect } from "react-redux";
import { Right } from "../right";
import { RootState } from "redux/store";
import { getActiveLeads } from "redux/selector";
import { getActiveDripCampaignSubscriptionsForLead } from "redux/selector";
import { getActiveMarketReportSubscriptionsForLead } from "redux/selector";
import { getActiveSavedSearchesForLead } from "redux/selector";
import moment from "moment";
import * as Icons from "react-feather";
import {urls } from "routes/urls";
import { LeadColumnType } from "type/lead-column";

interface Props extends ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: RootState) => {
	const agentId = state.agents.value;
    let leads = getActiveLeads(state);

    if (agentId && agentId !== -1) {
        leads = leads.filter(lead => lead.agent?.id === agentId);
    } else if (agentId === -1) {
        leads = leads.filter(lead => !lead.agent?.id);
    }
	const subscribedLeads = leads.filter(lead => lead.subscribed);
	const leadsSubscriptions = subscribedLeads.map(lead => [
		...getActiveDripCampaignSubscriptionsForLead(state, lead.id),
		...getActiveMarketReportSubscriptionsForLead(state, lead.id),
		...getActiveSavedSearchesForLead(state, lead.id),
	]);

	return {
		leads,
		leadsSubscriptions,
	};
};

const Component = (props: Props) => {
	const { leads, leadsSubscriptions } = props;
	const numSubscribersAddedToday = leadsSubscriptions.filter(
		leadsSubscriptions =>
			!leadsSubscriptions.some(
				({ createdOn }) =>
				createdOn ? createdOn.format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") : undefined,
			),
	).length;
	const numUnsubscribedLeads = leads.filter(lead => !lead.subscribed).length;
	let numLeadsLeftToNurture =
		numUnsubscribedLeads + numSubscribersAddedToday < 5
			? numUnsubscribedLeads
			: 5 - numSubscribersAddedToday;

	if (numLeadsLeftToNurture < 0) {
		numLeadsLeftToNurture = 0;
	}
	return (
		<Right
			icon={<Icons.Mail />}
			prefix="Nurture"
			value={numLeadsLeftToNurture}
			suffix={
				(numUnsubscribedLeads === 0 ? "" : "unsubscribed ") +
				(numLeadsLeftToNurture === 1 ? "lead" : "leads")
			}
			url={urls.leads({
				sort: {
					id: LeadColumnType.SUBSCRIBED.id,
					desc: false,
				},
			})}
		/>
	);
};

export const SubscribersToNurture = connect(mapStateToProps)(Component);
