import React from "react"
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { Market } from "model/market";
import { bindActionCreators, Dispatch } from "redux";
import { updateMarket, createMarket, updateMarketNoLoading } from "redux/slice/markets";
import { Criterion } from "model/criterion";
import { RootState } from "redux/store";
import { Manual } from "./manual";
import { MarketListing } from "model/market-listing";
import { MarketListingTableRow } from "./market-listings-table";
import { fetchMarketListings } from "redux/slice/market-listings";
import { styles } from "./styles";
import { ListingSearch } from "component/shared/listing-search";
import { getUser } from "redux/selector";
import { SaveButton } from "component/shared/save-button";

interface Props extends
	ReturnType<typeof mapDispatchToProps>,
	ReturnType<typeof mapStateToProps>,
	Mui.WithStyles<typeof styles>
{
	market: Market,
	marketListings: MarketListing[],
}

interface State {
	name: string;
	type: "search" | "manual";
	criteria: Criterion[];
	saved: boolean;
}

const mapStateToProps = (state: RootState) => {
	return {
		user: getUser(state),
		loading: state.markets.loading
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	updateMarket,
	updateMarketNoLoading,
	createMarket,
	fetchMarketListings,
}, dispatch);

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props)
		fetchMarketListings();
		this.state = {
			name: this.props.market.name,
			type: this.idsCriterion() ? "manual" : "search",
			criteria: this.props.market.criteria,
			saved: false,
		}
	}
	public componentDidMount(): void {
		
	}

	private handleNameChange(value: string) {
		this.setState({ name: value })
	}

	private idsCriterion() {
		const idsCriterion = this.props.market.criteria && this.props.market.criteria.find((criterion) => criterion.name === "ids");
		return idsCriterion;
	}

	private async save(noLoading?: boolean) {
		const { updateMarket,updateMarketNoLoading, market, fetchMarketListings } = this.props;
		const { name, criteria } = this.state;
		if (criteria) {
			if (noLoading) {
				await updateMarketNoLoading({ market: {
					...market,
					name,
					criteria,
				}});
				fetchMarketListings();
			} else {
				await updateMarket({ market: {
					...market,
					name,
					criteria,
				}});
				fetchMarketListings();
			}
		}
	}

	private toggleSearchType() {
		const type = this.state.type === "search" ? "manual" : "search"
		this.setState({type, criteria: []});
	}

	private handleCriteriaChange(criteria: Criterion[]) {
		const board = criteria.find((c) => c.name === "boardId");
		if (!board) {
			const defaultBoard: Criterion = {
				name: "boardId",
				label: "boardId",
				value: 0,
			};
			this.setState({ criteria: [defaultBoard, ...criteria] });
		} else {
			this.setState({ criteria });
		}
	}

	public render() {
		const { name, type, saved } = this.state;
		const { marketListings, classes, user, loading } = this.props;
		const { market } = this.props;
		return (
			<Mui.FormControl size="small" className={classes.formControl} fullWidth>
				<Mui.Grid container spacing={2} direction="column">
					<Mui.Grid item>
						<Mui.FormControl fullWidth variant="outlined" size="small">
							<Mui.TextField
								value={name}
								label="Name"
								variant="outlined"
								type="text"
								required
								size="small"
								onChange={e => this.handleNameChange(e.target.value)}
								helperText={!name && "Market must have a name"}
								FormHelperTextProps={{className: classes.helperText}}
							/>
						</Mui.FormControl>
					</Mui.Grid>
					<Mui.Grid item>
						<Mui.FormControl fullWidth variant="outlined" size="small" className={classes.typeSelect}>
							<Mui.InputLabel>
								Type
							</Mui.InputLabel>
							<Mui.Select
								required
								label="Type"
								value={type}
								onChange={() => this.toggleSearchType()}
							>
								<Mui.MenuItem value="search">
									Search
								</Mui.MenuItem>
								<Mui.MenuItem value="manual">
									Manual (add listing by MLS #)
								</Mui.MenuItem>
							</Mui.Select>
						</Mui.FormControl>
					</Mui.Grid>
					{type === "manual" && (
						<Mui.Grid item>
							<Manual 
								criteria={market.criteria} 
								onUpdate={(criterion) => {
								this.setState({ criteria: [criterion] });
							}}/>						
						</Mui.Grid>
					)}
					<Mui.Grid item className={classes.saveRow}>
						<SaveButton 
							loading={(!!loading) && saved} 
							label={{
								primary: "Save", 
								inProgress: "Saving...", 
								completed: "Saved" 
							}}
							snackBar={true}
							onClick={() => {
								this.setState({saved: true});
								this.save();
							}}
							onReset={() => this.setState({saved: false})}
							duration={3000}
							disabled={!name}
						/>
					</Mui.Grid>
					<Mui.Grid item>
						{type === "search" && (
							<ListingSearch
								user={user}
								criteria={market.criteria} 
								hideSaveButton={true}
								onCriteriaChange={(criteria) => this.handleCriteriaChange(criteria)}
								hideStatusField={true}
								disableRegistration={true}
								hideHeartButton={true}
								hideModal={true}
							/> 
						)}
						{this.idsCriterion() && type === "manual" && (
							<MarketListingTableRow
								marketListings={marketListings}
								market={market}
								onCriteriaChange={(criterion, noLoading) => {
									if (criterion) {
										this.setState({criteria: [criterion]}, () => {
											this.save(noLoading);
										});
									}
								}}
							/>
						)}
					</Mui.Grid>
				</Mui.Grid>
			</Mui.FormControl>
			
		)
	}
}

export const MarketEditCriteria = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));