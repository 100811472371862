import React from "react";
import { DashboardLayout } from "component/layout/dashboard";
import * as Mui from "@material-ui/core";
import { LeadDetails } from "./lead-details";
import { NotesCard } from "./notes-card";
import { SubscriptionsTab } from "./subscriptions-tab";
import { HistoryTab } from "./history-tab";
import { PropertyOrganizerTab } from "./property-organizer-tab";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { getLeadById } from "redux/selector";
import { styles } from "./style";
import { LeadAvatar } from "component/shared/lead-avatar";
import { NotFoundPage } from "component/page/not-found";
import { PageProps } from "shared/page-props";
import * as Router from "react-router-dom";
import { LoadingPage } from "../loading";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { getBookmarkTagForLead } from "redux/selector";
import { getTasksForLead } from "redux/selector";
import { fetchLeadActivities } from "redux/slice/lead-activities";
import { fetchEmailSents } from "redux/slice/email-sents";
import { fetchEmailClicks } from "redux/slice/email-clicks";
import { fetchSavedListings } from "redux/slice/saved-listings";
import { Pipeline } from "./pipeline";
import { Bookmark } from "component/shared/bookmark";
import { TasksCard } from "./tasks-card";
import { Lead } from "model/lead";
import { Confetti } from "component/shared/confetti";
import { LeadActivityFilter } from "../lead-activity/lead-activity-filter";

const mapStateToProps = (
	state: RootState,
	ownProps: Router.RouteComponentProps<RouteParams>
) => {
	const leadId = getId(ownProps);
	return {
		lead: getLeadById(state, leadId) as Lead,
		loading: state.leads.loading,
		bookmarkTag: getBookmarkTagForLead(state, leadId),
		tasks: getTasksForLead(state, leadId)
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	fetchLeadActivities,
	fetchEmailSents,
	fetchEmailClicks,
	fetchSavedListings,
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	PageProps,
	Router.RouteComponentProps<RouteParams>,
	Mui.WithStyles<typeof styles>,
	WithMediaQuery
{
}

interface State {
	tabIndex: number;
	confetti: boolean;
}

interface RouteParams {
	id: string;
}

interface Tab {
	label: string;
	view: React.ReactElement;
}

const getId = (props: Router.RouteComponentProps<RouteParams>) => {
	return parseInt(props.match.params.id);
};

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tabIndex: 0,
			confetti: false,
		};
	}

	public componentDidMount() {
		const { user } = this.props;
		const leadId = getId(this.props);
		this.props.fetchLeadActivities({leadId});
		if (user.permissions.marketReport || user.permissions.leadSaveSearch) {
			this.props.fetchEmailSents({leadId});
			this.props.fetchEmailClicks({leadId});
		}
		if (user.permissions.leadSaveListing) {
			this.props.fetchSavedListings({leadId});
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.lead && this.props.lead && this.props.lead.pipeline) {
			const { pipeline: prevPipeline } = prevProps.lead;
			const { pipeline } = this.props.lead;
			if ((!prevPipeline || (!prevPipeline.type.underContract && !prevPipeline.type.closed)) && (pipeline.type.underContract || pipeline.type.closed)) {
				this.setState({ confetti: true });
			}
			if ((prevPipeline && (prevPipeline.type.underContract || prevPipeline.type.closed)) && (!pipeline.type.underContract && !pipeline.type.closed)) {
				this.setState({ confetti: false });
			}
		}
	}

	private getSubscriptionTabPermission() {
		const { user } = this.props;
		if (user.admin) {
			return true;
		}
		return user.permissions.modifySubscriptions && (
			user.permissions.campaigns || 
			user.permissions.report || 
			user.permissions.leadEmailUpdates
		);
	}

	private get tabs(): Tab[] {
		const { lead, user } = this.props;
		let tabs: Tab[] = [];
		tabs.push({ label: "Activity", view: <LeadActivityFilter lead={lead} isAgentPage /> });
		if (this. getSubscriptionTabPermission()) {
			tabs.push({
				label: "Subscriptions",
				view: (
					<SubscriptionsTab
						lead={lead}
						showPropertyOrganizer={() => this.setState({ tabIndex: 2 })}
						user={user}
					/>
				),
			});
		}
		if (user.permissions.leadPropertyOrganizer) {
			tabs.push({
				label: "Property Organizer",
				view: <PropertyOrganizerTab lead={lead} user={user} />,
			});
		}
		if (user.permissions.crmNotes) {
			tabs.push({
				label: "History",
				view: <HistoryTab lead={lead} user={user} />,
			});
		}

		return tabs;
	}

	public render() {
		const { user, lead, loading, classes } = this.props;
		const { tabIndex, confetti } = this.state;
		if (!lead) {
			if (loading) {
				return <LoadingPage />;
			} else {
				return <NotFoundPage />;
			}
		}
		const title = lead.label;
		const isMobile = this.props.mediaQuery;

		return (
			<DashboardLayout
				permitted={user && user.permissions.leads}
				title={title}
				header={
					<div className={classes.header}>
						<Mui.Typography variant="h1">
							<LeadAvatar lead={lead} diameter={50} style={{ marginRight: 10 }} />
							{title}
						</Mui.Typography>
						{user.permissions.crmTags && <Bookmark leadId={lead.id} />}
					</div>
				}
			>
				{confetti && (
					<Confetti
						onStop={() => this.setState({ confetti: false })}
					/>
				)}
				<Mui.Grid container item spacing={2}>
					<Mui.Grid
						container
						item
						xs={12}
						lg={4}
						spacing={2}
						alignContent="flex-start"
					>
						{user.permissions.crmPipeline && (
							<Mui.Hidden lgUp>
								<Mui.Grid item xs={12}>
									<Pipeline lead={lead} />
								</Mui.Grid>
							</Mui.Hidden>
						)}
						<Mui.Grid item xs={12} md={8} lg={12}>
							<LeadDetails user={user} lead={lead} />
						</Mui.Grid>
						{user.permissions.crmTasks && (
							<Mui.Grid item xs={12} md={8} lg={12}>
								<TasksCard lead={lead} />
							</Mui.Grid>
						)}
						{!user.permissions.crmNotes && (
							<Mui.Grid item xs={12}>
								<NotesCard lead={lead} />
							</Mui.Grid>
						)}
					</Mui.Grid>
					<Mui.Grid item xs={12} lg={8} spacing={2} alignContent="flex-start">
						{user.permissions.crmPipeline && (
							<Mui.Hidden mdDown>
								<Mui.Grid item style={{ width: "100%" }}>
									<Pipeline lead={lead} />
								</Mui.Grid>
							</Mui.Hidden>
						)}
						<Mui.Grid item style={{ width: "100%" }}>
							<Mui.Tabs
								value={tabIndex}
								variant={isMobile ? "scrollable" : "standard"}
								onChange={(event, tabIndex) => this.setState({ tabIndex })}
								centered={!isMobile}
								scrollButtons={isMobile ? "on" : "off"}
								textColor="secondary"
							>
								{this.tabs.map((tab) => (
									<Mui.Tab label={tab.label} />
								))}
							</Mui.Tabs>
						</Mui.Grid>
						<Mui.Grid item style={{ height: 25 }} />
						<Mui.Grid item>{this.tabs[tabIndex].view}</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</DashboardLayout>
		);
	}
}

export const LeadPage = withMediaQuery("(max-width:600px)")(
	Mui.withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(Component),
	),
);