import React from "react";
import * as Mui from "@material-ui/core";
import { IntegrationType } from "type/integration-type";
import { AddButton } from "../add-button";

interface Props {
	integrationType: IntegrationType;
	onClose: Function;
}

class Component extends React.Component<Props> {

	public render() {
		const { integrationType, onClose } = this.props;
		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.Typography>For easy Wix integration, install the iHomefinder Real Estate App from the Wix Marketplace.</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item>
					<Mui.Button
						variant="contained"
						color="secondary"
						href="https://www.wix.com/app-market/ihomefinder-real-estate"
						target="_blank"
					>
						Install the App
					</Mui.Button>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const WixIntegration = Component;