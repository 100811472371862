import { Route } from "./route";
import * as paths from "./paths";
import { LoginPage } from "component/page/login";
import { LeadsPage } from "component/page/leads";
import { LeadPage } from "component/page/lead";
import { LeadDashboardPage } from "component/page/lead-dashboard";
import { TasksPage } from "component/page/tasks";
import { LeadActivityPage } from "component/page/lead-activity";
import { MarketsPage } from "component/page/markets";
import { ListingsActivePage } from "component/page/listings-active";
import { ReportsOverviewPage } from "component/page/reports-overview";
import { SettingsAccountPage } from "component/page/settings-account";
import { CampaignsPage } from "component/page/campaigns";
import { LeadImportPage } from "component/page/lead-import";
import { LeadExportPage } from "component/page/lead-export";
import { MarketSettingsPage } from "component/page/market-settings";
import { ListingsSupplementalPage } from "component/page/listings-supplemental";
import { ListingsOffMarketPage } from "component/page/listings-off-market";
import { RedirectPage } from "component/page/redirect";
import { ReportsEmailActivityPage } from "component/page/reports-email-activity";
import { ReportsSavedSearchesPage } from "component/page/reports-saved-searches";
import { ReportsSavedListingsPage } from "component/page/reports-saved-listings";
import { ReportsLeadsPage } from "component/page/reports-leads";
import { ReportsMobilePage } from "component/page/reports-mobile";
import { MarketPage } from "component/page/market";
import { CampaignPage } from "component/page/campaign";
import { CampaignNewEmailPage } from "component/page/campaign-new-email";
import { CampaignDraftEmailsPage } from "component/page/campaign-draft-emails";
import { CampaignDraftEmailPage } from "component/page/campaign-draft-email";
import { CampaignSentEmailsPage } from "component/page/campaign-sent-emails";
import { CampaignSentEmailPage } from "component/page/campaign-sent-email";
import { CampaignAutomatedGreetingsPage } from "component/page/campaign-automated-greetings";
import { CampaignRoutingPage } from "component/page/campaign-routing";
import { CampaignSettingsPage } from "component/page/campaign-settings";
import { SettingsOverviewPage } from "component/page/settings-overview";
import { SettingsLinksPage } from "component/page/settings-links";
import { SettingsPasswordPage } from "component/page/settings-password";
import { SettingsBillingPage } from "component/page/settings-billing";
import { SettingsMlsPage } from "component/page/settings-mls";
import { SettingsIntegrationsPage } from "component/page/settings-integrations";
import { SettingsPluginPage } from "component/page/settings-plugin";
import { SettingsBrandingPage } from "component/page/settings-branding";
import { SettingsTemplatesPage } from "component/page/settings-templates";
import { SettingsWidgetsPage } from "component/page/settings-widgets";
import { SettingsCssPage } from "component/page/settings-css";
import { SettingsFramingPage } from "component/page/settings-framing";
import { SettingsSubHeadsPage } from "component/page/settings-sub-heads";
import { SettingsPageSpecificBrandingPage } from "component/page/settings-page-specific-branding";
import { SettingsEmailPage } from "component/page/settings-email";
import { SettingsEmailBrandingPage } from "component/page/settings-email-branding";
import { SettingsCustomizationPage } from "component/page/settings-email-customization";
import { SettingsEmailRoutingPage } from "component/page/settings-email-routing";
import { SettingsEmailGeographicRoutingPage } from "component/page/settings-email-geographic-routing";
import { SettingsPropertyTypesPage } from "component/page/settings-property-types";
import { SettingsLocationCustomizationPage } from "component/page/settings-location-customization";
import { SettingsSearchCitiesPage } from "component/page/settings-search-cities";
import { SettingsSearchNeighborhoodsPage } from "component/page/settings-search-neighborhoods";
import { SettingsOpenHousePage } from "component/page/settings-open-house";
import { SettingsMapSearchPage } from "component/page/settings-map-search";
import { SettingsListingResultsPage } from "component/page/settings-listing-results";
import { SettingsListingDetailsPage } from "component/page/settings-listing-details";
import { SettingsLeadRoutingPage } from "component/page/settings-lead-routing";
import { SettingsUserRegistrationPage } from "component/page/settings-user-registration";
import { SettingsContactFormsPage } from "component/page/settings-contact-forms";
import { SettingsPropertyOrganizerPage } from "component/page/settings-property-organizer"; 
import { SettingsFacebookPage } from "component/page/settings-facebook";
import { SettingsMobilePage } from "component/page/settings-mobile";
import { SettingsBrokerPage } from "component/page/settings-broker";
import { SettingsAgentsPage } from "component/page/settings-agents";
import { SettingsOfficesPage } from "component/page/settings-offices";
import { SettingsAgentPage } from "component/page/settings-agent";
import { SettingsOfficePage } from "component/page/settings-office";
import { SettingsMortgageCalculator } from "component/page/settings-mortgage-calculator";
import { SettingsVersionPage } from "component/page/settings-version";
import { SettingsPagesPage } from "component/page/settings-pages";
import { SupportCenterPage } from "component/page/support-center";
import { OneBusinessDashboardPage } from "component/page/one-business-dashboard";
import { OneLeadsPage } from "component/page/one-leads";
import { OneAddNewLeadPage } from "component/page/one-add-new-lead";
import { OneActiveProspectsPage } from "component/page/one-active-prospects";
import { OnePendingProspectsPage } from "component/page/one-pending-prospects";
import { OneClosedProspectsPage } from "component/page/one-closed-prospects";
import { OneContactManagementPage } from "component/page/one-contact-management";
import { OneLeadRoutingPage } from "component/page/one-lead-routing";
import { OneListManagerPage } from "component/page/one-list-manager";
import { OneBlastEmailPage } from "component/page/one-blast-email";
import { OneEmailUsagePage } from "component/page/one-email-usage";
import { OneEmailQueuePage } from "component/page/one-email-queue";
import { OneGroupsPage } from "component/page/one-groups";
import { OneCampaignsPage } from "component/page/one-campaigns";
import { OneTeamListPage } from "component/page/one-team-list";
import { OneBusinessProcessSettingsPage } from "component/page/one-business-process-settings";
import { OneActionPlanTemplateManagementPage } from "component/page/one-action-plan-template-management";
import { OneMarketingExpensesPage } from "component/page/one-marketing-expenses";
import { OneReportsPage } from "component/page/one-reports";
import { OneCalendarPage } from "component/page/one-calendar";
import { OneMarketsPage } from "component/page/one-markets";
import { CampaignTextingPage } from "component/page/campaign-texting";
import { SettingsProfilePage } from "component/page/settings-profile";
import { CreateCampaignMessagePage } from "component/page/campaign-message/create-campaign-message";
import { EditCampaignMessagePage } from "component/page/campaign-message/edit-campaign-message";
import { CampaignUpdateTextMessagePage } from "component/page/campaign-text-message/update";
import { CampaignNewTextMessagePage } from "component/page/campaign-text-message/new";

export const routes: Route[] = [
	{
		path: paths.redirect,
		component: RedirectPage,
		authenticated: false,
	},
	{
		path: paths.login,
		component: LoginPage,
		authenticated: false,
	},
	{
		path: paths.lead,
		component: LeadPage,
		authenticated: true,
	},
	{
		path: paths.leads,
		component: LeadsPage,
		authenticated: true,
	},
	{
		path: paths.leadDashboard,
		component: LeadDashboardPage,
		authenticated: true,
	},
	{
		path: paths.tasks,
		component: TasksPage,
		authenticated: true,
	},
	{
		path: paths.leadActivity,
		component: LeadActivityPage,
		authenticated: true,
	},
	{
		path: paths.settingsleadRouting,
		component: SettingsLeadRoutingPage,
		authenticated: true,
	},
	{
		path: paths.LeadImport,
		component: LeadImportPage,
		authenticated: true,
	},
	{
		path: paths.leadExport,
		component: LeadExportPage,
		authenticated: true,
	},
	{
		path: paths.markets,
		component: MarketsPage,
		authenticated: true,
	},
	{
		path: paths.market,
		component: MarketPage,
		authenticated: true,
	},
	{
		path: paths.marketTab,
		component: MarketPage,
		authenticated: true,
	},
	{
		path: paths.marketSettings,
		component: MarketSettingsPage,
		authenticated: true,
	},
	{
		path: paths.campaigns,
		component: CampaignsPage,
		authenticated: true,
	},
	{
		path: paths.campaign,
		component: CampaignPage,
		authenticated: true,
	},
	{
		path: paths.campaignCampaignMessage,
		component: EditCampaignMessagePage,
		authenticated: true,
	},
	{
		path: paths.campaignCampaignEmailMessageNew,
		component: CreateCampaignMessagePage,
		authenticated: true,
	},
	{
		path: paths.campaignCampaignTextMessage,
		component: CampaignUpdateTextMessagePage,
		authenticated: true,
	},
	{
		path: paths.campaignCampaignTextMessageNew,
		component: CampaignNewTextMessagePage,
		authenticated: true,
	},
	{
		path: paths.campaignNewEmail,
		component: CampaignNewEmailPage,
		authenticated: true,
	},
	{
		path: paths.campaignsTexting,
		component: CampaignTextingPage,
		authenticated: true,
	},
	{
		path: paths.campaignDraftEmails,
		component: CampaignDraftEmailsPage,
		authenticated: true,
	},
	{
		path: paths.campaignDraftEmail,
		component: CampaignDraftEmailPage,
		authenticated: true,
	},
	{
		path: paths.campaignSentEmails,
		component: CampaignSentEmailsPage,
		authenticated: true,
	},
	{
		path: paths.campaignSentEmail,
		component: CampaignSentEmailPage,
		authenticated: true,
	},
	{
		path: paths.campaignAutomatedGreetings,
		component: CampaignAutomatedGreetingsPage,
		authenticated: true,
	},
	{
		path: paths.campaignsRouting,
		component: CampaignRoutingPage,
		authenticated: true,
	},
	{
		path: paths.campaignsSettings,
		component: CampaignSettingsPage,
		authenticated: true,
	},
	{
		path: paths.listingsActive,
		component: ListingsActivePage,
		authenticated: true,
	},
	{
		path: paths.listingsOffMarket,
		component: ListingsOffMarketPage,
		authenticated: true,
	},
	{
		path: paths.listingsSupplemental,
		component: ListingsSupplementalPage,
		authenticated: true,
	},
	{
		path: paths.reportsOverview,
		component: ReportsOverviewPage,
		authenticated: true,
	},
	{
		path: paths.reportsEmailActivity,
		component: ReportsEmailActivityPage,
		authenticated: true,
	},
	{
		path: paths.reportsSavedSearches,
		component: ReportsSavedSearchesPage,
		authenticated: true,
	},
	{
		path: paths.reportSavedListings,
		component: ReportsSavedListingsPage,
		authenticated: true,
	},
	{
		path: paths.reportsLeads,
		component: ReportsLeadsPage,
		authenticated: true,
	},
	{
		path: paths.reportsMobile,
		component: ReportsMobilePage,
		authenticated: true,
	},
	{
		path: paths.settingsOverview,
		component: SettingsOverviewPage,
		authenticated: true,
	},
	{
		path: paths.settingsPageLinks,
		component: SettingsLinksPage,
		authenticated: true,
	},
	{
		path: paths.settingsAccount,
		component: SettingsAccountPage,
		authenticated: true,
	},
	{
		path: paths.settingsPassword,
		component: SettingsPasswordPage,
		authenticated: true,
	},
	{
		path: paths.settingsBilling,
		component: SettingsBillingPage,
		authenticated: true,
	},
	{
		path: paths.settingsMls,
		component: SettingsMlsPage,
		authenticated: true,
	},
	{
		path: paths.settingsIntegrations,
		component: SettingsIntegrationsPage,
		authenticated: true,
	},
	{
		path: paths.settingsPlugin,
		component: SettingsPluginPage,
		authenticated: true,
	},
	{
		path: paths.settingsBranding,
		component: SettingsBrandingPage,
		authenticated: true,
	},
	{
		path: paths.settingsTemplates,
		component: SettingsTemplatesPage,
		authenticated: true,
	},
	{
		path: paths.settingsWidgets,
		component: SettingsWidgetsPage,
		authenticated: true,
	},
	{
		path: paths.settingsCss,
		component: SettingsCssPage,
		authenticated: true,
	},
	{
		path: paths.settingsFraming,
		component: SettingsFramingPage,
		authenticated: true,
	},
	{
		path: paths.settingsSubHeads,
		component: SettingsSubHeadsPage,
		authenticated: true,
	},
	{
		path: paths.settingsPageSpecificBranding,
		component: SettingsPageSpecificBrandingPage,
		authenticated: true,
	},
	{
		path: paths.settingsEmail,
		component: SettingsEmailPage,
		authenticated: true,
	},
	{
		path: paths.settingsEmailBranding,
		component: SettingsEmailBrandingPage,
		authenticated: true,
	},
	{
		path: paths.settingsEmailCustomization,
		component: SettingsCustomizationPage,
		authenticated: true,
	},
	{
		path: paths.settingsEmailRouting,
		component: SettingsEmailRoutingPage,
		authenticated: true,
	},
	{
		path: paths.settingsEmailGeographicRouting,
		component: SettingsEmailGeographicRoutingPage,
		authenticated: true,
	},
	{
		path: paths.settingsPropertyTypes,
		component: SettingsPropertyTypesPage,
		authenticated: true,
	},
	{
		path: paths.settingsLocationCustomization,
		component: SettingsLocationCustomizationPage,
		authenticated: true,
	},
	{
		path: paths.settingsSearchCities,
		component: SettingsSearchCitiesPage,
		authenticated: true,
	},
	{
		path: paths.settingsSearchNeighborhoods,
		component: SettingsSearchNeighborhoodsPage,
		authenticated: true,
	},
	{
		path: paths.settingsOpenHouse,
		component: SettingsOpenHousePage,
		authenticated: true,
	},
	{
		path: paths.settingsMapSearch,
		component: SettingsMapSearchPage,
		authenticated: true,
	},
	{
		path: paths.settingsListingResults,
		component: SettingsListingResultsPage,
		authenticated: true,
	},
	{
		path: paths.settingsListingDetails,
		component: SettingsListingDetailsPage,
		authenticated: true,
	},
	{
		path: paths.settingsUserRegistration,
		component: SettingsUserRegistrationPage,
		authenticated: true,
	},
	{
		path: paths.settingsContactForms,
		component: SettingsContactFormsPage,
		authenticated: true,
	},
	{
		path: paths.settingsPropertyOrganizer,
		component: SettingsPropertyOrganizerPage,
		authenticated: true,
	},
	{
		path: paths.settingsFacebook,
		component: SettingsFacebookPage,
		authenticated: true,
	},
	{
		path: paths.settingsMobile,
		component: SettingsMobilePage,
		authenticated: true,
	},
	{
		path: paths.settingsBroker,
		component: SettingsBrokerPage,
		authenticated: true,
	},
	{
		path: paths.settingsAgents,
		component: SettingsAgentsPage,
		authenticated: true,
	},
	{
		path: paths.settingsAgent,
		component: SettingsAgentPage,
		authenticated: true,
	},
	{
		path: paths.settingsProfile,
		component: SettingsProfilePage,
		authenticated: true,
	},
	{
		path: paths.settingsOffices,
		component: SettingsOfficesPage,
		authenticated: true,
	},
	{
		path: paths.settingsOffice,
		component: SettingsOfficePage,
		authenticated: true,
	},
	{
		path: paths.settingsMortgageCalculator,
		component: SettingsMortgageCalculator,
		authenticated: true,
	},
	{
		path: paths.settingsVersion,
		component: SettingsVersionPage,
		authenticated: true,
	},
	{
		path: paths.settingsPages,
		component: SettingsPagesPage,
		authenticated: true,
	},
	{
		path: paths.settingsAgent,
		component: SettingsProfilePage,
		authenticated: true,
	},
	{
		path: paths.supportCenter,
		component: SupportCenterPage,
		authenticated: true,
	},
	{
		path: paths.oneBusinessDashboard,
		component: OneBusinessDashboardPage,
		authenticated: true,
	},
	{
		path: paths.oneLeads,
		component: OneLeadsPage,
		authenticated: true,
	},
	{
		path: paths.oneContactManagement,
		component: OneContactManagementPage,
		authenticated: true,
	},
	{
		path: paths.oneAddNewLead,
		component: OneAddNewLeadPage,
		authenticated: true,
	},
	{
		path: paths.oneActiveProspects,
		component: OneActiveProspectsPage,
		authenticated: true,
	},
	{
		path: paths.onePendingProspects,
		component: OnePendingProspectsPage,
		authenticated: true,
	},
	{
		path: paths.oneClosedProspects,
		component: OneClosedProspectsPage,
		authenticated: true,
	},
	{
		path: paths.oneLeadRouting,
		component: OneLeadRoutingPage,
		authenticated: true,
	},
	{
		path: paths.oneListManager,
		component: OneListManagerPage,
		authenticated: true,
	},
	{
		path: paths.oneBlastEmail,
		component: OneBlastEmailPage,
		authenticated: true,
	},
	{
		path: paths.oneEmailUsage,
		component: OneEmailUsagePage,
		authenticated: true,
	},
	{
		path: paths.oneEmailQueue,
		component: OneEmailQueuePage,
		authenticated: true,
	},
	{
		path: paths.oneGroups,
		component: OneGroupsPage,
		authenticated: true,
	},
	{
		path: paths.oneCampaigns,
		component: OneCampaignsPage,
		authenticated: true,
	},
	{
		path: paths.oneTeamList,
		component: OneTeamListPage,
		authenticated: true,
	},
	{
		path: paths.oneBusinessProcessSettings,
		component: OneBusinessProcessSettingsPage,
		authenticated: true,
	},
	{
		path: paths.oneActionPlanTemplateManagement,
		component: OneActionPlanTemplateManagementPage,
		authenticated: true,
	},
	{
		path: paths.oneMarketingExpenses,
		component: OneMarketingExpensesPage,
		authenticated: true,
	},
	{
		path: paths.oneReports,
		component: OneReportsPage,
		authenticated: true,
	},
	{
		path: paths.oneCalendar,
		component: OneCalendarPage,
		authenticated: true,
	},
	{
		path: paths.oneMarkets,
		component: OneMarketsPage,
		authenticated: true
	}
];