import React from "react";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import { TasksComponent } from "component/shared/tasks-component";
import { getActiveLeadTasksDueThisWeek, getLeadsByUnassignedAgent, getTasks, getUser } from "redux/selector";
import { Task } from "model/task";
import { LoadingIndicator } from "component/shared/loading-indicator";
import { getLeadsByAgentId } from "redux/selector";

const mapStateToProps = (state: RootState) => {
	const tasksDueThisWeek = getActiveLeadTasksDueThisWeek(state);
	const agentId = state.agents.value;
	const leadsByAgent = getLeadsByAgentId(state, agentId);
	const leadsByUnassigned = getLeadsByUnassignedAgent(state);
	const unassigned = agentId === -1
	const user = getUser(state);
	let tasks;
	if (!agentId) {
		if (!unassigned) {
			tasks = tasksDueThisWeek.slice(0, 5);
		} else {
			tasks = tasksDueThisWeek
				.filter((task, index, array) => {
					if (index >= 5) return false; // Early termination
					return leadsByUnassigned.some(agent => agent.id === task.leadId);
				});
		}
	} else {
		tasks = tasksDueThisWeek
			.filter((task, index, array) => {
				if (index >= 5) return false; // Early termination
				return leadsByAgent.some(agent => agent.id === task.leadId);
			});
	}
	return {
		tasks,
		loading: state.tasks.loading,
		user,
	};
};

interface Props extends ReturnType<typeof mapStateToProps> {
	tasks: Task[];
}

class Component extends React.Component<Props> {
	render() {
		const { tasks, loading, user } = this.props;
		const filteredTasks = tasks.filter(task => {
			if (user.admin) {
				return task
			} else {
				return task.agentId === user.agentId
			}
		})

		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item xs>
					<Mui.Typography variant="h3">Tasks this Week</Mui.Typography>
				</Mui.Grid>
				<Mui.Grid item xs>
					<Mui.Card>
						<Mui.CardContent>
							{!!loading && (
								<Mui.Grid container justifyContent="center">
									<Mui.Grid item>
										<LoadingIndicator />
									</Mui.Grid>
								</Mui.Grid>
							)}
							{!loading && !filteredTasks.length && (
								<Mui.Typography align="center">You have no tasks due this week.</Mui.Typography>
							)}
							{!loading && !!filteredTasks.length && <TasksComponent tasks={filteredTasks}/>}
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const Tasks = connect(mapStateToProps)(Component);
