import { arrayToById, objectToById } from "redux/normalize";
import { PageResource } from "api/resource/page";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { Page } from "model/page";
import { loginReset } from "redux/slice/authentication";

export interface PagesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: PageResource;
	};
};

const initialState: PagesState = {
	byId: {},
};

const fetchPages = createAsyncThunk("pages/fetchPages", async (payload, thunkAPI) => {
	const result = api.getPages();
	result
		.then((pages) => thunkAPI.dispatch(pagesLoaded(pages)))
		.catch(error => thunkAPI.dispatch(pagesFailed(error)));
	return result;
});

const savePage = createAsyncThunk("pages/savePage", async (payload: {page: Page}, thunkAPI) => {
	thunkAPI.dispatch(pagesLoading());
	const result = api.savePage(payload.page);
	result
		.then((page) => thunkAPI.dispatch(pageLoaded(page)))
		.catch(error => thunkAPI.dispatch(pagesFailed(error)));
	return result;
});

export const pagesSlice = createSlice({
	name: "pages",
	initialState,
	reducers: {
		pagesLoading: (state) => {
			state.loading = true;
		},
		pagesLoaded: (state, action: PayloadAction<any[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		pageLoaded: (state, action: PayloadAction<any>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		pagesFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	}
});

export const { pagesLoading, pagesLoaded, pageLoaded, pagesFailed } = pagesSlice.actions;
export { fetchPages, savePage };
