import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import * as api from "api/api";
import { NewTask, Task } from "model/task";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { TaskResource } from "api/resource/task";

export interface TasksState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: TaskResource;
	};
	byLeadId: {
		[key: number]: number[];
	};
};

const initialState: TasksState = {
	byId: {},
	byLeadId: {},
};

const fetchTasks = createAsyncThunk("tasks/fetchTasks", async (payload, thunkAPI) => {
	thunkAPI.dispatch(tasksLoading());
	const result = api.getTasks();
	result
		.then((tasks) => thunkAPI.dispatch(tasksLoaded(tasks)))
		.catch(error => thunkAPI.dispatch(tasksFailed(error)));
	return result;
});

const createTask = createAsyncThunk("tasks/createTask", async (payload: { task: NewTask }, thunkAPI) => {
	thunkAPI.dispatch(tasksLoading());
	const result = api.createTask(payload.task);
	result
		.then((task) => thunkAPI.dispatch(taskLoaded(task)))
		.catch(error => thunkAPI.dispatch(tasksFailed(error)));
	return result;
});

const updateTask = createAsyncThunk("tasks/updateTask", async (payload: { task: Task }, thunkAPI) => {
	thunkAPI.dispatch(tasksLoading());
	const result = api.updateTask(payload.task);
	result
		.then((task) => thunkAPI.dispatch(taskLoaded(task)))
		.catch(error => thunkAPI.dispatch(tasksFailed(error)));
	return result;
});

export const tasksSlice = createSlice({
	name: "tasks",
	initialState,
	reducers: {
		tasksLoading: (state) => {
			state.loading = true;
		},
		tasksLoaded: (state, action: PayloadAction<TaskResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
		},
		taskLoaded: (state, action: PayloadAction<TaskResource>) => {
			const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
		},
		tasksFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { tasksLoading, tasksLoaded, taskLoaded, tasksFailed } = tasksSlice.actions;
export { fetchTasks, createTask, updateTask };