import { Board } from "model/board";

export const createListingId = (listingNumber: string, board: Board) => {
	return `${listingNumber}_${board.id}`;
}

export const createListingIdWithBoardId = (listingNumber: string, boardId: number) => {
	return `${listingNumber}_${boardId}`;
}

export const listingIdToParts = (listingId: string) => {
	const parts = listingId.split("_");
	if (parts.length === 2) {
		return {
			listingNumber: parts[0],
			boardId: parseFloat(parts[1]),
		}
	}
}