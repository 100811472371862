import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./style";
import { Lead } from "model/lead";
import { getActiveDripCampaigns, getUnsubscribedCampaigns } from "redux/selector";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { createCampaignSubscription } from "redux/slice/campaign-subscriptions";
import { Dialog } from "component/shared/dialog";
import { Select } from "component/shared/select";
import { CampaignSubscriptionStatus } from "type/campaign-subscription-status";

const mapStateToProps = (state: RootState, ownProps: any) => {
	return {
		campaigns: getActiveDripCampaigns(state),
		unsubscribedCampaigns: getUnsubscribedCampaigns(state, ownProps.lead.id)
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaignSubscription,
}, dispatch);

interface Props extends
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
{
	open: boolean;
	onClose: Function;
	lead: Lead;
}

interface State {
	selectedCampaignId?: number;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			selectedCampaignId: 0,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!prevProps.open && this.props.open) {
			this.setState({
				selectedCampaignId: 0,
			})
		}
	}

	private onSave = () => {
		const { lead, campaigns, onClose, createCampaignSubscription } = this.props;
		const { selectedCampaignId } = this.state;
		const campaign = campaigns.find(item => item.id === selectedCampaignId);
		if (campaign) {
			createCampaignSubscription({ subscription: {
				lead,
				campaign,
				status: CampaignSubscriptionStatus.ACTIVE,
			}});
		};
		onClose();
	}

	public render() {
		const { selectedCampaignId } = this.state;
		const { classes, open, onClose, unsubscribedCampaigns } = this.props;
		return (
			<Dialog open={open} onClose={() => onClose()} fullWidth={true}>
				<Mui.DialogTitle>Add to Campaign</Mui.DialogTitle>
				<Mui.DialogContent className={classes.dialogContent}>
					<Mui.FormControl className={classes.formControl}>
						<Select
							placeholder="Select a Campaign"
							value={selectedCampaignId}
							options={unsubscribedCampaigns}
							valueExtractor={(campaign) => campaign.id}
							labelExtractor={(campaign) => campaign && campaign.name}
							optionRenderer={(campaign) => (
								<Mui.ListItemText primary={campaign && campaign.name} />
							)}
							onChange={(value) => this.setState({selectedCampaignId: value })}
						/>
					</Mui.FormControl>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					<Mui.Button
						onClick={() => this.onSave()}
						variant="contained"
						color="secondary"
						autoFocus
						className={classes.button}
						disabled={!selectedCampaignId}
					>
						Save
					</Mui.Button>
					<Mui.Button
						onClick={() => onClose()}
						variant="outlined"
						color="primary"
						className={classes.button}
					>
						Cancel
					</Mui.Button>
				</Mui.DialogActions>
			</Dialog>
		);
	}
}

export const CampaignDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);