import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { arrayToById } from "redux/normalize";
import { CampaignCampaignMessageStatResource } from "api/resource/campaign-campaign-message-stat";

export interface CampaignCampaignMessageStatsState {
	loading?: boolean;
	error?: string | null;
	byCampaignCampaignMessageId: {
		[key: number]: CampaignCampaignMessageStatResource;
	};
};

const initialState: CampaignCampaignMessageStatsState = {
	byCampaignCampaignMessageId: {},
};

const fetchCampaignCampaignMessageStats = createAsyncThunk("campaignCampaignMessageStats/fetchCampaignCampaignMessageStats", async (payload, thunkAPI) => {
	thunkAPI.dispatch(campaignCampaignMessageStatsLoading());
	const result = api.getCampaignCampaignMessageStats();
	result
		.then((campaignCampaignMessageStats) => thunkAPI.dispatch(campaignCampaignMessageStatsLoaded(campaignCampaignMessageStats)))
		.catch(error => thunkAPI.dispatch(campaignCampaignMessageStatsFailed(error)));
	return result;
});

export const campaignCampaignMessageStatsSlice = createSlice({
	name: "campaignCampaignMessageStats",
	initialState,
	reducers: {
		campaignCampaignMessageStatsLoading: (state) => {
			state.loading = true;
		},
		campaignCampaignMessageStatsLoaded: (state, action: PayloadAction<CampaignCampaignMessageStatResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byCampaignCampaignMessageId = arrayToById(action.payload, "campaignCampaignMessageId");
		},
		campaignCampaignMessageStatsFailed: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { campaignCampaignMessageStatsFailed, campaignCampaignMessageStatsLoaded, campaignCampaignMessageStatsLoading } = campaignCampaignMessageStatsSlice.actions;
export { fetchCampaignCampaignMessageStats };