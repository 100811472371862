import React from "react";
import * as Router from "react-router-dom";

interface LocationState {
	path?: string;
}

interface Props extends Router.RouteComponentProps<{}, {}, LocationState> {
}

const Component = (props: Props) => {
	let url = props.location.state && props.location.state.path;
	if (!url) {
		url = "/";
	}
	return (
		<Router.Redirect to={url}/>
	)
}

export const RedirectPage = Component;
