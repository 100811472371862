import React from "react";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { getPayload, RootState } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { getActiveLeads } from "redux/selector";
import { createLead } from "redux/slice/leads";
import { getQuota } from "redux/selector";
import * as yup from "yup";
import { CustomAutocomplete } from "component/shared/auto-complete";
import { validate } from "shared/yup";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { sendCampaignCampaignMessageTestEmails } from "redux/slice/campaign-campaign-messages";
import { Lead } from "model/lead";
import { LeadStatus } from "type/lead-status";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		allLeads: getActiveLeads(state),
		quota: getQuota(state),
		loading: state.campaignCampaignMessages.loading,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createLead,
	sendCampaignCampaignMessageTestEmails,
}, dispatch);

interface OwnProps {
	open: boolean;
	onClose: () => void;
	campaignCampaignMessage: CampaignCampaignMessage;
}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;


interface State {
	input: string;
	leadIds: Set<number>;
	maxLeads: boolean;
	sending: boolean;
	sent: boolean;
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			input: "",
			leadIds: new Set(),
			maxLeads: false,
			sending: false,
			sent: false,
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.open !== prevProps.open) {
			this.setState({
				sending: false,
				sent: false,
			});
		}
	}

	private get options() {
		let { allLeads } = this.props;
		const { input } = this.state;
		const options = allLeads.map(lead => ({
			id: lead.id,
			email: lead.email,
			label: lead.label,
		}));
		if (input) {
			const schema = yup.string().email();
			const valid = schema.isValidSync(input);
			if (valid) {
				const exists = allLeads.some(lead => lead.email === input);
				if (!exists) {
					options.unshift({
						id: 0,
						email: input,
						label: `Create "${input}"`,
					});
				}
			}
		}
		return options;
	}

	private async createLead(email: string) {
		const { createLead } = this.props;
		const lead = await getPayload(createLead({ lead: {
			email: email,
			status: LeadStatus.ACTIVE,
			label: email,
		}}));
		if (lead.id) {
			this.addLeadIds([ lead.id ]);
		}
	}

	private addLeadIds(ids: number[]) {
		const { leadIds } = this.state;
		const { quota } = this.props;
		if (quota) {
			if (leadIds.size < quota.blastCampaignMessageQueueRemaining) {
				ids.forEach((id) => {
					leadIds.add(id);
				});
				this.setState({
					leadIds, 
				});
			} else {
				this.setMaxLeads(true);
			}
		}
	}

	private removeLeadId(id: number) {
		const { leadIds } = this.state;
		leadIds.delete(id);
		this.setState({
			leadIds, 
		}, () => this.setMaxLeads(false));
	}

	private setMaxLeads(bool: boolean) {
		this.setState({ maxLeads: bool });
	}

	private get validationSchema() {
		const { quota } = this.props;
		return {
			leadIds: yup
				.mixed()
				.test(
					"check-daily-email-quota",
					`Cannot send more than ${quota ? quota.blastCampaignMessageQueueLimit : 0} emails in 24 hours.`,
					(value) => {
						const leads: Set<number> = value;
						let pass = true;
						if (leads.size > (quota ? quota.blastCampaignMessageQueueRemaining : 0)) {
							pass = false;
						}
						return pass;
					}
				),
		}
	}

	private async sendtestEmail(leads: Lead[]) {
		this.setState(
			{
				sending: true,
				sent: false,
			}
		);
		const campaignCampaignMessage = this.props.campaignCampaignMessage;
		await this.props.sendCampaignCampaignMessageTestEmails({campaignCampaignMessage, leads})
		setTimeout(() => {
			this.setState(
				{
					sending: false,
					sent: true,
				}, () => {
					this.props.onClose()			
				}
			);
		}, 3000);
	}

	public render() {
		const {open, onClose, classes, allLeads} = this.props;
		const { input, leadIds, maxLeads } = this.state;
		const errors = validate(this.validationSchema, this.state);
		const leads = allLeads.filter((lead) => {
			return leadIds.has(lead.id);
		});
		return (
			<Dialog open={open} onClose={() => onClose()} scroll="paper" fullWidth={true} maxWidth="md">
				<Mui.DialogTitle>
					<Mui.Grid container direction="column" spacing={1}>
						<Mui.Grid item xs={12}>Send a Test Message</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={1} >
						<Mui.Grid item xs={12}>
							<CustomAutocomplete
								options={this.options}
								optionLabelExtractor={(option) => option.label}
								fullWidth
								margin="dense"
								label="Sent to"
								placeholder="Select Leads"
								textInputHeight={false}
								error={maxLeads && !!errors && !!errors.leadIds}
								helperText={maxLeads && errors && errors.leadIds}
								onInputChange={(event, value, reason) => {
									if (reason === "reset") {
										value = "";
									}
									this.setState({
										input: value
									})
								}}
								onChange={(event, option) => {
									if (option) {
										const id = option.id as number;
										if (id) {
											this.addLeadIds([ id ]);
										} else {
											this.createLead(option.email);
										}
									}
								}}
								inputValue={input}
							/>
						</Mui.Grid>
						{!!leads.length && (
							<Mui.Grid className={classes.tags} item>
								<Mui.Grid container spacing={1}>
									{leads.map((lead) => (
										<Mui.Grid key={lead.id} item>
											<Mui.Chip
												label={lead.label}
												onDelete={(event) => {
													this.removeLeadId(lead.id);
												}}
											/>
										</Mui.Grid>
									))}
								</Mui.Grid>
							</Mui.Grid>
						)}
					</Mui.Grid>
				</Mui.DialogContent>
				<Mui.DialogActions className={classes.dialogActions}>
					{this.props.open &&
						<Mui.Button
							variant={"contained"}
							color="secondary"
							onClick={() => this.sendtestEmail(leads)}
							disabled={this.state.sent || this.state.sending || this.state.leadIds.size === 0}
						>
							<>
								{!this.state.sent && !this.state.sending && 
									<>Send Now</>
								}
								{!this.state.sent && this.state.sending &&
									<>Sending...</>
								}
								{this.state.sent && !this.state.sending &&
									<>Sent</>
								}
								
							</>
						</Mui.Button>
					}
				</Mui.DialogActions>
			</Dialog>
		);
	}

}

export const SendTestEmail = Mui.withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(Component)
);