import React from "react";
import { useMediaQuery } from "@material-ui/core/";

export const withMediaQuery = (...args: Parameters<typeof useMediaQuery>) =>
	<P extends WithMediaQuery>(Component: React.ComponentType<P>) =>
	(props: Omit<P, keyof WithMediaQuery>) => {
	const mediaQuery = useMediaQuery(...args);
	return <Component {...props as P} mediaQuery={mediaQuery} />;
};

export interface WithMediaQuery {
	mediaQuery: boolean;
}
