import * as paths from "./paths";
import { Url } from "./url";
import { Lead } from "model/lead";
import { Market } from "model/market";
import { Campaign } from "model/campaign";
import { Agent } from "model/agent";
import { Office } from "model/office";
import { Reseller } from "model/reseller";
import { User } from "model/user";
import queryString from "query-string";
import { Sort } from "component/shared/table";
import { LeadsPageFilters } from "model/leads-page-filters";
import { CampaignCampaignMessage } from "model/campaign-campaign-message";
import { MarketResource } from "api/resource/market";
import { MarketTab } from "type/market-tab";

export type LeadQuery = {
	leadsPageFilters?: LeadsPageFilters,
	sort?: Sort,
} 

const landing = (user: User) => {
	if (user.permissions.oneCrm) {
		return paths.oneBusinessDashboard;
	} else if (user.permissions.crm) {
		if (!user.ownsLeads && !user.admin) {
			return paths.settingsProfile;
		}
		return paths.leadDashboard;
	} else if (user.permissions.leads || user.permissions.modifySubscriptions) {
		return paths.leadActivity;
	} else {
		if (user.type.agent) {
			return paths.settingsProfile;
		}
		return paths.settingsOverview;
	}
}

const lead = (lead: Lead | number) => {
	const id = (typeof lead === "number") ? lead : lead.id;
	return new Url(paths.lead).replace({id}).toString();
}

const leads = (leadQuery?: LeadQuery) => {
	if (!leadQuery) {
		return paths.leads;
	} else {
		const url = paths.leads;
		const query = queryString.stringify({
			...getSortsParams(leadQuery.sort ? leadQuery.sort : null),
			...getFilterParams(leadQuery.leadsPageFilters ? leadQuery.leadsPageFilters : null),
		});
		return url + "?" + query;
	}
}

const getSortsParams = (sort: Sort | null) => {
	if (sort) {
		return {
			sort: sort.id + ":" + (sort.desc ? "desc" : "asc"),
		}
	}
}

const getFilterParams = (leadsPageFilters: LeadsPageFilters | null) => {
	if (leadsPageFilters) {
		let queryParams: {
			[key: string]: any
		} = {};
		Object.keys(leadsPageFilters).forEach((name) => {
			// @ts-ignore
			const value = leadsPageFilters[name];
			queryParams[name] = value;
		});
		return queryParams;
	}
}

const campaign = (campaign: Campaign) => {
	const id = campaign.id;
	return new Url(paths.campaign).replace({id}).toString();
}

const campaignCampaignMessage = (campaignCampaignMessage: CampaignCampaignMessage) => {
	const id = campaignCampaignMessage.id;
	return new Url(paths.campaignCampaignMessage).replace({id}).toString();
}

const campaignCampaignEmailMessageNew = (campaign: Campaign | undefined) => {
	if (campaign) {
		const id = campaign.id;
		return new Url(paths.campaignCampaignEmailMessageNew).replace({id}).toString();
	}
	
}

const campaignCampaignTextMessageNew = (campaign: Campaign | undefined) => {
	if (campaign) {
		const id = campaign.id;
		return new Url(paths.campaignCampaignTextMessageNew).replace({id}).toString();
	}
	
}

const campaignCampaignTextMessage = (campaignCampaignMessage: CampaignCampaignMessage) => {
	const id = campaignCampaignMessage.id;
	return new Url(paths.campaignCampaignTextMessage).replace({id}).toString();
}

const market = (market: Market | MarketResource) => {
	const id = market.id;
	return new Url(paths.market).replace({id}).toString();
}

const marketTab = (market: Market | MarketResource, tab: MarketTab) => {
	const id = market.id;
	return new Url(paths.marketTab).replace({id, tab}).toString();
}

const settingsAgent = (agent: Agent) => {
	const id = agent.id;
	return new Url(paths.settingsAgent).replace({id}).toString();
}

const settingsAgentById = (id: number) => {
	return new Url(paths.settingsAgent).replace({id}).toString();
}

const agentProfile = (id: number) => {
	return new Url(paths.settingsAgent).replace({id}).toString();
}

const logout = (reseller?: Reseller) => {
	let url = paths.login;
	if (reseller) {
		url += "?resellerId=" + reseller.id;
	}
	return url;
}

const settingsOffice = (office: Office) => {
	const id = office.id;
	return new Url(paths.settingsOffice).replace({id}).toString();
}

const settingsOfficeById = (id: number) => {
	return new Url(paths.settingsOffice).replace({id}).toString();
}

const campaignDraftEmail = (id: number) => {
	return new Url(paths.campaignDraftEmail).replace({id}).toString();
}

const campaignSentEmail = (id: number) => {
	return new Url(paths.campaignSentEmail).replace({id}).toString();
}

export const urls = {
	...paths,
	landing,
	lead,
	leads,
	market,
	marketTab,
	campaign,
	campaignCampaignMessage,
	campaignCampaignEmailMessageNew,
	campaignCampaignTextMessage,
	campaignCampaignTextMessageNew,
	settingsAgent,
	settingsAgentById,
	agentProfile,
	settingsOfficeById,
	logout,
	settingsOffice,
	campaignDraftEmail,
	campaignSentEmail,
};