import React from "react";
import * as Mui from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { X } from "react-feather";
import { styles } from "./style";
import { ListingSearch } from "component/shared/listing-search";
import { User } from "model/user";
import { Lead } from "model/lead";
import { bindActionCreators } from "redux";
import { updateSavedListing, createSavedListing } from "redux/slice/saved-listings";
import { connect } from "react-redux";
import { FeatherIcon } from "component/shared/feather-icon";
import { SavedListing } from "model/saved-listing";

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
	updateSavedListing,
	createSavedListing,
}, dispatch)

interface Props extends 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapDispatchToProps>
{
	user: User;
	lead: Lead;
	open: boolean;
	onClose: Function;
	savedListings?: SavedListing[];
}

interface State {
	saved: boolean;
	errorText: string;
}

const Transition = React.forwardRef((props: TransitionProps, ref) => {
	return <Mui.Slide direction="up" ref={ref} {...props} />;
});

class Component extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			saved: false,
			errorText: ""
		}
	}

	private save(listingId: string) {
		const { createSavedListing } = this.props;
		const { lead } = this.props;
		createSavedListing({ savedListing: {
			lead,
			listing: {
				id: listingId,
			},
		}});
	}
	
	private unsave(listingId: string) {
		const { updateSavedListing, savedListings } = this.props;
		if (savedListings) {
			savedListings.forEach(savedListing => {
				if (savedListing.listing && savedListing.listing.id === listingId) {
					updateSavedListing({ savedListing: {
						...savedListing,
						deleted: true,
					}});
				}
			});
		}
	}

	public render() {
		const { user, lead, open, onClose, savedListings } = this.props;
		return (
			<Mui.Dialog
				fullScreen
				open={open}
				TransitionComponent={Transition}
			>
				<Mui.DialogTitle>
					<Mui.Grid container justifyContent="space-between" alignItems="center">
						<Mui.Grid item>
							<Mui.Typography variant="h2" component="div">
								Add or Edit Saved Listings
							</Mui.Typography>
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.IconButton color="inherit" 
								onClick={() => onClose()}
							>
								<FeatherIcon>
									<X />
								</FeatherIcon>
							</Mui.IconButton>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogTitle>
				<Mui.DialogContent>
					<Mui.Grid container direction="column" spacing={2}>
						<Mui.Grid item xs={6}>
							<Mui.Typography variant="h2" component="div">
								{lead.name ? lead.name : lead.email}
							</Mui.Typography>
						</Mui.Grid>		
						<Mui.Grid item>
							<ListingSearch
								user={user}
								onSaveListing={(listingId) => this.save(listingId)}
								onUnsaveListing={(listingId) => this.unsave(listingId)}
								savedListings = {
									savedListings ?
										savedListings
											.map(savedListing => savedListing.listing && savedListing.listing.id)
											.filter(Boolean) as string[] : []
								}
								hideSaveButton={true}
								disableRegistration={true}
								hideModal={true}
							/>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.DialogContent>
			</Mui.Dialog>
		);
	}
}

export const SaveListingDialog = Mui.withStyles(styles)(connect(null, mapDispatchToProps)(Component));
