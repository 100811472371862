import React from "react";
import { RootState, getPayload } from "redux/store";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as Mui from "@material-ui/core";
import { styles } from "./styles";
import { ColumnLabel } from "component/shared/column-label";
import { createCampaign, updateCampaign, } from "redux/slice/campaigns";
import { createCampaignMessage } from "redux/slice/campaign-messages";
import { createCampaignCampaignMessage } from "redux/slice/campaign-campaign-messages";
import { getCampaignRoutes } from "redux/selector";
import { deleteCampaignRoute } from "redux/slice/campaign-routes";
import { Campaign } from "model/campaign";
import { CampaignStatus } from "type/campaign-status";
import { withMediaQuery, WithMediaQuery } from "component/shared/with-media-query";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getCampaignSubscriptionsForCampaign } from "redux/selector";
import { EmailMarketingType } from "type/email-marketing-type";

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	const subscriptions = getCampaignSubscriptionsForCampaign(state, ownProps.campaign.id);
	const routes = getCampaignRoutes(state);
	return {
		subscriptions,
		routes,
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	createCampaign, 
	updateCampaign,
	createCampaignMessage,
	createCampaignCampaignMessage,
	deleteCampaignRoute,
}, dispatch);

interface OwnProps {
	campaign: Campaign;

}

type Props = OwnProps &
	Mui.WithStyles<typeof styles> & 
	WithMediaQuery &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

interface State {
	disabled: boolean;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			disabled: false,
		}
	}

	private async handleCopy(campaign: Campaign) {
		const campaignCampaignMessages = campaign.campaignCampaignMessages;
		const {createCampaign, createCampaignMessage, createCampaignCampaignMessage} = this.props;
		const newCampaign = getPayload(await createCampaign({ campaign: {
			...campaign,
			name: campaign.name + " (Copy)",
			emailMarketingType: EmailMarketingType.DRIP,
		}}));
		for (let i=0; i < campaignCampaignMessages.length; i++ ) {
			const newCampaignMessage = getPayload(await createCampaignMessage({ campaignMessage: {
				...campaignCampaignMessages[i].campaignMessage,
			}}));
			if (newCampaignMessage.id && newCampaign.id) {
				getPayload(await createCampaignCampaignMessage({ campaignCampaignMessage: {
					campaignMessageId: newCampaignMessage.id,
					campaignId: newCampaign.id,
					messageOrder: campaignCampaignMessages[i].messageOrder,
				}}));
			}	
		}
	}

	private handleDelete(campaign: Campaign) {
		const { routes, deleteCampaignRoute } = this.props;
		if (window.confirm("Delete this campaign? Any associated Automation rules will be deleted as well.")) {
			routes.forEach((route) => {
				if (route.campaign.id === campaign.id) {
					deleteCampaignRoute({campaignRoute: route});
				}
			});
			campaign.inactiveYn = true;
			this.props.updateCampaign({campaign});
		}
	}

	private handleToggleActivate(campaign: Campaign) {
		if (campaign.status === CampaignStatus.ACTIVE) {
			campaign.status = CampaignStatus.INACTIVE;
		} else if (campaign.status === CampaignStatus.INACTIVE) {
			campaign.status = CampaignStatus.ACTIVE;
		}
		
		this.props.updateCampaign({campaign});
	}

	private get disabledColor(): string | undefined {
		if (this.props.campaign.status === CampaignStatus.INACTIVE) {
			return "#ccc";
		} else {
			return undefined;
		}
	}

	public render() {
		const { campaign, classes } = this.props;
		return (
			<Mui.Grid container spacing={5}>
				<Mui.Grid item xs={12}>
					<Mui.Card >
						<Mui.CardContent>
							<Mui.Grid container direction="row" className={classes.container} spacing={2}>
								<Mui.Grid item lg={3} xs={12}> 
									<RouterLinkWrapper className={classes.title} style={{ whiteSpace: "nowrap"}} to={urls.campaign(campaign)}>
										<Mui.Typography variant="h4" color="secondary">
											{campaign.name}
										</Mui.Typography>
									</RouterLinkWrapper>
								</Mui.Grid>
								<Mui.Grid item lg={5} md={6} sm={12} xs={12}>
									{this.stats()}
								</Mui.Grid>
								<Mui.Grid item style={{backgroundColor: "#fff"}} lg={4} md={6} sm={12} xs={12}>
									{this.buttons()}
								</Mui.Grid>
							</Mui.Grid>
						</Mui.CardContent>
					</Mui.Card>
				</Mui.Grid>
			</Mui.Grid>
		)
	}

	private stats() {
		const { campaign, classes, mediaQuery, subscriptions } = this.props;
		const subscribes = subscriptions.length;
		return (
			<Mui.Grid container className={classes.stats} direction="row" style={{color: this.disabledColor}}>
				<Mui.Grid item xs={3}>
					<Mui.Grid container direction="column">
						<Mui.Grid item className={classes.stat} xs={12}>
							{campaign.campaignStat ? campaign.campaignStat.sents : "0"}
						</Mui.Grid>
						<Mui.Grid item className={classes.stat} xs={12}>
							<ColumnLabel>
								Sent
							</ColumnLabel>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item xs={3} sm={3} md={4}>
					<Mui.Grid container direction="column">
						<Mui.Grid item className={classes.stat} xs={12}>
						{subscribes ? subscribes : "0"}
						</Mui.Grid>
						<Mui.Grid item className={classes.stat} xs={12}>
							<ColumnLabel>
								{mediaQuery && 
									<>Subs</>
								}
								{!mediaQuery &&
									<>Subscribers</>
								}
								
							</ColumnLabel>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item xs={3} md={3}>
					<Mui.Grid container direction="column">
						<Mui.Grid item className={classes.stat} xs={12}>
						{campaign.campaignStat && campaign.campaignStat.opens ? campaign.campaignStat.opens : "0"}
						</Mui.Grid>
						<Mui.Grid item className={classes.stat} xs={12}>
							<ColumnLabel>
								Opens
							</ColumnLabel>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
				<Mui.Grid item xs={3} sm={3} md={2}>
					<Mui.Grid container direction="column">
						<Mui.Grid item className={classes.stat} xs={12}>
							{campaign.campaignStat && campaign.campaignStat.clicks ? campaign.campaignStat.clicks : "0"}
						</Mui.Grid>
						<Mui.Grid item className={classes.stat} xs={12}>
							<ColumnLabel>
								Links
							</ColumnLabel>
						</Mui.Grid>
					</Mui.Grid>
				</Mui.Grid>
			</Mui.Grid>
		);
	}

	private buttons() {
		const { campaign, classes, mediaQuery } = this.props;
		
		return (
			<Mui.Grid container direction={mediaQuery ? "column" : "row"} spacing={1} justifyContent={mediaQuery ? "center" : "flex-end"} wrap="nowrap">
				<Mui.Grid item >
					<Mui.Button
						color="secondary"
						variant="contained"
						className={classes.actionButtons}
						onClick={() => this.handleCopy(campaign)}
						style={{backgroundColor: this.disabledColor}}
						fullWidth={mediaQuery ? true : false}
					>
						Copy
					</Mui.Button>
				</Mui.Grid>

				<Mui.Grid item >
					<Mui.Button
						color="secondary"
						variant="contained"
						className={classes.actionButtons}
						onClick={() => this.handleDelete(campaign)}
						style={{backgroundColor: this.disabledColor}}
						fullWidth={mediaQuery ? true : false}
					>
						Delete
					</Mui.Button>
				</Mui.Grid>

				<Mui.Grid item >
					<Mui.Button
						color="secondary"
						variant="contained"
						className={classes.actionButtons}
						onClick={() => this.handleToggleActivate(campaign)}
						style={{backgroundColor: this.disabledColor}}
						fullWidth={mediaQuery ? true : false}
					>
						{campaign.status === CampaignStatus.ACTIVE ?
								<>Deactivate</> : <>Activate</>
						} 
					</Mui.Button>
				</Mui.Grid>
					
			</Mui.Grid>
		);
	}

}

export const Card = withMediaQuery("(max-width:375px)")(Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
));