import { AgentResource } from "api/resource/agent";

export const agents: AgentResource[] = [
	{
		id: 1,
		firstName: "Alex",
		lastName: "Gent",
		email: "a.gent@mailinator.com",
		officeId: 1,
		agentPageUrl: "google.com/search?q=Alex+Gent",
		photoUrl: "https://material-ui.com/static/images/avatar/1.jpg",
		designation: "Real Agent",
		username: "A.Gent",
		password: "agent",
		admin: true,
		ownsLeads: true,
		homePhone: "(123) 456-7890",
		officePhone: "(234) 567-8910",
		homeFax: "(123) 456-7890",
		officeFax: "(234) 567-8910",
		cellPhone: "(987) 654-3210",
		staffTypeId: 2,
		code: "007",
		officeAddress: "1900 Addison St",
		officeCity: "Berkeley",
		officeState: "CA",
		officeZip: "94704",
		head: "<b>Header</b>",
		body: "<i>Body</i>",
		allowModifyBio: false,
		hasOwnSite: false,
		redirectUrl: "https://www.google.com",
		enableAgentLink: false,
		showOnSignupDropDown: false,
		emailHeader: "<b><i>Email Header</i></b>",
		sendEmailUpdatesToAgent: false,
		allowModifyListings: false,
		allowModifySubscribers: false,
		showImageOnPage: true,
		languageIds: [
			63,
		],
	},
	{
		id: 2,
		firstName: "Jane",
		lastName: "Doe",
		email: "tawnya@mailinator.com",
		officeId: 2,
		agentPageUrl: "google.com/search?q=Jane+Doe",
		photoUrl: "https://material-ui.com/static/images/avatar/3.jpg",
		username: "J Doe",
		password: "password",
		ownsLeads: true,
		showImageOnPage: true,
		hasOwnSite: false,
		showOnSignupDropDown: false,
		languageIds: [
			63,
		], 
	},
	{
		id: 3,
		firstName: "Patt",
		lastName: "Patterson",
		email: "patt.patterson@mailinator.com",
		agentPageUrl: "google.com/search?q=Patt+Patterson",
		photoUrl: "https://material-ui.com/static/images/avatar/2.jpg",
		username: "pattpatt",
		password: "pattpatt",
		ownsLeads: false,
		showImageOnPage: true,
		hasOwnSite: false,
		showOnSignupDropDown: false,
		languageIds: [
			63,
		], 
	},
	{
		id: 412345,
		firstName: "Johny",
		lastName: "Smith",
		email: "a@gmail.com",
		agentPageUrl: "google.com/search?q=John+Smith",
		photoUrl: "",
		username: "John Smith",
		password: "qwerty",
		ownsLeads: true,
		showImageOnPage: true,
		hasOwnSite: false,
		showOnSignupDropDown: false,
		languageIds: [
			63,
		], 
	}
];