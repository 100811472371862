class Type {
	
	public static UNCONTACTED = new Type(1, 1, "Uncontacted");
	public static ATTEMPTED_CONTACT = new Type(2, 2, "Attempted Contact");
	public static NURTURING = new Type(3, 3, "Nurturing");
	public static DEAD = new Type(4, 4, "Dead");
	public static UNDER_CONTRACT = new Type(5, 5, "Under Contract");
	public static CLOSED = new Type(6, 6, "Closed");
	
	private constructor(
		public readonly id: number,
		public readonly stage: number,
		public readonly label: string,
	) {
	}

	public get uncontacted() {
		return this === Type.UNCONTACTED;
	}

	public get attemptedContact() {
		return this === Type.ATTEMPTED_CONTACT;
	}

	public get nurturing() {
		return this === Type.NURTURING;
	}

	public get dead() {
		return this === Type.DEAD;
	}

	public get underContract() {
		return this === Type.UNDER_CONTRACT;
	}

	public get closed() {
		return this === Type.CLOSED;
	}

	public static get default(): Type {
		return this.UNCONTACTED;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: number) {
		return this.values().find(value => value.id === id);
	}

	public static getByLabel(label: string) {
		return this.values().find(value => value.label.toLowerCase() === label.toLowerCase());
	}

	public static getByIdOrDefault(id?: number): Type {
		return this.getById(id) || this.default;
	}
}

export { Type as LeadPipelineType };