import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { arrayToById } from "redux/normalize";
import { CampaignEmailResource } from "api/resource/campaign-email";

export interface CampaignEmailsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: CampaignEmailResource;
	}
};

const initialState: CampaignEmailsState = {
	byId: {},
};

const fetchCampaignEmails = createAsyncThunk("campaignEmails/fetchCampaignEmails", async (payload: {leadId?: number, campaignId?: number, blast?: boolean}, thunkAPI) => {
	thunkAPI.dispatch(campaignEmailsLoading());
	const result = api.getCampaignEmails(payload.leadId, payload.campaignId, payload.blast);
	result
		.then((campaignEmails) => thunkAPI.dispatch(campaignEmailsLoaded(campaignEmails)))
		.catch(error => thunkAPI.dispatch(campaignEmailsFailed(error)));
	return result;
});

const refreshCampaignEmails = createAsyncThunk("campaignEmails/refreshCampaignEmails", async (payload: {leadId?: number, campaignId?: number, blast?: boolean}, thunkAPI) => {
	const result = api.getCampaignEmails(payload.leadId, payload.campaignId, payload.blast);
	result
		.then((campaignEmails) => thunkAPI.dispatch(campaignEmailsLoaded(campaignEmails)))
		.catch(error => thunkAPI.dispatch(campaignEmailsFailed(error)));
	return result;
});

export const campaignEmailsSlice = createSlice({
	name: "campaignEmails",
	initialState,
	reducers: {
		campaignEmailsLoading: (state) => {
			state.loading = true;
		},
		campaignEmailsLoaded: (state, action: PayloadAction<CampaignEmailResource[]>) => {
			const byId = arrayToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		campaignEmailsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { campaignEmailsFailed, campaignEmailsLoaded, campaignEmailsLoading } = campaignEmailsSlice.actions;
export { fetchCampaignEmails, refreshCampaignEmails };
