import React, { CSSProperties } from "react";
import * as Mui from "@material-ui/core";
import { TableRow } from "./table-row";
import { SortCell } from "./sort-cell";
import { TableCell } from "./table-cell";
import { TablePagination } from "./table-pagination";

interface Props<I> {
	[key: string]: any;
	items: I[];
	columns: Column<I>[];
	hidePagination?: boolean;
	page?: number;
	onPageChange?: (page: number) => void;
	pageSize?: number;
	onPageSizeChange?: (pageSize: number) => void;
	sort?: Sort;
	onSortChange?: (sort: Sort) => void;
	isSelected?: (item: I) => boolean;
	onSelect?: (items: I[]) => void;
	onUnselect?: (items: I[]) => void;
}

interface State {
	page: number;
	pageSize: number;
	sort?: Sort;
}

export interface Column<I> {
	[key: string]: any;
	id?: string;
	hidden?: boolean;
	title?: React.ReactNode;
	value: (item: I, column: number, row: number) => React.ReactNode;
	sort?: (a: I, b: I) => number;
	width?: CSSProperties["width"];
	align?: Mui.TableCellProps["align"];
	showOnHover?: boolean;
}

export interface Sort {
	id: string;
	desc: boolean;
}

class Component<I> extends React.Component<Props<I>, State> {

	public constructor(props: Props<I>) {
		super(props);
		this.state = {
			page: 0,
			pageSize: 10,
		};
	}

	public render() {
		const { isSelected, onSelect, onUnselect } = this.props;
		let { items, hidePagination } = this.props;
		const columns = this.props.columns.filter(column => !column.hidden);
		const { pageSizeOptions, page, pageSize, sort } = this;

		if (sort) {
			const column = columns.find((column) => column.id === sort.id);
			if (column && column.sort) {
			  items = [...items].sort((a, b) => column.sort!(a, b) * (sort.desc ? -1 : 1));
			}
		  }  

		const subItems = items.slice(page * pageSize, page * pageSize + pageSize);

		return (
			<Mui.Grid container direction="column" spacing={2}>
				<Mui.Grid item>
					<Mui.TableContainer>
						<Mui.Table size="small">
							<Mui.TableHead>
								<Mui.TableRow>
									{(isSelected && onSelect && onUnselect) && (
										<TableCell
											style={{
												width: 70,
											}}
										>
											<Mui.Checkbox 
												style={{ marginLeft: 10 }}
												checked={subItems.every(item => isSelected(item))}
												onChange={() => {
													if (subItems.every(item => isSelected(item))) {
														onUnselect(subItems);
													} else {
														onSelect(subItems);
													}
												}}
											/>
										</TableCell>
									)}
									{columns.map((column, index) => (
										this.renderTitleCell(column, index)
									))}
								</Mui.TableRow>
							</Mui.TableHead>
							<Mui.TableBody>
								{subItems.map((item, row) => (
									<TableRow
										key={row}
									>
										{(isSelected && onSelect && onUnselect) && (
											<TableCell>
												<Mui.Checkbox
													style={{ marginLeft: 10 }}
													checked={isSelected(item)}
													onChange={() => {
														if (isSelected(item)) {
															onUnselect([ item ])
														} else {
															onSelect([ item ])
														}
													}}
												/>
											</TableCell>
										)}
										{columns.map((column, index) => (
											<TableCell
												key={index}
												align={column.align}
											>
												{column.showOnHover ? (
													<div className="showOnHover">
														{column.value(item, index, row)}
													</div>
												) : (
													column.value(item, index, row)
												)}
											</TableCell>
										))}
									</TableRow>
								))}
							</Mui.TableBody>
						</Mui.Table>
					</Mui.TableContainer>
				</Mui.Grid>
				{!!!hidePagination && (
					<Mui.Grid item>
						<TablePagination
							count={items.length}
							page={page}
							rowsPerPage={pageSize}
							rowsPerPageOptions={pageSizeOptions}
							onPageChange={(event, page) => this.onPageChange(page)}
							onRowsPerPageChange={(event) => this.onSizeChange(parseInt(event.target.value))}
						/>
					</Mui.Grid>
				)}
				
			</Mui.Grid>
		);
	}

	private renderTitleCell(column: Column<I>, index: number) {
		const { sort } = this;
		if (column.sort && column.id) {
			const desc = (sort && sort.id === column.id) ? sort.desc : undefined;
			return (
				<SortCell
					key={index}
					style={{
						width: column.width,
					}}
					desc={desc}
					onClick={() => {
						if (!column.id) {
							throw new Error();
						}
						this.onSortChange({
							id: column.id,
							desc: !desc,
						});
					}}
				>
					{column.title}
				</SortCell>
			);
		} else {
			return (
				<TableCell
					key={index}
					style={{
						width: column.width,
					}}
				>
					{column.title}
				</TableCell>
			);
		}
	}

	private get pageSizeOptions() {
		return [
			5,
			10,
			20,
			25,
			50,
			100,
			...this.pageSize > 100 ? [250, 500] : [],
		].map((value) => ({
			value,
			label: `${value} rows`,
		}));
	}

	private get page() {
		return this.props.page || this.state.page;
	}

	private onPageChange(page: number) {
		const { onPageChange } = this.props;
		if (onPageChange) {
			onPageChange(page);
		} else {
			this.setState({ page });
		}
	}

	private get pageSize() {
		return this.props.pageSize || this.state.pageSize;
	}

	private onSizeChange(pageSize: number) {
		const { onPageSizeChange } = this.props;
		if (onPageSizeChange) {
			onPageSizeChange(pageSize);
		} else {
			this.setState({ pageSize });
		}
	}

	private get sort() {
		return this.props.sort || this.state.sort;
	}

	private onSortChange(sort: Sort) {
		const { onSortChange } = this.props;
		if (onSortChange) {
			onSortChange(sort);
		} else {
			this.setState({ sort });
		}
	}

}

export const Table = Component;