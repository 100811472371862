import { createStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const styles = (theme: Theme) => {
	return createStyles({
		cardContent: {
			"&:hover .actions": {
				visibility: "visible"
			},
			"& .actions": {
				visibility: "hidden"
			}
		},
		greyCardContent: {
			"&:hover .actions": {
				visibility: "visible",
			},
			"& .actions": {
				visibility: "hidden"
			},
			opacity: .5,
		},
		agentLabel: {
			margin: 10,
		},
		position: {
			minWidth: 40,
		},
		positionItem: {
			display: "flex", 
			justifyContent: "flex-end", 
			flexGrow: 1,
		},
		deleteItem: {
			display: "flex", justifyContent: "flex-end"
		}
	})
};
