import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog } from "component/shared/dialog";
import { Stars } from "component/shared/ranking/stars";
import { Select } from "component/shared/select";
import { getUserUnsafe } from "redux/selector";
import { getLeadsSources } from "redux/selector";
import { setLeadsPageFilters, resetLeadsPageFilters } from "redux/slice/leads-page"
import { getLeadsPageFilters } from "redux/selector";
import { getCustomTagTexts } from "redux/selector";
import { getAgentsWithLeads } from "redux/selector";
import { RootState } from "redux/store";
import { LeadPipelineType } from "type/lead-pipeline";
import { LeadRanking } from "type/lead-ranking";
import { LeadStatus } from "type/lead-status";
import { LeadTagType } from "type/lead-tag";
import { FiltersChips } from "./filter-criteria-chips";
import { styles } from "./style";

const mapStateToProps = (state: RootState) => {
	const filters = getLeadsPageFilters(state);
	const tagTexts = getCustomTagTexts(state);
	const leadsSources = getLeadsSources(state);
	const user = getUserUnsafe(state);
	const agents = getAgentsWithLeads(state);
	const agentId = state.agents.value
	return {
		user,
		filters,
		tagTexts,
		leadsSources,
		agents,
		agentId
	};
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setLeadsPageFilters,
	resetLeadsPageFilters,
}, dispatch);

interface Props extends 
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles>
{ 
	open: boolean;
	onClose: Function;
	onSave?: Function;
	count: number;
	buttonText: string;
	emailFilter?: boolean;
}

interface State { 
	selectedAgent: number;
}

const initialState: State = {
	selectedAgent: 0,
}

class Component extends React.Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = initialState;
	}

	private get activeOnlyStatusOptions() {
		let activeOnlyStatusOptions = [];
		activeOnlyStatusOptions.push(LeadStatus.ACTIVE); 
		return activeOnlyStatusOptions;
	}

	private get activeOnlyStatusValue() {
		let activeOnlyStatusValue: string[] = [];
		if (this.props.filters.status) {
			activeOnlyStatusValue = this.props.filters.status.filter(status => status === LeadStatus.ACTIVE.id)
		}
		return activeOnlyStatusValue;
	}

	componentDidMount() {
		const { agentId } = this.props;
	
		if (agentId) {
		  this.setState({ selectedAgent: agentId });
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const { filters, setLeadsPageFilters } = this.props;
		
		if (this.state.selectedAgent !== prevState.selectedAgent) {
			setLeadsPageFilters({ ...filters, agent: [Number(this.state.selectedAgent)] });
		}
	}

	public render() {
		const {
			user,
			classes,
			open,
			onClose,
			onSave,
			count,
			buttonText,
			filters,
			tagTexts,
			leadsSources,
			setLeadsPageFilters,
			resetLeadsPageFilters,
			emailFilter,
			agents
		} = this.props;
		const {activeOnlyStatusOptions, activeOnlyStatusValue} = this;
		
		if (!user) {
			throw new Error();
		}
		return (
			<>	
				<Dialog open={open} onClose={() => onClose()} scroll="paper" fullWidth={true} maxWidth="md">
					<Mui.DialogTitle>Filters</Mui.DialogTitle>
					<Mui.DialogContent style={{ height: 450 }}>
						<Mui.Grid container spacing={2}>
							<Mui.Grid item xs={12} className={classes.chipContainer}>
								<FiltersChips emailFilter={emailFilter} agents={agents}/>
							</Mui.Grid>
							<Mui.Grid container item md={user.permissions.crmTags ? 6 : 12} spacing={1} justifyContent="space-between">
								<Mui.Grid item xs={12} sm="auto" className={classes.header}>
									<Mui.Typography variant="h3">Lead Info</Mui.Typography>
								</Mui.Grid>
								{user.admin && (
									<Mui.Grid item xs={12}>
										<Select
											fullWidth
											variant="outlined"
											placeholder="Agents"
											multiple
											value={filters.agent}
											options={[{ id: -1, name: 'Unassigned' }, ...agents]}
											valueExtractor={agent => agent.id}
											labelExtractor={agent => agent.name || agent.email}
											optionRenderer={agent => <Mui.ListItemText primary={agent.name || agent.email} />}
											onChange={(values) => {									
												setLeadsPageFilters({
													...filters,
													agent: values,
												});
											}}
										/>
									</Mui.Grid>
								)}
								{user.permissions.crmTags && 
									<Mui.Grid item xs={12}>
										<Select
											placeholder="Type"
											variant="outlined"
											multiple
											fullWidth
											value={filters.type}
											options={LeadTagType.leadTypeValues()}
											valueExtractor={(leadTagType) => leadTagType.id}
											labelExtractor={(leadTagType) => leadTagType.label}
											optionRenderer={(leadTagType) => (
												<Mui.ListItemText primary={leadTagType.label} />
											)}
											onChange={(values) => {
												setLeadsPageFilters({
													...filters,
													type: values,
												});
											}}
										/>
									</Mui.Grid>
								}
								<Mui.Grid item xs={12}>
									<Select
										placeholder="Status"
										variant="outlined"
										multiple
										fullWidth
										value={emailFilter ? activeOnlyStatusValue : filters.status}
										options={emailFilter ? activeOnlyStatusOptions : LeadStatus.values()}
										valueExtractor={(leadStatus) => leadStatus.id}
										labelExtractor={(leadStatus) => leadStatus.label}
										optionRenderer={(leadStatus) => (
											<Mui.ListItemText primary={leadStatus.label} />
										)}
										onChange={(values) => {
											setLeadsPageFilters({
												...filters,
												status: values,
											});
										}}
									/>
								</Mui.Grid>
								<Mui.Grid item xs={12}>
									<Select
										placeholder="Rating"
										variant="outlined"
										fullWidth
										multiple
										value={filters.ranking}
										options={LeadRanking.values()}
										valueExtractor={(leadRanking) => leadRanking.id}
										labelExtractor={(leadRanking) => <Stars leadRanking={leadRanking} />}
										optionRenderer={(leadRanking) => (
											<Mui.ListItemText primary={<Stars leadRanking={leadRanking}/>} />
										)}
										onChange={(values) => {
											setLeadsPageFilters({
												...filters,
												ranking: values as number[],
											})
										}}
									/>
								</Mui.Grid>
								{!!leadsSources.length && <Mui.Grid item xs={12}>
									<Select
										placeholder="Lead Sources"
										variant="outlined"
										fullWidth
										multiple
										value={filters.source}
										options={leadsSources}
										valueExtractor={(leadsSource) => leadsSource}
										labelExtractor={(leadsSource) => leadsSource}
										optionRenderer={(leadsSource) => (
											<Mui.ListItemText primary={leadsSource} />
										)}
										onChange={(values) => {
											setLeadsPageFilters({
												...filters,
												source: values,
											});
										}}
									/>
								</Mui.Grid>}
								{user.permissions.crmPipeline && 
									<Mui.Grid item xs={12}>
										<Select
											placeholder="Pipeline Stage"
											variant="outlined"
											multiple
											fullWidth
											value={filters.pipeline}
											options={LeadPipelineType.values()}
											valueExtractor={(leadPipeline) => leadPipeline.id}
											labelExtractor={(leadPipeline) => leadPipeline.label}
											optionRenderer={(leadPipeline) => (
												<Mui.ListItemText primary={leadPipeline.label} />
											)}
											onChange={(values) => {
												setLeadsPageFilters({
													...filters,
													pipeline: values,
												});
											}}
										/>
									</Mui.Grid>
								}

								{user.permissions.crmTags && 
									<Mui.Grid item xs={12}>
										<Mui.Grid container wrap="nowrap" alignItems="center">
											<Mui.Grid item>
												<Mui.FormControlLabel 
													control={
														<Mui.Checkbox 
															className={classes.checkbox}
															checked={filters.bookmarked}
															onChange={() => {
																setLeadsPageFilters({
																	...filters,
																	bookmarked: !filters.bookmarked,
																});
															}}
														/>
													}
													label="Bookmarked Leads"
												/>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
								}
								<Mui.Grid item xs={12}>
									<Mui.Grid container wrap="nowrap" alignItems="center">
										<Mui.Grid item>
											<Mui.FormControlLabel 
												control={
													<Mui.Checkbox 
														className={classes.checkbox}
														checked={filters.subscribed}
														onChange={() => {
															setLeadsPageFilters({
																...filters,
																subscribed: !filters.subscribed,
															});
														}}
													/>
												}
												label="Subscribed Leads"
											/>
										</Mui.Grid>
									</Mui.Grid>
								</Mui.Grid>
							</Mui.Grid>
							{user.permissions.crmTags && 
								<Mui.Grid container item md={6}>
									<Mui.Grid item xs={12} sm="auto" className={classes.header}>
										<Mui.Typography variant="h3">Tags</Mui.Typography>
									</Mui.Grid>
									<Mui.Grid item xs={12} className={classes.filters}>
										<Select
											multiple
											expanded
											fullWidth
											numberVisible={6}
											variant="outlined"
											placeholder="Select Tags"
											value={filters.tag}
											options={tagTexts}
											valueExtractor={(text) => text}
											labelExtractor={(text) => text}
											optionRenderer={(text) => (
												<Mui.ListItemText primary={text} />
											)}
											onChange={(values) => {
												setLeadsPageFilters({
													...filters,
													tag: values,
												});
											}}
										/>
									</Mui.Grid>
								</Mui.Grid>
							}
						</Mui.Grid>
					</Mui.DialogContent>
					<Mui.DialogActions className={classes.dialogActions}>
						<Mui.Button 
							disabled={count === 0 ? true : false}
							variant="contained"
							color="secondary"
							onClick={() => {
								if (onSave) {
									onSave();
								}
								onClose();
							}}
						>
							{count === 0 && "No Leads"}
							{count === 1 && `${buttonText} 1 Lead`}
							{count > 1 && `${buttonText} ${count} Leads`}
						</Mui.Button>
						<Mui.Button 
							variant="outlined"
							color="primary"
							onClick={() => {
								resetLeadsPageFilters();
								onClose()
							}}
						>
							Clear
						</Mui.Button>
					</Mui.DialogActions>
				</Dialog>
				
			</>
		);
	}
}

export const FilterDialog = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);