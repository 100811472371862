class Type {
	public static ALPHABETICAL = new Type("alpha", "Alphabetical");
	public static RANDOM = new Type("random", "Random");
	public static CUSTOM = new Type("custom", "Custom");

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get alphabetical() {
		return this === Type.ALPHABETICAL;
	}

	public get random() {
		return this === Type.RANDOM;
	}

	public get custom() {
		return this === Type.CUSTOM;
	} 

	public static get default(): Type {
		return this.ALPHABETICAL;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id?: string) {
		return this.values().find((value) => value.id === id);
	}

	public static getByIdOrDefault(id?: string): Type {
		return this.getById(id) || this.default;
	}

}

export { Type as AgentSortOrderType };
