const compare = (object1: any, object2: any): boolean => {
	if (object1 === object2) return true;
	if (typeof object1 !== "object" || typeof object2 !== "object" || object1 === null || object2 === null) return false;
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	};
	return keys1.every(key => compare(object1[key], object2[key]));
};

/**
 * Deeply compares two arrays of objects to determine if they are equal.
 * For such uses as componentDidUpdate.
 * Handles nested objects and arrays.
 *
 * @param {any[]} array1 - The first array to compare.
 * @param {any[]} array2 - The second array to compare.
 * @returns {boolean} - Returns true if the arrays are equal.
 */
export const deepCompare = (array1: any[], array2: any[]): boolean => {
	if (array1.length !== array2.length) {
		return false;
	};
	return array1.every((item, index) => compare(item, array2[index]));
};
