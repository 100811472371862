import React from "react";
import * as Mui from "@material-ui/core";
import * as Router from "react-router-dom";
import { User } from "model/user";
import { urls } from "routes/urls";
import { MenuItem } from "./menu-item";
import { styles } from "./style";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon"
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";
import { getIntegrationByTypeId } from "redux/selector";
import { IntegrationType } from "type/integration-type";
import { RootState } from "redux/store";
import { connect } from "react-redux";
import * as env from "shared/env";

interface Props extends Router.RouteComponentProps, 
	Mui.WithStyles<typeof styles>,
	ReturnType<typeof mapStateToProps> {
	user: User;
}

const mapStateToProps = (state: RootState) => {
	const wixIntegration = getIntegrationByTypeId(state, IntegrationType.WIX_APP)
	return {
		wixIntegration
	};
};

class Component extends React.Component<Props> {

	public render() {
		const { classes } = this.props;
		return this.items.map((item, index) => {
			if (item.target) {
				return (
					<Mui.Button
						key={index}
						className={item.className}
						classes={{
							root: classes.topMenuItem,
						}}
						href={item.path}
						target={item.target}
					>
						{this.renderLabel(item)}
					</Mui.Button>
				);
			} else {
				return (
					<Mui.Button
						key={index}
						className={item.className}
						classes={{
							root: classes.topMenuItem,
						}}
						component={RouterLinkWrapper}
						to={item.path}
					>
						{this.renderLabel(item)}
					</Mui.Button>
				);
			}
		});
	}

	private renderLabel(item: MenuItem) {
		const { classes } = this.props;
		return (
			<>
				<FeatherIcon>
					<item.icon />
				</FeatherIcon>
				<Mui.Hidden mdDown>
					<div className={classes.label}>
						{item.label}
					</div>
				</Mui.Hidden>
			</>
		);
	}

	private get items() {
		const { user, wixIntegration } = this.props;
		let items: MenuItem[] = [];
		if (!user.permissions.oneCrm) {
			items = [
				((user.permissions.leads && user.ownsLeads) || user.admin ) && { 
					label: user.permissions.crm ? "CRM" : "Leads",
					className: "ihf-leads",
					icon: Icons.User,
					path: urls.landing(user),
				},
				user.admin && user.permissions.markets && {
					label: "Markets",
					className: "ihf-markets",
					icon: Icons.MapPin,
					path: urls.markets,
				},
				((user.permissions.modifyListings && user.ownsLeads) || user.admin ) && {
					label: "Listings",
					className: "ihf-listings",
					icon: Icons.Home,
					path: urls.listingsActive,
				},
				user.admin && user.permissions.reporting && {
					label: "Reports",
					className: "ihf-reports",
					icon: Icons.BarChart2,
					path: urls.reportsOverview,
				},
				{
					label: "Setup",
					className: "ihf-setup",
					icon: Icons.Settings,
					path: user.type.agent ? urls.settingsProfile : urls.settingsOverview,
				},
				user.admin && user.permissions.campaigns && {
					label: "Communications",
					className: "ihf-communications",
					icon: Icons.Send,
					path: user.permissions.blastCampaigns ? urls.campaignNewEmail : urls.campaigns,
				},
				user.admin && user.website && !wixIntegration && {
					label: "Website",
					className: "ihf-website",
					icon: Icons.Sidebar,
					path: (!/^https?:\/\//i.test(user.website) ? "http://" + user.website : user.website),
					target: "_blank",
				},
			] as MenuItem[];
		} else {
			if (user.admin) {
				items = [
					user.permissions.leads && { 
						label: "Leads",
						className: "ihf-leads",
						icon: Icons.User,
						path: urls.landing(user),
					},
					user.permissions.markets && {
						label: "Markets",
						className: "ihf-markets",
						icon: Icons.MapPin,
						path: urls.markets,
					},
					{
						label: "Listings",
						className: "ihf-listings",
						icon: Icons.Home,
						path: urls.listingsActive,
					},
					{
						label: "IDX Setup",
						className: "ihf-setup",
						icon: Icons.Settings,
						path: urls.settingsOverview,
					},
					user.onePermissions.sendEmail && {
						label: "Communications",
						icon: Icons.Send,
						path: urls.oneBlastEmail,
					},
					{
						label: "CRM Tools",
						icon: Icons.Sliders,
						path: urls.oneTeamList,
					},
					user.website && !wixIntegration && {
						label: "Website",
						className: "ihf-website",
						icon: Icons.Sidebar,
						path: (!/^https?:\/\//i.test(user.website) ? "http://" + user.website : user.website),
						target: "_blank",
					},
					{
						label: "Calendar",
						icon: Icons.Calendar,
						path: urls.oneCalendar,
						target: "_blank",
					},
				] as MenuItem[];
			} else if (user.type.agent) {
				items = [
					{
						label: "Listings",
						className: "ihf-listings",
						icon: Icons.Home,
						path: urls.listingsActive,
					},
					{
						label: "IDX Setup",
						className: "ihf-setup",
						icon: Icons.Settings,
						path: urls.settingsOverview,
					},
					user.onePermissions.sendEmail && {
						label: "Communications",
						icon: Icons.Send,
						path: urls.oneBlastEmail,
					},
					{
						label: "CRM Tools",
						icon: Icons.Sliders,
						path: urls.oneTeamList,
					},
					user.website && {
						label: "Website",
						className: "ihf-website",
						icon: Icons.Sidebar,
						path: (!/^https?:\/\//i.test(user.website) ? "http://" + user.website : user.website),
						target: "_blank",
					},
					{
						label: "Calendar",
						icon: Icons.Calendar,
						path: urls.oneCalendar,
						target: "_blank",
					},
				] as MenuItem[];
			}
		}
		items = items.filter(item => !!item);
		return items;
	}
}

export const TopMenu = Mui.withStyles(styles)(
	connect(mapStateToProps)(Router.withRouter(Component))
);
