class Type {

	public static AVATAR = new Type("avatar", "Team Member Photo");
	public static LABEL = new Type("label", "Name");
	public static ID = new Type("id", "ID");
	public static ROLE = new Type("role", "User Role");
	public static CREATED_ON = new Type("createdOn", "Created");
	public static LAST_LOGIN = new Type("lastLogin", "Last Login");
	public static ACTION = new Type("action", "Action");
	public static BILLABLE_USER = new Type("billableUser", "Agent/Lead Owner");
	public static LEADS = new Type("leads", "Leads");
	public static GEOGRAPHIC_ROUTING = new Type("geographicRouting", "Geographic Routing");
	public static ROUND_ROBIN = new Type("roundRobin", "Round Robin");
	

	private constructor(
		public readonly id: string,
		public readonly label: string,
	) {
	}

	public get avatar() {
		return this === Type.AVATAR;
	}

	public get lastLogin() {
		return this === Type.LAST_LOGIN;
	}

	public static values(): Type[] {
		return Object.values(Type);
	}

	public static getById(id: string) {
		return this.values().find(value => value.id === id);
	}

}

export { Type as AgentColumnType };
