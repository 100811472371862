import React from 'react';
import * as Mui from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from 'redux/store';
import { AgentBoard } from "model/agent-board";
import { Agent } from "model/agent";
import { getBoardsForUser } from "redux/selector";
import { Row } from "../../row";
import { FeatherIcon } from "component/shared/feather-icon";
import * as Icons from "react-feather";
import { Select } from "component/shared/select";

interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>
	{}

interface OwnProps {
	agent: Agent;
	agentBoards: AgentBoard[];
	onChange: (agentBoards: AgentBoard[]) => void;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		
		boards: getBoardsForUser(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{},
		dispatch
	);
};

class Component extends React.Component<Props> {

	render() {
		const { agentBoards, agent, boards, onChange } = this.props;
		return (
			<Mui.Grid container direction="column">
				{[...agentBoards.filter((agentBoard) => agentBoard.agentId).map((agentBoard, index) => {
					return (
						<Mui.Grid item xs={12} key={index}>
							<Row action={
								<Mui.IconButton
									onClick={() => {
										agentBoard.agentId = 0;
										onChange(agentBoards);
									}}
									size="small"
								>
									<FeatherIcon>
										<Icons.Trash />
									</FeatherIcon>
								</Mui.IconButton>
							}>
								<Mui.Grid container spacing={1}>
									<Mui.Grid item xs={6}>
										<Select
											fullWidth
											labelExtractor={(board) => board && board.shortName ? board.shortName : "Error - Select an MLS"}
											margin="dense"
											options={boards.sort((a, b) =>
												a.shortName > b.shortName ? 1 : a.shortName < b.shortName ? -1 : 0
											)}
											optionRenderer={(board) => (
												<Mui.ListItemText primary={board && board.shortName ? board.shortName : "Error - Select an MLS"} />
											)}
											onChange={(boardId) => {
												agentBoard.boardId = boardId;
												onChange(agentBoards);	
											}}
											placeholder="Select an MLS"
											value={agentBoard.boardId}
											valueExtractor={(board) => board.id}
											variant="outlined"
										/>
									</Mui.Grid>
									<Mui.Grid item xs={6}>
										<Mui.TextField
											fullWidth
											margin="dense"
											onChange={(event) => {
												agentBoard.agentCode = event.target.value;
												onChange(agentBoards);
											}}
											placeholder="Agent ID"
											value={agentBoard.agentCode}
											variant="outlined"
										/>
									</Mui.Grid>
								</Mui.Grid>
							</Row>
						</Mui.Grid>
					)
				})]}
				<Mui.Grid item xs={12}>
					<Mui.Button
						onClick={() => {
							agentBoards.push({id: 0, boardId: 0, agentCode: "", agentId: agent.id});
							onChange(agentBoards);
						}}
						size="small"
						startIcon={<Icons.PlusCircle />}
					>
						Add MLS Id
					</Mui.Button>
				</Mui.Grid>
			</Mui.Grid>
		);
	}
}

export const MlsSelector = connect(mapStateToProps, mapDispatchToProps)(Component);