import React from "react";
import * as Mui from "@material-ui/core";
import { SingleValue, MultiValue, BaseProps } from "../types";

interface Props extends BaseProps {
	disabledOptions?: SingleValue[];
}

class Component extends React.Component<Props> {

	public render() {
		const { placeholder, multiple, variant, margin, fullWidth, label, required, disabled, disabledOptions } = this.props;
		const { value, options, valueExtractor, labelExtractor, onChange } = this.props;
		return (
			<Mui.FormControl
				variant={variant}
				margin={margin}
				fullWidth={fullWidth}
				required={required}
				disabled={disabled}
			>
				{label &&
					<Mui.InputLabel>{label}</Mui.InputLabel>
				}
				<Mui.Select
					native
					value={value}
					placeholder={placeholder}
					multiple={multiple}
					onChange={(event) => {
						const { options } = event.target as HTMLSelectElement;
						const values: MultiValue = [];
						for (let i = 0; i < options.length; i++) {
							const option = options[i];
							if (option.selected) {
								const value = this.castValue(option.value);
								(values as SingleValue[]).push(value);
							}
						}
						if (multiple) {
							onChange(values);
						} else {
							onChange(values[0]);
						}
					}}
				>
					{!multiple &&
						<option>
							{placeholder}
						</option>
					}
					{options.map((option) => {
						const value = valueExtractor(option);
						const label = labelExtractor(option);
						const disabled = disabledOptions && disabledOptions.includes(value);
						return (
							<option key={value as string | number} value={value as string | number} disabled={disabled}>
								{label}
							</option>
						);
					})}
				</Mui.Select>
			</Mui.FormControl>
		);
	}

	private castValue(value: string) {
		return this.isNumericValue() ? parseInt(value) : value;
	}

	private isNumericValue() {
		const { options, valueExtractor } = this.props;
		const option = options[0];
		return typeof valueExtractor(option) === "number";
	}
	
}

export const NativeSelect = Component;