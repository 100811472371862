import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		loadingContainer: {
			height: "100%",
		},
		typeSelect: {
			width: 250
		},
		listingContainer: {
			position: "relative",
		},
		saveError: {
			position: "absolute",
			bottom: "100%",
			left: "0",
			width: "100%",
			whiteSpace: "nowrap"
		}
	});
};
