import { arrayToById } from "redux/normalize";
import * as api from "api/api";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";
import { NeighborhoodResource } from "api/resource/neighborhood";

export interface NeighborhoodsState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: NeighborhoodResource;
	};
};

const initialState: NeighborhoodsState = {
	byId: {},
};

const fetchNeighborhoods = createAsyncThunk("neighborhoods/fetchNeighborhoods", async (payload, thunkAPI) => {
	thunkAPI.dispatch(neighborhoodsLoading());
	const result = api.getNeighborhoods();
	result
		.then((neighborhoods) => thunkAPI.dispatch(neighborhoodsLoaded(neighborhoods)))
		.catch(error => thunkAPI.dispatch(neighborhoodsFailed(error)));
	return result;
});

export const neighborhoodsSlice = createSlice({
	name: "neighborhoods",
	initialState,
	reducers: {
		neighborhoodsLoading: (state) => {
			state.loading = true;
		},
		neighborhoodsLoaded: (state, action: PayloadAction<NeighborhoodResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		neighborhoodsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
}
});

export const { neighborhoodsLoading, neighborhoodsLoaded, neighborhoodsFailed } = neighborhoodsSlice.actions;
export { fetchNeighborhoods };
