import React from "react";
import { RootState } from "redux/store";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Mui from "@material-ui/core";
import { BulkActionTypes } from "type/bulk-action-types";
import { BulkAddTagsDialog } from "./dialogs/bulk-add-tags-dialog";
import { BulkRemoveTagsDialog } from "./dialogs/bulk-remove-tags-dialog";
import { BulkCampaignDialog } from "./dialogs/bulk-campaign-dialog";
import { BulkPipelineStageDialog } from "./dialogs/bulk-pipeline-stage-dialog";
import { BulkExportCSVDialog } from "./dialogs/bulk-export-csv-dialog";
import { BulkAssignToAgentDialog } from "./dialogs/bulk-assign-to-agent";
import { Select } from "component/shared/select";
import { User } from "model/user";
import { BulkAddToMarketDialog } from "./dialogs/bulk-add-to-market-dialog";

const mapStateToProps = (state: RootState) => {
	return {};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({}, dispatch);

interface Props
	extends ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps> {
	leadIds: number[];
	user: User;
}

interface State {
	bulkActionId: number;
}

class Component extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			bulkActionId: 0,
		};
	}

	private onClose = () => {
		this.setState({ bulkActionId: 0 });
	};

	private placeholder = () => {
		const { leadIds } = this.props;
		const total = leadIds.length;
		if (total === 1) {
			return "Edit 1 Lead";
		}
		return `Edit ${total} Leads`;
	};

	private getBulkActions = (user: User): BulkActionTypes[] => {
		return BulkActionTypes.values().filter(a => !a.needBrokerPermission || (user.permissions.broker && user.admin));
	};

	render() {
		const { leadIds, user } = this.props;
		const { bulkActionId } = this.state;
		const total = leadIds.length;
		const bulkActions = this.getBulkActions(user);
		return (
			<>
				{(bulkActionId === BulkActionTypes.ADD_TAGS.id) && (
					<BulkAddTagsDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(bulkActionId === BulkActionTypes.REMOVE_TAGS.id) && (
					<BulkRemoveTagsDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(bulkActionId === BulkActionTypes.UPDATE_STAGE.id) && (
					<BulkPipelineStageDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(bulkActionId === BulkActionTypes.ADD_TO_CAMPAIGN.id) && (
					<BulkCampaignDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(bulkActionId === BulkActionTypes.EXPORT_TO_CSV.id) && (
					<BulkExportCSVDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(bulkActionId === BulkActionTypes.ADD_TO_MARKET.id) && (
					<BulkAddToMarketDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				{(user && user.permissions.broker && user.admin && (bulkActionId === BulkActionTypes.ASSIGN_TO_AGENT.id)) && (
					<BulkAssignToAgentDialog
						onClose={() => this.onClose()}
						leadIds={leadIds}
					/>
				)}
				<Select
					disabled={!total}
					placeholder={this.placeholder()}
					value={bulkActionId}
					options={bulkActions}
					valueExtractor={(type) => type.id}
					labelExtractor={(type) => type.label}
					numberVisible={bulkActions.length}
					optionRenderer={(type) => (
						<Mui.ListItemText key={leadIds.length} primary={type.label} />
					)}
					onChange={(bulkActionId) => {
						this.setState({ bulkActionId });
					}}
				/>
			</>
		);
	}
}

export const BulkActions = connect(
	mapStateToProps,
	mapDispatchToProps
)(Component);
