import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		helperText: {
			color: "red",
			margin: 0
		},
		saveRow: {
			position: "sticky", 
			top: 64, 
			zIndex: 1000, 
			backgroundColor: theme.palette.background.default
		},
		formControl: {
			minWidth: 450
		},
		typeSelect: {
			width: 250
		}
	});
};