import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import * as Router from "react-router-dom";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { FullPageCard } from "component/shared/full-page-card";
import { FullWidthDropdown } from "component/shared/full-width-dropdown";
import { DashboardLayout } from "component/layout/dashboard";
import { CustomLabelRadioField } from "component/shared/custom-label-radio-field";
import { YesNoRadioField } from "component/shared/yes-no-radio-field";
import { User } from "model/user";
import { bindActionCreators, Dispatch } from "redux";
import { saveUser } from "redux/slice/authentication";
import { RootState } from "redux/store";
import { PageProps } from "shared/page-props";
import { ActiveResultsSortType } from "type/active-results-sort";
import { EurekaResultsSortType } from "type/eureka-results-sort";
import { FeaturedSortCodeType } from "type/featured-sort-code";
import { ListingResultsDisplayType } from "type/listing-results-display";
import { SoldsOnFeaturedType } from "type/solds-on-featured";
import { FeaturedPendingResultsType } from "type/featured-pending-results";
import { LimitSoldResultsByMonthsType } from "type/limit-sold-results-by-months";
import { PhotoUploadDialogButton } from "component/shared/photo-upload-dialog-button";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { styles } from "./style";

interface Props
extends PageProps, Mui.WithStyles<typeof styles>,
		Router.RouteComponentProps,
		ReturnType<typeof mapStateToProps>,
		ReturnType<typeof mapDispatchToProps> {
	user: User,
	label: string,
}

interface State {
	activeResultsSort: ActiveResultsSortType;
	automatedFeaturedSolds: boolean;
	displayMapGridResults: boolean;
	displayMapListResults: boolean;
	eurekaResultsSort: EurekaResultsSortType;
	featuredSortCode: FeaturedSortCodeType;
	hideSoldSearch: boolean;
	listingResultsDisplay: ListingResultsDisplayType;
	limitSoldResultsByMonths: LimitSoldResultsByMonthsType;
	saving: boolean;
	showClientListingsFirst: boolean;
	featuredPendingResults: FeaturedPendingResultsType;
	soldsOnFeatured: boolean;
	showSupplementalPropertiesOnFeatured: boolean;
	smallLogoImageSource: "upload" | "url";
	smallLogoUrl: string;
	resultsPerPage: number;
	openSnackbar: boolean;
	snackbarMessage: string;
	snackbarSeverity?: Color;
}

const mapStateToProps = (state: RootState) => {
	return {
		authLoading: state.authentication.loading,
		authError: state.authentication.error,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			saveUser,
		},
		dispatch
	);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...props.user,
			saving: false,
			smallLogoImageSource: "url",
			resultsPerPage: props.user.resultsPerPage ? props.user.resultsPerPage : 24,
			openSnackbar: false,
			snackbarMessage: "",
		};
	}
	
	public save() {
		const { saveUser } = this.props;
		const {
			activeResultsSort,
			eurekaResultsSort,
			featuredSortCode,
			listingResultsDisplay,
			limitSoldResultsByMonths,
			featuredPendingResults,
		} = this.state;
		const user = {
			...this.state,
			activeResultsSortId: activeResultsSort.id,
			eurekaResultsSortId: eurekaResultsSort.id,
			featuredSortCodeId: featuredSortCode.id,
			listingResultsDisplayId: listingResultsDisplay.id,
			limitSoldResultsByMonthsId: limitSoldResultsByMonths.id,
			featuredPendingResultsId: featuredPendingResults.id,
			resultsPerPage: this.state.resultsPerPage,
		};
		saveUser({user});
		this.setState({ saving: true });
	}

	public render() {
		const { authError, authLoading, user, classes } = this.props;
		const eurekaSearch = user.permissions.eurekaSearch && user.eurekaEnabled;
		const {
			activeResultsSort,
			automatedFeaturedSolds,
			displayMapGridResults,
			displayMapListResults,
			eurekaResultsSort,
			featuredSortCode,
			hideSoldSearch,
			listingResultsDisplay,
			limitSoldResultsByMonths,
			saving,
			showClientListingsFirst,
			featuredPendingResults,
			soldsOnFeatured,
			showSupplementalPropertiesOnFeatured,
			smallLogoImageSource,
			smallLogoUrl,
			openSnackbar,
			snackbarMessage,
			snackbarSeverity,
		} = this.state;
		
		const title = "Results & Featured Settings";
		return (
			<DashboardLayout
				permitted={user && user.permissions.listingResults}
				title={title}
				header={
					<Mui.Typography variant="h1">
						<FeatherIcon>
							<Icons.Settings />
						</FeatherIcon>
						{title}
					</Mui.Typography>
				}
			>
				<FullPageCard>
					<Mui.Grid container direction="column" spacing={3}>
						{!user.kestrelVersion.all && (
							<Mui.Grid item>
								<CustomLabelRadioField
									label="Display Listing Results As"
									onChange={(value) => {
										this.setState({
											listingResultsDisplay:
												ListingResultsDisplayType.getByIdOrDefault(value),
										});
									}}
									options={ListingResultsDisplayType.values().map((option) => ({
										label: option.label,
										value: option.id,
									}))}
									row
									value={listingResultsDisplay.id}
								/>
							</Mui.Grid>
						)}
						{ user.kestrelVersion.all && eurekaSearch ? ( 				
							<Mui.Grid item xs>
								<Mui.Grid container direction="row" spacing={2}>
									<Mui.Grid item xs>
										<Mui.Grid container spacing={3}>
											<Mui.Grid item xs={8}>
												<Mui.Typography className={classes.label}> Search Results View </Mui.Typography>
											</Mui.Grid>
											<Mui.Grid item xs={4}>
												<Mui.RadioGroup row={true} onChange={(event) =>
													listingResultsDisplay.grid
														? this.setState({
																displayMapGridResults: event.target.value === 'true',
															})
														: this.setState({															
																displayMapListResults: event.target.value === 'true',
															}) 
												}		
												value={
													listingResultsDisplay.grid
														? displayMapGridResults
														: displayMapListResults
												}
												defaultChecked={true}>
													<Mui.FormControlLabel
														label="Map View (Default)"
														control={<Mui.Radio />}
														value={true} 
														
														/>
													<Mui.FormControlLabel
														label="List View"
														control={<Mui.Radio />}
														value={false} 
														
														/>
												</Mui.RadioGroup>
											</Mui.Grid>
										</Mui.Grid>
									</Mui.Grid>
									
									<Mui.Grid item xs>
										<Mui.Typography>
											<strong>Note:</strong>
											{" "}
											Displays either a Map view which includes listings or a List view without the map visible.
										</Mui.Typography>
									</Mui.Grid>
									
								</Mui.Grid>
							</Mui.Grid>
						) : (
							<Mui.Grid item>
								<YesNoRadioField
									label="Display Map"
									onChange={(value) =>
										listingResultsDisplay.grid
											? this.setState({
													displayMapGridResults: value,
												})
											: this.setState({
													displayMapListResults: value,
												})
									}
									onOff
									value={
										listingResultsDisplay.grid
											? displayMapGridResults
											: displayMapListResults
									}
								/>
							</Mui.Grid>
						)}
						
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Show Sold in Last"
								onChange={(value) =>
									this.setState({
										limitSoldResultsByMonths:
											LimitSoldResultsByMonthsType.getByIdOrDefault(value),
									})
								}
								value={limitSoldResultsByMonths.id}
								sidebarNote={
									<>
										(Only applies to Search Results and not to
										Featured Listings.)
									</>
								}
							>
								{LimitSoldResultsByMonthsType.values().map((value) => (
									<Mui.MenuItem key={value.id} value={value.id}>
										{value.label}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Hide Sold Search Results"
								onChange={(value) =>
									this.setState({
										hideSoldSearch: value,
									})
								}
								onOff
								value={hideSoldSearch}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Automated Featured Sold Listings"
								onOff
								value={automatedFeaturedSolds}
								default={true}
								sidebarNote={
									<>
										(Uses MLS data to display off-marketing listings on your site. Only
										listings currently searchable on your site will display. Will also
										display buyer’s agent listings and co-listings.)
									</>
								}
								onChange={(value) =>
									this.setState({
										automatedFeaturedSolds: value,
									})
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Show Sold Properties"
								onChange={(value) =>
									this.setState({
										soldsOnFeatured: !!value,
									})
								}
								value={soldsOnFeatured ? 1 : 0}
							>
								{SoldsOnFeaturedType.values().map((value) => (
									<Mui.MenuItem key={value.label} value={value.id ? 1 : 0}>
										{value.label}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<FullWidthDropdown<number>
								label="Show Pending Properties"
								onChange={(value) =>
									this.setState({
										featuredPendingResults:
											FeaturedPendingResultsType.getByIdOrDefault(value),
									})
								}
								value={featuredPendingResults.id}
							>
								{FeaturedPendingResultsType.values().map((value) => (
									<Mui.MenuItem key={value.id} value={value.id}>
										{value.label}
									</Mui.MenuItem>
								))}
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item>
							<YesNoRadioField
								label="Show Supplemental Properties on Featured Listings Page"
								onOff
								value={showSupplementalPropertiesOnFeatured}
								default={true}
								onChange={(value) =>
									this.setState({
										showSupplementalPropertiesOnFeatured: value,
									})
								}
							/>
						</Mui.Grid>
						<Mui.Grid item>
							{user.kestrelVersion.all || eurekaSearch ? (
								<FullWidthDropdown<string>
									label="Results Sort"
									onChange={(value) => {
										this.setState({
											eurekaResultsSort: EurekaResultsSortType.getByIdOrDefault(value),
										})
									}}
									value={eurekaResultsSort.id}
								>
									{EurekaResultsSortType.values().map((value) => (
										<Mui.MenuItem key={value.id} value={value.id}>
											{value.label}
										</Mui.MenuItem>
									))}
								</FullWidthDropdown>
							) : (
								<FullWidthDropdown<string>
									label="Active Results Sort"
									onChange={(value) => {
										this.setState({
											activeResultsSort: ActiveResultsSortType.getByIdOrDefault(value),
										})
									}}
									value={activeResultsSort.id}
								>
									{ActiveResultsSortType.values().map((value) => (
										<Mui.MenuItem key={value.id} value={value.id}>
											{value.label}
										</Mui.MenuItem>
									))}
								</FullWidthDropdown>
							)}
						</Mui.Grid>
						{!user.kestrelVersion.all && (
							<Mui.Grid item>
								<FullWidthDropdown<string>
									label="Featured Listings Sort"
									onChange={(value) =>
										this.setState({
											featuredSortCode: FeaturedSortCodeType.getByIdOrDefault(value),
										})
									}
									sidebarNote={
										<>
											(Status sorts in this order: ACTIVE, COMING SOON, PENDING, SOLD.
											Property Type sorts in this order: SINGLE FAMILY RESIDENTIAL, LOTS &
											LAND, MOBILE HOMES, RESIDENTIAL INCOME, COMMERCIAL, RENTAL, FARM.)
										</>
									}
									value={featuredSortCode.id}
								>
									{FeaturedSortCodeType.values().map((value) => (
										<Mui.MenuItem key={value.id} value={value.id}>
											{value.label}
										</Mui.MenuItem>
									))}
								</FullWidthDropdown>
							</Mui.Grid>
						)}
							<Mui.Grid item>
								<YesNoRadioField
									label="Always Show Own Listings first in Search Results?"
									onOff
									value={showClientListingsFirst}
									default={true}
									onChange={(value) =>
										this.setState({
											showClientListingsFirst: value,
										})
									}
								/>
							</Mui.Grid>
						{(!user.permissions.eurekaSearch || !user.eurekaEnabled) &&
							<>
								{!user.kestrelVersion.all &&
									<Mui.Grid item xs={12}>
										<FullWidthDropdown<number>
											label="Results Per Page"
											onChange={(value) => {
												this.setState({resultsPerPage: value})
											}}
											value={this.state.resultsPerPage}
										>
											<Mui.MenuItem value={12}>12</Mui.MenuItem>
											<Mui.MenuItem value={24}>24</Mui.MenuItem>
											<Mui.MenuItem value={36}>36</Mui.MenuItem>
											<Mui.MenuItem value={48}>48</Mui.MenuItem>
										</FullWidthDropdown>
									</Mui.Grid>
								}
							</>
						}
						<Mui.Grid item>
							<FullWidthDropdown<"upload" | "url">
								label="Small Logo for Search Results"
								onChange={(value) =>
									this.setState({
										smallLogoImageSource: value,
									})
								}
								sidebarNote={
									<>
										(Logo is displayed on your own listings on results pages. Maximum
										size is 100 pixels wide by 100 pixels tall.)
									</>
								}
								value={smallLogoImageSource}
							>
								<Mui.MenuItem value="url">Image URL</Mui.MenuItem>
								<Mui.MenuItem value="upload">Upload Image</Mui.MenuItem>
							</FullWidthDropdown>
						</Mui.Grid>
						<Mui.Grid item xs={6}>
							{smallLogoImageSource === "url" && (
								<Mui.TextField
									fullWidth
									inputProps={{ maxLength: 100 }}
									label="Enter Image URL"
									onChange={(event) =>
										this.setState({ smallLogoUrl: event.target.value })
									}
									placeholder="Enter Image URL (include http://)"
									value={smallLogoUrl}
									variant="outlined"
								/>
							)}
							{smallLogoImageSource === "upload" && (
								<PhotoUploadDialogButton
									multiple={false}
									callback={(uploads) => {
										const upload = uploads.find((upload) => upload.url);
										if (upload && upload.url) {
											this.setState({
												smallLogoImageSource: "url",
												smallLogoUrl: upload ? upload.url : "",
												openSnackbar: true,
												snackbarMessage: "Upload Successful",
												snackbarSeverity: "success",
											})
										} else {
											this.setState({
												openSnackbar: true,
												snackbarMessage: "Error" ,
												snackbarSeverity: "error",
											})
										}
}
									}
									label="Upload Image"
									style={{ marginBottom: 4, marginTop: 8 }}
									resourceType="image"
								/>
							)}
						</Mui.Grid>
						<Mui.Grid item>
							<Mui.Grid container spacing={1} style={{ marginTop: 24 }}>
								<Mui.Grid item>
									<Mui.Button
										color="secondary"
										disabled={saving}
										onClick={() => this.save()}
										style={{ marginRight: 32 }}
										variant="contained"
									>
										Save Changes
									</Mui.Button>
									<Mui.Button
										onClick={() =>
											this.setState({
												saving: this.state.saving,
											})
										}
										variant="contained"
									>
										Cancel
									</Mui.Button>
								</Mui.Grid>
							</Mui.Grid>
						</Mui.Grid>
					</Mui.Grid>
					<Mui.Snackbar
						open={saving && !authLoading && !authError}
						message="Settings saved"
						autoHideDuration={6000}
						onClose={() => this.setState({ saving: false })}
						action={
							<Mui.IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={() => this.setState({ saving: false })}
							>
								<Icons.X fontSize="small" />
							</Mui.IconButton>
						}
					>
						<MuiAlert severity="info">
							<Mui.Typography>Settings saved</Mui.Typography>
						</MuiAlert>
					</Mui.Snackbar>
				</FullPageCard>
				<Mui.Snackbar
					action={
						<Mui.IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => this.setState({
								openSnackbar: false,
							})}
						>
							<Icons.Link fontSize="small" />
						</Mui.IconButton>
					}
					autoHideDuration={6000}
					onClose={() => this.setState({
						openSnackbar: false,
					})}
					open={openSnackbar}
				>
					<MuiAlert severity={snackbarSeverity}>
						<Mui.Typography>{snackbarMessage}</Mui.Typography>
					</MuiAlert>
				</Mui.Snackbar>
			</DashboardLayout>
		);
	}
}

export const SettingsListingResultsPage = Mui.withStyles(styles)(
	connect( mapStateToProps, mapDispatchToProps)(Component)
);
