import React from "react";
import * as Mui from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import { styles } from "./style";
import { GeographicRoute } from "model/geographic-route";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getPostalCodes } from "redux/selector";
import { deepCompare } from "shared/deep-compare";


interface Props extends OwnProps,
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {}

interface OwnProps {
	rowEdit: number; 
	row: number;
	geographicRoutes: GeographicRoute[];
	disabledOptions: GeographicRoute[];
	onCreate: (postalCodes: string) => void;
	onDelete: (geographicRoute: GeographicRoute) => void;
	onToggle: (index: number) => void;
}

interface State {
	selected: string[];
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
	return {
		postalCodes: getPostalCodes(state),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

class Component extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			selected: this.props.geographicRoutes.map((route => route.postalCode)),
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!deepCompare(prevProps.geographicRoutes, this.props.geographicRoutes)) {
			this.setState({selected: this.props.geographicRoutes.map((route => route.postalCode))});
		}
	}

	private toggle() {
		const { rowEdit, row } = this.props;
		this.props.onToggle(rowEdit === row ? -1 : row);
	}

	render() {
		const { 
			rowEdit, 
			row, 
			geographicRoutes, 
			disabledOptions, 
			postalCodes, 
			onDelete, 
			onCreate, 
			onToggle, 
			classes
		} = this.props;
		const { selected } = this.state;
		return (
			<>
				{rowEdit === row ? (
					<Autocomplete
						multiple
						disableClearable
						id="postal-code"
						getOptionDisabled={(option) => option ? disabledOptions.map((option) => option.postalCode).includes(option) : false}
						options={postalCodes.map(postalCode => postalCode?.postalCode)}
						value={selected}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Mui.Chip
									key={index}
									{...getTagProps({ index })}
									variant="outlined"
									label={option}
									onDelete={() => onDelete(geographicRoutes[index])}
								/>
							))
						}
						renderInput={(params) => (
							<Mui.TextField
								{...params}
								label="Postal Codes"
								placeholder="Add..."
								className={classes.textField}
								variant="outlined"
								onKeyUpCapture={(event) => {
									if (event.key === "Escape") {
										onToggle(-1);
									}
								}}
								onClick={(event) => {
									event.stopPropagation();
								}}
							/>
						)}
						onChange={(event, values) => {
							if (values.length > selected.length) {
								const added = values.find((value) => value && !selected.includes(value));
								if (added && !geographicRoutes.some((geographicRoute) => geographicRoute.postalCode === added)) {
									onCreate(added);
								}
							}
							if (values.length < selected.length) {
								const removed = selected.find((selectedValue) => !values.includes(selectedValue));
								const route = geographicRoutes.find((geographicRoute) => geographicRoute.postalCode === removed);
								route && onDelete(route);
							}
							this.setState({ selected: values as string[] });
						}}
					/>
				) : (
					<div 
						className={classes.chipContainer}
						onClick={() => this.toggle()}
					>
						{geographicRoutes.map((geographicRoute) => (
							<Mui.Chip
								variant="outlined"
								label={geographicRoute.postalCode}
								onDelete={() => onDelete(geographicRoute)}
								className={classes.chip}
							/>
						))}
					</div>
				)}
			</>
		);
	}
}

export const Routes = Mui.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Component));