import { User } from "model/user";
import { numberedDashedDateFormatter } from "shared/date-formatter";

interface AppcuesSettings{
	enableURLDetection?: boolean;
}



interface Appcues {
	identify: (userId: string, properties?: object) => void;
	group: (groupId: string, properties?: object) => void;
	anonymous: () => void;
	page: () => void;
	track: (eventName: string, eventProperties: object) => void;
	show: (contentId: string) => void;
	clearShow: () => void;
	on: (eventName: string, callbackFn: (event: AppcuesEvent) => void, context?: object) => void;
	off: (eventName: string) => void;
	once: this["on"];
	reset: () => void;
	debug: (enable?: boolean) => void;
}

interface AppcuesEvent {

}

declare global {
	interface Window {
		AppcuesSettings: AppcuesSettings,
		Appcues?: Appcues;
	}
}

const key = "113252";

let loaded = false;

const load = () => {
	if (loaded) {
		return Promise.resolve();
	}
	loaded = true;
	return new Promise<void>((resolve, reject) => {
		window.AppcuesSettings = {
			enableURLDetection: true,
		};
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = `https://fast.appcues.com/${key}.js`;
		script.addEventListener("load", () => resolve());
		script.addEventListener("error", () => reject());
		document.head.appendChild(script);
	});
}

export const identify = (user: User, leadsTotal: number, marketsTotal: number) => {
	
	if (user.reseller.direct) {
		load().then(() => {
			if (window.Appcues) {
				 //window.Appcues.debug();
				 //window.Appcues.on("all", console.log);
				window.Appcues.identify(user.clientId.toString(), {
					user_name: user.name,
					account_id: user.clientId,
					client_phone : user.clientCellPhoneNumber,
					texting_on: user.textMessageOptIn,
					version: user.kestrelVersion.id,
					account_name: user.name,
					user_email: user.email,
					websitePlatform: user.websitePlatform.label,
					signup_date: numberedDashedDateFormatter(user.contractStart),
					package_id: user.userPackage ? user.userPackage.packageId: null,
					package_name: user.userPackage ? user.userPackage.packageName: null,
					optima_express: (!!user.permissions.plugin && !user.permissions.omnipress),
					omnipress: !!user.permissions.omnipress,
					agent_crm: !!user.permissions.crm,
					eureka: !!user.permissions.eurekaSearch, 
					marketing_automation: !!user.permissions.campaigns,
					market_boost: !!user.permissions.report,
					trial: !!user.trial,
					annual: !!user.annual,
					account_paid_date: user.accountPaidDate ? numberedDashedDateFormatter(user.accountPaidDate): null,
					number_of_boards: user.boardIds ? user.boardIds.length: null,
					board_ids: user.boardIds? user.boardIds.toString(): null,
					number_of_leads: leadsTotal,
					number_of_markets: marketsTotal,
					broker: !!user.permissions.broker,
				});
			}
		});
	}
}

export const reset = () => {
	if (window.Appcues) {
		window.Appcues.reset();
	}
}