import { arrayToByForeignId } from "redux/normalize";
import * as api from "api/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";
import { loginReset } from "redux/slice/authentication";

export interface EmailClicksState {
    loading?: boolean;
    error?: string | null;
    byId: {
        [key: number]: EmailClickState;
    };
    byLeadId: {
        [key: number]: number[];
    };
    bySavedSearchId: {
        [key: number]: number[];
    };
    byMarketReportId: {
        [key: number]: number[];
    };
};

// we need this state because were casting the listing to a string & in the resource its the listingResource object
export interface EmailClickState {
    id: number;
    leadId: number;
    savedSearchId?: number;
    marketReportId?: number;
    clickedOn: string;
    listing?: string;
};

const initialState: EmailClicksState = {
    byId: {},
    byLeadId: {},
    bySavedSearchId: {},
    byMarketReportId: {},
};

const fetchEmailClicks = createAsyncThunk("emailClicks/fetchEmailClicks", async (payload: {leadId: number}, thunkAPI) => {
    thunkAPI.dispatch(emailClicksLoading());
    const result = api.getEmailClicks(payload.leadId);
    result
        .then((emailClicks) => thunkAPI.dispatch(emailClicksLoaded(emailClicks)))
        .catch(error => thunkAPI.dispatch(emailClicksFailed(error)));
    return result;
});

export const emailClicksSlice = createSlice({
    name: "emailClicks",
    initialState,
    reducers: {
        emailClicksLoading: (state) => {
            state.loading = true;
        },
        emailClicksLoaded: (state, action: PayloadAction<any[]>) => {
            const listing = new schema.Entity("listings");
            const emailClick = new schema.Entity("emailClicks", {
                listing,
            });
            const emailClicks = new schema.Array(emailClick);
            const normalized = normalize(action.payload, emailClicks);
            const byId = normalized.entities.emailClicks || {};
            const byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
            const bySavedSearchId = arrayToByForeignId(action.payload, "id", "savedSearchId");
            const byMarketReportId = arrayToByForeignId(action.payload, "id", "marketReportId");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
			state.byLeadId = byLeadId;
			state.bySavedSearchId = bySavedSearchId;
			state.byMarketReportId = byMarketReportId;
        },
        emailClicksFailed: (state, action: PayloadAction<Error>) => {
            state.loading = false;
            state.error = action.payload.message;
        },
        default: (state) => {
            return state;
        }
    },
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
	},
});

export const { emailClicksLoading, emailClicksLoaded, emailClicksFailed } = emailClicksSlice.actions;
export { fetchEmailClicks };
