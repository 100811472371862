import { arrayToById, objectToById, arrayToByForeignId, objectToByForeignId, mergeByForeignId } from "redux/normalize";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "api/api";
import { loginReset } from "redux/slice/authentication";
import { LeadPhone, NewLeadPhone } from "model/lead-phone";
import { LeadPhoneResource } from "api/resource/lead-phone";

export interface LeadPhonesState {
	loading?: boolean;
	error?: string | null;
	byId: {
		[key: number]: LeadPhoneResource;
	};
	byLeadId: {
		[key: number]: number[];
	};
};

const initialState: LeadPhonesState = {
	byId: {},
	byLeadId: {},
};

const fetchLeadPhones = createAsyncThunk("leadPhones/fetchLeadPhones", async (payload, thunkAPI) => {
	thunkAPI.dispatch(leadPhonesLoading());
	const result = api.getLeadPhones();
	result
		.then((leadPhones) => thunkAPI.dispatch(leadPhonesLoaded(leadPhones)))
		.catch(error => thunkAPI.dispatch(leadPhonesFailed(error)));
	return result;
});

const createLeadPhone = createAsyncThunk("leadPhones/createLeadPhone", async (payload: { leadPhone: NewLeadPhone }, thunkAPI) => {
	thunkAPI.dispatch(leadPhonesLoading());
	const result = api.createLeadPhone(payload.leadPhone);
	result
		.then((leadPhone) => thunkAPI.dispatch(leadPhoneLoaded(leadPhone)))
		.catch(error => thunkAPI.dispatch(leadPhonesFailed(error)));
	return result;
});

const updateLeadPhone = createAsyncThunk("leadPhones/updateLeadPhone", async (payload: { leadPhone: LeadPhone }, thunkAPI) => {
	thunkAPI.dispatch(leadPhonesLoading());
	const result = api.updateLeadPhone(payload.leadPhone);
	result
		.then((leadPhone) => thunkAPI.dispatch(leadPhoneLoaded(leadPhone)))
		.catch(error => thunkAPI.dispatch(leadPhonesFailed(error)));
	return result;
});

export const leadPhonesSlice = createSlice({
	name: "leadPhones",
	initialState,
	reducers: {
		leadPhonesLoading: (state) => {
			state.loading = true;
		},
		leadPhonesLoaded: (state, action: PayloadAction<any[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
			state.byLeadId = arrayToByForeignId(action.payload, "id", "leadId");
		},
		leadPhoneLoaded: (state, action: PayloadAction<any>) => {
			const byId = objectToById(action.payload, "id");
			const byLeadId = mergeByForeignId(state.byLeadId, objectToByForeignId(action.payload, "id", "leadId"));
			state.loading = false;
			state.error = null;
			state.byId = { 
				...state.byId, 
				...byId 
			};
			state.byLeadId = byLeadId;
		},
		leadPhonesFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
            return state;
        }
    },
    extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
    }
});

export const { leadPhonesLoading, leadPhonesLoaded, leadPhoneLoaded, leadPhonesFailed } = leadPhonesSlice.actions;
export { fetchLeadPhones, createLeadPhone, updateLeadPhone };