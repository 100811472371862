import React from "react";
import Plot from "react-plotly.js";
import { AggregateStat } from "model/aggregate-stat";

interface Props {
	aggregateStats: AggregateStat[];
	extrapolatedStat: AggregateStat; 
	lastStat: AggregateStat;
	type: string;
}

class Component extends React.Component<Props> {

	public getMonthsValuesTexts(type: string) {
		const { aggregateStats } = this.props;
		const monthsLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		let months = [];
		let values = [];
		let texts: string[] = [];
		let monthIndex;
		if (aggregateStats.length < 12) {
			monthIndex = aggregateStats.length - 1;
		} else {
			monthIndex = 11;
		}
		while (values.length < 12 && aggregateStats[monthIndex]) {
			let month = aggregateStats[monthIndex].dateStamp.month(); 
			months.push(monthsLabels[month]);
			if (type === "viewedListings") {
				values.push(aggregateStats[monthIndex].listingViews);
				texts.push((aggregateStats[monthIndex].listingViews) + " Viewed Listings");
			} else if (type === "leadActions") {
				values.push(aggregateStats[monthIndex].leadActions);
				texts.push((aggregateStats[monthIndex].leadActions) + " Lead Actions");
			} else if (type === "siteSearches") {
				values.push(aggregateStats[monthIndex].siteSearches);
				texts.push((aggregateStats[monthIndex].siteSearches) + " Site Searches");
			}
			monthIndex--;
		}
		return {months, values, texts};
	}
	
	public render() {
		const { extrapolatedStat, lastStat, type } = this.props;
		const monthsValuesTexts = this.getMonthsValuesTexts(type);
		const months = monthsValuesTexts.months;
		const values = monthsValuesTexts.values;
		const texts = monthsValuesTexts.texts;
		let extrapolatedValue;
		if (type === "leadActions") {
			extrapolatedValue = extrapolatedStat.leadActions - lastStat.leadActions;
		} else if (type === "viewedListings") {
			extrapolatedValue = extrapolatedStat.listingViews - lastStat.listingViews;
		} else if (type === "siteSearches") {
			extrapolatedValue = extrapolatedStat.siteSearches - lastStat.siteSearches;
		}
		const extrapolatedMonth = months[months.length - 1];
		const extrapolatedText = "+" + extrapolatedValue + " Predicted";

		return (
			<Plot
				data={[
					{
						type: "bar",
						x: months ? months : [],
						y: values ? values : [],
						text: texts ? texts : [],
						marker: {
							color: "#398ad6",
						},
						hoverinfo: "text",
						hoverlabel: {
							bordercolor: "#cdcdcd",
							font: {
								color: "#ffffff",
							},
						}
					},
					{
						type: "bar",
						x: [
							extrapolatedMonth ? extrapolatedMonth : "",
						],
						y: [
							extrapolatedValue ? extrapolatedValue : 0,
						],
						text: [
							extrapolatedText ? extrapolatedText : "",
						],
						marker: {
							color: "#75d176",
						},
						hoverinfo: "text",
						hoverlabel: {
							bordercolor: "#cdcdcd",
							font: {
								color: "#ffffff",
							},
						}
					},
				]}
				layout={{
					barmode: "stack",
					showlegend: false,
					margin: {
						t: 10,
						b: 20,
						l: 40,
						r: 40,
						pad: 0,
					},
					xaxis: {
						fixedrange: true,
					},
					yaxis: {
						fixedrange: true,
					},
				}}
				config={{
					displayModeBar: false,
					responsive: true,
				}}
			/>
		);
	}

}

export const Chart = Component;