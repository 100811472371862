import { arrayToById, objectToById } from "redux/normalize";
import * as api from "api/api";
import { MarketReport } from "model/market-report";
import { MarketReportResource } from "api/resource/market-report";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { loginReset } from "redux/slice/authentication";

export interface MarketReportsState {
    byId: {
        [key: number]: MarketReportResource;
    },
    loading?: boolean,
    error?: string | null,
};

const initialState: MarketReportsState = {
    byId: {},
};

const fetchMarketReports = createAsyncThunk("marketReports/fetchMarketReports", async (payload, thunkAPI) => {
    thunkAPI.dispatch(reportsLoading());
    const result = api.getMarketReports();
    result
        .then((reports) => thunkAPI.dispatch(reportsLoaded(reports)))
        .catch(error => thunkAPI.dispatch(reportsFailed(error)));
    return result;
});

const updateMarketReport = createAsyncThunk("marketReports/updateMarketReport", async (payload: {marketReport: MarketReport}, thunkAPI) => {
    thunkAPI.dispatch(reportsLoading());
    if (payload.marketReport.market && payload.marketReport.type) {
        const result = api.updateMarketReport(payload.marketReport, payload.marketReport.market.id);
        result
            .then((report) => thunkAPI.dispatch(reportLoaded(report)))
            .catch(error => thunkAPI.dispatch(reportsFailed(error)));
        return result;
    }
});

export const marketReportsSlice = createSlice({
    name: "marketReports",
    initialState,
    reducers: {
        reportsLoading: (state) => {
            state.loading = true;
        },
		reportsLoaded: (state, action: PayloadAction<MarketReportResource[]>) => {
			state.loading = false;
			state.error = null;
			state.byId = arrayToById(action.payload, "id");
		},
		reportLoaded: (state, action: PayloadAction<MarketReportResource>) => {
			const byId = objectToById(action.payload, "id");
			state.loading = false;
			state.error = null;
			state.byId = {
				...state.byId,
				...byId,
			};
		},
		reportsFailed: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},
		default: (state) => {
			return state;
		}
	},
	extraReducers(builder) {
		builder.addCase(loginReset, (state) => {
			return {
				...initialState,
				error: state.error,
			};
		});
}
});

export const { reportsLoading, reportsLoaded, reportLoaded, reportsFailed } = marketReportsSlice.actions;
export { fetchMarketReports, updateMarketReport };