import React from "react";
import confetti from "canvas-confetti";

interface OwnProps {
	length?: number;
	onStop?: () => void;
}

type Props = OwnProps;

class Component extends React.Component<Props> {

	private timeout?: number;

	public componentDidMount() {
		const {
			length = 7000,
		} = this.props;
		this.timeout = window.setInterval(() => {
			confetti({
				startVelocity: 30,
				spread: 360,
				ticks: 60,
				shapes: [
					"square",
					"circle",
				],
				origin: {
					x: Math.random(),
					y: Math.random() - 0.2,
				},
				zIndex: 1,
			});
		}, 250);
		setTimeout(() => {
			this.stop();
		}, length);
	}

	public componentWillUnmount() {
		this.stop();
	}

	private stop() {
		const { onStop } = this.props;
		clearTimeout(this.timeout);
		if (onStop) {
			onStop();
		}
	}

	public render() {
		return null;
	}

}

export const Confetti = Component;
