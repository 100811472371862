import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => {
	return createStyles({
		cardContainer: {
			minWidth: "45.5vw"
		},
		image: {
			height: 200,
		},
		truncatedMessage: {
			display: "inline-block", 
			maxWidth: 500, 
			overflow: "hidden", 
			textOverflow: "ellipsis", 
			whiteSpace: "nowrap", 
			verticalAlign: "top", 
			WebkitLineClamp: 1
		},
		textResponded: {
			paddingBottom: 20,
		},
		card: {
			overflowWrap: "anywhere",
		},
	});
};
